import React, { useEffect, useState } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import GeneralService from "../../service/generalService";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import MyLeadListFilter from "./MyLeadListFilter";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { LEADS_QUERY, RM_LIST, STATUS_QUERY } from '../../service/leads.gql';
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { GET_MASTER_DATA } from "../../queries/sangam/masterdata.gql";
import { DASHBOARD_QUERY } from "../../queries/sangam/dashboard.gql";
import { GET_USER_LIST } from "../../service/role.gql";
import dateFormat from "dateformat";
import Pagination from "../../view/pagination/Pagination";
import Loader from '../../view/elements/PageLoader';
import ActionTypes from "../../store/action/action";
import crypto from "../../config/crypto";
import Modal from "../../view/common/Modal";
import NewLeadCreate from "./NewLeadCreate";
import ReAssignLeadPopup from "./ReAssignLeadPopup";


const ReAssignLeadList = (props) => {
  
  const [reAssignNewLead, setReAssignNewLead] = useState(false);
  const client ="";
  const [activeTab, setActiveTab] = useState("All Leads");
  const [newLead, setNewLead] = useState(false);
  const history = useHistory();
  const updateStateIntoRedux = props.updateStateIntoRedux;
  const [filterParam, setFilterParam] = useState({ page_no: 1, action_type: "lead_id" })
  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [financers, setFinancers] = useState([]);
  const [fulfillments, setFulfillment] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);
  const[masterdata,setMasterData] = useState([]);
  const randomCardColorId = () => Math.floor(Math.random() * 5) + 1;
  const [dashboard, setDashboard] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [assignTo, setAssignTo] = useState([]);

  const updateReduxData = async () => {
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      props.updateStateIntoRedux(ActionTypes.LOGIN_USER, loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    const role = loginResponse?.data?.user_data?.role || null;
    if (role !== "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const togglePopup = () => {
    setNewLead(!newLead);
    if (!newLead) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const REAssignLeadPopup = () => {
    setReAssignNewLead(!reAssignNewLead);
    if (!reAssignNewLead) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    
  };

  const getMasterData = async () => {
    try {
      const response = await executeGraphQLQueryFinex(GET_MASTER_DATA());
  
      if (response && response.data && response.data.masterdata) {
        let result = { ...response.data.masterdata }; // Create a copy to avoid mutating the original response
        
        // Define keys that need processing
        let keyList = [
          'tenure', 'agreement_type', 'profession', 'salary_credit_mode',
          'property_type', 'usage_type', 'loan_type', 'loan_sub_type'
        ];
  
        // Process each key in keyList
        for (let item of keyList) {
          if (result[item] && result[item].length) {
            result[item] = result[item].map(data => ({
              ...data,
              value: +data.id, // Convert id to number
              label: data.label // Maintain the label
            }));
          }
        }  
        // Process bank_list if it exists
        if (result.bank_list && result.bank_list.length) {
          result.bank_list = result.bank_list.map(data => ({
            ...data,
            value: data.id, // Use id as value
            label: data?.banklang?.bank_name || '' // Use bank name or empty string if not available
          }));
        }  
        // Set the processed data in state
        setMasterData(result);
      }
    } catch (error) {
      // Handle any errors that occur during the query
      console.error("Error fetching master data:", error);
      toast.error("Something went wrong, please try again");
    }
  };

  let loan_type = masterdata?.loan_type;
  let loan_sub_type = masterdata?.loan_sub_type;

  const getstatusList = () => {
    executeGraphQLQueryFinex(STATUS_QUERY, client).then((result) => {
      setStatuses(result?.data?.status_list)
      setSubStatusList(result?.data?.sub_status_list)
      setFinancers(result.data?.financier_list)
      setFulfillment(result.data?.fulfillment_type)
    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }

  useEffect(() => {    
updateReduxData();
    getLeadList(filterParam);
    getstatusList();
    getMasterData();
    //getDashboardCount();  
    
  }, []);  
 
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  
  let name = loginUserInfo?.name ? loginUserInfo.name : "";
  let userId = (loginUserInfo && loginUserInfo.user_data) ? loginUserInfo.user_data.user_id : 1;
 
  const onPaginate = (p) => {
    setFilterParam((prev) => ({ ...prev, ['page_no']: p }));
    filterParam.page_no = p;
    getLeadList(filterParam);
  }

  const getLeadList = (filterData = {}) => {
    filterData.user_id = userId;  
    filterData.partner_id = loginUserInfo.user_data.dealer_id;  
    setIsLoading(true)
    if (filterData.search_type) delete filterData.action_type
    if (!filterData.column) filterData.column = "last_updated_date";
    if (!filterData.sort) filterData.sort = "desc";
   // if (USERS_FOR_VIEW_AMBAK_LEADS.includes(UserInfo?.email)) filterData.fulfillment_type = 'ambak';
   executeGraphQLQueryFinex(LEADS_QUERY(filterData)).then((result) => {
      setLeads(result?.data?.leads?.[0]?.lead_list)
      setTotalPage(result?.data?.leads?.[0]?.pagination?.totalpage)
      setIsLoading(false)
    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }

  const handleSearch = (filterData) => {   
    filterData.action_type = filterParam.action_type
    setFilterParam((prev) => ({ ...prev, ...filterData }));
    filterData.page_no = 1;
    setFilterParam((prev) => ({ ...prev, ['page_no']: 1 }));
    getLeadList(filterData);
    //document.getElementById("more-filter-dropdown").classList.remove('show');
  }

  useEffect(() => {
    const fetchData = async () => {
        await getUserList();
    };
    fetchData();
}, []);


const getUserList = async () => {
  // setUserList([]);
   let user_list = [],userlist=[];
   await executeGraphQLQueryFinex(GET_USER_LIST(5,1)).then((response) => {
    
       if (response && response.data && response.data.get_user_list) {
           user_list = response.data.get_user_list?.filter(obj => obj.status === '1');
           user_list && user_list.map((v)=>{
               userlist.push({value:v.user_id,label:v.name,role_id:v.role_assigned_ids})
               return v;
           })
           setUserList(userlist);
       }
   }).catch((error) => {
       console.log(error);
   });  
};

useEffect(() => {
  const fetchAssignToList = async () => {      
    try {
      const response = await GeneralService.subPartnerList(loginUserInfo.user_data.dealer_id);     
      if (response?.data?.status === 200) {               
        setAssignTo(response?.data?.data?.sub_partner_list || []);       
      }
    } catch (error) {
      console.error("Error fetching assignTo list:", error);
    }
  };
  fetchAssignToList();    
}, [loginUserInfo.user_data.dealer_id]);

const formatCurrency = (amount) => {
  const formattedAmount =  new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
  }).format(amount);
  return formattedAmount.replace(/\.00$/, '');
};

const handleSelectAll = (e) => {
  const isChecked = e.target.checked;
  setIsSelectAll(isChecked);

  if (isChecked) {
    const allLeadIds = leads.map((lead) => lead.id);
    setSelectedLeads(allLeadIds);
  } else {
    setSelectedLeads([]);
  }
};

const handleCheckboxChange = (leadId) => {
  setSelectedLeads((prevSelected) => {
    if (prevSelected.includes(leadId)) {
      // If already selected, remove it
      return prevSelected.filter((id) => id !== leadId);
    } else {
      // Otherwise, add it
      return [...prevSelected, leadId];
    }
  });
};



  return (
    <>
      <div className="LeadContainer">
        <div className="myleadcontainer">
          <div className="leftmyleadcontainer">
            <Link to={"/saathi-leads"}>
              <div>
                <img
                  src={require(`../../../src/saathi-web/images/left arrow.svg`)}
                  alt="not"
                /> 
              </div>
            </Link>
            <div className="leadheading">Re Assign Leads</div>
          </div>
          <div className="rightmyleadcontainer">
            <div className="not_assigned_lead">
              <button onClick={REAssignLeadPopup}>Re-assign Leads</button>
            </div>         
           
          </div>
        </div>
        <MyLeadListFilter masterdata = {masterdata} handleSearch={handleSearch} setFilterParam={setFilterParam} />

        <div className="tablecontainer">
          <div className="table-container ">
            <table>
              <thead>
                <tr>
                  <th> 
                    <input
                    type="checkbox"
                    name="select_all"
                    id="select_all"
                    checked={isSelectAll}
                    onChange={handleSelectAll}
                    />
                  </th>
                  <th>Lead ID</th>
                  <th>Name</th>
                  <th>Loan Type</th>
                  <th>Sourced By</th>
                  <th>Assigned</th>
                  <th>Fulfilled By</th>
                  <th>Creadted</th>
                  <th>Updated</th>
                  
                </tr>
              </thead>
              <tbody>
              {isLoading ? <Loader /> : null}
              {leads?.map((lead, index) => {
                        let leadStatus = lead?.status_info?.statuslang?.status_name || ''; 
                        let databaseDate = new Date(lead?.updated_date);
                        let currentDate = new Date();                                                 
                        // Calculate the difference in milliseconds
                        let differenceInMilliseconds = currentDate.getTime() - databaseDate.getTime();
                        // Convert the difference to seconds
                        let differenceInSeconds = differenceInMilliseconds / 1000;
                        // Convert the difference to minutes
                        let differenceInMinutes = differenceInSeconds / 60;
                        // Convert the difference to hours
                        let differenceInHours = differenceInMinutes / 60;                

                  return <React.Fragment key={lead.id}>
                <tr>
                  <td>
                    <div>
                    <input
                    type="checkbox"
                    name={`lead-${lead.id}`}
                    id={`lead-${lead.id}`}
                    checked={selectedLeads.includes(lead.id)}
                    onChange={() => handleCheckboxChange(lead.id)}
                    />
                    </div>
                  </td>
                  <td> {lead?.id}</td>
                  <td>{lead?.customer?.first_name} {lead?.customer?.last_name}</td>
                  <td> {loan_type?.filter(data => data.id == lead?.loan_type)?.map(v => v.label)} {lead?.loan_sub_type ? "-" : ""} {loan_sub_type?.filter(data => data.id == lead?.loan_sub_type)?.map(v => v.label)}</td>
                  <td> {lead?.partner_name || "--"}</td>
                  <td>{lead?.assigned_partner_name || "--"}</td>
                  <td>{lead?.fulfillment_type.toUpperCase()}</td>
                  <td>{dateFormat(lead?.created_date, 'd mmm,yyyy | hh:MM TT')}</td>
                  <td>{dateFormat(lead?.updated_date, 'd mmm,yyyy | hh:MM TT')}</td>
                  
                </tr>
                </React.Fragment>
                })}
              </tbody>
            </table>
            <Pagination pageCount={totalPage} activePage={leads && leads[0] && leads[0].pagination && leads[0].pagination.page_no && 1} onPaginate={onPaginate} />
            
          </div>         
          <Modal isOpen={reAssignNewLead} togglePopup={REAssignLeadPopup} width={800}>
            <ReAssignLeadPopup masterData = {masterdata} loginUserInfo = {loginUserInfo} selectedLeads = {selectedLeads} />
          </Modal>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(ReAssignLeadList)
);

