import React, { useEffect, useState } from "react";
import Select from 'react-select';
import MasterService from "../../service/MasterService";
import { Link, NavLink, Redirect } from "react-router-dom/cjs/react-router-dom.min";
import InputField from "../elements/InputField";
import DateFormate from 'dateformat';
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { SCHEME_LIST, SCHEME_LIST_WITH_FILTERS, FINEX_MASTER_LIST, SCHEME_COPY, GET_SCHEME_FINANCER_GROUPS, SCHEME_PARTNER_LISTING, UPDATE_SCHEME_STATUS } from "../../queries/scheme.gql";
import { toast } from 'react-toastify';
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
// import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");

import secureStorage from './../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Pagination from "../pagination/Pagination";
import MultiSelect from "../../commonselect/MultiSelect.js";


const SchemeListing = (props) => {
    const [partnerData, setPartnerData] = useState({ partner_status: 0, page_no: 1, search_by: "", search_text: "", is_subscribed: 1, scheme_id: props.schemedetailsData?.id });
    const [filterData, setFilterData] = useState({
        fulfillment_type: null,
        expiry_from: null,
        expiry_to: null,
        product_type: [],
        scheme_type: null,
        page_no: 1,
        scheme_name: null,
        approved_status: "0",
        city_ids: [],
        scheme_for: null,
        product_type_ids: [],
        financier_id: []
    });
    const [page_no, setPageNo] = useState(1);
    const [cityAllList, setcityAllList] = useState([]);
    const [selectedCityIds, setSelectedCityIds] = useState([]);
    const [schemeData, setSchemeList] = useState([]);
    const [paginationdata, setPaginations] = useState({});
    const [pageloading, setpageloading] = useState(true)
    const [financers, setFinancers] = useState([]);
    const [productType, setProductType] = useState([]);
    const [financersData, setFinancersData] = useState([]);
    const [fulfillmenttypes, setFulfillmentTypes] = useState([])
    const [selectedRow, setSelectedRow] = useState(null);
    const [rangeList, setRangeList] = useState([]);
    const [partnerListing, setpartnerListing] = useState([])

    const [statusClassname, setStatusClassname] = useState({
        'Pending': 'nav-item active',
        'Rejected': 'nav-item',
        'Approved': 'nav-item',
        // 'blacklist': 'nav-item',
        // 'pending': 'nav-item',
        // 'all': 'nav-item'
    });

    const handleRowClick = (index, scheme_id) => {
        setSelectedRow(selectedRow === index ? null : index);
        GetSchemeRangesList(scheme_id)
        getPartnerListWithFilters(scheme_id)
    };

    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Redirect to={"/login"} />);
        }
    }

    const afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Redirect to={"/"} />);
        }
    }

    const handleStatusClick = (sname, ovalue) => {
        const filterDatanew = { ...filterData };
        if (sname === 'Approved') {
            filterDatanew['approved_status'] = '1';
        } else if (sname === 'Pending') {
            filterDatanew['approved_status'] = '0';
        }else if (sname === 'Rejected') {
            filterDatanew['approved_status'] = '2';
        }
        //  else if (sname === 'all') {
        //     filterDatanew['approved_status'] = "all";
        // }
        setFilterData(filterDatanew);

        if (typeof props.statusClick === 'function') {
            props.statusClick(sname, ovalue);
            setStatusClassname({
                'Pending': 'nav-item',
                'Rejected': 'nav-item',
                'Approved': 'nav-item',
                // 'blacklist': 'nav-item',
                // 'pending': 'nav-item',
                // 'all': 'nav-item active',
                [sname]: 'nav-item active'
            });
        }
        getSchemeListWithFilters(filterDatanew['approved_status'] ,filterDatanew['approved_status'])
    }


    const handleInputarray = (e, key = "") => {
        const filterDatanew = { ...filterData }
        if (key == "scheme_for") {
            setFilterData({ ...filterData, ["scheme_for"]: e.value });
        } else {
            const name = e.target.name;
            const val = e.target.value;
            filterDatanew[name] = val;
            setFilterData(filterDatanew);
        }

    };

    const handleCityOptionChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.id);
        const filterDataNew = { ...filterData }
        filterDataNew['city_ids'] = selectedIds
        setFilterData(filterDataNew)
        setSelectedCityIds(selectedIds);
    };



    const submitFilterForm = (event) => {
        event.preventDefault();
    };

    const resetFilterForm = (event) => {
        window.location.reload(false);
    };

    const getCityList = async () => {
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                if (response.data.status == 200) {
                    setcityAllList(response.data.data.city)
                    localStorage.setItem("citylist", JSON.stringify(response.data.data.city));
                }
            })
            .catch(function (response) {
            });
    }


    const getSchemeListWithFilters = async (status, page_no) => {
        setpageloading(true)
        try {
            const current_approve_status = status ? status : filterData.approved_status
           // console.log("current_approve_status",status)
            const result = await executeGraphQLQuery(SCHEME_LIST_WITH_FILTERS(page_no ? page_no : filterData.page_no, filterData.fulfillment_type, filterData.product_type_ids && filterData.product_type_ids.length > 0 ? filterData.product_type_ids[0] : null, filterData.scheme_type, filterData.expiry_from, filterData.expiry_to, filterData.scheme_name, current_approve_status, filterData.city_ids, filterData.scheme_for, filterData.financier_id && filterData.financier_id.length > 0 ? filterData.financier_id.join(",") : null));

            if (result.data.scheme_list.length == 0) {
                //   toast.success("No records found!");
                setSchemeList([]);
                setPaginations({});
                setpageloading(false)

            }
            if (result.data.scheme_list.length > 0) {

                if (result.data.scheme_list && result.data.scheme_list.length > 0) {
                    setSchemeList(result.data.scheme_list[0].scheme_list);
                    setPaginations(result.data.scheme_list[0].pagination);
                    setpageloading(false)
                }

            }
        } catch (error) {
            toast.error("Failed");
        }
    }
   const searchFilter = async () =>{
       getSchemeListWithFilters()
   }
    const GetSchemeRangesList = async (scheme_id) => {
        try {
            if (scheme_id && scheme_id > 0) {
                const schemeFGDataList = await executeGraphQLQuery(GET_SCHEME_FINANCER_GROUPS(scheme_id))
                if (schemeFGDataList && schemeFGDataList.data && schemeFGDataList.data.schemeFinancerGroupbySchemeId) {
                    const financerGroupListNew = schemeFGDataList.data.schemeFinancerGroupbySchemeId;
                    setRangeList(financerGroupListNew);
                }
            }
        } catch (error) {
            console.error("Error fetching scheme ranges:", error);
        }
    };

    const showFinancers = (financer_id,) => {
        const financerobj = financers.find(o => o.id === financer_id);
        return (
            <div>
                {financerobj && financerobj.name}
            </div>
        )
    }
    const showProductType = (product_type,) => {
        const productName = productType.find(o => o.id === product_type);
        return (
            <div>
                {productName && productName.name}
            </div>
        )
    }

    const getPartnerListWithFilters = async (scheme_id) => {
        try {
            const result = await executeGraphQLMutation(SCHEME_PARTNER_LISTING(scheme_id, partnerData.search_by, partnerData.search_text, partnerData.partner_status, page_no, partnerData.is_subscribed=1));
            if (result.data.partners.length == 0) {
                setpartnerListing([]);
            }
            if (result) {
                if (result.data.partners && result.data.partners.length > 0) {
                    const partners = result.data.partners[0].dealers;
                    setpartnerListing(partners);
                }
            } else {
                setpartnerListing([]);
            }
        } catch (error) {
            toast.error("Failed");
        }
    }

    const handleStatusVerified = async (scheme_id,approved_status_new) => {
        if (!window.confirm("Do you want to update scheme approved status!")) {
            return false
        } else {
            try {
                if (scheme_id && scheme_id > 0) {
                    const result = await executeGraphQLQuery(UPDATE_SCHEME_STATUS(scheme_id,approved_status_new))
                   window.location.reload();
                }
            } catch (error) {
                console.error("Error fetching scheme ranges:", error);
            }
        }
    };


    const copyScheme = async (id) => {
        setpageloading(true)
        if (!window.confirm("Do you want to create a duplicate scheme of this scheme!")) {
            return false
        } else {
            try {
                const result = await executeGraphQLQuery(SCHEME_COPY(id));

                if (result?.data?.copy_scheme?.id) {
                    toast.success(result?.data?.copy_scheme?.msg)
                } else {
                    toast.error(result?.data?.copy_scheme?.msg || "Something went wrong!")
                }

            } catch (error) {
                toast.error("Failed");
            }
            setpageloading(false)
        }

    }

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const producttypedata = []
            const fulfillmenttypeData = []
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const product_type = data.loan_type && data.loan_type.length > 0 ? data.loan_type : []
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
            }
            if (product_type && product_type.length > 0) {
                product_type.map(ptype => {
                    producttypedata.push({ id: ptype.id, name: ptype.label })
                })
                setProductType(producttypedata)
            }
            const fulfillmenttype = data.fulfillment_type && data.fulfillment_type.length > 0 ? data.fulfillment_type : []
            if (fulfillmenttype && fulfillmenttype.length > 0) {
                fulfillmenttype.map(ptype => {
                    fulfillmenttypeData.push({ id: ptype.id, name: ptype.label })
                })
                setFulfillmentTypes(fulfillmenttypeData)
            }
        }

    }
    // const handleFinancersOptionChange = (name, selectedOptions) => {
    //     const rangeFinancersdata = { ...filterData }
    //     const financers = []
    //     const financersids = []
    //     selectedOptions && selectedOptions.length > 0 && selectedOptions.map((selectedData) => {
    //         financers.push({ financer_id: selectedData.id })
    //         financersids.push(selectedData.id)
    //     })
    //     setFilterData({ ...rangeFinancersdata, schemefinancers: financers, financersids: financersids });
    // };

    const handleFinancersOptionChange = (name, e, checked, optionValue, optionLabel, event) => {
        const rangeFinancersdata = { ...filterData }
        const financers = []
        const financersids = []
        // if (Array.isArray(selectedOptions)) {
        //     selectedOptions.forEach(selectedData => {
        //         financers.push({ financer_id: selectedData.id })
        //         financersids.push(selectedData.id)
        //         console.log("selectedOptions",selectedOptions);
        //     });
        // }
        setFilterData({ ...rangeFinancersdata, schemefinancers: financers, financersids: financersids });
    };




    const handleProductTypeOptionChange = (name, selectedOptions) => {
        const rangeFinancersdata = { ...filterData }
        if (name == 'product_type') {
            const product_type = [{ id: selectedOptions.id }]
            const product_type_ids = [selectedOptions.id]
            setFilterData({ ...rangeFinancersdata, product_type: product_type, product_type_ids: product_type_ids });
        }
        if (name == 'employment_type') {
            const employment_type = [{ id: selectedOptions.id }]
            const employment_type_ids = [selectedOptions.id]
            setFilterData({ ...rangeFinancersdata, employment_type: employment_type, employment_type_ids: employment_type_ids });
        }

    };
    const handleFullfilmentType = (name, selectedOptions) => {
        const formDataNew = { ...filterData }
        const fulfillment_type_Array = []
        fulfillment_type_Array.push({ id: selectedOptions.id })
        formDataNew['fulfillment_type'] = selectedOptions.id
        formDataNew['fulfillment_type_Array'] = fulfillment_type_Array
        setFilterData(formDataNew);
    };
    useEffect(() => {
        getCityList();
        getSchemeListWithFilters()
        getFinexMasterData()
        updateReduxData()

    }, []);

    const onPaginate = (pageNumber) => {
        setFilterData({ ...filterData, page_no: pageNumber });
        getSchemeListWithFilters(filterData.approved_status, pageNumber)
    };


    const calculation = [
        { value: "count", label: "Count" },
        { value: "amount", label: "Amount" }
    ]

    const handleChange = async (n, v, isMulti, opt, optl) => {
        switch (n) {

            case 'financier_id':
                let financier_ids = [];
                financier_ids = v.map(obj => { return obj.id });
                setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
                break;

        }
    }

    return (
        <>
            <div className={(pageloading) ? "container-fluid loading" : "container-fluid"}>
                {/* {JSON.stringify(filterData)} */}
                <h1>Listing</h1>
                <div className="search-wrap">
                    {/* {JSON.stringify(schemeData)} */}
                    <form onSubmit={submitFilterForm}>
                        <div className="row">
                            <div className="col-sm-2 col-md-2">
                                <InputField
                                    inputProps={{
                                        id: "scheme_name",
                                        type: "text",
                                        name: "scheme_name",
                                        autocompleate: "off",
                                        label: "Scheme Name",

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>
                            <div className="col-sm-2">

                                <label>Fulfillment Type</label>
                                <Select
                                    id="city_id"
                                    onChange={(e) => handleFullfilmentType('fulfillment_type', e)}
                                    options={fulfillmenttypes}
                                    name="fulfillment_type"
                                    value={fulfillmenttypes && fulfillmenttypes.length > 0 && filterData.fulfillment_type && filterData.fulfillment_type != "" && fulfillmenttypes.filter((items) => items.id == filterData.fulfillment_type)}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}

                                />
                            </div>
                            <div className="col-sm-1 col-md-1">

                                <label>Scheme For</label>
                                <Select
                                    id="scheme_for"
                                    name="scheme_for"
                                    options={calculation}
                                    value={calculation.filter(({ value }) => value === filterData.scheme_for)}
                                    onChange={(e) => handleInputarray(e, 'scheme_for')}

                                >
                                </Select>
                            </div>
                            <div className="col-sm-1 col-md-1">
                                <InputField
                                    inputProps={{
                                        id: "expiry_from",
                                        type: "date",
                                        name: "expiry_from",
                                        autocompleate: "off",
                                        label: ('Start Date'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>
                            <div className="col-sm-1 col-md-1">
                                <InputField
                                    inputProps={{
                                        id: "expiry_to",
                                        type: "date",
                                        name: "expiry_to",
                                        autocompleate: "off",
                                        label: ('End Date'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>

                            <div className="col-sm-2 col-md-2">
                                <label>Banks<span className="required" style={{ color: 'red' }}>*</span></label>
                                {/* <Select
                                    id="financer_id"
                                    onChange={(e) => handleFinancersOptionChange('financer_id', e)}
                                    options={financers}
                                    label={"Select Financers"}
                                    isMulti
                                
                                    name="financer_id"
                                    value={financers && financers.length > 0 && filterData.financersids.length > 0 && financers.filter(({ id }) => filterData.financersids.includes(id))}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                /> */}
                                <MultiSelect
                                    options={financers}
                                    placeholder={"Banks"}
                                    name={'financier_id'}
                                    optionLabel="name"
                                    optionValue="id"
                                    values={filterData.financier_id}
                                    onChange={handleChange}
                                    isMulti
                                />
                            </div>
                            <div className="col-sm-1">
                                <label>Product Type<span className="required" style={{ color: 'red' }}>*</span></label>
                                <Select
                                    id="product_type"
                                    onChange={(e) => handleProductTypeOptionChange('product_type', e)}
                                    options={productType}
                                    label={"Select Product Type"}
                                    name="product_type"
                                    value={productType && productType.length > 0 && filterData.product_type_ids.length > 0 && productType.filter(({ id }) => filterData.product_type_ids.includes(id))}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                />
                            </div>

                            <div className="col-sm-2 col-md-2" style={{ marginTop: 28 }}>
                                <button type="submit" className="btn btn-primary mrg-r10" onClick={searchFilter}>{('Search')}</button>
                                <button type="reset" className="btn btn-reset"
                                    onClick={resetFilterForm}
                                >{('Reset')}</button>
                            </div>

                        </div>
                    </form>
                </div>


                <div className="pad-15">
                    <div className="row">
                        <div className="col-sm-8">
                            <ul className="list-inline nav-tabs">
                                {/* <li className={statusClassname.active} onClick={() => handleStatusClick('active')}>
                                    <div>
                                        {('Active')}<span></span>
                                    </div>
                                </li>
                                <li className={statusClassname.inactive} onClick={() => handleStatusClick('inactive')}>
                                    <div>
                                        {('Inactive')}<span></span>
                                    </div>
                                </li>*/}
                                <li className={filterData.approved_status == '0' ? "nav-item active" : "nav-item"} onClick={() => handleStatusClick('Pending')}>
                                    <div>
                                        {('Pending')}<span></span>
                                    </div>
                                </li>
                                <li className={filterData.approved_status == '1' ? "nav-item active" : "nav-item"} onClick={() => handleStatusClick('Approved')}>
                                    {/* <div>
                                        {('All')}<span>({paginationdata.totalrecords ? paginationdata.totalrecords : 0})</span>
                                    </div> */}
                                    <div>
                                        {('Approved')}
                                    </div>
                                </li>
                                <li className={filterData.approved_status == '2' ? "nav-item active" : "nav-item"} onClick={() => handleStatusClick('Rejected')}>
                                    <div>
                                        {('Rejected')}<span></span>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>
                        <div className="text-right">
                            <NavLink to="/schemes/addscheme" className="btn btn-success">
                                {('Add Scheme')}
                            </NavLink>
                        </div>
                    </div>
                </div>


                <div className="clearfix">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{('ID')}</th>
                                    <th>{('Name')}</th>
                                    <th>{('Fulfillment Type')}</th>
                                    <th>{('Type')}</th>
                                    <th>{('Scheme For')}</th>
                                    <th>{('Start Date')}</th>
                                    <th>{('End Date')}</th>
                                    <th>{('Default')}</th>
                                    <th>{('Financers')}</th>
                                    <th>{('Action')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {schemeData && schemeData.map((scheme, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>{scheme.id}</td>
                                            <td>{scheme.scheme_name}</td>
                                            <td>{scheme.fulfillment_type}</td>
                                            <td>{scheme.scheme_type}</td>
                                            <td>{scheme.scheme_for}</td>
                                            <td>{DateFormate(new Date(scheme.start_date), "dS mmm yyyy")}</td>
                                            <td>{DateFormate(new Date(scheme.end_date), 'dS mmm yyyy')}</td>
                                            <td>{scheme.is_default == '1' ? "Yes" : "No"}</td>
                                            <td>{scheme.is_all_financers == '1' ? "All" : "Selective"}</td>
                                            <td>
                                                <div className="btn-group btn-group-small mrg-r10">
                                                    <div className="btn btn-default">
                                                        <i onClick={() => copyScheme(scheme.id)} className="ic-content_copy icons-normal" title="Copy Duplicate"></i>
                                                    </div>
                                                    {/* <Link to="#" className="btn btn-default" >Copy</Link> */}
                                                </div>

                                                <div className="btn-group btn-group-small mrg-r10">
                                                    <Link to={`/scheme/${scheme.scheme_code}/details`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link>
                                                </div>
                                                <div className="btn-group btn-group-small mrg-r10">
                                                    <div className="btn btn-default" title="Mark as View" onClick={() => handleRowClick(index, scheme.id)}><i className="ic-remove_red_eyevisibility icons-normal"></i></div>
                                                </div>
                                              
                                                <div className="btn-group btn-group-small mrg-r10">
                                                    {scheme.approved_status === 0 ? (
                                                        <div className="btn btn-default" title="Mark as Verified" onClick={() => handleStatusVerified(scheme.id,1)}>
                                                            <i className="ic-check icons-normal"></i>
                                                        </div>
                                                    ) : null}
                                                    {scheme.approved_status === 1 ?(
                                                        <div className="btn btn-default" style={{cursor:"not-allowed"}}>
                                                        <img src={require('../../webroot/images/verified.svg')} alt="verfied" width={24} height={24} />
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {scheme.approved_status === 0 ?(
                                                <div className="btn-group btn-group-small mrg-r10">
                                                    <div className="btn btn-default" title="Mark as Reject" onClick={() => handleStatusVerified(scheme.id,2)}><i className="ic-clearclose icons-normal"></i></div>
                                                </div>
                                                ):null}
                                                


                                            </td>
                                        </tr>
                                        {selectedRow === index ? (
                                            <tr>
                                                <td colSpan={16}>
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ flex: '60%', marginRight: '20px', width: '100%' }}>
                                                            <div style={{ overflow: 'hidden', overflowY: 'auto', height: '200px', width: '100%' }}>
                                                                <table className="table table-bordered" style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="payouttable">Payout Ranges</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {rangeList && rangeList.length > 0 && rangeList.map((scheme, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <tr style={{ width: '100%' }}>
                                                                                    <td style={{ width: '40%' }}>
                                                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                                            {scheme.schemebanks.map((bank, idx) => (
                                                                                                <div key={idx} style={{ marginRight: '10px' }}>
                                                                                                    <span>{showFinancers(bank.financer_id)}</span>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ width: '25%' }}>{showProductType(scheme.product_type)}</td>
                                                                                    <td style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                                                        <div style={{ marginRight: '10px' }}>
                                                                                            {scheme.schemerange.map((range, idx) => (
                                                                                                <div key={idx}>{range.start_range} - {range.end_range}</div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ width: '8%' }}>
                                                                                        <div>
                                                                                            {scheme.schemerange.map((range, idx) => (
                                                                                                <div key={idx}>{range.payout} % </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div style={{ flex: '40%', width: '100%' }}>
                                                            <div style={{ overflow: 'hidden', overflowY: 'auto', height: '200px', width: '100%' }}>
                                                                <table className="table table-bordered" style={{ width: '100%' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="payouttable">Partners</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {partnerListing && partnerListing.length > 0 && partnerListing.map((payout, index) => (
                                                                            <tr key={payout.id}>
                                                                                <td style={{ width: '33%' }}>{payout.id}</td>
                                                                                <td style={{ width: '33%' }}>{payout.first_name}</td>
                                                                                <td style={{ width: '33%' }}>{payout.organization}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : null}

                                    </React.Fragment>

                                ))}

                                {schemeData.length == 0 ?
                                    <tr>
                                        <td colSpan={8}>No scheme found!</td>
                                    </tr> : ""
                                }

                            </tbody>
                            <tr>
                                <td colSpan={7}>
                                    <div>
                                        <Pagination pageCount={paginationdata && paginationdata.totalpage ? paginationdata.totalpage : 0} activePage={filterData.page_no ? filterData.page_no : 1} onPaginate={onPaginate} />
                                    </div>
                                </td>
                                <td colSpan={1}> Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords : 0} </td>
                            </tr>
                        </table>
                    </div>

                </div>

            </div>

        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('ac_manager')(connect(mapStateToProps, mapDispatchToProps)(SchemeListing));

