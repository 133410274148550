import React, { useState } from "react";
import { useHistory } from "react-router-dom";


const LoanOffersCalculator = (props) => {

  const history = useHistory();

  const CibilCheck = () =>{
    history.push("/my-tool-cibil");
  }
  
 
  return (
    <div className="offercalculator">
      <div className="offerloanflex">
        <div className="offercalcard">
          <div>
            <img
              src="https://ambak.storage.googleapis.com/partner/2006/1723186894708.png"
              alt="loan offer"
              width={110}
              height={80}
            />
          </div>
          <div>
            <div className="loanoffercibil">
              Get your Customer Free CIBIL Score & Report instantly.
            </div>
            <button className="loanofferbtn" onClick={CibilCheck}>Check Now</button>
          </div>
        </div>
        <div className="offercalcard">
          <div style={{width:"15%"}}>
            <img
              src="https://ambak.storage.googleapis.com/partner/2006/1723191634903.png"
              alt="loan offer"
              width={80}
              height={80}
            />
          </div>
          <div>
            <div className="loanoffercibil">
            Check out best home  loan offers with Ambak’s free offer calculator
            </div>
            <button
              className="loanofferbtn"
              onClick={() => props.setCheckOfferLoan(true)}
            >
              Check Now
            </button>
          </div>
        </div>
      </div>

      <div className="offerloanflex">
        <div className="offerlistcard">
          <img
            src="https://ambak.storage.googleapis.com/partner/2006/1723192167400.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">APF Search Engine</div>

          <button className="offercommonbtn"> Check Now</button>
        </div>

        <div className="offerlistcard">
          <img
            src="https://ambak.storage.googleapis.com/partner/2006/1723193405707.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">EMI Calculator</div>

          <button className="offercommonbtn"> Check Now</button>
        </div>

        <div className="offerlistcard">
          <img
            src="https://ambak.storage.googleapis.com/partner/2006/1723193491801.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">Payout Calculator</div>

          <button className="offercommonbtn"> Check Now</button>
        </div>

        <div className="offerlistcard">
          <img
            src="https://ambak.storage.googleapis.com/partner/2006/1723193561304.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">Doc List Generator</div>

          <button className="offercommonbtn"> Check Now</button>
        </div>
      </div>
    </div>
  );
};

export default LoanOffersCalculator;
