import React, { useContext, useEffect, useState } from "react";
import { PanCardKyc } from "./PanCardKyc.component";
import { AadharCardKycComponent } from "./AadharCardKyc.component";
import { CompanyKycComponent } from "./UserCompanyInfo/CompanyKyc.component";
import { PartnerOnBordingContext } from "../../PartnerContextApi/PartnerOnBordingContext";
import { DealerService } from "../../../service";

const KycDocPicker = () => {
  const [user_type , setUserType] = useState("")

  const { kycData } = useContext(PartnerOnBordingContext);

  useEffect(() => {
    getUserDetails()
  })

  const getUserDetails = async () => {
    try {
      const dealer_hash_id = localStorage.getItem("dealer_hash_id");
      const postData = { 
        dealer_id_hash: dealer_hash_id, 
        source: "saathi_web", 
        _with: ["DIF"] 
      };
  
      const response = await DealerService.getBasicDetails(postData);
      console.log("++++", response.data.data[0].user_type);
      setUserType(response.data.data[0].applicant_type_id)
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const renderPanCardComponent = () => {
    return (
      <div ol-md-12 style={{ marginBottom: 20 }}>
        <PanCardKyc disables = {true}/>
      </div>
    );
  };

  return (
    <>
      {renderPanCardComponent()}
      {user_type === 1 ? (
        <div ol-md-12 style={{ marginBottom: 20 }}>
          <AadharCardKycComponent/>
        </div>
      ) : 
      // <AadharCardKycComponent/>
       <CompanyKycComponent></CompanyKycComponent>
      }
    </>
  );
};

export default KycDocPicker;
