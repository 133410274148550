import { executeGraphQLQueryFinex } from "../common/executeGraphQLQuery";
import { LEADS_HISTORY_QUERY } from "../queries/sangam/leads.gql";
import React, { useState,useEffect } from "react";
import DateFormate from "dateformat";

const HistoryShow = ({leadDetail}) =>{
  const [ historyList, setHistoryList ]=useState([])
  const tab_status = {
    Verified: {
      is_completed: true,
      is_active: true,
    },
    New_Lead: {
      is_completed: false,
      is_active: false,
    },
    Reject: {
      is_completed: false,
      is_active: false,
    },
  };
  
  const getHistory = async () => {
    const result = await executeGraphQLQueryFinex(LEADS_HISTORY_QUERY(leadDetail?.id));
    setHistoryList(result?.data.get_history);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      await getHistory();
    };
    fetchHistory();
  }, []); 
  
  return(
    <>
<div className="historymenucard">
  <div className="menu-tab-left-panel-new">
    <ul>
      {historyList?.map((data, index) => (
        <>
          <li key={index}>
            <p
              className={
                tab_status?.Verified?.is_completed
                  ? "completed"
                  : tab_status?.Verified?.is_active
                  ? "active"
                  : ""
              }
            >
              <div className="img-type"></div>
              <div className="statusborder">
                <div className="historydetails">{data.title}</div>
                <div className="statusdetails">
                  <span>BY {data?.user_details?.added_by_name} | </span>
                  <span>{DateFormate(data?.created_date, "dd-mm-yy, hh:MM:ss")}</span>
                  {data?.followup_details?.comment && (
                    <div style={{ marginTop: 5 }}>
                      <img
                        src="https://ambak.storage.googleapis.com/partner/3051/1724924753018.svg"
                        alt="massage"
                        width={16}
                        height={16}
                      />
                      <span style={{ marginLeft: 5 }}>
                        : {data?.followup_details?.comment}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </p>
          </li>
        </>
      ))}
    </ul>
  </div>
</div>

    </>
  );
}

export default HistoryShow;