import React, { useState } from "react";
import Select from "react-select";
import InputField from "../../../../view/elements/Input";
import { useLoanDetail } from "../../../../view/ruleEngine/component/loanDetailContext";

const AGE_TABS = [
  { id: "30", label: "21-40 yrs" },
  { id: "20", label: "40-50 yrs" },
  { id: "15", label: "50 yrs +" },
];


const CREDIT_SCORE_TABS = [
  { id: "650", label: "Below 700" },
  { id: "720", label: "700-730" },
  { id: "740", label: "730-750" },
  { id: "770", label: "750-780" },
  { id: "780", label: "Above 780" },
  { id: "-1", label: "Not Available" },
];

const LoanOfferBasicDetails = ({allOptions,handleErrorReset,errorFields}) => {

  const { loanDetails, updateLoanDetails } = useLoanDetail();

 
  const handleFieldsValue = (key,value)=>{
    console.log("errorFields",errorFields)
    handleErrorReset(key)
    updateLoanDetails(key, value)
  }
  
  

  return (
    <>
    <div className="commonloancard">
      <div className="mainheadingall">Basic Details</div>
      <div className="loanofferallbox">
        <div className="allheadingincome">Age</div>
        <div className="cibilloanflex">
          {AGE_TABS.map((tab) => (
            <div
              key={tab.id}
              className={`cibilhometab ${
                loanDetails.age === tab.id ? "active" : ""
              }`}
              onClick={() => handleFieldsValue("age",tab.id)}
            >
              <span>{tab.label}</span>
            </div>
          ))}
        </div>

        <div className="row">
          {
            loanDetails.employment_type==3?
            <>
          <div className="col-sm-4" style={{ marginBottom: 20 }}>
            <label>Company Type</label>
            <Select
              id="company_type"
              onChange={(e) => handleFieldsValue('company_type', e.value)}
              options={allOptions?.['company_type']||[]}
              name="company_type"
              placeholder="Company Type"
              value={allOptions?.["company_type"]?.find(option => option.value === loanDetails.company_type)}
              />
          </div>
                  <div className="col-sm-4">
                    <InputField
                      type="text"
                      name="industry"
                      id="industry"
                      placeholder="Enter Industry"
                      label="Industry"
                      onChange={(e) => handleFieldsValue('industry', e.target.value)}
                      value={loanDetails.industry}
                    />
                  </div>
                  <div className="col-sm-4">
                    <InputField
                      type="number"
                      name="business_vintage"
                      id="business_vintage"
                      placeholder="Enter Business Vintage Years"
                      label="Business Vintage (In Years)"
                      onChange={(e) => handleFieldsValue('business_vintage', e.target.value)}
                      value={loanDetails.business_vintage}
                    />
                  </div>
              </>
          :
          <>
          <div className="col-sm-4" style={{ marginBottom: 20 }}>
            <label>Company Type</label>
            <Select
              id="company_type"
              onChange={(e) => handleFieldsValue('company_type', e.value)}
              options={allOptions?.['company_type']||[]}
              name="company_type"
              placeholder="Company Type"
              value={allOptions?.["company_type"]?.find(option => option.value === loanDetails.company_type)}
            />
            </div>
            <div className="col-sm-4">
                      <InputField
                        type="text"
                        name="company_name"
                        id="company_name"
                        placeholder="Enter Company Name"
                        label="Company Name"
                        onChange={(e) => handleFieldsValue('company_name', e.target.value)}
                        value={loanDetails.company_name}
                        />
                    </div>
          <div className="col-sm-4">
                      <InputField
                        type="number"
                        name="work_experience"
                        id="work_experience"
                        placeholder="Enter Work Experience"
                        label="Work Experience (in Years)"
                        required={true}
                        maxLength={3}
                        onChange={(e) => handleFieldsValue('work_experience', e.target.value)}
                        value={loanDetails.work_experience}
                        />
                        {errorFields?.work_experience && <div className="formerrorinput">{errorFields.work_experience}</div>}
                    </div>
          </>
          }
        </div>

        <div className="allheadingincome">Credit Score Range</div>
        <div className="cibilloanflex">
          {CREDIT_SCORE_TABS.map((tab) => (
            <div
              key={tab.id}
              className={`cibilhometab ${
                loanDetails.credit_score === tab.id ? "active" : ""
              }`}
              onClick={() => handleFieldsValue("credit_score",tab.id)}
            >
              <span>{tab.label}</span>
            </div>
          ))}
        </div>

       
      </div>
       
        </div>
    </>
  );
};

export default LoanOfferBasicDetails;
