import { gql } from '@apollo/client';
export const DASHBOARD_QUERY = (user_id, partner_id, fulfillment_type="") => {
  return gql`{
    lead_dashboard(user_id: ${user_id}, role_id:1, api_called_by: saathi_web, partner_id: ${partner_id},loan_type:1, fulfillment_type: "${fulfillment_type}")
        {
            name
            sub_type
            lead_count
            filter_params{
                doc_status
                status_id
                sub_status_id
                source
                is_revisit
                is_otp_verified
            }
            card_styles {
                icon_className
                max_icon_paths
            }
        }
    }`
};