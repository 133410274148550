import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import { fileUploader } from "../Utility/user.utility";
import FileInput from "../NewLogin/Components/Input/FileInput";

const KycProfile = (props) => {
  const selectedOption = props.selectedOption;
  const error = props.errors;
  const dealer_hash_id = props.dealer_hash_id;
  const getOnChangeValue = props.getOnChangeValue
  const { kyc_save_value, set_kyc_save_value } = props; 
  console.log ("kycSaveValue" , kyc_save_value)
 const kyc_save_value_change = props.kyc_save_value
  const { HandleKycData, formData } = props;
  const [fileUploadArr, setFileUploadArr] = useState([]);
  const [pan, setPan] = useState(props.docsImages.pan);
  const [aadharFront, setAadharFront] = useState(props.docsImages.aadhar_front);
  const [aadharBack, setAadharBack] = useState(props.docsImages.aadhar_back);
  const [msme, setMsme] = useState(props.docsImages.msme);
  const [gst, setGst] = useState(props.docsImages.gst);
  // const [kyc_save_value , set_kyc_save_value] = useState({})
  const [formValues, setFormValues] = useState({
    pan_card: formData.pan_no || "",
    aadhar_card: formData.aadhar_no || "",
    msme_number: formData.msme_number || "",
    gstin_number: formData.gst_number || "",
    is_msme: formData.is_msme || "0",
    is_gst: formData.is_gst || "0",
  });

  useEffect(() => {
    setFormValues({
      pan_card: formData.pan_no || "",
      aadhar_card: formData.aadhar_no || "",
      msme_number: formData.msme_number || "",
      gstin_number: formData.gst_number || "",
      is_msme: formData.is_msme || "0",
      is_gst: formData.is_gst || "0",
    });
  }, [formData]);

  useEffect(() => {
    HandleKycData(formValues, fileUploadArr, pan, aadharFront, aadharBack, msme, gst);
  }, [formValues, fileUploadArr, HandleKycData]);

  const handleFileChange = async (e) => {
    const { name, files } = e.target;

    if (files.length > 0) {
      const response = await fileUploader(files[0]);
      set_kyc_save_value((prev) => ({
        ...prev,
        [name]: response.file_url
      }));
      const kycMasterIds = {
        pan_card_file: 3,
        aadhar_card_front_file: 1,
        aadhar_card_back_file: 2,
        msme_file: 7,
        gstin_file: 5,
        cancel_checque: 4,
      };
      if (selectedOption === 2) {
        kycMasterIds.pan_card_file = 6;
      }
      setFormValues((prevState) => ({
        ...prevState,
        [name]: files,
      }));

      if (kycMasterIds[name] && response) {
        setFileUploadArr((prevState) => [
          ...prevState,
          {
            kyc_master_id: 1,
            kyc_child_id: 0,
          },
          {
            kyc_master_id: 1,
            dealer_id_hash: dealer_hash_id,
            verified_status: "1",
            kyc_master_id: kycMasterIds[name],
            file_name: response.file_name,
            file_url: response.file_url,
          },
        ]);
      }

      setFormValues((prev) => ({
        ...prev,
        [name]: response.file_url,
      }));

      switch (name) {
        case "pan_card_file":
          setPan(response.file_url);
          break;
        case "aadhar_card_front_file":
          setAadharFront(response.file_url);
          break;
        case "aadhar_card_back_file":
          setAadharBack(response.file_url);
          break;
        case "msme_file":
          setMsme(response.file_url);
          break;
        case "gstin_file":
          setGst(response.file_url);
          break;
        default:
          break;
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const capitalizedValue = value.toUpperCase();
    
    setFormValues((prev) => ({
      ...prev,
      [name]: capitalizedValue,
    }));
  
    set_kyc_save_value((prev) => ({
      ...prev,
      [name]: capitalizedValue
    }));
  };
  getOnChangeValue(kyc_save_value)

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: checked ? "1" : "0",
    }));
  };

  return (
    <div className="myaccountpadding">
      <h6 className="kycheadingmain">Documents</h6>
      <div>Complete your KYC process to ensure timely payouts</div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-md-4">
          <InputField
            type="text"
            name="pan_card"
            id="pan_card"
            placeholder="Enter PAN Number"
            label="Pan Card"
            value={kyc_save_value_change.pan_card ? kyc_save_value_change.pan_card : formValues.pan_card}
            onChange={handleInputChange}
            required={true}
            error={error.pan_no}
            maxLength="10"
            disabled = {true}
          />
        </div>
        <div className="col-md-4">
          <label>Upload PAN Card</label>
          <FileInput
            name="pan_card_file"
            id="pan_card_file"
            placeholder="Pan Card"
            onChange={handleFileChange}
            fileUrl={kyc_save_value.pan_card_file ? kyc_save_value.pan_card_file :pan}
            required={true}
          />
        </div>
      </div>
      {selectedOption === "individual" && (
        <>
          <div className="row">
            <div className="col-md-4">
              <InputField
                type="text"
                name="aadhar_card"
                id="aadhar_card"
                placeholder="Enter Aadhar Card"
                label="Aadhar Card"
                value={kyc_save_value.aadhar_card ? kyc_save_value.aadhar_card :formValues.aadhar_card}
                onChange={handleInputChange}
                required={true}
                error={error.aadhar_no}
                maxLength="12"
                disabled = {true}
              />
            </div>
            <div className="col-md-4">
              <label>Upload Aadhar Card Front</label>
              <FileInput
                name="aadhar_card_front_file"
                id="aadhar_card_front_file"
                placeholder="Aadhar Card Front"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.aadhar_card_front_file ? kyc_save_value.aadhar_card_front_file : aadharFront}
                required={true}
                error={error.aadharFront}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <label>Upload Aadhar Card Back</label>
              <FileInput
                name="aadhar_card_back_file"
                id="aadhar_card_back_file"
                placeholder="Aadhar Card Back"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.aadhar_card_back_file ? kyc_save_value.aadhar_card_back_file :aadharBack}
                required={true}
                error={error.aadharBack}
              />
            </div>
          </div>
        </>
      )}
      {selectedOption === "company" && (
        <>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-4">
              <InputField
                type="text"
                name="msme_number"
                id="msme_number"
                placeholder="Enter MSME Number"
                label="MSME Number*"
                value={kyc_save_value.msme_number ? kyc_save_value.msme_number :formValues.msme_number}
                onChange={handleInputChange}
                error={error.msme_number}
                disabled={formValues.is_msme !== "1"} // Disable if MSME is not selected
              />
            </div>
            <div className="col-md-4">
              <div className="basiccheckbox">
                <input
                  type="checkbox"
                  name="is_msme"
                  id="is_msme"
                  checked={formValues.is_msme === "1"}
                  onChange={handleCheckboxChange}
                  value="1"
                />
                <label>MSME Registered</label>
              </div>
              <FileInput
                name="msme_file"
                id="msme_file"
                placeholder="MSME number"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.msme_file ? kyc_save_value.msme_file :msme}
                required={true}
                error={error.msme}
                disabled={formValues.is_msme !== "1"} // Disable if MSME is not selected
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <InputField
                type="text"
                name="gstin_number"
                id="gstin_number"
                placeholder="Enter GSTIN Number"
                label="GSTIN Number*"
                value={kyc_save_value.gstin_number ? kyc_save_value.gstin_number : formValues.gstin_number}
                onChange={handleInputChange}
                error={error.gst_number}
                disabled={formValues.is_gst !== "1"} // Disable if GST is not selected
              />
            </div>
            <div className="col-md-4">
              <div className="basiccheckbox">
                <input
                  type="checkbox"
                  name="is_gst"
                  id="is_gst"
                  checked={formValues.is_gst === "1"}
                  onChange={handleCheckboxChange}
                  value="1"
                />
                <label>GST Registered</label>
              </div>
              <FileInput
                name="gstin_file"
                id="gstin_file"
                placeholder="GST number"
                onChange={handleFileChange}
                fileUrl={kyc_save_value.gstin_file ? kyc_save_value.gstin_file :gst}
                required={true}
                error={error.gst_file}
                disabled={formValues.is_gst !== "1"} // Disable if GST is not selected
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KycProfile;
