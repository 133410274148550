import React from 'react';
import { withTranslation } from 'react-i18next';
import { LINK_URL,CONF_VAL } from './../../../config/constant';

const  LeftPanel =(props) => {
    const { showWelcomeHeading } = props;
        return (
            <div className="leftBlueBg">
                <div className="blueBg">
                {/* <div className="logoSec"><img src={require('./../assets/images/'+LINK_URL.LOGO)} /></div> */}
                {showWelcomeHeading ? (
                    <>
                        <p className='loginpara'>Welcome To <span style={{ color: "#6147FF", fontWeight: 600 }}>Ambak</span></p>
                        <h1 className='lgheading'>Sign in to<br></br> your Digital Dealership</h1>
                    </>
                ) : (
                    <>
                        <p className="loginpara"> {props.t('Delete')}</p>
                        <h1 className="lgheading"><strong>{props.t('your partner Account')}</strong></h1>
                    </>
                )}
                    {/* <h1 className="lgHeading"><strong>{props.t('login.login_title')}</strong></h1>
                    <p>{props.t('login.grow')}<span className="txt87"> {props.t('login.your_business')}</span></p> */}
                    
                    {/* <div className="playStore">
                        <a href="http://play.google.com/store/apps/details?id=com.oto.dealer.id" target="_blank"><img src={require('./../assets/images/google-play.png')} alt="" /></a>
                        <a href="https://itunes.apple.com/us/app/oto-dealer/id1489698884?ls=1&amp;mt=8" target="_blank"><img src={require('./../assets/images/app-store.png')} alt="" /></a>
                    </div> */}
                    {/* <div className="homeMobile">
                        <img className="img-mobile-bg" src={require('./../assets/images/'+CONF_VAL.MOBILE_LOGIN_IMAGE)} alt="" />
                        <img className="img-mobile-bg1" src={require('./../assets/images/'+CONF_VAL.MOBILE_LOGIN_IMAGE)} alt="" />
                        <video width="229" className="true" className="videoSec">
                            <source src={require('./../assets/images/videos.mp4')} type="video/mp4" />
                            {props.t('login.not_support')}
                        </video>
                        
                    </div> */}
                    {/* <div className="animationImage">
                        <img src={require('./../assets/images/car_animation_new.png')} alt="" />
                    </div> */}
                </div>
            </div>
        );
    }


export default withTranslation('common')(LeftPanel);

