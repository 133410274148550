import React, { useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import CibilHistoryDetails from "./CibilHistoryDetails";


const CibilScoreCheck = (props) => {
  const [currentSection, setCurrentSection] = useState(1);
  const [activeTab, setActiveTab] = useState("lap");

  const SendCodeShow = () => {
    setCurrentSection(currentSection + 1);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  

  return (
    <>
      {currentSection === 1 && (
        <>
          <h4>Get Your Free Credit Report</h4>
          <p>
            Check your score and get your full credit report in seconds. 100%
            secure!
          </p>
          <div className="row">
            <div className="col-md-12">
              <InputField
                type="text"
                name="search"
                id="search"
                placeholder="Pan Card"
                //value={}
                label="PAN CARD"
                // onChange={}
              />
            </div>
          </div>
          <div className="newleadbtnflex">
            <button className="submitbtnnewlead" onClick={SendCodeShow}>
              Send Code
            </button>
          </div>
        </>
      )}
      {currentSection === 2 && (
        <>
          <h4>Verification</h4>
          <p>
            You will receive a verification code on their PAN-linked mobile
            number.
          </p>

          <div>
            <InputField
              type="text"
              name="mobile"
              id="mobile"
              placeholder="Mobile"
              //value={}
              label="Mobile"
              // onChange={}
            />
          </div>

          <div className="mobileedit">Edit</div>
          <div className="newleadbtnflex">
            <button className="submitbtnnewlead" onClick={SendCodeShow}>
              Send Code
            </button>
          </div>
        </>
      )}
      {currentSection === 3 && (
        <>
          <div className="concentlink">
            {/* gif */}
            <h4>Waiting for customer’s consent</h4>

            <div>
              <div className="concentsms">
                Consent Link has been Shared with the Customer via SMS{" "}
              </div>
            </div>
            <div className="newleadbtnflex">
              <button className="submitbtnnewlead" onClick={SendCodeShow}>
                Send Code
              </button>
            </div>
          </div>
        </>
      )}

      {currentSection === 4 && (
        <>
          <div>
            <div className="additionalflex">
              <h4>Additional Details</h4>
              <div onClick={SendCodeShow}>Skip</div>
            </div>
            <div className="additionldetails">
              <div>
                <div className="additonaltype">Fulfillment Type</div>
                <div className="commontabs">
                  <div>
                    <div
                      className={
                        activeTab === "self_fulfillment"
                          ? "commontabsItem active"
                          : "commontabsItem"
                      }
                      onClick={() => handleTabClick("self_fulfillment")}
                    >
                      <div className="newtabContent">Self Fulfillment</div>
                    </div>
                  </div>

                  <div>
                    <div
                      className={
                        activeTab === "ambak_fulfillment"
                          ? "commontabsItem active"
                          : "commontabsItem"
                      }
                      onClick={() => handleTabClick("ambak_fulfillment")}
                    >
                      <div className="newtabContent">Ambak Fulfillment</div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="additonaltype">Loan Type</div>
                <div className="commontabs">
                  <div>
                    <div
                      className={
                        activeTab === "home_loan"
                          ? "commontabsItem active"
                          : "commontabsItem"
                      }
                      onClick={() => handleTabClick("home_loan")}
                    >
                      <div className="newtabContent">Home Loan</div>
                    </div>
                  </div>

                  <div>
                    <div
                      className={
                        activeTab === "lap"
                          ? "commontabsItem active"
                          : "commontabsItem"
                      }
                      onClick={() => handleTabClick("lap")}
                    >
                      <div className="newtabContent">Lap</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {activeTab === "lap" && (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    <InputField
                      type="text"
                      name="mobile"
                      id="mobile"
                      placeholder="Mobile"
                      //value={}
                      label="Mobile"
                      // onChange={}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label>Employment Type</label>
                    <Select
                      id="employment_type"
                      onChange={""}
                      options={""}
                      name="employment_type"
                      placeholder="employment_type"
                      value={""}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ value }) => value}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <InputField
                      type="text"
                      name="monthly_salary"
                      id="monthly_salary"
                      placeholder="Monthly Salary"
                      //value={}
                      label="Take Home Monthly Salary"
                      // onChange={}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="concentsms">
              All customer information is secure and no customer will be
              directly reached out without your permission
            </div>

            <div className="newleadbtnflex">
              <button className="submitbtnnewlead" onClick={SendCodeShow}>
                Submit
              </button>
            </div>
          </div>
        </>
      )}

      {currentSection === 5 && (
        <>
         <CibilHistoryDetails onPress={props.onPress} setModalVisiblity={props.setModalVisiblity}/>
        </>
      )}
    </>
  );
};

export default CibilScoreCheck;
