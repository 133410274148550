import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, NavLink } from "react-router-dom";
import secureStorage from './../../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import PropertyProfile from './propertyProfile';
import basicProfile from './basicProfile';
import incomeProfile from './incomeProfile';
import InputProvider from './InputProvider';
import BreDetails from './breDetails';
import { useEffect, useState } from 'react';

// import MasterService from './../../../service/MasterService';
class RuleEngine extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            errors: {},
            loading: true,
            urlprefix: props.urlprefix || null,
        }
    }



    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }

    componentDidMount = async () => {
        await this.updateReduxData();
    }



    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
      
    }



    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        const isDisabled = true;
        return (
            <div className="container-fluid">
                <InputProvider>
                    <Router>
                        <div>
                            <nav>
                                <ul className="pagesteps active">
                                    <li>
                                        <NavLink
                                            exact
                                            className={isDisabled ? 'disabled-link' : ''}
                                            to="/admin/basic-requirement"
                                            activeClassName="active"
                                            //onClick={(e) => isDisabled && e.preventDefault()}
                                        ><span className="pagecount">1</span>Basic Profile & Requirements</NavLink>
                                    </li>
                                    <li className="active">
                                        <NavLink exact activeClassName='active'
                                            to="/admin/property-profile"
                                            //className={isDisabled ? 'disabled-link' : ''}
                                            //onClick={(e) => isDisabled && e.preventDefault()}
                                        ><span className="pagecount">2</span>Property Profile</NavLink>
                                    </li>

                                    <li>
                                        <NavLink exact className={isDisabled ? 'disabled-link' : ''}
                                           activeClassName="active"
                                            //onClick={(e) => isDisabled && e.preventDefault()}
                                            to="/admin/income-profile"><span className="pagecount">3</span>Income Profile</NavLink>
                                    </li>
                                    <li>
                                        <NavLink exact className={isDisabled ? 'disabled-link' : ''}
                                            activeClassName="active"
                                            onClick={(e) => isDisabled && e.preventDefault()}
                                             to="/admin/bre-details"><span className="pagecount">4</span>Details</NavLink>
                                    </li>
                                </ul>
                            </nav>
                            <hr />
                            <Route
                                path="/admin/rule-engine"
                                component={basicProfile} />

                            <Route
                                path="/admin/basic-requirement"
                                component={basicProfile} />
                            <Route
                                path="/admin/property-profile"
                                component={PropertyProfile} />

                            <Route
                                path="/admin/income-profile"
                                component={incomeProfile} />

                            <Route
                                path="/admin/bre-details"
                                component={BreDetails} />
                        </div>
                    </Router>
                </InputProvider>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(RuleEngine));
