import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import secureStorage from './../../../config/encrypt';
import MasterService from '../../../service/MasterService';
class PartnerDashboard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            countReportLeadDpd: [],
            cardData: [
                { count: 0, text: 'New Leads', icon: 'ic-person_add', link: '/admin/dealer-list?type=ready_for_verification', filter: { status: '4', list_type: 'pending' } },
                { count: 0, text: 'Rejected', icon: 'ic-clearclose', link: '/admin/dealer-list?type=rejected', filter: { status: '4', list_type: 'rejected' } },
                { count: 0, text: 'Inactive', icon: 'ic-access_timequery_builderschedule', link: '/admin/dealer-list?type=inactive', filter: { status: '2', list_type: '' } },
                { count: 0, text: 'Verified', icon: 'ic-check', link: '/admin/dealer-list?type=verified', filter: { status: '1', list_type: '' } },
                { count: 0, text: 'All Leads', icon: 'ic-person_add', link: '/admin/dealer-list?type=all', filter: { status: '', list_type: '' } },
            ],
            showDealerCount: false,
            selectedFilter: { status: '', list_type: '' },
            statusClassname: {
                'pending': 'nav-item active',
                'active': 'nav-item ',
                'rejected': 'nav-item',
                'inactive': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
                status: null,
                list_type: ''
            }
        };
        this.cancelAsyncTask = false;
    }


    getCountReportLeadDpd = async () => {
        try {

            const response = await MasterService.post('dealer/dealer/count_dealer_status');
            if (response.data.status === 200) {
                const counts = response.data.data;


                const updatedCardData = this.state.cardData.map(card => {
                    switch (card.text) {
                        case 'New Leads':
                            return { ...card, count: counts.pending || 0 };
                        case 'Rejected':
                            return { ...card, count: counts.rejected || 0 };
                        case 'Inactive':
                            return { ...card, count: counts.inactive || 0 };
                        case 'Verified':
                            return { ...card, count: counts.active || 0 };
                        case 'All Leads':
                            return { ...card, count: counts.all || 0 };
                        default:
                            return card;
                    }
                });

                this.setState({ cardData: updatedCardData });
            }
        } catch (error) {
            console.error('Error fetching count data:', error);
        }
    }



    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        this.checkLogin();
        await this.updateReduxData();
        await this.getCountReportLeadDpd();
    }

    checkLogin() {
        if(!localStorage.getItem('isLogin')) {
        localStorage.setItem('isLogin' , true);
        window.location.reload();
       }
    }

    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        
    }


    render() {
        const cardStyles = ['color-one', 'color-two', 'color-three', 'color-four', 'color-five', 'color-one'];
        let name = "Admin";

        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');

            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                name = loginResponse.data.user_data.name;
            }
            if (localStorage.getItem('loginUserInfo') && !name) {
                const loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'));
                name = loginUserInfo.user_data.name;
            }
        }
        return (
            <div className="dashboard-bg">
                <div className="dashboard-outer">
                    <div className="heading">
                        <span className="subheading">Hello, {name}</span>
                        <h1>Welcome to Ambak Partner Onboarding</h1>
                    </div>
                    <div className="dashboard-card">
                        {this.state.cardData.map((card, index) => (
                            <Link to={card.link} key={index} >
                                <div className={`cardnew ${cardStyles[index]}`} key={index}>
                                    <div className='roung-bg'>
                                        <i className={card.icon}></i>
                                    </div>
                                    <span className="count-txt">{card.count}</span>
                                    <span className="card-txt">{card.text}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    };

};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PartnerDashboard));