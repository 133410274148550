import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import loginlogo from '../../../view/login/assets/images/loginlogo.svg'
import googlelogo from '../../../webroot/images/google-logo.png'
import secureStorage from './../../../config/encrypt';
import { Redirect } from 'react-router-dom';
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { API_URL } from '../../../config/constant';

const Email = (props) => {

    const query = new URLSearchParams(props.location.search);
    const data = query.get("userdata");
    const querydata = data  && data!="" ? JSON.parse(data) : {}
    const [email, setEmail] = useState(querydata && querydata!="" ? querydata.email : "");
    const [userID, setUserID] = useState(query && query!="" ? query.get("user_id") : "");
    const [redirect, setRedirect] = useState(0);
    const [redirectUrl, setRedirectUrl]=useState(API_URL.GOOGLE_AUTH_URL+window.location.href)
    //console.log("email", querydata.email )
    useEffect(() => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse);
        } 
      
        if(email && email!=""){
            loginwithGoogle()
        }
        if(userID){
           //alert("userID",userID)
           MasterService.get('user/user-manager/email-verification/'+userID)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    window.commonErrorToast("Email verified");
                }else{
                    window.commonErrorToast(response.data.message);
                   }
            }).catch(function (error) {
                console.log(error);
            });
        }
     
        
    }, [email,userID]);

    const afterLoginSetState = (loginResponse) => {
        let role =
            loginResponse &&
            loginResponse.data &&
            loginResponse.data.user_data &&
            loginResponse.data.user_data.role ||
            null;
        if (role === "admin" || role === "Partner") {
            setRedirect(2);
        }
    };

   const loginwithGoogle = ()=>{
          let postData = {
            email:email,
            password:"HJsfsYsgsSGF"
          }
        
            MasterService.post('account/user/emaillogin', postData)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    let responseData = response.data;
                    secureStorage.setItem('loginUserInfo', responseData);
                    afterLoginSetState(responseData)
                }else{
                    window.commonErrorToast(response.data.message);
                    return <Redirect to={'/login'} />;

                   }
            }).catch(function (error) {
                console.log(error);
            });

        }
   
        if (redirect === 2) {
            return <Redirect to={'/admin/partner-dashboard'} />;
         } 

    return (
        <>
           <div className="loginbox">
                 {
                    (userID && userID!="") ? 
                    <div style={{ color: 'green', fontSize: '16px',fontWeight: 'bold'}}>
                        Thank you for confirming your email address!
                    </div> : 
              
                    <div className='loginCard'>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "40px" }}>

                            <img src="https://storage.googleapis.com/ambak/logo/ambak_logo.svg" alt='logo' width={200} height={60} />
                        </div>
                        <div className='logingsign'>
                            {/* <div>-------------------</div> */}
                            {/* <div style={{ marginLeft: 10, marginRight: 10 }}> Or Sign in with </div> */}
                            {/* <div>-------------------</div> */}
                        </div>
                        <a href={redirectUrl} className='logingmail'>
                            <img src={googlelogo} alt='logo' width={20} height={20} />
                            <div style={{ marginLeft: 5 }}>Sign in with Google</div>
                        </a>
                    </div>
                      } 
                    </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        user_loggedin: state.userLoggedIn
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    };
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Email));

