import React, { useState } from "react";
import InputField from "../../../../view/elements/Input";
import Select from "react-select";
import { useLoanDetail } from "../../../../view/ruleEngine/component/loanDetailContext";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";

const MODE = [
  {id: "1", label: "Account" ,value:"1"},
  { id: "2", label: "Cash",value:"2" },
]
const NO_OF_ACCOUNTS = [
  { id: "1", label: "1",value: "1" },
  { id: "2", label: "2",value: "2" },
  { id: "3", label: "3",value: "3" },
  { id: "4", label: "4",value: "4" },
  { id: "5", label: "5",value: "5" },
];

const ACCOUNT_TYPE=[
  {id: "1", label: "Current",value:"1" },
  { id: "2", label: "Saving" ,value:"2"},
]


const LoanOfferCoApplicant = ({ allOptions,handleErrorReset,errorFields }) => {
  const [activeTab, setActiveTab] = useState("coApplicant_0");
  const { loanDetails, updateLoanDetails } = useLoanDetail();



  const handleAddCoApplicant= (index) => {
    updateLoanDetails("coapplicant_details",[...loanDetails.coapplicant_details, {}]);
    if(index>0){
      addCoIncomeTypeFilter(index)
    }
    // setIncomeEntries([...incomeEntries, {}]);
  };
  const handleAddAccount = (index) => {
    handleAddAdditionalIncome(index,[...loanDetails.coapplicant_details])
    // setAccountEntries([...accoutEntries, {}]);
  };

  const handleCoApplicantEntryChange = (index, field, value,price=false) => {
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
    handleErrorReset(`${field+index}`)
    const updatedEntries = [...loanDetails.coapplicant_details];

      updatedEntries[index] = {
        ...updatedEntries[index],
        [field]: value,
      };
    updateLoanDetails("coapplicant_details", updatedEntries)
    if(field==="co_additional_income" && value=='yes' && !updatedEntries[index]?.income_entries?.length){
      handleAddAdditionalIncome(index,updatedEntries)
    }
  };


  const handleAddAdditionalIncome = (coAppIndex,existingEntry) => { 
    const updatedEntries = [...existingEntry];
    if (!updatedEntries[coAppIndex]?.income_entries) {
      updatedEntries[coAppIndex].income_entries = [];
    }
    updatedEntries[coAppIndex].income_entries.push({ amount: "", mode: "",income_type:"" });
    updateLoanDetails("coapplicant_details", updatedEntries);
    addCoIncomeTypeFilter(coAppIndex,updatedEntries[coAppIndex].income_entries.length)
  };

  const handleRemoveAdditionalIncome = (coAppIndex, incomeIndex) => {
    const updatedEntries = [...loanDetails.coapplicant_details];
    updatedEntries[coAppIndex].income_entries = updatedEntries[coAppIndex].income_entries.filter(
      (_, i) => i !== incomeIndex
    );
    updateLoanDetails("coapplicant_details", updatedEntries);
  };

  const handleAdditionalIncomeChange = (coAppIndex, incomeIndex, field, value,price=false) => {
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
    const updatedEntries = [...loanDetails.coapplicant_details];
    updatedEntries[coAppIndex].income_entries[incomeIndex][field] = value;
    updateLoanDetails("coapplicant_details", updatedEntries);
    if(field=="income_type"){
      handleAdditionalCoIncomeType(value,coAppIndex,incomeIndex)
    }
  };
  const handleAdditionalCoIncomeType = (data,coAppIndex, index) => {
    if (data) {
      allOptions[`filtered_co_additional_income_type_${coAppIndex}_${index + 1}`] =
        allOptions[`filtered_co_additional_income_type_${coAppIndex}_${index}`].filter(
          (obj) => obj.value !== +data
        );
    }
  };
  const addCoIncomeTypeFilter = (coAppIndex,incomeIndex=0)=>{
    allOptions[`filtered_co_additional_income_type_${coAppIndex}_${incomeIndex}`]=allOptions[`filtered_co_additional_income_type_0_0`]
  }


const handleCoapplicantChange = (coAppIndex, accountIndex, field, value,price=false) => {
  if(price){
    value=value.replace(/[^0-9]/g, '')
  }
  const updatedEntries = [...loanDetails.coapplicant_details];
  updatedEntries[coAppIndex].acc_details[accountIndex][field] = value;
  updateLoanDetails("coapplicant_details", updatedEntries);
};

const handleCoApplicantAccountChange = (index, value) => {
  const updatedEntries = [...loanDetails.coapplicant_details];

    updatedEntries[index] = {
      ...updatedEntries[index],
      acc_details: value,
    };
  updateLoanDetails("coapplicant_details", updatedEntries)
};

const handleAccountChange = (e,index,entry)=>{
    handleCoApplicantEntryChange(index,"banking_acc",e.value)
    const newAccDetails=entry?.acc_details ? [...entry.acc_details] : [];
    if (e.value > newAccDetails.length) {
      for (let i = newAccDetails.length; i < e.value; i++) {
        newAccDetails.push({ account_type: '', amount: '' }); // Add empty objects
      }
    } else if (e.value < newAccDetails.length) {
      // Remove objects if the new length is smaller
      newAccDetails.length = e.value; // Truncate the array to the new length
    }
    handleCoApplicantAccountChange(index,newAccDetails)
}
const handleFieldsValue = (key,value,price=false)=>{
  if(price){
    value=value.replace(/[^0-9]/g, '')
  }
  updateLoanDetails(key, value)
}

const handleItrValue = (yearKey, field, value,price=false) => {
  if(price){
    value=value.replace(/[^0-9]/g, '')
  }
    const updatedAccDetails = {...loanDetails.CO_ITR};
    updatedAccDetails[yearKey] = {
      ...updatedAccDetails[yearKey] , 
      [field]: value, 
    };
    updateLoanDetails('CO_ITR', updatedAccDetails)
};


  return (
    <>
      <div className="commonloancard">
        <div className="mainheadingall">Co-Applicant Details</div>
        <div className="loanofferallbox">
          <div className="allheadingincome">Any Co-applicants?</div>
          <div className="cibilloanflex">
            <div
              className={`cibilhometab ${loanDetails.coapplicant_available === "yes" ? "active" : ""}`}
              onClick={() => updateLoanDetails("coapplicant_available", 'yes')}
            >
              <span>Yes</span>
            </div>

            <div
              className={`cibilhometab ${loanDetails.coapplicant_available === "no" ? "active" : ""}`}
              onClick={() => updateLoanDetails("coapplicant_available", 'no')}
            >
              <span>No</span>
            </div>
            {loanDetails.coapplicant_available==='yes' && loanDetails.coapplicant_details.length<3 && <div className="loanadditional" onClick={()=>handleAddCoApplicant(loanDetails?.coapplicant_details.length)}>
                + Co-applicant
              </div>}
          </div>


{loanDetails.coapplicant_available === "yes" && (
            <>
              {/* Render Tabs for Each Co-Applicant */}
              <div className="tab-list">
                {loanDetails?.coapplicant_details?.map((coApp, index) => (
                  <div
                    key={index}
                    className={`commontabsItem ${activeTab === `coApplicant_${index}` ? "active" : ""}`}
                    onClick={() => setActiveTab(`coApplicant_${index}`)}
                  >
                    {!coApp?.co_app_first_name?`Co-applicant - ${index + 1}`:coApp?.co_app_first_name}
                  </div>
                ))}
              </div>

              {/* Render Content Based on Active Tab */}
              <div className="tab-content">
                {loanDetails?.coapplicant_details?.map((entry, index) => (
                  activeTab === `coApplicant_${index}` && (
                    <>
                    <div className="row" key={index}>
                      <div className="col-sm-4">
                        <InputField
                          required={true}
                          type="text"
                          name={`co_app_first_name_${index}`}
                          id={`co_app_first_name_${index}`}
                          placeholder="Enter First Name"
                          label="First Name"
                          onChange={(e) => handleCoApplicantEntryChange(index, 'co_app_first_name', e.target.value)}
                          value={entry?.co_app_first_name}
                        />
                        {errorFields[`co_app_first_name${index}`] && <div className="formerrorinput">{errorFields[`co_app_first_name${index}`]}</div>}
                      </div>
                      <div className="col-sm-4">
                        <label>Employment Type <span className="form-required">*</span></label>
                        <Select
                          id={`co_employment_type_${index}`}
                          onChange={(e) => handleCoApplicantEntryChange(index, 'co_employment_type', e.value)}
                          options={allOptions['profession']}
                          name={`co_employment_type_${index}`}
                          placeholder="Select Employment Type"
                          value={allOptions['profession'].find(option => option.value === entry?.co_employment_type) || ""}
                        />
                        {errorFields[`co_employment_type${index}`] && <div className="formerror">{errorFields[`co_employment_type${index}`]}</div>}
                      </div>
                      
                     
                         
                    </div>

                    <div className="row">
                    {entry.co_employment_type==3 ?
                        <>
                          <div className="cibilloanflex">
                          <div className="allheadingincome paddingLeftHeading" >What income do you want to consider?</div>
                            <div
                              className={`cibilhometab ${entry?.co_itr_filing === "itrincome" ? "active" : ""}`}
                              onClick={() =>handleCoApplicantEntryChange(index, 'co_itr_filing', "itrincome") }
                            >
                              <span>ITR Income</span>
                            </div>
                
                            <div
                              className={`cibilhometab ${entry?.co_itr_filing === "banking" ? "active" : ""}`}
                              onClick={() => handleCoApplicantEntryChange(index, 'co_itr_filing', "banking")}
                            >
                              <span>Banking Income</span>
                            </div>
                          </div>
                          </>:<div>
                          <div className="col-sm-4">
                                    <InputField
                                      type="text"
                                      maxLength={12}
                                      name="take_home_salary"
                                      id="take_home_salary"
                                      placeholder="Enter Take Home Monthly Salary"
                                      label="Take Home Monthly Salary"
                                      onChange={(e) => handleCoApplicantEntryChange(index,'take_home_salary', e.target.value,true)}
                                      value={handlePriceInputIndian(entry.take_home_salary)}
                                      />
                                  </div>
                                  <div className="col-sm-4">
                                  <InputField
                                    type="text"
                                    maxLength={12}
                                    name="gross_salary"
                                    id="gross_salary"
                                    placeholder="Enter Gross Salary"
                                    label="Gross Salary"
                                    onChange={(e) => handleCoApplicantEntryChange(index, 'gross_salary', e.target.value,true)}
                                    value={handlePriceInputIndian(entry.gross_salary)}
                                  />
                                </div>
                          </div>}
                          {entry.co_employment_type==3 &&entry?.co_itr_filing === "itrincome" && (
          <div className="cibilloanflex paddingLeftHeading">
            <div
              className={`cibilhometab ${loanDetails?.co_itr_year === "one" ? "active" : ""}`}
              onClick={() => handleFieldsValue('co_itr_year', 'one')}
            >
              <span>Year 1</span>
              
            </div>

            <div
              className={`cibilhometab ${loanDetails?.co_itr_year === "two" ? "active" : ""}`}
              onClick={() => handleFieldsValue('co_itr_year', 'two')}
            >
              <span>Year 2</span>
            </div>
            </div>
          )}
           {entry.co_employment_type==3 && entry?.co_itr_filing === "itrincome" && loanDetails?.co_itr_year === "one" && (
              <div className="row paddingLeftHeading">
                <div className="col-sm-4">
                  <InputField
                    type="text"
                    maxLength={12}
                    placeholder="NPAT"
                    value={handlePriceInputIndian(loanDetails.CO_ITR.year1.npat)}
                    label="NPAT"
                    onChange={(e) => handleItrValue('year1', 'npat', e.target.value,true)}
                  />
                </div>
                <div>
                <div className="col-sm-4">
                <InputField
                    type="text"
                    maxLength={12}
                    label="Depreciation"
                    placeholder="Depreciation"
                    value={handlePriceInputIndian(loanDetails.CO_ITR.year1.depreciation)}
                    onChange={(e) => handleItrValue('year1', 'depreciation', e.target.value,true)}
                  />
                 </div>
                 <div className="col-sm-4">
                <InputField
                    type="text"
                    maxLength={12}
                    label="Interest"
                    placeholder="Interest"
                    value={handlePriceInputIndian(loanDetails.CO_ITR.year1.interest)}
                    onChange={(e) => handleItrValue('year1', 'interest', e.target.value,true)}
                  />
                 </div>
                 <div className="col-sm-4">
                <InputField
                    type="text"
                    maxLength={12}
                    label="Tax paid"
                    placeholder="Tax paid"
                    value={handlePriceInputIndian(loanDetails.CO_ITR.year1.tax_paid)}
                    onChange={(e) => handleItrValue('year1', 'tax_paid', e.target.value,true)}
                  />
                 </div>
                </div>
              </div>
            )}

          {entry.co_employment_type==3 && entry?.co_itr_filing === "itrincome" && loanDetails?.co_itr_year === "two" && (
                        <div className="row paddingLeftHeading">
                          <div className="col-sm-4">
                            <InputField
                              type="text"
                              maxLength={12}
                              placeholder="NPAT"
                              value={handlePriceInputIndian(loanDetails.CO_ITR.year2.npat)}
                              label="NPAT"
                              onChange={(e) => handleItrValue('year2', 'npat', e.target.value,true)}
                            />
                          </div>
                          <div>
                          <div className="col-sm-4">
                          <InputField
                              type="text"
                              maxLength={12}
                              label="Depreciation"
                              placeholder="Depreciation"
                              value={handlePriceInputIndian(loanDetails.CO_ITR.year2.depreciation)}
                              onChange={(e) => handleItrValue('year2', 'depreciation', e.target.value,true)}
                            />
                          </div>
                          <div className="col-sm-4">
                          <InputField
                              type="text"
                              maxLength={12}
                              label="Interest"
                              placeholder="Interest"
                              value={handlePriceInputIndian(loanDetails.CO_ITR.year2.interest)}
                              onChange={(e) => handleItrValue('year2', 'interest', e.target.value,true)}
                            />
                          </div>
                          <div className="col-sm-4">
                          <InputField
                              type="text"
                              maxLength={12}
                              label="Tax paid"
                              placeholder="Tax paid"
                              value={handlePriceInputIndian(loanDetails.CO_ITR.year2.tax_paid)}
                              onChange={(e) => handleItrValue('year2', 'tax_paid', e.target.value,true)}
                            />
                          </div>
                          </div>
                        </div>
              )}
                          
                          {
                           entry.co_employment_type==3 && entry.co_itr_filing=='banking' && (
                              <>
                              <div className="paddingLeftHeading">
                              <div className="row">
                              <div className="col-sm-4" style={{ marginBottom: 20 }}>
                              <label>Banking Income</label>
                              <Select
                                id="banking_acc"
                                onChange={(e)=>handleAccountChange(e,index,entry)}
                                options={NO_OF_ACCOUNTS||[]}
                                name="banking_acc"
                                placeholder="No of accounts"
                                value={NO_OF_ACCOUNTS.find(option => option.value == entry?.banking_acc)}
                              />
                              </div>
                              </div> 
                              {
                                entry?.acc_details?.map((accEntry,accountIndex)=>(
                                  <div className="row">
                                  <div className="bank-account-entry">
                                    <div className="allheadingincome paddingLeftHeading" >Bank Account {accountIndex + 1}</div>
                                {/* Example fields for each bank account component */}
                                
                                  <div className="col-sm-4">
                                    <label>Account Type</label>
                                    <Select
                                      id={`account_type_${accountIndex}`}
                                      onChange={(e) => handleCoapplicantChange(index,accountIndex, 'account_type', e.value)}
                                      options={ACCOUNT_TYPE || []}
                                      placeholder="Select Account Type"
                                      value={ACCOUNT_TYPE.find(option=>option.value===accEntry.account_type)}
                                    />
                                  </div>
                                  <div className="col-sm-4">
                                    <InputField
                                      type="text"
                                      name={`amount_${accountIndex}`}
                                      id={`amount_${accountIndex}`}
                                      onChange={(e) => handleCoapplicantChange(index,accountIndex, 'amount', e.target.value,true)}
                                      placeholder="Amount"
                                      label="Amount"
                                      value={handlePriceInputIndian(accEntry.amount)}
                                      />
                                  </div>
                                  {/* Add more fields as necessary */}
                                </div>
                                </div>
                                 
                                ))
                              }
                          </div>
                              </>
                            )
                          }

                    </div>



                    <div>
                    <div className="allheadingincome">Any Additional Income?</div>
                    <div className="cibilloanflex">
                      <div
                        className={`cibilhometab ${entry.co_additional_income === "yes" ? "active" : ""}`}
                        onClick={() => handleCoApplicantEntryChange(index, 'co_additional_income', 'yes')}
                      >
                        <span>Yes</span>
                      </div>
    
                      <div
                        className={`cibilhometab ${entry.co_additional_income === "no" ? "active" : ""}`}
                        onClick={() => handleCoApplicantEntryChange(index, 'co_additional_income', 'no')}
                      >
                        <span>No</span>
                      </div>
                    </div>
    
                    {entry.co_additional_income === "yes" && (
                      <>
                      {entry?.income_entries?.map((incomeEntry, incomeIndex) => (
                <div className="row" key={incomeIndex}>
                  <div className="col-sm-4">
                    <label>Income Type</label>
                    <Select
                      id={`income_type_${incomeIndex}`}
                      onChange={(e) => handleAdditionalIncomeChange(index,incomeIndex, 'income_type', e.value)}
                      options={incomeEntry.co_employment_type==1?allOptions[`filtered_co_additional_income_type_${index}_${incomeIndex}`].filter(item=>item.value!==6):allOptions[`filtered_co_additional_income_type_${index}_${incomeIndex}`]}
                      name={`income_type_${incomeIndex}`}
                      placeholder="Income Type"
                      value={allOptions[`filtered_co_additional_income_type_${index}_${incomeIndex}`].find(option=>option.value === incomeEntry.income_type)||""}
                    />
                  </div>
                  <div className="col-sm-4">
                  <label>Mode</label>
                    <Select
                      id={`mode_${incomeIndex}`}
                      onChange={(e) => handleAdditionalIncomeChange(index,incomeIndex, 'mode', e.value)}
                      options={MODE||[]}
                      name={`mode_${incomeIndex}`}
                      placeholder="Select Mode"
                      value={MODE.find(option=>option.value === incomeEntry.mode)||""}
                    />
                  </div>
                  <div className="col-sm-4">
                    <InputField
                      type="text"
                      maxLength={12}
                      name={`amount_${incomeIndex}`}
                      id={`amount_${incomeIndex}`}
                      placeholder="Amount"
                      label="Amount"
                      onChange={(e) => handleAdditionalIncomeChange(index,incomeIndex, 'amount', e.target.value,true)}
                      value={handlePriceInputIndian(incomeEntry?.amount)}
                    />
                  </div>
                  <div className="loanremoveoffer">
                    <div onClick={() => handleRemoveAdditionalIncome(index,incomeIndex)}>Remove</div>
                  </div>
                </div>
              ))}
                        <div className="loanadditional" onClick={()=>handleAddAccount(index)}>
                          + Add Additional Income
                        </div>
                      </>
                    )}

                    </div>
                    <div className="allheadingincome">Obligation</div>
        <br/>
          <div className="row">
            <div className="col-sm-4">
              <InputField
                type="text"
                maxLength={12}
                name="co_current_emi"
                id="co_current_emi"
                placeholder="Current EMIs"
                label="Current EMIs"
                onChange={(e) => handleCoApplicantEntryChange(index, 'co_current_emi', e.target.value,true)}
                value={handlePriceInputIndian(entry.co_current_emi)}
              />
            </div>
            <div className="col-sm-4">
              <InputField
                type="text"
                maxLength={12}
                name="co_emi_end_in_6_month"
                id="co_emi_end_in_6_month"
                placeholder="EMIs Ending in 6 Months"
                label="EMIs Ending in 6 Months"
                onChange={(e) => handleCoApplicantEntryChange(index, 'co_emi_end_in_6_month', e.target.value,true)}
                value={handlePriceInputIndian(entry.co_emi_end_in_6_month)}
              />
            </div>
          </div>
                    </>
                  )
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoanOfferCoApplicant;
