import React from 'react';
import ReactPaginate from 'react-paginate';
const Pagination = ({ perPage = 10, onPaginate, pageCount, activePage}) => {
	const handlePageClick = (spage) => { onPaginate(spage.selected + 1) };
	return (
		<div className='pagination-bx'>
			<ReactPaginate
				breakLabel="..."
				nextLabel=">"
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				pageCount={pageCount}
				forcePage={activePage ? activePage - 1 : 0}
				previousLabel="<"
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName="pagination"
				activeClassName="active"
				renderOnZeroPageCount={null}
			/>
		</div>
	);
}
export default Pagination;