import React, { Component } from 'react';
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Button from '../../elements/Button';
import InputField from '../../elements/Input';
import secureStorage from './../../../config/encrypt';
import InputContext from './InputContext';

const search_by_occupation = [
    { value: 'salaried', label: 'Salaried' },
    { value: 'self_empoyee_professional', label: 'Self Employed Professional (Eg: CA, Doctor)' },
    { value: 'self_empoyee_non_professional', label: 'Self Employed Non-Professional' },
];

const company_type = [
    { value: 'proprietorship', label: 'Proprietorship' },
    { value: 'partnership', label: 'Partnership Firm' },
    { value: 'pvt_ltd', label: 'Private Limited' },
    { value: 'public_pimited', label: 'Public Limited' },
    { value: 'llp', label: 'Limited Liability Partnership' },
    { value: 'govt', label: 'Government' },
    //{ value: 'other', label: 'Other' }
];
const company_cat = [
    { value: 'tcs_A', label: 'TCS' },
    { value: 'infosys_A', label: 'Infosys' },
    { value: 'bharti_airtel_A', label: 'Bharti Airtel' },
    { value: 'hindustan_unilever_A', label: 'Hindustan Unilever' },
    { value: 'itc_A', label: 'ITC' },
    { value: 'sova_health_B', label: 'Sova Health' },
    { value: 'ultrahuman_B', label: 'Ultrahuman' },
    { value: 'the_zebras_B', label: 'The Zebras' },
    { value: 'kanak_consultancy_B', label: 'Kanak Consultancy' },
    { value: 'abc_Partners_B', label: 'ABC Partners' },
    { value: 'other_B', label: 'Other' }

];
const digit1 = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
const digit2 = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

class BasicProfile extends Component {
    static contextType = InputContext;

    constructor(props) {
        super(props);
        this.state = {
            addNewUserData: "",
            search_by_occupation: null,
            company_type: null,
            dob: null,
            company_cat: null,
            errors: {},
            inputErrors: {
                name: false,
                dob: false,
                desired_loan_amount: false,
                occupation: false,
                total_work_exp: false,
                // Add more fields as needed
              },

        }
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            //this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }

    componentDidMount = async () => {
        await this.updateReduxData();
    }

    handleNextTab = () => {
        this.props.history.push('/admin/property-profile');
    };

    handlePrevPage = () => {
        this.props.history.goBack();
    };

    handleInputChange = (event, inputName) => {
        const { setInputValue } = this.context;
        const value = event.target.value;
        setInputValue(inputName, value);
    };

    handlePriceInputChange = (event, inputName) => {
        const { setInputValue } = this.context;
        let inputValue = event.target.value;
        // Remove non-numeric characters
        inputValue = inputValue.replace(/[^0-9]/g, '');
        // Format to Indian currency
        inputValue = new Intl.NumberFormat('en-IN').format(inputValue);
        setInputValue(inputName, inputValue);
    };

    formatCurrency = (value) => {
        if (value) {
            // Format the raw numeric value for display
            const parts = value.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        }
    };

    handleSelectChange = (selectedOption, inputName) => {
        const { setInputValue } = this.context;

        const value = selectedOption ? selectedOption.value : null;
        setInputValue(inputName, value);
        this.setState({ [inputName]: value });
    };

    handleDateChange = (date, inputName) => {
        const { setInputValue } = this.context;
        const formattedDate = date.toISOString().split('T')[0];
        setInputValue(inputName, formattedDate);
        this.setState({ [inputName]: date });
    };

    addHandleDateTimeChange = (date, sname) => {
        let addNewUserData = this.state.addNewUserData;
        let inputDate = new Date(date);
        addNewUserData[sname] = date;
        this.setState({ addNewUserData });
        addNewUserData[sname] = inputDate;
        this.setState({
            addNewUserData
        });
    }

    __getStyle = (fieldName) => {
        return this.state.inputErrors[fieldName] ? { borderColor: 'red' } : {};
      };

    navigateToPropertyComponent = () => {
        const { inputValues } = this.context;
        //console.log(inputValues);
        if (!inputValues.name) {
            toast.error('Please enter name.');
            // this.setState({
            //     inputErrors: { ...this.state.inputErrors, name: true },
            //   });
            return;
        }
        else if (!inputValues.dob) {
            toast.error('Please enter age.'); return;
        }
        else if (inputValues.dob <= 18) {
            toast.error('Applicant’s age has to be above 18 yrs.'); return;
        }
        else if (!inputValues.desired_loan_amount) {
            toast.error('Please enter desired loan amount.'); return;
        }
        else if (!inputValues.occupation) {
            toast.error('Please select occupation.'); return;
        }
        else if (!inputValues.total_work_exp && inputValues.occupation === 'salaried') {
            toast.error('Please enter total work experiance.'); return;
        }
        // else if (!inputValues.company_type) {
        //     toast.error('Please select company type.'); return;
        // }
        else if (!inputValues.bussiness_continuity && (inputValues.occupation === 'self_empoyee_professional' || inputValues.occupation === 'self_empoyee_non_professional')) {
            toast.error('Please enter Years of Business.'); return;
        } else if (!inputValues.company_cat && inputValues.occupation === 'salaried') {
            toast.error('Please select company name.'); return;
        }

        else {
            this.props.history.push({
                pathname: '/admin/property-profile',
                state: { inputValues: this.state.inputValues },
            });
        }
    }

    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
       
    }


    convertToWords(num) {
        if ((num = num.toString()).length > 9) return 'overflow';
        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (digit1[Number(n[1])] || digit2[n[1][0]] + ' ' + digit1[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (digit1[Number(n[2])] || digit2[n[2][0]] + ' ' + digit1[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (digit1[Number(n[3])] || digit2[n[3][0]] + ' ' + digit1[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (digit1[Number(n[4])] || digit2[n[4][0]] + ' ' + digit1[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (digit1[Number(n[5])] || digit2[n[5][0]] + ' ' + digit1[n[5][1]]) + 'only ' : '';
        return str;
    }

    render() {
        const { inputValues } = this.context;
        return (
            <>
                <div className='card'>
                    <div className='card-body'>
                        <div className='property-card'>
                            <div className="card-new">
                                <div className="card-new-body">
                                    <div className='mainheading'>Basic Profile & Requirements</div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <InputField type="text"
                                                name="name"
                                                id="name"
                                                placeholder="Eg: Neha Singh"
                                                value={inputValues.name}
                                                autoComplete="off"
                                                onChange={(event) => this.handleInputChange(event, 'name')}
                                                label="Full Name"
                                                required
                                                //style={this.__getStyle('name')}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="number"
                                                name="dob"
                                                id="dob"
                                                placeholder="In Years"
                                                value={inputValues.dob}
                                                onChange={(event) => this.handleInputChange(event, 'dob')}
                                                className="form-control"
                                                autoComplete="off"
                                                label="Age"
                                                required
                                            />
                                            {/* <label>Date Of Birth (Age) <span style={{ color: "red" }}>*</span></label> */}
                                            {/* <DatePicker
                                                showYearDropdown
                                                showMonthDropdown
                                                selected={this.state.dob} // Assuming you have a state property for the date
                                                onChange={(date) => this.handleDateChange(date, 'dob')}
                                                name="dob"
                                                id="dob"
                                                autoComplete="off"
                                                className="form-control"
                                                dateFormat="yyyy-MM-dd"
                                                value={inputValues.dob}
                                                label="Date Of Birth (Age)"
                                                required
                                            /> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="desired_loan_amount"
                                                id="desired_loan_amount"
                                                placeholder="In INR"
                                                autoComplete="off"
                                                value={this.formatCurrency(inputValues.desired_loan_amount)}
                                                onChange={(event) => this.handlePriceInputChange(event, 'desired_loan_amount')}
                                                label="Required Loan Amount"
                                                required
                                            />
                                            {inputValues.desired_loan_amount && (
                                                <div className="amount-in-words">
                                                    <span>{this.convertToWords(inputValues.desired_loan_amount.replace(/[^\d.]/g, ''))}</span>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-sm-6">
                                            <label>Occupation <span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                id="occupation"
                                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'occupation')}
                                                options={search_by_occupation}
                                                name="occupation"
                                                placeholder="Select Occupation"
                                                value={search_by_occupation.find(({ value }) => value === inputValues.occupation)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                                label="Occupation"
                                                required
                                            />
                                        </div>
                                    </div>
                                    {inputValues.occupation === 'salaried' && (
                                        <div className="row">
                                            <div className="col-sm-6">
                                                {/* {inputValues.occupation === 'salaried' && (
                                                 <span style={{ color: "red" }}>*</span>
                                            )} */}
                                                <InputField
                                                    type="number"
                                                    name="total_work_exp"
                                                    id="total_work_exp"
                                                    placeholder="In Years"
                                                    autoComplete="off"
                                                    value={inputValues.total_work_exp}
                                                    onChange={(event) => this.handleInputChange(event, 'total_work_exp')}
                                                    label="Total Work Experience"
                                                    required={inputValues.occupation === 'salaried'}
                                                />
                                            </div>

                                            <div className="col-sm-6">
                                                <label>Company Name
                                                    {inputValues.occupation === 'salaried' && (
                                                        <span style={{ color: "red" }}>*</span>
                                                    )}
                                                </label>
                                                <Select
                                                    id="company_cat"
                                                    onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'company_cat')}
                                                    options={company_cat}
                                                    name="company_cat"
                                                    placeholder="Select Company Name"
                                                    value={company_cat.find(({ value }) => value === inputValues.company_cat)}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                />
                                            </div>

                                        </div>
                                    )}

                                    <div className='row'>
                                        <div className="col-sm-6">
                                            <label>Company Type
                                            </label>
                                            <Select
                                                id="company_type"
                                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'company_type')}
                                                options={company_type}
                                                name="company_type"
                                                placeholder="Select Company Type"
                                                value={company_type.find(({ value }) => value === inputValues.company_type)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                        </div>
                                        {
                                            (inputValues.occupation === 'self_empoyee_professional' || inputValues.occupation === 'self_empoyee_non_professional') && (
                                                <div className="col-sm-6">
                                                    <InputField
                                                        type="number"
                                                        name="bussiness_continuity"
                                                        id="bussiness_continuity"
                                                        placeholder="In Years"
                                                        autoComplete="off"
                                                        value={inputValues.bussiness_continuity}
                                                        onChange={(event) => this.handleInputChange(event, 'bussiness_continuity')}
                                                        label="Years of Business"
                                                        required={inputValues.occupation === 'self_empoyee_professional' || inputValues.occupation === 'self_empoyee_non_professional'}
                                                    />
                                                </div>
                                            )}
                                    </div>

                                    <div className='propertybtn'>
                                        {/* <div>
                                    <Button btnClass="btn btn-primary" onClick={this.handlePrevPage}>Back</Button>
                                </div> */}
                                        <Button btnClass="btn btn-primary" onClick={this.navigateToPropertyComponent}>Next</Button>
                                    </div>


                                    {/* <div className="row">
                        <div className="col-sm-6 offset-sm-6">
                            <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">{this.props.t('dealerDetails.Outlet_Details_master.Previous')}</NavLink>
                        </div>
                        <div className="col-sm-6 offset-sm-6"><Button btnClass="btn btn-primary" title="Next"/></div>
                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default BasicProfile;
//export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(basicProfile));
