import React, { Component } from 'react';
import InputField from '../elements/Input';
import RadioBox from './../elements/Radiobox';
import CheckBox from './../elements/CheckBox';
import Button from '../elements/Button';

class BasicDetails extends Component {
    render() {
        return (
            <div><h1>Basic Details (Saroj Sahoo - GCD1069)</h1>
            <div className="card">
                <div className="card-heading"><h2 className="card-title">Basic Details</h2></div>
                <div className="card-body">                    
                    <div className="row">
                        <div className="col-sm-4"><InputField type="text" name="dealership_name" id="dealership_name" placeholder="Enter Dealership Name" value="Saroj Sahoo" label="Dealership Name*" /></div>
                        <div className="col-sm-4"><InputField type="text" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="dinesh.babu@ambak.com" label="Dealership Email*" /></div>
                        <div className="col-sm-4"><InputField type="text" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                    </div>
                    <div>
                        <label>Dealership Type*</label>
                        <div className="rc-inline">
                            <RadioBox type="radio" name="parent_dealer_type" id="dealer" value="1" label="Dealer" />
                            <RadioBox type="radio" name="parent_dealer_type" id="dsa" value="3" label="DSA" />
                            <RadioBox type="radio" name="parent_dealer_type" id="proxyFinance" value="4" label="Proxy Finance Dealer" />
                        </div>
                    </div>
                    <div className="alert alert-default form-group">
                        <div className="row">
                            <div className="col-sm-6 ">
                                <h6>Used Car Dealer</h6>
                                <div className="rc-inline">
                                    <CheckBox type="checkbox" name="dealer_type[]" id="ucdAll" value="ucdall" label="All" />
                                    <CheckBox type="checkbox" name="dealer_type[]" id="dealer_type_1" value="1" label="Normal" />
                                    <CheckBox type="checkbox" name="dealer_type[]" id="dealer_type_2" value="2" label="Luxury" />
                                    <CheckBox type="checkbox" name="dealer_type[]" id="dealer_type_3" value="3" label="Services" />
                                    <CheckBox type="checkbox" name="dealer_type[]" id="dealer_type_4" value="4" label="Prime" />
                                    <CheckBox type="checkbox" name="dealer_type[]" id="dealer_type_5" value="5" label="5 Key" />
                                </div>
                            </div>
                            <div className="col-sm-6 ">
                                <h6>New Car Dealer</h6>
                                <div className="rc-inline">
                                    <CheckBox type="checkbox" name="dealer_type[]" id="ncdAll" value="ncdAll" label="All" />
                                    <CheckBox type="checkbox" name="dealer_type[]" id="dealer_type_1" value="1" label="Normal" />
                                    <CheckBox type="checkbox" name="dealer_type[]" id="dealer_type_2" value="2" label="Luxury" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <h2 className="contentbreak">Owner Details</h2>
                    <div className="row">
                        <div className="col-sm-4"><InputField type="text" name="dealership_name" id="dealership_name" placeholder="Enter Dealership Name" value="Saroj Sahoo" label="Dealership Name*" /></div>
                        <div className="col-sm-4"><InputField type="text" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="dinesh.babu@ambak.com" label="Dealership Email*" /></div>
                        <div className="col-sm-4"><InputField type="text" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                        <div className="col-sm-4"><InputField type="password" name="dealership_password" id="dealership_password" placeholder="Password" value="9876543210" label="Password*" /></div>
                        <div className="col-sm-4"><InputField type="text" name="dob" id="dob" placeholder="DOB" value="22/11/1990" label="Date of Birth" /></div>
                        <div className="col-sm-12"><Button btnClass="btn btn-primary" title="Save"/></div>
                    </div>
                </div>
            </div>
            
        </div>

        )
    }
}

export default BasicDetails