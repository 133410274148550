import React, { useEffect, useMemo, useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import RadioBox from "../../../src/view/elements/Radiobox";
import { Link } from "react-router-dom";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { LEAD_CREATION_FORM } from "../../queries/sangam/create.gql";
import { toast } from "react-toastify";
import GeneralService from "../../service/generalService";
import FormFields from "./formFields/customerDetailsFormFields.json";
import {
  convertCsvToJson,
  convertJsonToCsv,
  downloadFile,
} from "./helpers/helpers";
import * as yup from "yup";
import { RoleAccess } from "../../config/constant";
import MultiSelect from "../../view/elements/MultiSelect";
const BULK_LEAD_FORMAT = FormFields["LEAD_CREATION_BULK_FORMAT"];
const FULFILLMENT_TYPE_ENUM = ["ambak", "self"];
const LOAN_TYPE_ENUM = ["home loan", "llp"];
const LOAN_SUB_TYPE_ENUM = ["bt", "topup"];

const NewLeadCreate = ({ masterData, loginUserInfo }) => {
  const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [errors, setErrors] = useState({});
  const assigned_to = RoleAccess("Assign to New Lead");
  const slef_vs_ambak = RoleAccess("Self Vs Ambak New Lead");
  const [selectedOption, setSelectedOption] = useState("add_manually");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    loan_type: "",
    loan_sub_type: "",
    fulfillment_type: "self",
    assigned_to: "",
  });
  const [selectedRadioOption, setSelectedRadioOption] = useState("self");
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [assignTo, setAssignTo] = useState([]);
  const [uploadedFileJson, setUploadedFileJson] = useState([]);
  const [isFileInvalid, setIsFileInvalid] = useState(false);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterData ? { ...masterData } : {};
    return options;
  }, [masterData]);

  useEffect(() => {
    const assignToList = async () => {
      const response = await GeneralService.subPartnerList(
        loginUserInfo.user_data.dealer_id,
        "team_members"
      );
      if (response?.data?.status === 200) {
        setAssignTo(response?.data?.data?.sub_partner_list);
      }
    };
    assignToList();
  }, []);

  const record = assignTo
    .filter((ob) => ob.user_type === "team_member") // Filter only team members
    .map(({ sub_partner_id: value, sub_partner_name: label }) => ({
      value,
      label,
    }));

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleFormatDownload = (event) => {
    const { id } = event.target;
    let jsonArray = [],
      fileName = "";
    if (id === "bulkLeadFormat") {
      const parentPartnerId = loginUserInfo?.user_data?.parent_partner_id;
      const partner_id = user_info?.dealer_id;
      jsonArray = BULK_LEAD_FORMAT.map(({ fulfillment_type, ...rest }) => ({
        ...rest,
        partner_id,
        ...(parentPartnerId ? { fulfillment_type } : {}),
      }));
      fileName = "bulk_lead_file_format.csv";
    }
    const csvData = convertJsonToCsv(jsonArray);
    downloadFile(csvData, "text/csv", fileName);
  };

  const handleTypeChange = (event) => {
    const { id, value } = event.target;
    setSelectedOption(id);
  };

  const handleLoanTypeChange = (selectedOptions) => {
    setFormData((prevState) => ({
      ...prevState,
      loan_type: selectedOptions,
    }));
  };

  const handleMultiSelectChange = async (n, v, isMulti, opt) => {
    setFormData((prevState) => ({
      ...prevState,
      loan_sub_type: v.map((obj) => obj[opt]),
    }));
  };

  const handleAssigToChange = (selectedOptions) => {
    setFormData((prevState) => ({
      ...prevState,
      assigned_to: selectedOptions,
    }));
  };

  const handleRadioChange = (event) => {
    setSelectedRadioOption(event.target.value);
    const { id, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      fulfillment_type: value,
    }));
  };

  const validateLead = async (lead) => {
    try {
      await leadSchema.validate(lead);
      return {
        error: false,
        message: "",
      };
    } catch (error) {
      return {
        error: true,
        message: error.message,
      };
    }
  };

  const LOAN_TYPE_MAPPING = {
    "home loan": 1,
    llp: 2,
  };

  const LOAN_SUB_TYPE_MAPPING = {
    bt: "1",
    topup: "2",
  };

  const leadSchema = yup.object().shape({
    fulfillment_type: yup.string().oneOf(FULFILLMENT_TYPE_ENUM).notRequired(),
    loan_type: yup.string().lowercase().oneOf(LOAN_TYPE_ENUM).required(),
    loan_sub_type: yup
      .string()
      .lowercase()
      .oneOf([...LOAN_SUB_TYPE_ENUM, ""])
      .nullable(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    mobile: yup.string().length(10).required(),
    loan_amount: yup.string().required(),
    partner_id: yup.number().required(),
    assigned_to: yup.number().nullable(),
  });

  const handleSubmit = async (event) => {
    if (Object.keys(formData).length === 0 && uploadedFileJson.length === 0) {
      return toast.error("Either fill form or upload file");
    }

    if (uploadedFileJson.length > 0) {
      let errorCount = 0;
      const jsonClone = [],
        parsedJson = [];
      for (let i = 0; i < uploadedFileJson.length; i++) {
        const lead = uploadedFileJson[i];
        lead.fulfillment_type = lead.fulfillment_type
          ? lead.fulfillment_type
          : "ambak";
        const response = await validateLead(lead);
        if (response.error) {
          errorCount++;
          jsonClone.push({ ...lead, error: response.message });
        } else {
          parsedJson.push({
            ...leadSchema.cast(lead),
            loan_type: LOAN_TYPE_MAPPING[lead.loan_type.toLowerCase()],
            loan_sub_type:
              LOAN_SUB_TYPE_MAPPING[lead.loan_sub_type.toLowerCase()],
            loan_amount: lead.loan_amount.replaceAll(",", ""),
            fulfillment_type: "self",
          });
          jsonClone.push({ ...lead, error: "" });
        }
      }

      if (errorCount > 0) {
        toast.error("Invalid File");
        setUploadedFileJson(jsonClone);
        setIsFileInvalid(true);
      } else {
        setIsFileInvalid(false);
        const responseArry = [];
        const mutation = LEAD_CREATION_FORM;
        try {
          for (let i = 0; i < parsedJson.length; i++) {
            const lead = parsedJson[i];
            const variables = {
              partner_id: lead.partner_id,
              api_called_by: "saathi_web",
              newLeadInput: {
                first_name: lead.first_name,
                last_name: lead.last_name,
                mobile: lead.mobile,
                loan_type: +lead.loan_type,
                loan_sub_type: lead.loan_sub_type,
                fulfillment_type: lead.fulfillment_type,
                loan_amount: lead.loan_amount,
                assigned_partner_id: lead.assigned_to || user_info?.dealer_id,
                parent_partner_id: loginUserInfo?.user_data?.parent_partner_id,
              },
            };

            const resp = await executeGraphQLMutationFinex(mutation, variables);
            if (resp && !resp?.data?.create_loan) {
              throw new Error("Internal Server error");
            }
            responseArry.push(1);
          }

          if (parsedJson.length === responseArry.length) {
            toast.success("Success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error("Internal Server error");
          }
        } catch (error) {
          console.log(error.message);
          toast.error("Internal Server error");
        }
      }
    } else {
      handleSave();
    }
  };
  const handleDownloadErrorFile = () => {
    const csvData = convertJsonToCsv(uploadedFileJson);
    downloadFile(csvData, "text/csv", "error_file.csv");
    setIsFileInvalid(false);
  };

  const handleSave = async (event) => {
    if (!validate()) {
      return;
    }
    const mutation = LEAD_CREATION_FORM;
    const variables = {
      partner_id: loginUserInfo.user_data.dealer_id,
      api_called_by: "saathi_web",
      newLeadInput: {
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        mobile: formData?.mobile,
        loan_type: +formData?.loan_type?.id,
        ...(formData?.loan_sub_type
          ? { loan_sub_type: formData?.loan_sub_type?.join(",") }
          : {}),
        fulfillment_type: formData.fulfillment_type,
        assigned_partner_id: formData.assigned_to || user_info?.dealer_id,
        parent_partner_id: loginUserInfo?.user_data?.parent_partner_id,
      },
    };

    try {
      const resp = await executeGraphQLMutationFinex(mutation, variables);

      if (!resp?.data?.create_loan) {
        throw new Error("Something went wrong");
      }

      toast.success("Success");
      setShowForm(false);
      setShowThankYou(true);
    } catch (err) {
      console.log(` 🔻 ERROR : ${err.message}`);
      toast.error("Got Invalid value. Please Check");
    }
  };

  const validate = () => {
    const errors = {};

    if (!formData.first_name.trim()) {
      errors.first_name = "First Name is required";
    }
    if (!formData.last_name.trim()) {
      errors.last_name = "Last Name is required";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile is required";
    } else if (!/^[6-9]\d{9}$/.test(formData.mobile)) {
      errors.mobile =
        "Mobile number must start with 6, 7, 8, or 9 and be exactly 10 digits";
    }
    if (!formData.loan_type) {
      errors.loan_type = "Loan Type is required";
    }
    if (!formData.fulfillment_type) {
      errors.fulfillment_type = "Fulfillment Type is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFileChange = async (event) => {
    const { files, id } = event.target;
    const fileNameElement = document.getElementById("fileName");

    if (files.length > 0) {
      const fileName = files[0].name;
      const jsonArray = await convertCsvToJson(files[0]);
      setUploadedFileJson(jsonArray);
      fileNameElement.textContent = "Selected file: " + fileName;
    } else {
      fileNameElement.textContent = "";
    }
  };

  return (
    <>
      {showForm && (
        <>
          <div className="leadtype">
            <div style={{ marginRight: 10 }}>
              <RadioBox
                type="radio"
                name="lead_type"
                id="add_manually"
                value="add_manually"
                label="Add Manually"
                checked={selectedOption === "add_manually"}
                onChange={handleTypeChange}
              />
            </div>
            <div>
              <RadioBox
                type="radio"
                name="lead_type"
                id="bulk_upload"
                value="bulk_upload"
                label="Bulk Upload"
                checked={selectedOption === "bulk_upload"}
                onChange={handleTypeChange}
              />
            </div>
          </div>

          {selectedOption === "add_manually" && (
            <>
              <h4>Add New Lead</h4>
              <div className="newleadcreateflexbtn">
                {!loginUserInfo?.user_data?.parent_partner_id && (
                  <div className="newleadtabscreate">
                    <input
                      type="radio"
                      id="self"
                      name="self"
                      value="self"
                      onChange={handleRadioChange}
                      checked={selectedRadioOption === "self"}
                    />
                    <label htmlFor="self">I will do it myself</label>
                  </div>
                )}
                {slef_vs_ambak === 1 && (
                  <div className="newleadtabscreate">
                    <input
                      type="radio"
                      id="ambak"
                      name="ambak"
                      value="ambak"
                      onChange={handleRadioChange}
                      checked={selectedRadioOption === "ambak"}
                    />
                    <label htmlFor="ambak">Refer to Ambak</label>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputField
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="First Name"
                    label="First Name"
                    value={formData.first_name}
                    onChange={handleChange}
                    error={errors.first_name}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Last Name"
                    label="Last Name"
                    value={formData.last_name}
                    onChange={handleChange}
                    error={errors.last_name}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    type="tel"
                    name="mobile"
                    id="mobile"
                    placeholder="Mobile"
                    label="Mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    maxLength={10}
                    error={errors.mobile}
                  />
                </div>
                <div className="col-md-6">
                  <label>Loan Type</label>
                  <Select
                    id="loan_type"
                    options={masterData?.loan_type}
                    onChange={handleLoanTypeChange}
                    name="loan_type"
                    value={formData?.loan_type}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errors.loan_type}{" "}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Loan Sub Type</label>
                  <MultiSelect
                    options={ALL_OPTIONS && ALL_OPTIONS["loan_sub_type"]}
                    placeholder="Loan Sub Type"
                    name="loan_sub_type"
                    id="loan_sub_type"
                    optionLabel="label"
                    optionValue="id"
                    values={formData["loan_sub_type"] || []}
                    onChange={handleMultiSelectChange}
                  />
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errors.loan_sub_type}{" "}
                  </p>
                </div>
                {assigned_to === 1 && (
                  <div>
                    {record.length > 0 && (
                      <div className="col-md-6">
                        <label>Assigned to</label>
                        <Select
                          id="assigned_to"
                          options={record}
                          onChange={(e) => handleAssigToChange(e.value)}
                          name="assigned_to"
                          value={record.find(
                            (opt) => opt.value === formData.assigned_to
                          )}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {selectedOption === "bulk_upload" && (
            <>
              <h4>Upload</h4>
              <div>
                <div className="csvfileupload">
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="fileInput" className="file-label">
                    <span>
                      <img
                        src={require(`../../../src/saathi-web/images/uploadimg.svg`)}
                        alt="not"
                        width={50}
                        height={50}
                      />
                    </span>
                    <p>
                      Drop your file’s here or <span> Browse</span>
                    </p>
                    <p id="fileName"></p>
                  </label>
                  <p id="fileName"></p>
                </div>
                {isFileInvalid && (
                  <div
                    className="downloadformat leaddownload"
                    onClick={handleDownloadErrorFile}
                  >
                    <span id="bulkLeadFormat">Download error file</span>
                  </div>
                )}
                <div className="leaddownload">
                  <p>
                    How to Know data upload to CSV format{" "}
                    <span id="bulkLeadFormat" onClick={handleFormatDownload}>
                      {" "}
                      Download format
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}

          <div className="newleadbtnflex">
            <button className="submitbtnnewlead" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </>
      )}

      {showThankYou && (
        <>
          <div className="newleadthankyou">
            <img
              src={require(`../../../src/saathi-web/images/thankyouimg.svg`)}
              alt="thankyou"
              width={300}
            />
            <h4>Your Lead Successfully Uploaded</h4>
          </div>
          <div className="newleadbtnflex">
            <Link to="/saathi-leads" onClick={window.location.reload()}>
              <button className="submitbtnnewlead">Go to Lead</button>
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default NewLeadCreate;
