import React from 'react';

const FileInput = ({ name, id, onChange, file, placeholder, fileUrl, required, disabled, error }) => {
  return (
    <div>
    <div col-md-12>
      <div className="hideinputborder" style={{ marginBottom: 20, borderColor: error ? 'red' : '' }}>
        <label htmlFor={id} className="custom-input">
          <div>
            <img
              src="https://ambak.storage.googleapis.com/partner/1797/1720691116166.png"
              alt="doc"
              width="16"
              height="16"
            />
            <span>{placeholder}
           {required && <span className="required" style={{ color: 'red' }}>*</span>}
            </span>
          </div>
          <div>
            <img
              src="https://ambak.storage.googleapis.com/partner/1797/1720691944687.svg"
              alt="doc"
              width="16"
              height="16"
            />
          </div>
        </label>
        <input
          type="file"
          name={name}
          id={id}
          accept=".jpg,.jpeg,.png,.pdf"
          className="custom-file-input"
          style={{ display: "none" }}
          onChange={onChange}
          disabled={disabled}
        />
        {(file || fileUrl) && (
          <div style={{marginLeft: 10, color:'007bff'}}>
            <a href={fileUrl ? fileUrl : URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
              View
            </a>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default FileInput;
