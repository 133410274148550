import React from "react";
import InputField from "../../../../view/elements/Input";



const NewLeadLoanOffer = () =>{
   
    return(
        <>
         <div className="mainheadingall">Save Lead Details</div>
         <div className="row">
         <div className="col-sm-6">
            <InputField
            type="text"
            name="first_name"
            id="first_name"
            placeholder="First Name"
            label="First Name"
            />
        </div>
        <div className="col-sm-6">
            <InputField
            type="text"
            name="last_name"
            id="last_name"
            placeholder="Last Name"
            label="Last Name"
            />
        </div>
        <div className="col-sm-6">
            <div style={{fontSize:13,fontWeight:500,marginBottom:20}}>Pick the home loan sub-type (if applicable)</div>
            <div className='loanfulfillment'>
              <div className='loaninput'>
                <input type="checkbox" id="balancetransfer" name="bt" value="1"  />
                <label htmlFor='balancetransfer'>Balance Transfer</label>
              </div>
              <div className='loaninput'>
                <input type="checkbox" id="top-up" name="top_up" value="2"  />
                <label htmlFor='top-up'>Top-Up</label>
              </div>
            </div>

        </div>
        <div className="col-sm-6">
            <InputField
            type="text"
            name="mobile_no"
            id="mobile_no"
            placeholder="Mobile Number"
            label="Mobile Number"
            />
        </div>
         </div>

         <div className="loannewlead">
            <button className="loannewleadbtn">create Lead</button>
         </div>
         

        </>
    );
}


export default NewLeadLoanOffer;