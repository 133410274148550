import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Button from '../elements/Button';
import InputField from '../elements/Input';
import CheckBox from './../elements/CheckBox';



const city = [
    { value: '125', label: 'New Delhi' },
    { value: '227', label: 'Bangalore' },
    { value: '336', label: 'Mumbai' },
    { value: '471', label: 'Chennai' },
    { value: '344', label: 'Pune' },
    { value: '8', label: 'Hyderabad' },
    { value: '162', label: 'Gurgaon' }

];

const state = [
    { value: '125', label: 'Asam' },
    { value: '227', label: 'Bihar' },
    { value: '336', label: 'Haryana' },
    { value: '471', label: 'New Delhi' },

];

const location = [
    { value: '125', label: 'Agroha' },
    { value: '227', label: 'Vasant Kunj' },
    { value: '336', label: 'Sector 61' },
    { value: '471', label: 'Palam Vihar' },

];

class OutletDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            city: "",
            state: "",
            location: "",
            startDate: new Date()
        };
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {

        return (
            <div><h1>Outlet Details (Saroj Sahoo - GCD1069)</h1>
                <div className="card">
                    <div className="card-heading">
                        <div className="row">
                            <div className="col-sm-6"><h2 className="card-title">Raj Motors</h2></div>
                            <div className="col-sm-6 text-right">
                                <ul className="list-inline clearfix">
                                    <li className="btn btn-link mrg-r15">
                                        <label className="switch-btn" htmlFor="primary_switch_15571">
                                            <input className="switch-btn " id="primary_switch_15571" value="15571" name="is_primary_button[]" type="checkbox" checked="checked" />
                                            <div className="slider round"></div>
                                            <span className="switch-label">Primary</span>
                                        </label>

                                    </li>
                                    <li className="mrg-r15">
                                        <Button type="button" btnClass="btn btn-link"><i className="ic-content_copy"></i> Copy Address</Button>
                                    </li>
                                    <li className="mrg-r15">
                                        <Button type="button" btnClass="btn btn-link"><i className="ic-createmode_editedit"></i> Edit Outlet</Button>
                                    </li>
                                    <li>
                                        <Button type="button" btnClass="btn btn-link"><i className="ic-add"></i></Button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div id="outleteditbox">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <InputField type="text" name="showroom_name" id="showroom_name" placeholder="Enter Outlet Name" value="Raj Motors" label="Outlet Name*" />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="tel" name="contact_number" id="contact_number" placeholder="Outlet Contact No." value="9876543210" label="Outlet Contact No.*" />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="text" name="address1" id="address1" placeholder="Address Line 1*" value="" label="Address Line 1*" />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="text" name="address2" id="contact_number" placeholder="Address Line 2" value="" label="Address Line 2" />
                                        </div>
                                        <div className="col-sm-6 form-group">
                                            <label>State*</label>
                                            <Select
                                                id="state"
                                                value={this.state.state}
                                                onChange={this.handleChange.bind(this, 'state')}
                                                options={state}
                                                name="state"
                                                placeholder="state"
                                                value={state.filter(({ value }) => value === this.state.state)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                        </div>
                                        <div className="col-sm-6 form-group">
                                            <label>City*</label>
                                            <Select
                                                id="city"
                                                value={this.state.city}
                                                onChange={this.handleChange.bind(this, 'city')}
                                                options={city}
                                                name="city"
                                                placeholder="City"
                                                value={city.filter(({ value }) => value === this.state.city)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                        </div>
                                        <div className="col-sm-6 form-group">
                                            <label>Location*</label>
                                            <Select
                                                id="location"
                                                value={this.state.location}
                                                onChange={this.handleChange.bind(this, 'location')}
                                                options={location}
                                                name="location"
                                                placeholder="location"
                                                value={location.filter(({ value }) => value === this.state.location)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                        </div>
                                        <div className="col-sm-6 form-group">
                                            <label>Front End City*</label>
                                            <Select
                                                id="city"
                                                value={this.state.city}
                                                onChange={this.handleChange.bind(this, 'city')}
                                                options={city}
                                                name="city"
                                                placeholder="City"
                                                value={city.filter(({ value }) => value === this.state.city)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="tel" name="pincode" id="pincode" placeholder="Pin Code" value="122003" label="Pincode*" />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="text" name="Latitude" id="Latitude" placeholder="Latitude" value="12.3453452003" label="Latitude*" />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="text" name="Longitude" id="Longitude" placeholder="Longitude" value="78.8888782003" label="Longitude*" />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="url" name="url" id="url" placeholder="Website URL" value="http://testurl.com" label="Website Url*" />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField type="text" name="pandacode" id="pandacode" placeholder="Panda Code" value="" label="Panda Code" />
                                        </div>
                                        <div className="col-sm-12 ">
                                            <h6>Outlet Type*</h6>
                                            <div className="rc-inline">
                                                <CheckBox type="checkbox" name="showroom_type[]" id="showroom_1" value="1" label="Showroom" />
                                                <CheckBox type="checkbox" name="showroom_type[]" id="workshop_1" value="2" label="Workshop" />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 ">
                                            <h6>Marked as*</h6>
                                            <div className="rc-inline">
                                                <CheckBox type="checkbox" name="markas" id="markas-primary" value="1" label="Mark as Primary" />
                                                <CheckBox type="checkbox" name="markas" id="markas-latlong" value="2" label="Mark Lat. Long. as Verified" />
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="contentbreak">Outlet Timing</h2>
                                    <div className="mrg-b30"><CheckBox type="checkbox" name="alldaysopen" id="alldaysopen" value="alldaysopen" label="Set For All Days" /></div>
                                    <ul className="">
                                        <li>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2">
                                                    <p className="week-day">Monday</p>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Open Time</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                    <div className="form-group mrg-B29">
                                                        <label>Close Time</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <label className="switch-btn btn btn-link mrg-t25" htmlFor="openhrs">
                                                        <input className="switch-btn " id="openhrs" value="open" name="open[]" type="checkbox" checked="checked" />
                                                        <div className="slider round"></div>
                                                        <span className="switch-label">Open</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2">
                                                    <p className="week-day">Tuesday</p>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Open Time</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                    <div className="form-group mrg-B29">
                                                        <label>Close Time</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <label className="switch-btn btn btn-link mrg-t25" htmlFor="openhrs">
                                                        <input className="switch-btn " id="openhrs" value="open" name="open[]" type="checkbox" checked="checked" />
                                                        <div className="slider round"></div>
                                                        <span className="switch-label">Open</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2">
                                                    <p className="week-day">Wednesday</p>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Open Time</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                    <div className="form-group mrg-B29">
                                                        <label>Close Time</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <label className="switch-btn btn btn-link mrg-t25" htmlFor="openhrs">
                                                        <input className="switch-btn " id="openhrs" value="open" name="open[]" type="checkbox" checked="checked" />
                                                        <div className="slider round"></div>
                                                        <span className="switch-label">Open</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <div className="map-wrap"></div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="card-footer text-center">
                        <Button btnClass="btn btn-default mrg-r30" ><i className="sprite ic-prev-b"></i> Previous</Button>
                        <Button btnClass="btn btn-primary"> Next <i className="sprite ic-next-w"></i></Button>
                    </div>
                </div>
            </div>

        )
    }
}

export default OutletDetails