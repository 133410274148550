import React, { Component } from 'react';
import Select from 'react-select';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
let stock_category_id;

class MmvDropDown extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            model_list: [],
            make_list: [],
            version_list: [],
            stock_category_id:props.stock_category_id || 1,
            car_make: props.defaultMake,
            car_model: '',            
            car_version: ''            
        };
        this.firstUpdate = true;
        stock_category_id = this.state.stock_category_id;
    }

    componentDidMount = () => {        
        this.__getMake();
    }

    handleMakeChange = (sname, ovalue) => {
        let make_id;
        if (ovalue)
            make_id = Array.isArray(ovalue) ? ovalue.map((v) => v.id) : ovalue.id;
        this.setState({
            [sname]: make_id,
            version_list: [],            
            car_model: '',            
            car_version: ''
        }, () => {
            this.__getModel(make_id);
        });
        if (typeof this.props.handleOptionChange === 'function') {
            this.props.handleOptionChange(sname, ovalue);
        }
    }

    handleModelChange = (sname, ovalue) => {
        let model_id;
        if (ovalue)
            model_id = Array.isArray(ovalue) ? ovalue.map((v) => v.id) : ovalue.id;
        this.setState({
            [sname]: model_id,
            car_version: ''
        },() => {
            if (this.props.showVersion === '1'){
                this.__getVersion(model_id);
            }
        });
        if (typeof this.props.handleOptionChange === 'function') {
            this.props.handleOptionChange(sname, ovalue);
        }
    }

    handleVersionChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.vn_id
        });
        if (typeof this.props.handleOptionChange === 'function') {
            this.props.handleOptionChange(sname, ovalue);
        }
    }

    __getMake(){
        var parentObj = this;
        MasterService.get('core/commonservice/make_list?category_id='+stock_category_id)
        .then(function (response) {
            if(response.data.status == 200){
                if(!response.data.data){
                    parentObj.setState({ make_list: []});    
                }
                else{
                    parentObj.setState({ make_list: response.data.data});
                }
            }            
        })
        .catch(function (response) {
        });
    }

    __getModel(make_id){
        let makeIdArray = [];
        if(Array.isArray(make_id)){
            makeIdArray = make_id;
        }else{
            makeIdArray.push(make_id);
        }
        var parentObj = this;
        MasterService.get('core/commonservice/model_list?make='+makeIdArray+'&category_id='+stock_category_id)
        .then(function (response) {
            if(response.data.status == 200){                
                if(!response.data.data){
                    parentObj.setState({ model_list: [] });
                    this.setState({car_model: ''})
                }
                else{
                    parentObj.setState({ model_list: response.data.data });
                }
            }
        })
        .catch(function (response) {
        });
    }

    __getVersion(model_id){
        let modelIdArray = [];
        if(Array.isArray(model_id)){
            modelIdArray = model_id;
        }else{
            modelIdArray.push(model_id);
        }
        var parentObj = this;
        MasterService.get('core/commonservice/version_list?model='+modelIdArray+'&category_id='+stock_category_id)
        .then(function (response) {
            if(response.data.status == 200){
                parentObj.setState({ version_list: response.data.data });
            }
        })
        .catch(function (response) {
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.defaultMake && this.firstUpdate){                          
            this.handleMakeChange('car_make',{id: nextProps.defaultMake}); 
            if(!nextProps.defaultModel){
                this.firstUpdate = false; 
            }         
        }
        if(nextProps.defaultModel && this.firstUpdate){      
                    
            this.handleModelChange('car_model',{id: nextProps.defaultModel});
            if(!nextProps.defaultVersion){
                this.firstUpdate = false; 
            }     
        }
        if(nextProps.defaultVersion && this.firstUpdate){           
            
            this.handleVersionChange('car_version',{vn_id: nextProps.defaultVersion}); 
            //this.setState({car_version: nextProps.defaultVersion});    
            this.firstUpdate = false;         
        }

        if(nextProps.filterData && !nextProps.filterData.car_make){
            this.setState({'car_make':{}})
        }
        if(nextProps.filterData && !nextProps.filterData.car_model){
            this.setState({'car_model':{}})
        }
    }  

    resetAll() {
        this.handleMakeChange('car_make',{id: ''});
    }

    render() {
        let version_content = '';        
        if (this.props.showVersion === '1'){
            version_content = <div className={this.props.coloumnClass} >
                <Select
                    id="car_version"
                    value={this.state.car_version}
                    onChange={this.handleVersionChange.bind(this, 'car_version')}
                    options={this.state.version_list}
                    name="car_version"
                    placeholder= {this.props.t('listLead.Version')}
                    value={this.state.version_list.filter(({ vn_id }) => vn_id === this.state.car_version)}
                    getOptionLabel={({ vn }) => vn}
                    getOptionValue={({ vn_id }) => vn_id}
                />
            </div>
        }
        return (
            <div className="clearfix ">
              <label>{this.props.t('listLead.Select_A_Car')}{this.props.isRequired ? <span className="required" style={{ color: 'red' }}>*</span> : '' }</label>
                <div className="row">
                    <div className={this.props.coloumnClass}>
                        {/* <Select
                            id="car_make"
                            isDisabled={this.props.makeDisable}
                            value={this.state.car_make}
                            onChange={this.handleMakeChange.bind(this, 'car_make')}
                            options={this.state.make_list}
                            name="car_make"
                            placeholder= {this.props.t('listLead.Make')}
                            value={this.state.make_list.filter(({ id }) => id === this.state.car_make)}
                            getOptionLabel={({ make }) => make}
                            getOptionValue={({ id }) => id}
                        /> */}
                        <Select
                            id="car_make"
                            name="car_make"
                            value={this.state.make_list.filter(({ id }) => {
                                if(this.state.car_make && Array.isArray(this.state.car_make)){
                                    return (this.state.car_make.indexOf(id) > -1)
                                }
                                else {
                                    if (this.state.car_make && typeof this.state.car_make === 'string')
                                        return (this.state.car_make.split(',').map(v => Number(v)).indexOf(id) > -1)
                                    else
                                        return (id === this.state.car_make)
                                }
                            })}
                            isDisabled={this.props.makeDisable}
                            isMulti={this.props.multiSelectEnable}
                            placeholder={this.props.t('listLead.Make')}
                            onChange={this.handleMakeChange.bind(this, 'car_make')}
                            getOptionLabel={({ make }) => make}
                            getOptionValue={({ id }) => id}
                            options={this.state.make_list}
                        />

                    </div>
                    <div className={this.props.coloumnClass}>
                        {/* <Select
                            id="car_model"
                            isDisabled={this.props.modelDisable}
                            value={this.state.car_model}
                            onChange={this.handleModelChange.bind(this, 'car_model')}
                            options={this.state.model_list}
                            name="car_model"
                            placeholder= {this.props.t('listLead.Model')}
                            value={this.state.model_list.filter(({ id }) => id === this.state.car_model)}
                            getOptionLabel={({ m }) => m}
                            getOptionValue={({ id }) => id}
                        /> */}
                             <Select
                            id="car_model"
                            value={this.state.model_list.filter(({ id }) => {
                                if(this.state.car_model && Array.isArray(this.state.car_model)){
                                    return (this.state.car_model.indexOf(id) > -1)
                                }
                                else {
                                    if (this.state.car_model && typeof this.state.car_model == 'string')
                                        return (this.state.car_model.split(',').map(v => Number(v)).indexOf(id) > -1)
                                    else
                                        return (id === this.state.car_model)
                                }
                            })}
                            isDisabled={this.props.makeDisable}
                            isMulti={this.props.multiSelectEnable}
                            placeholder={this.props.t('listLead.Model')}
                            onChange={this.handleModelChange.bind(this, 'car_model')}
                            getOptionLabel={({ m }) => m}
                            getOptionValue={({ id }) => id}
                            options={this.state.model_list}
                        />
                    </div>
                    {version_content}                    
                </div>
            </div>
        );
    }
}

export default withTranslation('lead', { withRef: true })(MmvDropDown);
