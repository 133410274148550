import React, { useState, useEffect } from 'react';
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import LoanRibbenBox from './LoanRibbenBox';
import CoApplicantDetailsTabs from './CoApplicantDetailsTabs';
//import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { LEAD_DETAIL_QUERY } from '../../queries/sangam/leads.gql';
//import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
//import { useParams } from 'react-router-dom';
import Loader from '../../view/elements/PageLoader';
import { toast } from 'react-toastify';
import crypto from '../../config/crypto'
import { getLeadRemarks } from '../../store/action/allAction';
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import secureStorage from '../../config/encrypt';
import ActionTypes from '../../store/action/action';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { executeGraphQLQueryFinex,executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { GET_MASTER_DATA } from "../../queries/sangam/masterdata.gql";
import {STATUS_QUERY} from "../../service/leads.gql";

const CoApplicantDetail = (props) => {
    const updateStateIntoRedux = props.updateStateIntoRedux;    
    const params = useParams();   
    const [isLoading, setIsLoading] = useState(false);
    const [leadDetail, setLeadData] = useState({});
    const[masterdata,setMasterData] = useState([]);
    const [statuses, setStatuses] = useState([]);

    let lead_id = crypto.decode(params.lead_id);
    useEffect(() => {
      updateReduxData();
    })

    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        props.updateStateIntoRedux(ActionTypes.LOGIN_USER, loginResponse);
        afterLoginSetState(loginResponse);
      } else {
        return <Redirect to={"/login"} />;
      }
    };
  
    const afterLoginSetState = (loginResponse) => {
      const role = loginResponse?.data?.user_data?.role || null;
      if (role !== "dealercentral") {
        return <Redirect to={"/"} />;
      }
    };

    useEffect(() => {      
      const customerInfo = async () => {
        await leadDetails();
      };
      customerInfo();
      getMasterData();
      getstatusList();
    }, lead_id); 

    const leadDetails = async () => {
      setIsLoading(true);
      if(!lead_id) lead_id = 0;
      const result = await executeGraphQLQueryFinex(LEAD_DETAIL_QUERY(lead_id));
      if(result?.data?.get_lead_detail[0]){
        setLeadData(result?.data?.get_lead_detail[0]);
      }
      setIsLoading(false);
    };
    
    const getMasterData = () => {  
     
      executeGraphQLQueryFinex(GET_MASTER_DATA()).then((response) => {     
        setMasterData(response?.data?.masterdata)     
      })
        .catch((errors) => {
          toast.error("Somethig went wrong ,please try again")
        });
    }
    const getstatusList = () => {
      executeGraphQLQueryFinex(STATUS_QUERY).then((result) => {     
        setStatuses(result?.data?.status_list)      
      })
        .catch((errors) => {
          toast.error("Somethig went wrong ,please try again")
        });
    }

   return (
            <>
           <div className="detailstop">
           <LoanRibbenBox leadDetail={leadDetail}  masterdata={masterdata} statuses = {statuses} />
                { isLoading ? <Loader/> : ''}
                <CoApplicantDetailsTabs 
                    leadDetail={leadDetail}  
                    masterdata={masterdata}   
                    statuses = {statuses}              
                />

           </div>
</>
        )
}
const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(CoApplicantDetail)
);
