import React, { useEffect, useState } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import RadioBox from "../../../src/view/elements/Radiobox";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import MasterService from "../../service/MasterService";
import { toast } from "react-toastify";

const SubPartnerKyc = (props) => {
  const data = props.formData;
  const userList = props.userList;
  const [selectedOption, setSelectedOption] = useState("individual");
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [profession_list, setProfessions_list] = useState(
    props.professions_list
  );
  const [formValues, setFormValues] = useState({
    fullName: data.fullName,
    mobile: data.mobile,
    email: data.email,
    panNo: data.panCard,
    profession: "",
    dateOfReg: "",
    address: "",
    pincode: "",
    state: "",
    city: "",
    profession_id: null,
    AadharNo: "",
    is_msme: false,
    msme_number: "",
    is_gst: false,
    gst_number: "",
    is_payout_ambakid: "",
    is_payout_ambakName: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [ambak_payout, Setambak_payout] = useState([
    { id: 0, value: "No" },
    { id: 1, value: "Yes" },
  ]);
  useEffect(() => {
    getCityList();
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getCityList = async () => {
    const result = await MasterService.get("core/commonservice/state_city_all");

    setCity(result.data.data.city);
    setState(result.data.data.state);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxInputChange = (name, event) => {
    setFormValues({
      ...formValues,
      [name]: event.target.checked,
    });
  };

  const handleSelectChange = (name, selectedOption) => {
    if (name === "profession") {
      setFormValues({
        ...formValues,
        profession: selectedOption,
        profession_id: selectedOption.id,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: selectedOption,
      });
    }
  };
  const handlePayoutSelectChange = (name, selectedOption) => {
    setFormValues({
      ...formValues,
      is_payout_ambakName: selectedOption,
      is_payout_ambakid: selectedOption.id,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formValues.fullName) errors.fullName = "Full Name is required";
    if (!formValues.mobile) {
      errors.mobile = "Mobile Number is required";
    }
    else if (!/^\d{12}$/.test(formValues.mobile)) {
      errors.mobile = "Invalid Mobile Number";
    }
    if (formValues.email && !/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formValues.panNo) errors.panNo = "PAN Card Number is required";
    if (!formValues.dateOfReg) errors.dateOfReg = "Date of Reg is required";
    if (!formValues.address) errors.address = "Address is required";
    if (!formValues.pincode) errors.pincode = "Pincode is required";
    if (!formValues.state) errors.state = "State is required";
    if (!formValues.city) errors.city = "City is required";
    if (!formValues.is_payout_ambakName)
      errors.is_payout_ambak = "Required Field";
    if (!formValues.city) errors.city = "City is required";
    if (selectedOption === "individual") {
      if (!formValues.AadharNo) errors.AadharNo = "Aadhar is required";
      else if (!/^\d{10}$/.test(formValues.AadharNo)) {
        errors.AadharNo = "Invalid Aadhar Number";
      }
    }
    if (!formValues.profession) errors.profession = "Profession is required";
    if (selectedOption === "company") {
      if (formValues.is_msme) {
        if (!formValues.msme_number)
          errors.msme_number = "MSME number is required";
      }
      if (formValues.is_gst) {
        if (!formValues.gst_number)
          errors.gst_number = "GST number is required";
      }
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveBasicDetails = async () => {
    let reqData = {
      user_id: userList.user_id,
      partner_id: userList.dealer_id,
      name: formValues.fullName,
      mobile: formValues.mobile,
      email: formValues.email,
      pan_no: formValues.panNo,
      birth_date: formValues.dateOfReg,
      address: formValues.address,
      pincode: formValues.pincode,
      state: formValues.state.id,
      city: formValues.city.id,
      user_type: "60",
      term_condition: true,
      is_pan_verified: 1,
      profession_id: formValues.profession_id,
      aadhar_no: formValues.AadharNo,
      is_payout_ambak: formValues.is_payout_ambakid,
    };

    if (formValues.is_msme) {
      reqData.is_msme = "1";
      reqData.msme_number = formValues.msme_number;
    }
    if (formValues.is_gst) {
      reqData.is_gst = "1";
      reqData.gst_number = formValues.gst_number;
    }
    if (selectedOption === "individual") {
      reqData.applicant_type_id = 1;
    } else {
      reqData.applicant_type_id = 2;
    }

    const response = await MasterService.post(
      "/partner/partner/save-basic-details",
      reqData
    );

    if (response.data.status === 200) {
      toast.success("Success");
    } else {
      toast.error(response.data.message[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      saveBasicDetails();
    }
  };

  return (
    <>
      <h4>Add Sub Partner</h4>
      <div style={{ marginBottom: 10 }}>Partner Type</div>
      <div className="myteamsflex">
        <div style={{ marginRight: 10 }}>
          <RadioBox
            type="radio"
            name="kyc_add"
            id="individual"
            value="individual"
            label="Individual*"
            checked={selectedOption === "individual"}
            onChange={handleChange}
          />
        </div>
        <div style={{ marginRight: 10 }}>
          <RadioBox
            type="radio"
            name="kyc_add"
            id="company"
            value="company"
            label="Company*"
            checked={selectedOption === "company"}
            onChange={handleChange}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-md-4">
            <InputField
              type="text"
              name="fullName"
              id="full_name"
              placeholder="Full Name"
              label="Full Name*"
              value={formValues.fullName}
              onChange={handleInputChange}
              error={formErrors.fullName}
            />
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              maxLength={10}
              name="mobile"
              id="mobile"
              placeholder="Mobile"
              label="Mobile Number*"
              value={formValues.mobile}
              onChange={handleInputChange}
              error={formErrors.mobile}
            />
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              label="Email Address"
              value={formValues.email}
              onChange={handleInputChange}
              error={formErrors.email}
            />
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="panNo"
              maxLength={10}
              id="pan_no"
              placeholder="PAN NO"
              label="PAN Card Number*"
              value={formValues.panNo}
              onChange={handleInputChange}
              error={formErrors.panNo}
            />
          </div>
          <div className="col-md-4">
            <label>Profession*</label>
            <Select
              id="profession_id"
              options={profession_list}
              onChange={(selected) =>
                handleSelectChange("profession", selected)
              }
              name="profession_id"
              value={formValues.profession}
              getOptionLabel={({ profession_name }) => profession_name}
              getOptionValue={({ id }) => id}
              className={formErrors.profession ? "select-error" : ""}
              errors={formErrors.profession}
            />

            <div className="error show">{formErrors.profession}</div>
          </div>
          <div className="error">{formErrors.profession}</div>

          {selectedOption === "individual" && (
            <div className="col-md-4">
              <InputField
                type="text"
                name="AadharNo"
                maxLength={12}
                id="AadharNo"
                placeholder="Aadhar NO"
                label="Aadhar NO*"
                value={formValues.AadharNo}
                onChange={handleInputChange}
                error={formErrors.AadharNo}
              />
            </div>
          )}
          <div className="col-md-4">
            <InputField
              type="date"
              name="dateOfReg"
              id="date_of_reg"
              placeholder="Date of Reg"
              label="Date of Reg.*"
              value={formValues.dateOfReg}
              onChange={handleInputChange}
              error={formErrors.dateOfReg}
            />
          </div>
          <div className="col-md-4">
            <label>Payout From Ambak*</label>
            <Select
              id="is_payout_ambak"
              options={ambak_payout}
              onChange={(selected) =>
                handlePayoutSelectChange("is_payout_ambak", selected)
              }
              name="is_payout_ambak"
              value={formValues.is_payout_ambak}
              getOptionLabel={({ value }) => value}
              getOptionValue={({ id }) => id}
            />
            <div className="error show">{formErrors.is_payout_ambak}</div>
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="address"
              id="address"
              placeholder="Address Line 1"
              label="Address Line 1*"
              value={formValues.address}
              onChange={handleInputChange}
              error={formErrors.address}
            />
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="pincode"
              id="pincode"
              placeholder="Pincode"
              label="Pincode*"
              value={formValues.pincode}
              onChange={handleInputChange}
              error={formErrors.pincode}
            />
          </div>
          <div>
            <div className="col-md-4">
              <label>State*</label>
              <Select
                id="state"
                options={state}
                onChange={(selected) => handleSelectChange("state", selected)}
                name="state"
                value={formValues.state}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                className={formErrors.state ? "select-error" : ""}
              />
              {formErrors.state && (
                <div className="error show">{formErrors.state}</div>
              )}
            </div>
            <div className="col-md-4">
              <label>City*</label>
              <Select
                id="city"
                options={city}
                onChange={(selected) => handleSelectChange("city", selected)}
                name="city"
                value={formValues.city}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                className={formErrors.city ? "select-error" : ""}
              />
              {formErrors.city && (
                <div className="error show">{formErrors.city}</div>
              )}
            </div>
            {selectedOption === "company" && (
              <>
                <div>
                  <div className="col-md-4">
                    <input
                      type="checkbox"
                      name="is_msme"
                      id="is_msme"
                      checked={formValues.is_msme}
                      onChange={(event) =>
                        handleCheckboxInputChange("is_msme", event)
                      }
                      value="1"
                    />
                    <label style={{ marginLeft: 5 }}>MSME Number*</label>
                    <InputField
                      type="text"
                      name="msme_number"
                      id="msme_number"
                      placeholder="MSME Number"
                      value={formValues.msme_number}
                      onChange={handleInputChange}
                      error={formErrors.msme_number}
                      disabled={!formValues.is_msme}
                      is_label_required={true}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="checkbox"
                      name="is_gst"
                      id="is_gst"
                      checked={formValues.is_gst}
                      onChange={(event) =>
                        handleCheckboxInputChange("is_gst", event)
                      }
                      value="1"
                    />
                    <label style={{ marginLeft: 5 }}>GST Number*</label>
                    <InputField
                      type="text"
                      name="gst_number"
                      id="gst_number"
                      placeholder="GST Number"
                      value={formValues.gst_number}
                      onChange={handleInputChange}
                      error={formErrors.gst_number}
                      disabled={!formValues.is_gst}
                      is_label_required={true}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="newleadbtnflex">
          <button className="submitbtnnewlead" type="submit">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default SubPartnerKyc;
