import React, { Component } from 'react';
const CURRENCY_SEPARATOR = ",";

class BankData extends Component {

__numberWithCommas = (num) => {
  const formattedNumber = num.toLocaleString('en-IN');
  return formattedNumber;
};

  render() {
    const { flatRecords,bankName } = this.props;
    return (
      <div className='offerlistingcard'>
      <div className='offerlisting'>
        <div className='brename'>Eligible Loan Amount</div>
        <div className='breheading'>₹ {flatRecords[bankName].eligibalLoanAmount ? this.__numberWithCommas(flatRecords[bankName].eligibalLoanAmount):"0"}</div> 
      </div>

      <div className='offerlisting'>
        <div className='brename'>Monthly EMI</div>
        <div className='breheading'>₹ { flatRecords[bankName].monthlyEMI ? this.__numberWithCommas(flatRecords[bankName].monthlyEMI) : "NA"}</div>
      </div>
      <div className='offerlisting'>
        <div className='brename'>Interest Rate</div>
        <div className='breheading'>{(flatRecords[bankName].minRoi)+"% onwards" || "NA"}</div>
      </div>

      <div className='offerlisting'>
        <div className='brename'>Tenure</div>
        <div className='breheading'>{flatRecords[bankName].maxTenure  ? flatRecords[bankName].maxTenure+" Years":""}</div>
      </div>
      <div className='offerlisting'>
        <div className='brename'>Processing Fees</div>
        {/* <div className='breheading'>₹ {this.__numberWithCommas(flatRecords[bankName].processingFee) || "NA"}</div> */}
        <div className='breheading' style={{fontSize:14}}>1% of Loan Amount + GST</div>
      </div>

    </div>
      );
  }
}

export default BankData;