import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import InputField from "../../../../view/elements/Input";
import LoanAllInfo from "./LoanAllInfo";
import { useSelector } from "react-redux";
import { executeGraphQLMutationFinex, executeGraphQLQueryFinex } from "../../../../common/executeGraphQLQuery";
import { GET_MASTER_DATA } from "../../../../queries/sangam/masterdata.gql";
import { toast } from "react-toastify";
import { SAVE_LOAN_DETAILS } from "../../../../queries/sangam/customer.gql";
import { useLoanDetail } from '../../../../view/ruleEngine/component/loanDetailContext';
import GeneralService from "../../../../service/generalService";
import { getNumberFormat_IN, handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";

const SEP_ID = 2;
const LoanOffer = ({ setHome }) => {
  const [showNext, setShowNext] = useState(1);
  const [masterdata,setMasterData] = useState([]);
  const [errors, setErrors] = useState({});
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [offerVisibility, setOfferVisibility] = useState(false);
  const { loanDetails, updateLoanDetails } = useLoanDetail();

  const handleFieldChange = (name, value,price=false) => {
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name];  
      return updatedErrors;
    });
    updateLoanDetails(name, value);
  };

const setStateAndCityList = async () => {
  try {
    const response = await GeneralService.stateCityList();
    if (response?.data?.status === 200) {
      const result = { ...response.data.data };
      if (result.city && result.city.length) {
        result.city = result.city.map((data) => ({
          ...data,
          value: data.id,
          label: data.name,
        }));
      }

      if (result.state && result.state.length) {
        result.state = result.state.map((data) => ({
          ...data,
          value: data.id,
          label: data.name,
        }));
      }
      setCityList(result.city);
      setStateList(result.state);
    }
  } catch (error) {
    console.log(error.message);
    toast.error("Failed to fecth state and city list");
  }
};
  const getMasterData = async () => {
    try {
      const response = await executeGraphQLQueryFinex(GET_MASTER_DATA());
  
      if (response && response.data && response.data.masterdata) {
        let result = { ...response.data.masterdata }; // Create a copy to avoid mutating the original response
        
        // Define keys that need processing
        let keyList = [
          'tenure', 'agreement_type', 'profession', 'salary_credit_mode',
          'property_type', 'usage_type', 'loan_type', 'loan_sub_type'
        ];
  
        // Process each key in keyList
        for (let item of keyList) {
          if (result[item] && result[item].length) {
            result[item] = result[item].map(data => ({
              ...data,
              value: +data.id, // Convert id to number
              label: data.label // Maintain the label
            }));
          }
        }
  
        // Process bank_list if it exists
        if (result.bank_list && result.bank_list.length) {
          result.bank_list = result.bank_list.map(data => ({
            ...data,
            value: data.id, // Use id as value
            label: data?.banklang?.bank_name || '' // Use bank name or empty string if not available
          }));
        }
  
  
        // Set the processed data in state
        console.log("result",result)
        setMasterData(result);
      }
    } catch (error) {
      // Handle any errors that occur during the query
      console.error("Error fetching master data:", error);
      toast.error("Something went wrong, please try again");
    }
  };

  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? masterdata : {};
    options["company_type"] = options["company_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["additional_income_type"] = options["additional_income_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["property_type"] = options["property_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["property_sub_type"] = options["property_sub_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["agreement_type"] = options["agreement_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["profession"] = options["profession"]?.filter(
      (obj) => obj.value !== SEP_ID
    );
    options["filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    options["filtered_co_additional_income_type_0_0"] = options[
      "additional_income_type"
    ]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    options["state_list"] = stateList;
    options["city"] = cityList;
    return options;
  }, [masterdata,stateList, cityList]);



const handleNext = () => {
  if(!loanDetails?.loan_amount){
    setErrors((prevErrors) => ({ ...prevErrors, "loan_amount": "Loan amount is required" }));
  }
  if(parseInt(loanDetails?.loan_amount)<=0){
    setErrors((prevErrors) => ({ ...prevErrors, "loan_amount": "Loan amount should be greater than 0" }));
  }
  if(!loanDetails?.employment_type){
    setErrors((prevErrors) => ({ ...prevErrors, "employment_type": "Employment type is required" }));
  }
  if (!(!loanDetails?.loan_amount || +loanDetails?.loan_amount<=0 || !loanDetails.employment_type)) {
    setShowNext(showNext + 1);
  } 
};

  const handlePre = () => {
    if (offerVisibility) return setOfferVisibility(false);
    if(showNext!==1){
      setShowNext(showNext - 1);
    }
    if(showNext==1){
      setHome(false);
    }
  };

  useEffect(()=>{
    getMasterData();
    setStateAndCityList()
  },[])

  const handleBankOfferVisibility = (visibility) => {
    setOfferVisibility(visibility);
  }


  return (

    // <div class="containernew">
    //     <div class="top">Top Content</div>
    //     <div class="middle">Middle Content</div>
    //     <div class="bottom">Bottom Content</div>
    // </div>
    <div className="mytoolscard">
      <div className="myloanoffercard">
        <div onClick={handlePre} style={{ cursor: "pointer" }}>
          <img src={require("../../../images/left arrow.svg")} alt="not" />
          Loan Offers Calculator
        </div>

        {showNext === 1 && (
          <>
            <div className="myloanofferflex">
              <div className="loanofferleftpanle">
                <div className="loanofferheading">
                  Match your
                  <br /> customers with
                  <span className="loanoffersub"> the right lenders</span>
                </div>
                <div className="loanoffermiddle">
                <video width="100%" height="360" autoPlay controls={false}>
                  <source src="https://storage.googleapis.com/ambak/video/ambak-video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                
                </div>
              </div>

              <div className="loanofferrightpanle">
                  <div className="loanoffersubheading">
                    Pick the best loan offer
                    <br /> your customers!
                  </div>
                  <div className="loanoffermiddle">
                  <div className="row">
                    <div className="col-sm-12" style={{ marginBottom: 20 }}>
                      <label>Employment Type <span className="form-required">*</span></label>
                      <Select
                        required={true}
                        id="employment_type"
                        onChange={(e) => handleFieldChange('employment_type', e.value)}
                        options={ALL_OPTIONS?.["profession"]||[]}
                        name="employment_type"
                        placeholder="Select Employment Type"
                        value={ALL_OPTIONS?.["profession"]?.find(option => option.value === loanDetails.employment_type)}
                      />
                      {errors.employment_type && <div className="formerror">{errors.employment_type}</div>}
                    </div>
                    <div className="col-sm-12" >
                      <InputField
                        type="text"
                        name="loan_amount"
                        id="loan_amount"
                        maxLength={12}
                        onChange={(e)=>handleFieldChange('loan_amount', e.target.value,true)}
                        placeholder="Required Loan Amount"
                        label="Required Loan Amount"
                        required={true}
                        value={handlePriceInputIndian(loanDetails['loan_amount'])}
                      />
                      {errors.loan_amount && <div className="formerrorinput">{errors.loan_amount}</div>}
                    </div>
                  </div>

                  <button className="getstartedbtn" onClick={handleNext}>Get Started!</button>
                  </div>
              </div>
            </div>
          </>
        )}



        {showNext === 2 && (
          <>
          <LoanAllInfo allOptions={ALL_OPTIONS} showNext={showNext} bankOfferVisibility={handleBankOfferVisibility} showBankOfferList={offerVisibility}/>

          </>
        )}

      </div>
    </div>
  );
};

export default LoanOffer;
