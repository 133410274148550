import React, { useEffect, useState } from "react";
import "./verify-otp-popup.css";
import MasterService from "../../../service/MasterService";
import { toast } from "react-toastify";

const VerifyOtpModal = ({ mobile , verifyOtp }) => {
  const [inputOtp, setInputOtp] = useState("");
  useEffect(() => {
    if (mobile) {
      sendOTP(mobile);
    }
  }, []);

  const sendOTP = (mobile) => {
    const params = {
      mobile: mobile,
      api_called_by: "check_valid_mobile",
    };

    MasterService.post("/account/user/send_otp", params)
      .then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
        } else {
          if (response.data.error && response.data.error.length > 0) {
            response.data.error.forEach((element) => {
              toast.error(element);
            });
          } else if (response.data.message) {
            toast.error(response.data.message);
          } else {
            toast.error("Failed");
          }
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputOtp(value);


    if (value.length === 6) {
      handleVerifyOTP(value);
    }
  };

  const handleVerifyOTP = (otp) => {
    const params = {
      mobile: mobile,
      api_called_by: "check_valid_mobile",
      otp: otp ?? inputOtp,
      check_valid_mobile: 1,
      kind: "1",
      api_called_by: "check_valid_mobile",
    };

    MasterService.post("/account/user/validate_otp", params)
      .then(function (response) {
        if (response.data.status === 200) {
            // convertLead(lead);
        //   setOtp(response?.data?.data.otp);
        //   toast.success(response.data.message);
        verifyOtp(true)
        alert("success")
        } else {
          if (response.data.error && response.data.error.length > 0) {
            response.data.error.forEach((element) => {
              toast.error(element);
            });
          } else if (response.data.message) {
            toast.error(response.data.message);
          } else {
            toast.error("Failed");
          }
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
  };

  return (
    <div className="lead-otp-verify-container">
      <h2>Verify OTP</h2>
      <br></br>
      <p>{`We have sent an OTP to ${mobile}`}</p>
      <div className="lead-otp-verify-input-container">
        <input
          type="text"
          value={inputOtp}
          onChange={handleChange}
          maxLength="6"
          className="lead-otp-verify-otp-input"
          placeholder="------"
        />
      </div>
      <button
        className="lead-otp-verify-verify-button"
        onClick={() => handleVerifyOTP(inputOtp)}
        disabled={inputOtp.length !== 6}
      >
        Verify
      </button>
    </div>
  );
};

export default VerifyOtpModal;
