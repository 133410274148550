import React, { useState } from "react";

const MobileAlready = ({ leadList , handleDataFromMobileAlready}) => {
  const [selectedRadioOption, setSelectedRadioOption] = useState("");

  
  const [ead_data , setLead_data] = useState({})
  console.log ("+++++++++++++++++" , leadList)
  const handleRadioChange = (event) => {
    console.log (event.target.value , event.target.name)
    setSelectedRadioOption(event.target.value);

  };
  const handleInput  = (data) => {
    console.log (">>>>>>>>>>>>>" , data)
    setLead_data(data)

  }

  const handleNext = () => {
    handleDataFromMobileAlready(ead_data)

  }

  return (
    <>
    
    <div className="mytoolverification">
      <h2 className="verificationheadingmain">Mobile No. Already Exists</h2>
      <p>
        This number exists for the following customer(s). Select the customer in
        order to<br></br> proceed or create a new lead.
      </p>

      <div className="row">
        {leadList.map((data) => {
          return (
            <div className="col-sm-6">
              <div className="loanfulfillment">
                <div className="loaninput">
                  <input
                    type="radio"
                    required
                    id="ambakFulfillment"
                    name="fulfillment_type"
                    value="ambak"
                    checked={selectedRadioOption === "ambak"}
                    onChange={(e) => handleRadioChange(e)}
                    onClick={(e) => handleInput(data)}
                  />
                  <div>
                    <label htmlFor="ambakFulfillment">{data.customer.first_name}</label>
                    <div>ID - {data.id} | {data.loan_type === 1 ? "HL" : "LAP"}</div>
                  </div>
                </div>
                <div className="primaryaccount">Primary</div>
              </div>
            </div>
          );
        })}

            

      </div>
    </div>
     <div className="buttonflexmobile">
     <button className="cibilnewlead">+ New Lead</button>
     <button className="cibilcheckbtn" onClick={handleNext}>
       Continue
     </button>
   </div>
    </>
  );
};

export default MobileAlready;
