import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import MultiSelect from '../../view/elements/MultiSelect';

const MyLeadListFilter = ({ masterdata, handleSearch, setFilterParam , assignTo }) => {
  const [filterData, setFilterData] = useState({
    search_type: "lead_id",
    search_text: "",
    assigned_to: null,
  });

  const fulfillments = masterdata?.fulfillment_type || [];
  const productType = masterdata?.loan_type || [];
  const productSubType = masterdata?.loan_sub_type?.map(ob => ({ ...ob, value: ob.id })) || [];
  const propertyType = masterdata?.property_type || [];
  const DateType = masterdata?.sub_status_dates;
  let BankList = [];

  const SEARCH_TYPE = [
    { value: "lead_id", label: "Lead ID" },
    { value: "full_name", label: "Full Name" },
    { value: "mobile", label: "Customer Phone" },    
  ];

  const handleChange = (name, value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };

  const handleMultiChange = async (n, v, isMulti, opt, optl) => {
    if (n === 'bank_id') {
      setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
    }
  };

  const handleDateChange = (name, value) => {
    switch(name) {
      case 'createFromDate':
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          [name]: value,
          from_date: value,
          date_type: 'created_date',
        }));
      break;
      case 'createToDate':
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          [name]: value,
          to_date: value,
          date_type: 'created_date',
        }));
      break;
      case 'updatedDate':
        setFilterData((prevFilterData) => ({
          ...prevFilterData,
          [name]: value,
          last_update_from_date: value,
          last_update_to_date: value,
        }));
      break;
    }
  };

  const searchLead = () => {
   // console.log("filterData", filterData);
    localStorage.removeItem("leadFilterData");
    localStorage.setItem('leadFilterData', JSON.stringify (filterData));
    handleSearch(filterData);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchLead();
    }
  };

  const resetSearchLead = (e) => {  
    localStorage.removeItem("leadFilterData");  
    setFilterData({});   
    setFilterParam();    
    handleSearch({});
    window.location.reload()
       
  };

  let fulfillments_option = fulfillments.filter(item => item.id === 'self' || item.id === 'ambak')
    .map(item => ({ ...item, value: item.id }));

  if (BankList.length === 0) {
    let banklistAray = masterdata?.bank_list;
    banklistAray && banklistAray.forEach(ob => {
      BankList.push({ bank_id: ob?.id, bank_name: ob?.banklang?.bank_name });
    });
  }

  return (
    <div className="leadcontainer">
      <div className="searchinput" style={{marginBottom:"-20px"}}>
        <div className="row">
          <div className="col-md-2" style={{ marginTop: 20 }}>
            <Select
              options={SEARCH_TYPE}
              placeholder="Search Type"
              name="search_type"
              value={SEARCH_TYPE.find((opt) => opt.value === filterData.search_type)}
              onChange={(e) => handleChange("search_type", e.value)}
            />
          </div>
          <div className="col-md-2">
            <InputField
              id="search_text"
              type="text"
              name="search_text"
              value={filterData.search_text}
              onChange={(e) => handleChange("search_text", e.target.value)}
              className="form-input-new"
              title={"SEARCH_TEXT"}
              placeholder="Search"
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="col-md-2">
            <InputField
              type="date"
              name="createFromDate"
              id="createFromDate"
              value={filterData.createFromDate}
              placeholder="From date"
              label="Create from Date"
              onChange={(e) => handleDateChange("createFromDate", e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <InputField
              type="date"
              name="createToDate"
              id="createToDate"
              value={filterData.createToDate}
              placeholder="To date"
              label="Create to Date"
              onChange={(e) => handleDateChange("createToDate", e.target.value)}
            />
          </div>
          <div className="col-md-2" style={{ marginTop: 20 }}>
            <div className="more-filter">
              <label className="morefilterbtn" htmlFor="toggleCheckbox">
                More Filters
                <span>
                  <img
                    src={require("../../../src/saathi-web/images/filtericon.svg")}
                    alt="not"
                    width={16}
                    height={16}
                  />
                </span>
              </label>
              <input type="checkbox" id="toggleCheckbox" />
              <div id="filterCard">
                <div className="row">
                <div className="col-md-6" style={{marginBottom:20}} >  
                    <MultiSelect
                      options={BankList}
                      placeholder={"Bank"}
                      name={"bank_id"}
                      optionLabel="bank_name"
                      optionValue="bank_id"
                      values={filterData.bank_id}
                      onChange={handleMultiChange}
                    />               
                   </div>
                   <div className="col-md-6" style={{marginBottom:20}}>                  
                    <Select
                      options={productType}
                      placeholder={"Product Type"}
                      name={"loan_type"}
                      optionLabel="label"
                      optionValue="id"
                      value={productType.find(({ id }) => id === filterData.loan_type)}
                      onChange={(e) => handleChange("loan_type", e.id)}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6" style={{marginBottom:20}}>
                    <Select
                      id="assigned"
                      options={assignTo?.map(opt => ({
                        value: opt.sub_partner_id,
                        label: opt.sub_partner_name
                      }))}
                      onChange={(e) => handleChange("assigned_to", e)}
                      name="assigned"
                      value={filterData.assigned_to}
                      placeholder={"Assigned"}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  </div>
                  <div className="col-md-6" style={{marginBottom:20}}>                  
                    <Select
                      options={productSubType}
                      placeholder={"Product Sub Type"}
                      name={"loan_sub_type"}
                      optionLabel="label"
                      optionValue="id"
                      value={productSubType.find(({ id }) => id === filterData.loan_sub_type)}
                      onChange={(e) => handleChange("loan_sub_type", e.id)}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6" style={{marginBottom:20}}>                  
                    <Select
                      options={propertyType}
                      placeholder={"Property Type"}
                      name={"property_type"}
                      optionLabel="label"
                      optionValue="id"
                      value={propertyType.find(({ id }) => id === filterData.property_type)}
                      onChange={(e) => handleChange("property_type", e.id)}
                    />
                  </div>
                  <div className="col-md-6" style={{marginBottom:20}}>                  
                    <Select
                      options={fulfillments_option}
                      placeholder={"Fulfillment"}
                      name={"fulfillment_type"}
                      optionLabel="label"
                      optionValue="id"
                      value={fulfillments_option.find(({ id }) => id === filterData.fulfillment_type)}
                      onChange={(e) => handleChange("fulfillment_type", e.id)}
                    />
                  </div>
                  
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                    <InputField
                      type="date"
                      name="updatedDate"
                      id="updatedDate"
                      value={filterData.updatedDate}
                      placeholder="Updated Date"
                      is_label_required={true}
                      label="Updated Date"
                      onChange={(e) => handleDateChange("updatedDate", e.target.value)}
                    />
                  </div>
                  </div>
                
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{ marginTop: 20 }}>
            <button className="searchbutton mrg-r10" onClick={searchLead}>
              Search
            </button>
            <button
              onClick={resetSearchLead}
              className="resetbutton"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLeadListFilter;
