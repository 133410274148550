import React from "react";
import InputField from "../../../../view/elements/Input";
import Select from "react-select";
import { useLoanDetail } from "../../../../view/ruleEngine/component/loanDetailContext";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";

const PROPERTY_TABS = [
  { id: "yes", label: "Yes" },
  { id: "no", label: "No" }
];

const SUB_PROPERTY_MAPPING_OBJ = {
  9: [1, 2, 3],
  11: [1, 4],
  12: [1, 4],
  13: [1, 2, 3],
  14: [1, 2, 3, 4],
};

const LoanOfferProperty = ({allOptions,handleErrorReset,errorFields}) =>{
  const { loanDetails, updateLoanDetails } = useLoanDetail();
  const handlePropertyClick = (propertyId) =>{
    updateLoanDetails('property_detail', propertyId)
  }
  const filteredSubPropertyOptions = () => {
    if (loanDetails.property_type && SUB_PROPERTY_MAPPING_OBJ[loanDetails.property_type]) {
      return allOptions['property_sub_type'].filter(option =>  SUB_PROPERTY_MAPPING_OBJ[loanDetails.property_type].includes(+option.value));
    }
    return [];
  };
  const handleFieldChange = (name, value,price=false) => {
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
    updateLoanDetails(name, value);
  };
  const setCityByStateChange = (state) => {
    let options = allOptions ? { ...allOptions } : {};
    if (options?.city && options?.city?.length && state) {
      let record = options.city
        .filter((ob) => ob.state_id == state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });

      allOptions["city_list"] = record;
    }
  };
    return(
        <>
          <div className="commonloancard">
          <div className="mainheadingall">Property Details</div>
          <div className="allheadingincome">Property Details</div>
        <div className="cibilloanflex">
            {PROPERTY_TABS.map((tab) =>(
                <div
                key={tab.id} 
                className={`cibilhometab ${
                loanDetails.property_detail === tab.id ? "active" : ""
                }`}
                onClick={() => handlePropertyClick(tab.id)}
                >
                    <span>{tab.label}</span>

                </div>
            ))}

        </div>
        {
          loanDetails.property_detail=='yes' && 
          <div className="loanofferallbox">
            <div className="row">
            <div className="col-sm-4">
                <InputField
                  type="text"
                  maxLength={12}
                  name="exp_market_value"
                  id="exp_market_value"
                  placeholder="Expected Market Value"
                  label="Expected Market Value"
                  onChange={(e) => handleFieldChange('exp_market_value', e.target.value,true)}
                  value={handlePriceInputIndian(loanDetails.exp_market_value)}
                />
              </div>
              <div className="col-sm-4">
                <InputField
                  type="text"
                  maxLength={12}
                  name="registration_value"
                  id="registration_value"
                  placeholder="Property Registration Value"
                  label="Property Registration Value"
                  onChange={(e) => handleFieldChange('registration_value', e.target.value,true)}
                  value={handlePriceInputIndian(loanDetails.registration_value)}
                />
              </div>
              <div className="col-sm-4">
                    <label>Property Type</label>
                    <Select
                      id="property_type"
                      onChange={(e) => handleFieldChange('property_type', e.value)}
                      options={allOptions['property_type']}
                      name="property_type"
                      placeholder="Property Type"
                      value={allOptions['property_type'].find(option=>option.value === loanDetails.property_type)||""}
                    />
                  </div>
            </div>
            <div className="row">
            <div className="col-sm-4">
                    <label>Property Sub-Type</label>
                    <Select
                      id="property_sub_type"
                      onChange={(e) => handleFieldChange('property_sub_type', e.value)}
                      options={filteredSubPropertyOptions()}
                      name="property_sub_type"
                      placeholder="Property Sub-Type"
                      value={allOptions['property_sub_type'].find(option=>option.value === loanDetails.property_sub_type)||""}
                    />
                  </div>
                  {(loanDetails.property_type==9||loanDetails.property_type==13)&&<div className="col-sm-4">
                    <label>Agreement Type</label>
                    <Select
                      id="agreement_type"
                      onChange={(e) => handleFieldChange('agreement_type', e.value)}
                      options={allOptions['agreement_type']}
                      name="agreement_type"
                      placeholder="Agreement Type"
                      value={allOptions['agreement_type'].find(option=>option.value === loanDetails.agreement_type)||""}
                    />
                  </div>}
                  <div className="col-sm-4">
                    <label>State</label>
                    <Select
                      id="state"
                      onChange={(e) => {
                        setCityByStateChange(e.value)
                        handleFieldChange('state', e.value)
                      }}
                      options={allOptions['state_list']}
                      name="state"
                      placeholder="Select State"
                      value={allOptions['state_list'].find(option=>option.value === loanDetails.state)||""}
                    />
                  </div>
                 
            </div>
            <div className="row">
                  {loanDetails?.state && <div className="col-sm-4" style={{marginTop:20}}>
                    <label>City</label>
                    <Select
                      id="city"
                      onChange={(e) => handleFieldChange('city', e.value)}
                      options={allOptions['city_list']}
                      name="city"
                      placeholder="Select City"
                      value={allOptions['city_list'].find(option=>option.value === loanDetails.city)||""}
                    />
                  </div>}
                  </div>

          </div>
        }

          </div>
        </>
    );
}



export default LoanOfferProperty;


