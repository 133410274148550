import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


const bankCarouselData = [
  {
    bankName: "HDFC BANK",
    maxAmount: "₹ 10,50,12,600",
    monthlyEmi: "₹ 12,600*",
    interestRate: "8.45%*",
    processingFees: "1% of loan amt + GST",
    imageSrc: require(`../../src/saathi-web/images/hdfclogo.svg`),
  },
  {
    bankName: "ICICI BANK",
    maxAmount: "₹ 8,00,00,000",
    monthlyEmi: "₹ 10,000*",
    interestRate: "7.50%*",
    processingFees: "0.5% of loan amt + GST",
    imageSrc: require(`../../src/saathi-web/images/notifybell.svg`),
  },
  {
    bankName: "SBI BANK",
    maxAmount: "₹ 15,00,00,000",
    monthlyEmi: "₹ 15,000*",
    interestRate: "6.80%*",
    processingFees: "1.2% of loan amt + GST",
    imageSrc: require(`../../src/saathi-web/images/hdfclogo.svg`),
  },
];

const BankCarouselList = () => {

  
  return (


    <>
    <Carousel
      // autoPlay={true}
      // interval={2000}
      showArrows={false}
      showThumbs={false}
      infiniteLoop={true}
      stopOnHover={true}
      swipeable={true}
      emulateTouch={true}
      showStatus={false}
      transitionTime={500}
    >
      {bankCarouselData.map((bank, index) => (
        <div key={index} className="bankcarouselcard">
          <div className="bankcarousellist">
            <div className="blistankname">
              <div>
                
                <img
                  src={bank.imageSrc}
                  alt="bank logo"
                  width={20}
                  height={20}
                />
              </div>
              <span>{bank.bankName}</span>
            </div>
            <div className="bankpricelist">
              <div>
                <div className="priceinamount">{bank.maxAmount}</div>
                <div className="priceinmonth">Max Amount</div>
              </div>
              <div>
                <div className="priceinamount">{bank.monthlyEmi}</div>
                <div className="priceinmonth">Monthly EMI</div>
              </div>
              <div>
                <div className="priceinamount">{bank.interestRate}</div>
                <div className="priceinmonth">Rate of interest</div>
              </div>
            </div>
            <div className="bankonwordsnew">*onwards</div>
          </div>
          <div className="bankprocessing">
            <span>.</span>
            Processing Fees: {bank.processingFees}
          </div>
        </div>
      ))}
    </Carousel>
    </>
  );
};

export default BankCarouselList;
