import React, { Component } from 'react';
import Button from '../../elements/Button';
import secureStorage from './../../../config/encrypt';
import MasterService from './../../../service/MasterService';
import InputContext from './InputContext';
import BankData from './bankData';
import Modal from './../../common/Modal';
import { toast } from 'react-toastify';
import InputField from '../../elements/Input';
import { Redirect } from 'react-router-dom';

const CURRENCY_SEPARATOR = ",";

class BreDetails extends Component {
  static contextType = InputContext;

  constructor(props) {
    super(props);
    this.state = {
      ruleData: {},
      customerId: Math.floor(10000 + Math.random() * 90000),
      loading: false,
      pageloading: true,
      listData: [],
      DocumentData: [],
      page: 1,
      calculatedData: {},
      tenure: 0,
      min_roi: 0,
      isOpen: false,
      isSend: false,
      isDocument: false,
      hideSendWA: false,
      inputValue: '',
      activeIndex: null,
      bankClick: null,
    }
  }



  fetchBREData = async () => {
    const { inputValues } = this.context;
    if (Object.keys(inputValues).length > 0) {
      const match = (inputValues.company_cat) ? inputValues.company_cat.match(/_([^_]+)$/) : "";
      const lastPart = match ? match[1] : null;
      let property_apf;

      if (!inputValues.project_name) {
        property_apf = "No";
      } else if (inputValues.project_name == "Other") {
        property_apf = "No";
      } else if (inputValues.project_name) {
        property_apf = "Yes";
      }
      const requestData = {
        page_no: this.state.page,
        net_monthly_salary: inputValues.net_monthly_salary?.replace(/[^\d.]/g, '') || 0,
        dob: inputValues.dob,
        desired_loan_amount: inputValues.desired_loan_amount?.replace(/[^\d.]/g, '') || 0,
        occupation: inputValues.occupation || '',
        total_work_exp: inputValues.total_work_exp || 0,
        company_type: inputValues.company_type || '',
        company_cat: lastPart || '',
        property_apf: property_apf || '',
        property_decided: inputValues.property_decided || '',
        property_city: inputValues.property_city || '',
        property_usage: inputValues.property_usage || '',
        property_type: inputValues.property_type || '',
        property_sub_type: inputValues.property_sub_type || '',
        expected_market_value: inputValues.expected_market_value?.replace(/[^\d.]/g, '') || 0,
        bureau_score: inputValues.bureau_score || 0,
        is_additional_income: inputValues.additional_income ? 'Yes' : 'No',
        //rent: inputValues.rent || 0,
        rent_amount: inputValues.rent_amount?.replace(/[^\d.]/g, '') || 0,
        //monthly_incentives: inputValues.monthly_incentives || 0,
        monthly_incentives_amount: inputValues.monthly_incentives_amount?.replace(/[^\d.]/g, '') || 0,
        //annual_bonus: inputValues.annual_bonus || 0,
        annual_bonus_amount: inputValues.annual_bonus_amount?.replace(/[^\d.]/g, '') || 0,
        monthly_reimbursements: inputValues.monthly_reimbursements?.replace(/[^\d.]/g, '') || 0,
        total_montly_emi: inputValues.total_montly_emi?.replace(/[^\d.]/g, '') || 0,
        avg_monthly_credit_outstanding: inputValues.avg_monthly_c_outstanding?.replace(/[^\d.]/g, '') || 0,
        co_income: inputValues.co_income?.replace(/[^\d.]/g, '') || 0,
        co_bureau_score: inputValues.co_bureau_score || 0,
        is_co_appliant: inputValues.is_co_appliant ? 'Yes' : 'No',
        //co_rent: inputValues.co_rent || 0,
        co_rent_amount: inputValues.co_rent_amount?.replace(/[^\d.]/g, '') || 0,
        //co_monthly_incentives: inputValues.co_monthly_incentives || 0,
        co_monthly_incentives_amount: inputValues.co_monthly_incentives_amount?.replace(/[^\d.]/g, '') || 0,
        //co_annual_bonus: inputValues.co_annual_bonus || 0,
        co_monthly_reimbursements_amount: inputValues.co_monthly_reimbursements_amount?.replace(/[^\d.]/g, '') || 0,
        co_total_montly_emi: inputValues.co_total_montly_emi?.replace(/[^\d.]/g, '') || 0,
        avg_monthly_credit_outstanding_co: inputValues.co_avg_monthly_c_outstanding?.replace(/[^\d.]/g, '') || 0,
      };
      //console.log(requestData)
      var thisObj = this;
      this.setState({ loading: true })
      await MasterService.post('process-rule/process-rule', requestData)
        .then(function (response) {
          thisObj.flag = false;
          //console.log(response.data);
          thisObj.setState({ loading: false, pageloading: false })
          if (response.data.status == 200) {
            if (response.data.data.length) {


              if (thisObj.state.page <= 1) {
                thisObj.setState({ listData: response.data.data, loading: false, pageloading: false }, () => {
                  thisObj.flag = true;
                });
              }
              else {
                thisObj.setState({
                  listData: thisObj.state.listData.concat(response.data.data)
                  , loading: false, pageloading: false
                }, () => {
                  thisObj.flag = true;
                });
              }
            } else {
              if (thisObj.state.page <= 1) {
                thisObj.setState({ listData: response.data.data });
              }
            }
          }
        })
        .catch(function (response) {
          thisObj.setState({ loading: false, pageloading: false })
        });
    } else {
      //thisObj.setState({ listData: { result: "No Data Found" } });
    }
  };



  updateReduxData = async () => {
    if (secureStorage.getItem('loginUserInfo')) {
      var loginResponse = secureStorage.getItem('loginUserInfo');
      this.afterLoginSetState(loginResponse)
    } else {
      this.setState({ redirect: true })
    }
  }
  componentDidMount = async () => {
    await this.fetchBREData();
    await this.updateReduxData();
  }

  componentWillUnmount() {

  }

  handleBackTab = () => {
    this.props.history.push('/admin/income-profile');
  };

  handlePrevPage = () => {
    this.props.history.goBack();
  };

  afterLoginSetState = (loginResponse) => {
    let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
  
  }


  findValuesByKey = (data, keyToFind) => {
    let values = [];
    const searchForKey = (entry) => {
      if (entry && entry.hasOwnProperty(keyToFind)) {
        values.push(entry[keyToFind]);
      } else if (Array.isArray(entry)) {
        entry.forEach((obj) => searchForKey(obj));
      } else if (entry && entry.params && entry.params.hasOwnProperty(keyToFind)) {
        values.push(entry.params[keyToFind]);
      }
    };
    data.forEach((entry) => searchForKey(entry));
    return values.length > 0 ? values[0] : null;
  };

  handleApplyNow = () => {
    this.setState({ isOpen: true });
  }

  handleSendNow = () => {
    this.setState({ isSend: true });
  }

  handleDocument = (bankName) => {
    this.setState({ isDocument: true, bankClick: bankName });
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.inputValue.trim() !== '') {
      this.setState({ hideSendWA: true });
    } else {
      toast.error('Please enter whatsApp number.'); return;
    }
  };

  DocumentData = [
    {
      heading: 'Identity and Residence Proof 1',
      subItems: ['Electricity Bill', 'Election ID Card', 'Landline Telephone Bill', 'Aadhaar Card', 'Passport', 'Driving Licence']
    },
    {
      heading: 'Identity and Residence Proof 2',
      subItems: ['Item 1', 'Item 2', 'Item 3']
    },
    // Add more objects as needed
  ];

  render() {
    const { inputValues } = this.context;
    // Flatten the nested arrays of records
    const flatRecords = this.state.listData;


    if (Object.keys(inputValues).length > 0) {
      return (

        <div className={(this.state.pageloading) ? "card container-fluid loading" : "card container-fluid"}>
          <div className='brenewcard'></div>
          <div className='brecard'>
            <div className='brelisting'>
              <div className='brelistingwidth'>
                <div className='bredetails'>
                  <div className='brename'>Name</div>
                  <div className='breheading'>{inputValues.name || "Customer"}</div>
                </div>

                <div className='bredetails'>
                  <div className='brename'>Customer ID</div>
                  <div className='breheading'>#{this.state.customerId}</div>
                </div>
                <div className='bredetails'>
                  <div className='brename'>Required Loan Amount</div>
                  <div className='breheading'>₹ {(inputValues.desired_loan_amount) || "0"}</div>
                </div>
              </div>
            </div>
          </div>
          {flatRecords ? (
            Object.keys(flatRecords).map((bankName, index) => (


              <div key={index} className='breoffercard'>

                <div className="breofferheading">
                  {bankName === "Lnt" ? "Preferred Loan Offers" : bankName === "Hdfc" ? "Other Loan Offers" : ""}
                </div>
                <div className='offerbox'>
                  <div className='offercardflex'>

                    <div className='offerimg'>
                      <img src={require(`./../../ruleEngine/assets/images/${bankName.toLowerCase()}-bank-logo.svg`)} alt="" />
                    </div>

                    <div className='offerwidth'>
                      <BankData flatRecords={flatRecords} bankName={bankName} />

                      <div className='offermeterflex'>
                        <div className='offermeter'>
                          <div className='confidencename'>Confidence Meter</div>
                          <div className='commonchance'>Low</div>
                          <div className='inputrange'>
                            <input
                              type="range"
                              min="0"
                              max="100"
                              value={flatRecords[bankName].confidenceMeter}
                              onChange={this.handleSliderChange}
                            />
                          </div>
                          <div className='commonchance'> Excellent</div>
                        </div>
                        {(bankName === "Lnt") && (
                        <>
                        <input type="checkbox" className="toggle-checkbox" id="toggle-tips" />
                        
                            <label htmlFor="toggle-tips">
                              <div className='offermeternew'>
                                <div className='confidencename'>Explore tips to improve loan offer</div>
                                <img src={require('./../../ruleEngine/assets/images/downarrow.svg')} alt="" />
                                <div className="tips-list">
                                  <div>
                                    <ul>
                                      {flatRecords[bankName].messages && Object.keys(flatRecords[bankName].messages).map((messageKey, messageIndex) => (
                                        <li key={messageIndex}>{flatRecords[bankName].messages[messageKey]}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                        {Object.keys(flatRecords[bankName].documentSet).length > 0 && (
                          <div className='offerdocument' onClick={() => this.handleDocument(bankName)}>
                            <div className='offerdocumentname'>Documents List</div>
                          </div>
                        )}

                      </div>
                    </div>

                    <div className='offerapplynow'>
                      <div>
                        <button className='offerbtn' onClick={this.handleApplyNow}>Apply Now</button>
                      </div>
                      {(bankName === "Lnt") && (
                        <div className='offerlink' onClick={this.handleSendNow}>
                          <img src={require('./../../ruleEngine/assets/images/whatsappicon.svg')} alt="" />
                          <div>Send doc list on WhatsApp</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>

                <Modal isOpen={this.state.isOpen} togglePopup={() => this.setState({ isOpen: false })}>
                  <div className='offerthankyou'>
                    <img src={require('./../../ruleEngine/assets/images/thankyou.svg')} alt="thankyou" />
                    <div className='thankyouheading'>Thank You!</div>
                    <p className='thankyoupara'>We have received your Home Loan application. Our loan expert will get in touch within 24 hours.</p>
                    {/* <a href='' className='offerback'>Go Back</a> */}
                  </div>
                </Modal>

                <Modal isOpen={this.state.isSend} togglePopup={() => this.setState({ isSend: false })}>
                  {(this.state.hideSendWA === false) && (
                    <form onSubmit={this.handleSubmit}>
                      <div className='offerwhatsapp'>
                        <img src={require('./../../ruleEngine/assets/images/whatsappmodalicon.svg')} alt="whatsapp" />
                        <div className='whatsappheading'>Please share your WhatsApp number</div>
                        <div>
                          {/* <label>Enter your Number *</label> */}
                          <div className='whatsappnumber'><div className='numberborder'>+91</div>
                            <InputField
                              type="text"
                              maxLength={10}
                              value={this.state.inputValue}
                              onChange={this.handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className='sendbtncard'>
                          <button className='sendbtn'>
                            Send WhatsApp
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                  {(this.state.hideSendWA === true) && (
                    <div className='offerthankyou'>
                      <img src={require('./../../ruleEngine/assets/images/thankyou.svg')} alt="thankyou" />
                      <div className='thankyouheading'>Thank You!</div>
                      <p className='thankyoupara'>You will receive the list of all the required documents shortly </p>
                      {/* <a href='' className='offerback'>Go Back</a> */}
                    </div>
                  )}
                </Modal>

              </div>
            ))
          ) : null}


          <Modal isOpen={this.state.isDocument} togglePopup={() => this.setState({ isDocument: false })}>
            <h5>Document List :-</h5>
            <div className='offerdocumentlist'>
              {flatRecords ? (
                Object.keys(flatRecords).map((bankName, index) => (
                  <div key={index}>
                    {this.state.bankClick === bankName && (
                      <ul>
                        {flatRecords[bankName].documentSet && Object.keys(flatRecords[bankName].documentSet).map((item, setIndex) => (
                          <li key={setIndex} onClick={() => this.setState({ activeIndex: this.state.activeIndex === setIndex ? null : setIndex })}>
                            <h4 className='documentheading'>
                              {item ? item.replace(/_/g, ' ') : ""}
                              <span className={`documenticon ${this.state.activeIndex === setIndex ? 'rotated-icon rotate-180' : 'rotated-icon'}`}>
                                <img src={require('./../../ruleEngine/assets/images/downarrow.svg')} alt="document" />
                              </span>
                            </h4>
                            {this.state.activeIndex === setIndex && (
                              <div>
                                <ul>
                                  {flatRecords[bankName].documentSet[item].map((document, docIndex) => (
                                    <li className='subheading' key={docIndex}>{document}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))
              ) : null}
            </div>
          </Modal>


          <div className="propertybtn">
            <Button btnClass="btn btn-primary" onClick={this.handleBackTab}>Back</Button>
          </div>
        </div>

      )
    } else {
      return (<Redirect to={'/admin/basic-requirement'} />);
    }
  }
}
export default BreDetails;
