import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { executeGraphQLQueryFinex } from '../../../common/executeGraphQLQuery';
import { FINEX_MASTER } from '../../../queries/scheme.gql';
import { DealerService } from '../../../service';
import MasterService from '../../../service/MasterService';
import Modal from '../../common/Modal';
import Button from '../../elements/Button';
import InputField from '../../elements/Input';
import secureStorage from './../../../config/encrypt';

const account_type = [
    { id: 'saving', profession_name: 'Saving' },
    { id: 'current', profession_name: 'Current' },
];

class BankDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: "",
            basic_details: props.basic_details || {},
            get_dealer_id_hash: "",
            errors: {},
            kyc_errors: [],
            aadhaar_number: "",
            account_number: "",
            aadhaar_back: "",
            pan_card: "",
            kycDealerDoc: [{ file_url: "" }],
            bankList: [],
            kycDealerData: {},
            kycDealerDocData: [],
            isReject: false,
        }
    }

    componentDidMount = async () => {
        //  await this.getDealerDocKycDetail();
        await this.getDealerKycDetail();
        await this.getBankList();
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            //console.log(loginResponse.data.dealer_id)
            this.setState({ dealer_id: loginResponse.data.dealer_id })
            this.setState({ dealer_id_hash: loginResponse.data.dealer_id_hash });
        }
    }

    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }

    getDealerKycDetail = async () => {
        DealerService.getKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                let tempkycDealerData = [];
                if (result.data && result.data.data && result.data.data.length > 0) {
                    tempkycDealerData = result.data.data[0]
                }
                this.setState({ kycDealerData: tempkycDealerData, kycDealerDetail: tempkycDealerData, loading: false })
            }
            else {
                this.setState({ loading: false })
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            this.setState({ loading: false })
            toast.error(error.message || "Some Error Occurred");
        });
    }



    // getBankList = async () => {
    //     DealerService.getMasters(["bank_list"]).then((response) => {
    //         // console.log("response.data.data.bank_list",response.data.data.bank_list);
    //         if (response && response.status == 200 && response.data.status == 200) {
    //             this.setState({ bankList: response.data.data.bank_list });
    //         }
    //     }).catch((error) => {
    //         this.setState({ loading: false })
    //     });
    // }

    getBankList = async () => {
        const masterData = await executeGraphQLQueryFinex(FINEX_MASTER()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = [];
            const bank_list = masterData.data.masterdata.bank_list || [];
            bank_list.forEach(bank => {
                bankData.push({ bank_id: bank.id, bankname: bank.banklang.bank_name });
            });
            this.setState({ bankList: bankData });
        } else {
            this.setState({ bankList:[] });
            return [];
        }
    }

    get get_user_id() {
        return this.props.user_id
    }

    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }

    handleAccountTypeOptionChange = (ovalue) => {
        let errors = this.state.errors
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_account_type = ovalue.id;
        errors.bank_account_type = ""
        this.setState({ kycDealerData: kycMasterlist_new, account_length: ovalue.valid_length, errors: errors }, () => {
        })
    }

    handlebankListOptionChange = (ovalue) => {
        let errors = this.state.errors
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_id = ovalue.bank_id;
        kycMasterlist_new.bank_ifsc_code = ""
        errors.bankname = ""
        this.setState({ kycDealerData: kycMasterlist_new, account_length: ovalue.valid_length, errors: errors }, () => {
        })
    }

    handletKycDealerDataTextChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new[sname] = ovalue.target.value;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    saveBankDetail = async (event) => {
        event.preventDefault();
        var formIsValid = false;
        var kyc_errors = [...this.state.kyc_errors];
        var error = {};
        if (this.validFormData()) {
            const saveDetails = this.state.kycDealerData;
            const basicDetails = this.props.basic_details;
            let account_type;
            if(saveDetails?.bank_account_type==='current'){
                account_type =2;
            }
            if(saveDetails?.bank_account_type==='saving'){
                account_type =1;
            }
            const requestData = {
                partner_id: basicDetails?.id,
                dealer_id: basicDetails?.id,
                user_id: basicDetails?.user_id,
                account_number: saveDetails?.bank_account_no,
                account_type:account_type,
                bank_name: saveDetails?.bank_id ? Number(saveDetails?.bank_id) : 0,
                beneficiary_name: saveDetails?.beneficiary_name,
                ifsc_code: saveDetails?.bank_ifsc_code,
                upi_id: saveDetails?.upi_id,
                source: "Partner Backend",
                created_by: 0,
            };
            // console.log("basicDetails",basicDetails)
            console.log("requestData",requestData)
            await MasterService.post('/partner/partner/save-bank-details', requestData)
                .then(response => {
                    this.setState({ loading: false }, () => {
                    });
                    if (response.data.status == 200 && response.status == 200) {
                        let responseData = response.data;
                        //let dealer_id_hash = responseData.data && responseData.data[0] && responseData.data[0].dealer_id_hash;
                        let other = {};
                        this.setState({ kycDealerData: this.state.kycDealerData, ...other }, () => {
                            toast.success((response.data.message) ? response.data.message : "Success")
                        })

                    } else {
                        this.setState({ kycDealerData: this.state.kycDealerData }, () => {
                            toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                        });
                    }
                })
                .catch(error => console.log(error))
        } else {
            toast.error(this.props.t('dealerDetails.Basic_Details_master.validation_texts.fill_required_fields'));
        }
    }
    removecurrentdoc = (idx) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        kycDealerDocData_new[idx].file_url = null;
        this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
        })
    }

    validFormData = () => {
        let errors = {};
        let formData = this.state.kycDealerData;
        let formIsValid = true;
        // const aadhaarRegex = /^\d{12}$/;
        // const panCardRegex = /[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}$/;
        // if (!formData['bank_id']) {
        //     formIsValid = false;
        //     errors["bank_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_name_required');
        // }
        if (!formData['bank_ifsc_code']) {
            errors["bank_ifsc_code"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_ifsc_code_required');
            formIsValid = true;
        }
        if (!formData['beneficiary_name']) {
            errors["beneficiary_name"] = "Please enter beneficiary name";
            formIsValid = true;
        }
        if (!formData['bank_account_no']) {
            formIsValid = false;
            errors["bank_account_no"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_required');
        }

        this.setState({ errors: errors })
        return formIsValid;
    }

    isRejectedPartner = () => {
        this.setState({ isReject: true });
    }

    render() {
        const bankList = this.state.bankList;
        const { kycMasterlist, kycDealerData, errors, kycDealerDoc } = this.state;
        // console.log("kycDealerData",kycDealerData)
        let masterlist = {};
        if (kycMasterlist) {
            kycMasterlist.forEach((val) => {
                masterlist['cat_' + val.id] = val.child;
            })
        }

        return (
            <div className='card'>
                

                    <form onSubmit={this.saveBankDetail} method="post" id="bankdetail_form">
                        <div className='card-heading'>
                            <div className='flexcard'>
                                <div className="card-title">Bank Details</div>
                                {/* <button className="rejectbtn" onClick={this.isRejectedPartner} >Reject</button> */}
                            </div>
                        </div>
                        <div className='card-body'>
                        <Modal isOpen={this.state.isReject} togglePopup={() => this.setState({ isReject: false })}>
                            <div>
                                <h4>Are you sure you want to reject Bank Details?</h4>
                                <div>
                                    <label>Select a reason</label>
                                    <Select
                                        id="profession_id"
                                        options={''}
                                        onChange=''
                                        name="profession_id"
                                        value={''}
                                        getOptionLabel={({ profession_name }) => profession_name}
                                        getOptionValue={({ id }) => id}
                                    />
                                    <div style={{ marginTop: 20 }}>
                                        <button className="btn btn-primary pull-right "  >Cancel</button>
                                        <button className="btn btn-primary mrg-r10 pull-right ">Reject</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>


                        <div className="row" style={{ marginTop: 10 }}>
                            <div className="col-md-3 ">
                            <label>{this.props.t('dealerDetails.kyc_details.bank_name')}</label>
                                {/* <label>{this.props.t('dealerDetails.kyc_details.bank_name')}<span className="required" style={{ color: 'red' }}>*</span></label> */}
                                <Select
                                    id="bank_id"
                                    onChange={this.handlebankListOptionChange}
                                    options={bankList}
                                    name="bank_id"
                                    placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                    value={bankList && bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)}
                                    getOptionLabel={({ bankname }) => bankname}
                                    getOptionValue={({ bank_id }) => bank_id}
                                    //required={true}
                                />
                                {/* {errors.bank_id &&
                                    <div className="error show" >{errors.bank_id}</div>
                                } */}
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    type="text"
                                    error={errors['bank_account_no']}
                                    name="bank_account_no"
                                    id="bank_account_no"
                                    placeholder={this.props.t('dealerDetails.kyc_details.account_number')}
                                    value={kycDealerData.bank_account_no ? kycDealerData.bank_account_no : ""}
                                    label={this.props.t('dealerDetails.kyc_details.account_number')}
                                    onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_account_no')}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    type="text"
                                    error={errors['beneficiary_name']}
                                    name="beneficiary_name"
                                    id="beneficiary_name"
                                    placeholder='Beneficiary Name'
                                    value={kycDealerData.beneficiary_name}
                                    label='Beneficiary Name'
                                    onChange={(e) => this.handletKycDealerDataTextChange('beneficiary_name', e)}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    type="text"
                                    error={errors['bank_ifsc_code']}
                                    name="bank_ifsc_code"
                                    id="bank_ifsc_code"
                                    placeholder={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')}
                                    value={kycDealerData.bank_ifsc_code}
                                    label={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')}
                                    onChange={(e) => this.handletKycDealerDataTextChange('bank_ifsc_code', e)}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    type="text"
                                    error={errors['upi_id']}
                                    name="upi_id"
                                    id="upi_id"
                                    placeholder='UPI ID'
                                    value={kycDealerData.upi_id}
                                    label='UPI ID'
                                    onChange={(e) => this.handletKycDealerDataTextChange('upi_id', e)}
                                // required={true}
                                />
                            </div>
                        
                            <div className="col-md-3">
                                <label>Account Type</label>
                                <Select
                                    id="bank_account_type"
                                    onChange={this.handleAccountTypeOptionChange}
                                    options={account_type}
                                    name="bank_account_type"
                                    placeholder='Account Type'
                                    value={account_type.filter(({ id }) => id == kycDealerData.bank_account_type)}
                                    getOptionLabel={({ profession_name }) => profession_name}
                                    getOptionValue={({ id }) => id}
                                />
                            </div>
                            {/* <div className="col-md-3">
                                <InputField
                                    type="text"
                                    error={errors['branch_name']}
                                    name="branch_name"
                                    id="branch_name"
                                    placeholder='Branch Name'
                                    value={kycDealerData.branch_name}
                                    label='Branch Name'
                                    onChange={(e) => this.handletKycDealerDataTextChange('branch_name', e)}
                                // required={true}
                                />
                            </div> */}
                            <div className="col-md-3" style={{marginTop:28}}>
                            <Button btnClass="btn btn-primary pull-right" title={this.props.t('dealerDetails.Basic_Details_master.save')} type="submit" />
                            </div>
                            {/* <div className="col-md-2">
                            <InputField type="text" error={(errors.aadhar_no) ? errors.aadhar_no : ''} name="aadhar_no" id="aadhar_no" maxLength={12} placeholder={this.props.t('dealerDetails.kyc_details.aadhar_no')} value={kycDealerData.aadhar_no} label={this.props.t('dealerDetails.kyc_details.aadhar_no')} onChange={this.handletKycDealerDataTextChange.bind(this, 'aadhar_no')} required={true} />
                        </div>
                        <div className="col-md-2">
                            <InputField type="text" error={(errors.pan_no) ? errors.pan_no : ''} name="pan_no" id="pan_no" placeholder={this.props.t('dealerDetails.kyc_details.pan_no')} maxLength={10} value={kycDealerData.pan_no} label={this.props.t('dealerDetails.kyc_details.pan_no')} onChange={this.handletKycDealerDataTextChange.bind(this, 'pan_no')} required={true} />
                        </div> */}

                              
                                    
                                   

                        </div >
                       
                        </div>
                        
                    </form>

                </div>
            

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(BankDetails));