import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import InputField from '../../elements/Input';
import { toast } from 'react-toastify';
import { DealerService, InventoryService } from '../../../service';
import { connect } from 'react-redux';
import Q from 'q';
import Button from '../../elements/Button';
import secureStorage from './../../../config/encrypt';
class CompanyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: "",
            get_dealer_id_hash: "",
            errors: {},
            kyc_errors: [],
            aadhaar_number: "",
            account_number: "",
            aadhaar_back: "",
            pan_card: "",
            gstin_certificate:"",
            msme_certificate:"",
            cancelled_cheque:"",
            kycDealerDoc: [{ file_url: "" }],
            bankList: [],
            kycDealerData: {},
            kycDealerDocData: [],
        }
    }

    componentDidMount = async () => {
     //   await this.getDealerDocKycDetail();
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            //console.log(loginResponse.data.dealer_id)
            this.setState({ dealer_id: loginResponse.data.dealer_id })
            this.setState({ dealer_id_hash: loginResponse.data.dealer_id_hash });
        }
    }

    get get_user_id() {
        return this.props.user_id
    }

    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }

    handlebankListOptionChange = (ovalue) => {
        let errors = this.state.errors
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_id = ovalue.bank_id;
        kycMasterlist_new.bank_ifsc_code = ""
        errors.bankname = ""

        this.setState({ kycDealerData: kycMasterlist_new, account_length: ovalue.valid_length, errors: errors }, () => {
        })
    }

    handletKycDealerDataTextChange = (sname, ovalue) => {
        let errors = { ...this.state.errors };
        errors[sname] = "";
        let kycMasterlist_new = { ...this.state.kycDealerData };
        kycMasterlist_new[sname] = ovalue.target.value;
        this.setState({ kycDealerData: kycMasterlist_new, errors: errors });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    setShowHide = () => {
        this.props.setThankYouPage({
            otphide: false,
            thankyou: true,
            mainheading: false,
            bankinfo: false
        })
    }

    stringifyFormData(fd) {
        var object = {};
        fd.forEach((value, key) => {
            if (!object.hasOwnProperty(key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        return JSON.parse(JSON.stringify(object));
    }

    getDealerDocKycDetail = async () => {
        DealerService.getDocKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ kycDealerDocData: result.data.data }, () => { });
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
       
}


    s3UploadFiles = (allfiles, formPostdata) => {

        let dealerKycDocSaveArr = [];
        if (allfiles.length) {
            this.setState({ loading: true })
            let prom = [];
            allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();

                formData.append("upload_type", 'dealer_docs');
                formData.append("dealer_id", 20);
                if (filesData.length) {
                    formData.append('images', filesData[0]);
                    prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            });

            Q.allSettled(prom)
                .then((op) => {
                    console.log("prom", prom)
                    const kycDocJson = {
                        "kyc_master_id": 0,
                        "kyc_child_id": 0,
                        "doc_number": "",
                        "comment": "",
                        "verified_status": 0,
                        "file_name": [],
                        "file_url": []
                    }
                    op.forEach((res, index) => {
                        if (res.state == 'fulfilled') {
                            let response = res.value || [];
                            let tempFileName = response.data.data[0].file_name;
                            let tempFileUrl = response.data.data[0].file_url;
                            //   let response = res.value || [];
                            const docJson = { ...kycDocJson }
                            docJson.file_name = [tempFileName]
                            docJson.file_url = [tempFileUrl]
                            if (index == 0) {
                                docJson.kyc_master_id = 1;
                                docJson.doc_number = formPostdata.aadhar_no;
                            }
                            if (index == 1) {
                                docJson.kyc_master_id = 2;
                                docJson.doc_number = formPostdata.aadhar_no;
                            }
                            // if (index == 2) {
                            //     docJson.kyc_master_id = 3;
                            //     docJson.doc_number = formPostdata.pan_no;
                            // }
                            if (index == 2) {
                                docJson.kyc_master_id = 4;
                                docJson.doc_number = formPostdata.account_number;
                            }
                            if (index == 3) {
                                docJson.kyc_master_id = 5;
                                docJson.doc_number = formPostdata.company_gstin;
                            }
                            if (index == 4) {
                                docJson.kyc_master_id = 6;
                                docJson.doc_number = formPostdata.company_pan_no;
                            }
                            if (index == 5) {
                                docJson.kyc_master_id = 7;
                                docJson.doc_number = formPostdata.msme_no;
                            }
                            dealerKycDocSaveArr.push(docJson);
                        }
                    });

                    let postData = {
                        dealer_id: this.state.dealer_id ? this.state.dealer_id : 1,
                        docs: dealerKycDocSaveArr,
                        account_number: formPostdata.account_number,
                        bank_ifsc_code: formPostdata.bank_ifsc_code,
                        bank_id: formPostdata.bankname
                    };
                    //  alert("hello");
                    DealerService.saveKycDocDetails(postData).then(result => {
                        //    alert("hello");
                        toast.success("Thank you for joinus!, We are reviewing your details!");
                        this.setShowHide.bind();
                        return false;
                        if (result && result.status == 200) {
                            this.setShowHide.bind();
                            return false;
                        }
                        else {
                            // this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                            return false;
                        }
                    }).catch(error => {
                        console.log(error)
                        toast.error(error.message || "Some Error Occurred");
                        return false
                    });
                })
                .catch((error) => {
                    console.log(error)
                    toast.error(error.message || "Some Error Occurred");
                    return false
                });
        }
    }

    saveKycDetail = (event) => {
        event.preventDefault();
        var parentThis = this;
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        var loginResponse = secureStorage.getItem('loginUserInfo');
        // console.log("loginResponse",loginResponse)
        if (!loginResponse.data || !loginResponse.data.dealer_id) {
            return false;
        }
        var formIsValid = false;
        var kyc_errors = [...this.state.kyc_errors];
        var error = {};
        formdata && formdata.length > 0 && Object.keys(formdata).forEach((key) => {
            if (key == 'aadhaar_number' || key == 'pan_card') {
                let key_error_msg = '';
                if (key == 'aadhaar_front') {
                    key_error_msg = 'key aadhaar number';
                } else if (key == 'pan_card') {
                    key_error_msg = 'key pan card';
                }
            }

        })
        // console.log("this.validFormData(formdata)", this.validFormData(formdata))
        if (this.validFormData(formdata)) {
            this.setState({ loading: true });
            // var r = window.confirm(this.props.t('dealerDetails.kyc_details.warning_alert_msg'));
            this.setState({ kyc_errors: kyc_errors }, () => { })
            if ((Object.keys(error).length) == 0) {
                formIsValid = true;
            }
            this.props.setThankYouPage({
                thankyou: true,
            })
            // console.log("formIsValid", formIsValid);
            // console.log("error", error);
            if (formIsValid) {
                let allfiles = document.querySelectorAll('input[type="file"]');
                this.s3UploadFiles(allfiles, formdata);

                //  console.log(result)
                return false
            } else {
                toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
                return false
            }
        }
    }
    removecurrentdoc = (idx) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        kycDealerDocData_new[idx].file_url = null;
        this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
        })
    }

    validFormData = (formData) => {
        let errors = {};
        let formIsValid = true;
        const companyGSTIN = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
        const panCardRegex = /[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}$/;


            if (!formData['company_gstin']) {
                formIsValid = false;
                errors["company_gstin"] = this.props.t('please enter company gstin');
            }else if (!companyGSTIN.test(formData['company_gstin'])){
                formIsValid = false;
                errors['company_gstin'] = this.props.t("Please valid company gstin")
            }
        
            if (!formData['bank_ifsc_code']) {
                errors["bank_ifsc_code"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_ifsc_code_required');
                formIsValid = true;
            }
            if (!formData['account_number']) {
                formIsValid = false;
                errors["account_number"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_required');
            }   
            if (!formData['company_pan_no']) {
                formIsValid = false;
                errors['company_pan_no'] = this.props.t('Please enter pan card')
            } else if(!panCardRegex.test(formData['company_pan_no'])) {
                formIsValid = false;
                errors ['company_pan_no'] = this.props.t('Please enter a valid PAN card number')
            }

        //console.log("dddderroors", errors);

        this.setState({ errors: errors })
        return formIsValid;
    }


    onChangeHandler = event => {
        let files = event.target.files
        let err = []
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf', 'application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        for (var x = 0; x < files.length; x++) {
            if (types.every(type => files[x].type !== type)) {
                err[x] = files[x].type + ' ' + this.props.t('dealerDetails.kyc_details.kyc_file_support_msg') + ' \n';

            }
            if (files[x].size && files[x].size > 8000000) {
                err[x] = this.props.t('dealerDetails.kyc_details.kyc_file_size_error') + ' \n';
            }
        };
        for (var z = 0; z < err.length; z++) {
            event.target.value = null
            toast.error(err[z])
        }
        return true;
    }

    saveBankDetail = async (formdata) => {
        //     alert(JSON.stringify(formdata))
        //   return false;
        //   let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
        let dealerKycSaveJson = {
            "dealer_id_hash": this.get_dealer_id_hash,
            "bank_id": formdata.bankname,
            "bank_account_type": formdata.account_type ? formdata.account_type : "Saving",
            "bank_account_no": formdata.account_number,
            "confirm_bank_account_no": formdata.account_number,
            "beneficiary_name": formdata.beneficiary_name ? formdata.beneficiary_name : "",
            "bank_ifsc_code": formdata.bank_ifsc_code
            // "bank_ifsc_code": formdata.ifsccode
        };
        DealerService.saveKycDetails(dealerKycSaveJson).then(result => {
            if (result && result.data && result.data.status == 200) {

            }
            else {

            }
        }).catch(error => {
            this.setState({ loading: false })
            toast.error(error.message || "Some Error Occurred");
        });

    }

    render() {
        const bankList = this.state.bankList;
        const { kycMasterlist, kycDealerData, errors, kycDealerDoc } = this.state;
        let masterlist = {};
        if (kycMasterlist) {
            kycMasterlist.forEach((val) => {
                masterlist['cat_' + val.id] = val.child;
            })
        }

        return (
            <div>

                <form onSubmit={this.saveKycDetail} method="post" encType="multipart/form-data">
                    {/* {JSON.stringify(kycDealerData)} */}
                    <div style={{ fontSize: 16, marginBottom: 10 }}>e-KYC Verification</div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                type="text"
                                error={errors['company_gstin']}
                                name="company_gstin"
                                id="company_gstin"
                                placeholder={this.props.t('dealerDetails.kyc_details.company_gstin')}
                                value={kycDealerData.company_gstin}
                                label={this.props.t('Company GSTIN')}
                                onChange={(e) => this.handletKycDealerDataTextChange('company_gstin', e)}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                type="text"
                                error={errors['company_pan_no']}
                                name="company_pan_no"
                                id="company_pan_no"
                                placeholder={this.props.t('dealerDetails.kyc_details.company_pan_no')}
                                value={kycDealerData.company_pan_no}
                                label={this.props.t('Company_pan_no')}
                                onChange={this.handletKycDealerDataTextChange.bind(this, 'company_pan_no')}
                                required={true}
                            />
                        </div>


                    </div>
                    <div style={{ fontSize: 16, marginBottom: 10 }}>Financial Details</div>
                    <div className="row">
                       
                        <div className="col-md-6">
                            <InputField
                                type="text"
                                error={errors['account_number']}
                                name="account_number"
                                id="account_number"
                                placeholder={this.props.t('dealerDetails.kyc_details.account_number')}
                                value={kycDealerData.account_number}
                                label={this.props.t('dealerDetails.kyc_details.account_number')}
                                onChange={this.handletKycDealerDataTextChange.bind(this, 'account_number')}
                                required={true}
                            />
                        </div>

                        <div className="col-md-6">
                            <InputField
                                type="text"
                                error={errors['bank_ifsc_code']}
                                name="bank_ifsc_code"
                                id="bank_ifsc_code"
                                placeholder={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')}
                                value={kycDealerData.bank_ifsc_code}
                                label={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')}
                                onChange={(e) => this.handletKycDealerDataTextChange('bank_ifsc_code', e)}
                                required={true}
                            />
                        </div>

                    </div>
                    
                    <div style={{ fontSize: 16, marginBottom: 10 }}>Upload Documents</div>
                    <div className='row'>
                    {/* <div className="col-md-6">
                            <label>{('PAN Card Doc')}</label>
                            <input type="file" name="fileName" multiple className={
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    'form-control hide' : 'form-control'
                            } onChange={this.onChangeHandler} />
                            {
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    <div className="ion-document">
                                        {
                                            (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                <a href={kycDealerDoc.file_url} target="_blank" download>
                                                    {
                                                        (kycDealerDoc.pan_no == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.pan_no == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.pan_no == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                    }

                                                </a>
                                                : ''
                                        }
                                        {kycDealerDoc.verified_status != '1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this)}><i className="ic-clearclose"></i></span> : ""}
                                    </div>
                                    : ''
                            }
                        </div> */}
                        <div className="col-md-6" style={{ marginTop: 10 }}>
                            <label>{('Aadhaar Front Doc')}</label>
                            <input type="file" name="fileName" multiple className={
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    'form-control hide' : 'form-control'
                            } onChange={this.onChangeHandler} />

                            {
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    <div className="ion-document">
                                        {
                                            (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                <a href={kycDealerDoc.file_url} target="_blank" download>
                                                    {
                                                        (kycDealerDoc.aadhar_no == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.aadhar_no == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.aadhar_no == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                    }

                                                </a>
                                                : ''
                                        }
                                        {kycDealerDoc.verified_status != '1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this)}><i className="ic-clearclose"></i></span> : ""}
                                    </div>
                                    : ''
                            }
                        </div>
                        <div className="col-md-6" style={{ marginTop: 10 }}>
                            <label>{('Aadhaar Back Doc')}</label>
                            <input type="file" name="fileName" multiple className={
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    'form-control hide' : 'form-control'
                            } onChange={this.onChangeHandler} />
                            {
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    <div className="ion-document">

                                        {
                                            (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                <a href={kycDealerDoc.file_url} target="_blank" download>
                                                    {
                                                        (kycDealerDoc.aadhar_no == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.aadhar_no == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.aadhar_no == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                    }

                                                </a>
                                                : ''
                                        }
                                        {kycDealerDoc.verified_status != '1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this)}><i className="ic-clearclose"></i></span> : ""}
                                    </div>
                                    : ''
                            }
                        </div>
                        <div className="col-md-6" style={{ marginTop: 10 }}>
                            <label>{('GSTIN Certificate')}</label>
                            <input type="file" name="fileName" multiple className={
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    'form-control hide' : 'form-control'
                            } onChange={this.onChangeHandler} />
                            {
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    <div className="ion-document">
                                        {
                                            (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                <a href={kycDealerDoc.file_url} target="_blank" download>
                                                    {
                                                        (kycDealerDoc.company_gstin == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.company_gstin == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.company_gstin == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                    }

                                                </a>
                                                : ''
                                        }
                                        {kycDealerDoc.verified_status != '1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this)}><i className="ic-clearclose"></i></span> : ""}
                                    </div>
                                    : ''
                            }
                        </div>
                        <div className="col-md-6" style={{ marginTop: 10 }}>
                            <label>{('Company PAN')}</label>
                            <input type="file" name="fileName" multiple className={
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    'form-control hide' : 'form-control'
                            } onChange={this.onChangeHandler} />
                            {
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    <div className="ion-document">
                                        {
                                            (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                <a href={kycDealerDoc.file_url} target="_blank" download>
                                                    {
                                                        (kycDealerDoc.company_pan_no == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.company_pan_no == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.company_pan_no == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                    }

                                                </a>
                                                : ''
                                        }
                                        {kycDealerDoc.verified_status != '1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this)}><i className="ic-clearclose"></i></span> : ""}
                                    </div>
                                    : ''
                            }
                        </div>

                        <div className="col-md-6" style={{ marginTop: 10 }}>
                            <label>{('MSME Certificate')}</label>
                            <input type="file" name="fileName" multiple className={
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    'form-control hide' : 'form-control'
                            } onChange={this.onChangeHandler} />
                            {
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    <div className="ion-document">
                                        {
                                            (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                <a href={kycDealerDoc.file_url} target="_blank" download>
                                                    {
                                                        (kycDealerDoc.msme_no == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.msme_no == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.msme_no == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                    }

                                                </a>
                                                : ''
                                        }
                                        {kycDealerDoc.verified_status != '1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this)}><i className="ic-clearclose"></i></span> : ""}
                                    </div>
                                    : ''
                            }
                        </div>

                        <div className="col-md-6" style={{ marginTop: 10 }}>
                            <label>{('Cancelled Cheque')}</label>
                            <input type="file" name="fileName" multiple className={
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    'form-control hide' : 'form-control'
                            } onChange={this.onChangeHandler} />
                            {
                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                    <div className="ion-document">
                                        {
                                            (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                <a href={kycDealerDoc.file_url} target="_blank" download>
                                                    {
                                                        (kycDealerDoc.cancelled_cheque == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.cancelled_cheque == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.cancelled_cheque == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                    }

                                                </a>
                                                : ''
                                        }
                                        {kycDealerDoc.verified_status != '1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this)}><i className="ic-clearclose"></i></span> : ""}
                                    </div>
                                    : ''
                            }
                        </div>


                        
                    </div>
                    <div style={{marginTop:10}}>
                    <Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" />  <Button btnClass="btn btn-primary" title={this.props.t('Skip')} type="submit" onClick={this.setShowHide.bind()} />
                    </div>
                </form>
              


            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(CompanyDetails));