import React, { Component } from 'react';
import Button from '../../elements/Button';
import InputField from '../../elements/Input';
import '../assets/css/bre.css';
import InputContext from './InputContext';
import Select from 'react-select';
import { toast } from 'react-toastify';


const income_type = [
    { value: 'rent', label: 'Rent' },
    { value: 'monthly_incentives', label: 'Monthly Incentives' },
    { value: 'annual_bonus', label: 'Annual Bonus' },
    { value: 'monthly_reimbursements', label: 'Monthly Reimbursements' },
];

const digit1 = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
const digit2 = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];


class incomeProfile extends Component {
    static contextType = InputContext;
    state = {
        isAdditionIncomeChecked: false,
        isCoAppliantChecked: false,
        income_type: null
    };


    handleAdditionalIncome = () => {
        this.setState((prevState) => ({
            isAdditionIncomeChecked: !prevState.isAdditionIncomeChecked,
        }));
        const inputValue = this.state.isAdditionIncomeChecked ? "Yes" : "No";
        this.handleInputChange({ target: { value: inputValue, checked: !this.state.isAdditionIncomeChecked } }, 'additional_income')
    };

    handleCoAppliant = () => {
        this.setState((prevState) => ({ isCoAppliantChecked: !prevState.isCoAppliantChecked }));
        const inputValue = this.state.isCoAppliantChecked ? "Yes" : "No";
        this.handleInputChange({ target: { value: inputValue, checked: !this.state.isCoAppliantChecked } }, 'is_co_appliant')

    };

    handlePrevPage = () => {
        this.props.history.push('/admin/property-profile');
    };

    handleInputChange = (event, inputName) => {
        const { setInputValue } = this.context;
        const value = event.target.value;
        //console.log(event,value)
        setInputValue(inputName, value);
    };

    handleSelectChange = (selectedOption, inputName) => {
        const { setInputValue } = this.context;
        const value = selectedOption ? selectedOption.value : null;
        setInputValue(inputName, value);
        this.setState({ [inputName]: value });
    };

    handlePriceInputChange = (event, inputName) => {
        const { setInputValue } = this.context;
        let inputValue = event.target.value;
        // Remove non-numeric characters
        inputValue = inputValue.replace(/[^0-9]/g, '');
        // Format to Indian currency
        inputValue = new Intl.NumberFormat('en-IN').format(inputValue);
        setInputValue(inputName, inputValue);
    };

    formatCurrency = (value) => {
        if (value) {
            // Format the raw numeric value for display
            const parts = value.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        }
    };

    navigateToDetailsComponent = () => {
        const { inputValues } = this.context;

        if (!inputValues.property_decided) {
            inputValues.property_decided ="No";
        } 
        
        if (!inputValues.name) {
            toast.error('Please enter name.'); return;
        }
        else if (!inputValues.dob) {
            toast.error('Please enter age.'); return;
        }
        else if (inputValues.dob <= 18) {
            toast.error('Applicant’s age has to be above 18 yrs.'); return;
        }
        else if (!inputValues.desired_loan_amount) {
            toast.error('Please enter desired loan amount.'); return;
        }
        else if (!inputValues.occupation) {
            toast.error('Please select occupation.'); return;
        }
        else if (!inputValues.total_work_exp && inputValues.occupation === 'salaried') {
            toast.error('Please enter total work experiance.'); return;
        }
        // else if (!inputValues.company_type) {
        //     toast.error('Please select company type.'); return;
        // }
        else if (!inputValues.bussiness_continuity && (inputValues.occupation === 'self_empoyee_professional' || inputValues.occupation === 'self_empoyee_non_professional')) {
            toast.error('Please enter Years of Business.'); return;
        } else if (!inputValues.company_cat && inputValues.occupation === 'salaried') {
            toast.error('Please select company name.'); return;
        }
        if (!inputValues.property_decided) {
            toast.error('Please select property decided.'); return;
        }
        else if (!inputValues.property_usage && inputValues.property_decided != 'No') {
            toast.error('Please select property usage.'); return;
        }
        else if (!inputValues.property_type && inputValues.property_decided != 'No') {
            toast.error('Please select property type.'); return;
        }
        else if (!inputValues.property_sub_type && inputValues.property_decided != 'No') {
            toast.error('Please select property sub type.'); return;
        }
        if (!inputValues.net_monthly_salary) {
            toast.error('Please enter net monthly income.'); return;
        }
        else if (!inputValues.bureau_score) {
            toast.error('Please enter  bureau score.'); return;
        }

        this.props.history.push({
            pathname: '/admin/bre-details',
            state: { inputValues: this.state.inputValues },
        });
    };

    componentDidMount() {

    }

    convertToWords(num) {
        if ((num = num.toString()).length > 9) return 'overflow';
        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (digit1[Number(n[1])] || digit2[n[1][0]] + ' ' + digit1[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (digit1[Number(n[2])] || digit2[n[2][0]] + ' ' + digit1[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (digit1[Number(n[3])] || digit2[n[3][0]] + ' ' + digit1[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (digit1[Number(n[4])] || digit2[n[4][0]] + ' ' + digit1[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (digit1[Number(n[5])] || digit2[n[5][0]] + ' ' + digit1[n[5][1]]) + 'only ' : '';
        return str;
    }

    render() {
        const { isAdditionIncomeChecked } = this.state;
        const { isCoAppliantChecked } = this.state;
        const { inputValues } = this.context;
        return (
            <>
                <div className='card'>
                    <div className='card-body'>
                        <div className='property-card'>
                            <div className="card-new">
                                <div className="card-new-body">
                                    <div className='mainheading'>Income Profile</div>
                                    <div className="row">
                                        <div className="col-sm-4"><InputField
                                            type="text"
                                            name="net_monthly_salary"
                                            id="net_monthly_salary"
                                            placeholder="INR"
                                            value={this.formatCurrency(inputValues.net_monthly_salary)}
                                            onChange={(event) => this.handlePriceInputChange(event, 'net_monthly_salary')}
                                            label="Take Home Monthly Salary"
                                            required
                                        />
                                            {inputValues.net_monthly_salary && (
                                                <div className="amount-in-words">
                                                    <span>{this.convertToWords(inputValues.net_monthly_salary.replace(/[^\d.]/g, ''))}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-sm-4">
                                            <InputField
                                                type="number"
                                                name="bureau_score"
                                                id="bureau_score"
                                                placeholder="xxxx"
                                                autoComplete="off"
                                                value={inputValues.bureau_score}
                                                onChange={(event) => this.handleInputChange(event, 'bureau_score')}
                                                label="Bureau Score"
                                                required
                                            /></div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4 ">
                                            <div className='incomeflex'>
                                                <label className="toggle-label">Additional Income</label>
                                                <label className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={isAdditionIncomeChecked}
                                                        //checked={inputValues.additional_income}
                                                        onChange={this.handleAdditionalIncome}
                                                        name="additional_income"
                                                        id="additional_income"
                                                        value="Yes"
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>



                                    {isAdditionIncomeChecked && (
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="rent_amount"
                                                        id="rent_amount"
                                                        placeholder="xxxx"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.rent_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'rent_amount')}
                                                        label="Rental Income"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="monthly_incentives_amount"
                                                        id="monthly_incentives_amount"
                                                        placeholder="INR"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.monthly_incentives_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'monthly_incentives_amount')}
                                                        label="Monthly Incentives"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="annual_bonus_amount"
                                                        id="annual_bonus_amount"
                                                        placeholder="INR"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.annual_bonus_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'annual_bonus_amount')}
                                                        label="Annual Bonus"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="monthly_reimbursements_amount"
                                                        id="monthly_reimbursements_amount"
                                                        placeholder="INR"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.monthly_reimbursements_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'monthly_reimbursements_amount')}
                                                        label="Monthly Reimbursements"
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    )}

                                    <div className="row">
                                        <div className="col-sm-4"><InputField
                                            type="text"
                                            name="total_montly_emi"
                                            id="total_montly_emi"
                                            autoComplete="off"
                                            value={this.formatCurrency(inputValues.total_montly_emi)}
                                            onChange={(event) => this.handlePriceInputChange(event, 'total_montly_emi')}
                                            label=" Total Monthly EMIs" />
                                             {inputValues.total_montly_emi && (
                                                <div className="amount-in-words">
                                                    <span>{this.convertToWords(inputValues.total_montly_emi.replace(/[^\d.]/g, ''))}</span>
                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="col-sm-4"> <InputField
                                                    type="text"
                                                    name="avg_monthly_c_outstanding"
                                                    id="avg_monthly_c_outstanding"
                                                    autoComplete="off"
                                                    value={this.formatCurrency(inputValues.avg_monthly_c_outstanding)}
                                                    onChange={(event) => this.handlePriceInputChange(event, 'avg_monthly_c_outstanding')}
                                                    label="Avg Monthly Credit Card Outstanding" /></div>
                                            </div> */}
                                    </div>

                                    <div className="row">

                                        <div className="col-sm-4 ">
                                            <div className='incomeflex'>
                                                <label className="toggle-label">Co-applicant</label>
                                                <label className="toggle-switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={isCoAppliantChecked}
                                                        onChange={this.handleCoAppliant}
                                                        name="is_co_appliant"
                                                        id="is_co_appliant"
                                                        value="Yes"
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {isCoAppliantChecked && (
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-4"><InputField
                                                    type="text"
                                                    name="co_income"
                                                    id="co_income"
                                                    placeholder="INR"
                                                    autoComplete="off"
                                                    value={this.formatCurrency(inputValues.co_income)}
                                                    onChange={(event) => this.handlePriceInputChange(event, 'co_income')}
                                                    label="Take Home Monthly Salary" />
                                                    {inputValues.co_income && (
                                                        <div className="amount-in-words">
                                                            <span>{this.convertToWords(inputValues.co_income.replace(/[^\d.]/g, ''))}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-4"><InputField
                                                    type="text"
                                                    name="co_bureau_score"
                                                    id="co_bureau_score"
                                                    placeholder="xxxx"
                                                    autoComplete="off"
                                                    value={inputValues.co_bureau_score}
                                                    onChange={(event) => this.handleInputChange(event, 'co_bureau_score')}
                                                    label="Bureau Score"
                                                /></div>
                                            </div>

                                            <div className='mainheading'>Additional Income</div>
                                            <div className="row">
                                                {/* <div className="col-sm-4">
                                                <InputField
                                                    type="text"
                                                    name="co_monthly_incentives"
                                                    id="co_monthly_incentives"
                                                    placeholder="INR"
                                                    value={inputValues.co_monthly_incentives}
                                                    onChange={(event) => this.handleInputChange(event, 'co_monthly_incentives')}
                                                    label="Monthly Incentives" />
                                            </div> */}
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="co_rent_amount"
                                                        id="co_rent_amount"
                                                        placeholder="xxxx"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.co_rent_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'co_rent_amount')}
                                                        label="Rental Income"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="co_monthly_incentives_amount"
                                                        id="co_monthly_incentives_amount"
                                                        placeholder="INR"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.co_monthly_incentives_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'co_monthly_incentives_amount')}
                                                        label="Monthly Incentives"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="co_annual_bonus_amount"
                                                        id="co_annual_bonus_amount"
                                                        placeholder="INR"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.co_annual_bonus_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'co_annual_bonus_amount')}
                                                        label="Annual Bonus"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <InputField
                                                        type="text"
                                                        name="co_monthly_reimbursements_amount"
                                                        id="co_monthly_reimbursements_amount"
                                                        placeholder="INR"
                                                        autoComplete="off"
                                                        value={this.formatCurrency(inputValues.co_monthly_reimbursements_amount)}
                                                        onChange={(event) => this.handlePriceInputChange(event, 'co_monthly_reimbursements_amount')}
                                                        label="Monthly Reimbursements"
                                                    />
                                                </div>
                                            </div>




                                            {/* <div className='mainheading'>Existing Obligations</div> */}
                                            <div className="row">
                                                <div className="col-sm-4"><InputField
                                                    type="text"
                                                    name="co_total_montly_emi"
                                                    id="co_total_montly_emi"
                                                    autoComplete="off"
                                                    value={this.formatCurrency(inputValues.co_total_montly_emi)}
                                                    onChange={(event) => this.handlePriceInputChange(event, 'co_total_montly_emi')}
                                                    label="Total Monthly EMIs" />
                                                     {inputValues.co_total_montly_emi && (
                                                <div className="amount-in-words">
                                                    <span>{this.convertToWords(inputValues.co_total_montly_emi.replace(/[^\d.]/g, ''))}</span>
                                                </div>
                                            )}
                                                    </div>
                                                {/* <div className="col-sm-4"> <InputField
                                                    type="text"
                                                    name="co_avg_monthly_c_outstanding"
                                                    id="co_avg_monthly_c_outstanding"
                                                    autoComplete="off"
                                                    value={this.formatCurrency(inputValues.co_avg_monthly_c_outstanding)}
                                                    onChange={(event) => this.handlePriceInputChange(event, 'co_avg_monthly_c_outstanding')}
                                                    label="Avg Monthly Credit Card Outstanding" />
                                                </div> */}
                                            </div>
                                        </div>
                                    )}




                                    <div className="propertybtn">
                                        <Button btnClass="btn btn-primary" onClick={this.handlePrevPage}>Back</Button>
                                        <span style={{ marginRight: '10px' }} />
                                        <div ><Button btnClass="btn btn-primary" onClick={this.navigateToDetailsComponent}>Submit</Button></div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default incomeProfile;

//export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(incomeProfile));
