import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SoldIcn from '../../../webroot/images/sold-icn.svg'
class CrousalListing extends Component {
    constructor(props) {
        super(props);
    }

    updateFavroute = (event) => {
        let car_index = event.currentTarget.dataset.index;
        let car_id = event.currentTarget.dataset.carid;
        if (typeof this.props.updateFavrouteCar === 'function') {
            this.props.updateFavrouteCar(car_index, car_id);
        }
    }

    render() {
        const { crousalData, selectedTab, rowIndex } = this.props;

        return (
            <div className="clearfix pd-lr-30">
                <div className="col-sm-2">
                    <div className="img-box clearfix">
                        <a href="#" title="">
                            {(crousalData.car_profile_image && crousalData.car_status == 1) ?
                                <img src={crousalData.car_profile_image + '?heightonly=126'} alt="" className="img-responsive img-height" />
                                : (crousalData.car_profile_image && crousalData.car_status == 2) ?
                                    <div className="imgwrap-favourite"><img src={crousalData.car_profile_image + '?heightonly=126'} alt="" className="img-responsive img-height" />
                                        <img src={SoldIcn} className="sold-img" />
                                    </div>
                                    :
                                    <img src={require('./../assets/images/no-image.png')} alt="" className="img-responsive img-height" />
                            }
                        </a>
                        <div className="fav-car"><div onClick={this.updateFavroute} data-index={rowIndex} data-carid={crousalData.id} className={"ic-favorite " + (selectedTab === 'details' ? "text-primary" : " ")}></div></div>
                    </div>
                </div>

                <div className="col-sm-10 mrg-b15">
                    <div className="row">
                        <div className="col-sm-4">
                            <h2 className="carname">{crousalData.make + ' ' + crousalData.modelVersion}</h2>
                            <div className="pricefont">{crousalData.display_price}</div>
                        </div>
                        <div className="col-sm-4 col-md-4 brdr-left">
                            <div className="car-specs ">
                                <h6><strong>{this.props.t('crousal.overview')}</strong></h6>
                                <ul className="clearfix carpoints">
                                    <li>{crousalData.make_year || 'N/A'}</li>
                                    <li>{crousalData.reg_car_city_name || 'N/A'}</li>
                                    <li>{crousalData.km_driven || '0'} {this.props.t('crousal.kms')}</li>
                                    <li>{crousalData.owner_type || 'N/A'} {this.props.t('crousal.owner')}</li>
                                    <li>{crousalData.uc_colour || 'N/A'}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 brdr-left">
                            <div className="car-specs ">
                                <h6><strong>{this.props.t('crousal.features')}</strong></h6>
                                <ul className="clearfix carpoints">
                                    <li>{crousalData.transmission || 'N/A'}</li>
                                    <li>{crousalData.car_body_type || 'N/A'}</li>
                                    <li>{crousalData.fuel_type || 'N/A'}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('lead')(CrousalListing);
