import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import { UserActions } from "../../store/action/captchaAction";
import InputField from "../elements/CaptchaInputFields";

const Captcha = (props) => {

    const [captcha, setCaptcha] = useState('')
    const [captchaInvalid, setCaptchaInvalid] = useState('')
    const [captchaImg, setCaptchaImg] = useState({})
	const [captchaImgVal, setCaptchaImgVal] = useState({})


    const dispatch = useDispatch();

    useEffect(()=>{

        loadCaptchaImage()
        
    }, [props.reloadCaptcha])

    
    useEffect(()=> {
        setCaptchaInvalid(props.errField.captchaErr)
    }, [props.errField])


    /**
     *  Load Captcha Image
     */

	const loadCaptchaImage = (err = []) => {
        setCaptcha("");       
        dispatch(UserActions.loginCaptcha()).then(res=>{
            if(res && res.data && res.data.data){
                setCaptchaImg(res.data.data)
				        setCaptchaImgVal(res.data);
            }
        })
                
    };

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    const handleInputChange = async e => {
        let captchaVal = e.target.value;
        setCaptcha(captchaVal)
        props.onKeyPressCaptcha({captchaVal:captchaVal,captchaData:captchaImgVal});        
    };

    /**
     * Reset Captcha Error
     */
    const resetCaptchaError = async () => {
        if(this._isMount){
        await this.setState({
            captchaInvalid: ""
        });
        }
        
    };
    const { captchaError } = props;
    
    return (
      <>
        <div className={captchaInvalid ? "material": "material"} style={{position:"relative",display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          
                <InputField
                  inputProps={{
                    id: "captcha",
                    type: "text",
                    name: "captcha",
                    placeholder: "Captcha",
                    value: captcha,
                    className: "form-input-captcha",
                    maxLength:6,
                    dataerror: props.errField.captchaErrorMessage
                  }}
                  onChange={handleInputChange}
                />

                <i className="ic-survey icn-login"></i>
                <label data-label="" className="form-label"></label>
                {/* <span className="erroe-txt" style={{ left: "200px","fontSize": '10px'}}>{captchaInvalid||''}</span> */}

                {/* <span className="password-show" style={{ top:"20px",left: "260px","fontSize": '20px',"position": 'absolute',"right": '-60px'}}>
                  {captchaError ? (
                    ''
                  ) : (
                    <>
                      <img src={captchaImg.image} alt="" style={{width:'50%'}}/>
                      <span style={{"position": 'relative',
    "top": '3px',
    "cursor": 'pointer',
    "color": '#000'}}
                        onClick={loadCaptchaImage}
                        title="reload captcha"
                      >
                        &#x21bb;
                      </span>
                    </>
                  )}
                </span> */}

                <span className="password-show" style={{ top:"20px",left: "260px","fontSize": '20px',"position": 'absolute',"right": '-60px'}}>
                                  {captchaError ? (
                                    ''
                                  ) : (
                                    <>
                                     
                    </>
                  )}
                </span>
                <img src={captchaImg.image} alt="" style={{width:'30%',borderRadius:'10px',height:38,marginLeft:10,marginRight:10}}/>
                                      <span style={{"position": 'relative',padding:'4px 20px',fontSize:20,borderRadius:'10px',color:'white',background:'green',cursor:'pointer'}}
                                        onClick={loadCaptchaImage}
                                        title="reload captcha"
                                      >
                        &#x21bb;
                      </span>
          
        </div>
      </>
    );
}

export default Captcha;