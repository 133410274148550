import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MasterService from "../../../service/MasterService";
import Button from "../../elements/Button";
import { DealerService } from "../../../service";
import { format } from 'date-fns';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

class ViewHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic_detail_history: [],
            account_detail_history: [],
            activeTab: 'basic',
            bankList:[],
        };
    }

    getBankList = async()=>{
        DealerService.getMasters(["bank_list"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200) {
                this.setState({ bankList: response.data.data.bank_list});
            }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }

    getHistoryDetails() {
        MasterService.post("/dealer/dealer/view_dealer_history", { dealer_id: this.props.histoy_dealer_id })
            .then((resp) => {
                if (resp.status === 200) {
                    this.setState({ basic_detail_history: resp.data.data.detail_history });
                    this.setState({ account_detail_history: resp.data.data.account_history });
                }
            })
            .catch((err) => console.log(err));
    }

    handleTabClick = (tabName,event) => {
        event.preventDefault();
        this.setState({ activeTab: tabName });
    }

    componentDidMount() {
        this.getHistoryDetails();
        this.getBankList();
    }

    render() {
        return (
            <div>
                 <div className="tab-list-new">
                    <a
                        className={`nav-link ${this.state.activeTab === 'basic' ? 'active' : ''}`}
                        onClick={(e) => this.handleTabClick('basic', e)}
                    >
                        Basic Details
                    </a>
                    <a
                        className={`nav-link ${this.state.activeTab === 'account' ? 'active' : ''}`}
                        onClick={(e) => this.handleTabClick('account', e)}
                    >
                        Account Details
                    </a>
                    </div>
                {this.state.activeTab === 'basic' ? (
                    this.state.basic_detail_history && this.state.basic_detail_history.length > 0 ? (
                        <table style={{ width: '100%', tableLayout: 'fixed' }}>
                            <thead>
                                <tr>
                                    <th>Name </th>
                                    <th>Email</th>
                                    <th>Contact No.</th>
                                    {/* <th>Owner Name</th>
                                    <th>Owner Email</th>
                                    <th>Owner Contact No.</th> */}
                                    <th>Created By and DateTime</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.basic_detail_history.map((item, idx) => {
                                    let new_created_date = format(new Date(item.created_date), 'do MMMM yyyy, h:mm a');
                                    return (
                                        <tr>
                                            <td style={{  wordWrap: 'break-word' }}>{item.dealership_name}</td>
                                            <td style={{  wordWrap: 'break-word' }}>{item.dealership_email}</td>
                                            <td style={{  wordWrap: 'break-word' }}>{item.dealership_contact_no}</td>
                                            {/* <td style={{  wordWrap: 'break-word' }}>{item.owner_name}</td>
                                            <td style={{  wordWrap: 'break-word' }}>{item.owner_email}</td>
                                            <td style={{  wordWrap: 'break-word' }}>{item.owner_contact_no}</td> */}
                                            <td style={{  wordWrap: 'break-word' }}>{item.created_by}<br />{new_created_date}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <table>
                            <tbody>
                                <tr>
                                    <td colspan="7"><h6 >No Records Found</h6></td>
                                </tr>
                            </tbody>
                        </table>
                    )
                ) : (
                    this.state.account_detail_history && this.state.account_detail_history.length > 0 ? (
                        <table style={{ width: '100%', tableLayout: 'fixed' }}>
                            <thead>
                                <tr>
                                    <th>Bank Account No.</th>
                                    <th>Beneficiary Name</th>
                                    <th>Status</th>
                                    <th>Created By and DateTime</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.account_detail_history.map((item, idx) => {
                                    let new_created_date = format(new Date(item.created_date), 'do MMMM yyyy, h:mm a');
                                    let bank_name = "";
                                    if(this.state.bankList && this.state.bankList.length > 0){
                                        [bank_name] =this.state.bankList.filter(it => {
                                            if(item.bank_id == it.bank_id){
                                                return true;
                                            }else{
                                                return false;
                                            }
                                        }).map(it => it.name);
                                    }
                                    return (
                                        <tr>
                                            <td >{item.bank_account_no}</td>
                                            <td >{item.beneficiary_name}</td>
                                            <td >{item.bank_status == '1' ? "Active" : "Inactive"}</td>
                                            <td >{item.created_by} <br /> {new_created_date}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <table>
                            <tbody>
                                <tr>
                                    <td colspan="5"><h6 >No Records Found</h6></td>
                                </tr>
                            </tbody>
                        </table>
                    )
                )}
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};

export default withTranslation("dealers")(connect(mapStateToProps, mapDispatchToProps)(ViewHistory));
