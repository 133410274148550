import React, { useEffect, useState } from "react";
import RadioBox from "../../view/elements/Radiobox";
import IndividualProfile from "./IndividualProfile";
import CompanyProfile from "./CompanyProfile";
import KycProfile from "./KycProfile";
import BankDetailsProfile from "./BankDetailsProfile";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import { DealerService } from "../../service";
import MasterService from "../../service/MasterService";
import { toast } from "react-toastify";
import PageLoader from "../../view/elements/PageLoader";

const views = ["Basic Details", "KYC Documents", "Bank Details"];

const MyAccountProfile = (props) => {
  const history = useHistory();
  const [currentView, setCurrentView] = useState(views[0]);
  const [selectedOption, setSelectedOption] = useState("individual");
  const [selectNext, setSelectNext] = useState(1);
  const [profession, setProfession] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [kycData, setKycData] = useState({});
  const [fileUpload, setfileUploadArr] = useState([]);
  const [dealer_hash_id, setDealer_hash_id] = useState("");
  const [docsImages, setDocImages] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [pan, setPan] = useState();
  const [aadharFront, setAadharFront] = useState();
  const [aadharBack, setAadharBack] = useState();
  const [msme, setMsme] = useState();
  const [gst, setGst] = useState();
  const [isPayoutApplicable, setIsPayoutApplicable] = useState("");
  const [kycSaveValue, setKycSaveValue] = useState({});
  const [userType, setUserType] = useState("");
  const [loginType, setLginType] = useState(true);
  const [loading , setLoading] = useState(true)
  const [sub_partner , set_is_sub_partner] = useState(false)
  const [is_team_member , set_is_team_member] = useState(false)
  const updateStateIntoRedux = props.updateStateIntoRedux;
  const company_type = [
    { id: 1, profession_name: "Pvt. Ltd." },
    { id: 2, profession_name: "LLP" },
    { id: 3, profession_name: "Proprietorship" },
    { id: 4, profession_name: "Partnership" },
    { id: 5, profession_name: "HUF" },
    { id: 6, profession_name: "Pub. Ltd." },
    { id: 7, profession_name: "Others" },
  ];



  const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
  const partner_type = user_info?.user_type
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    let dealer_hash_id = [...searchParams.keys()][0];
    let type = [...searchParams.keys()][1];
    console.log ("type" , type)
    if (type == "sub_partners") {
      console.log ("--here--")
      setLginType(false);
      set_is_sub_partner(true)
    }
    else if (type === "team_members"){
      set_is_team_member(true)
    }
    getBankDetails(dealer_hash_id);
    setDealer_hash_id(dealer_hash_id);
    fetchData(dealer_hash_id);
    getDocs(dealer_hash_id);
    getProfessionList();
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();
  }, [updateStateIntoRedux]);

  const getBankDetails = async (dealer_hash_id) => {
    const dealer_id_hash = localStorage.getItem("dealer_hash_id");
    const response = await DealerService.get("/dealer/dealer/kyc", {
      dealer_id_hash: dealer_id_hash,
    });
    setBankDetails(response?.data?.data?.[0]);
  };

  const getDocs = async (dealer_hash_id) => {
    let responseObj = {};
    const response = await DealerService.get("dealer/dealer/kyc/doc", {
      dealer_id_hash: dealer_hash_id,
    });
    response.data.data.map((data) => {
      if (data.kyc_master_id === 1) {
        responseObj.aadhar_front = data.file_url;
      }
      if (data.kyc_master_id === 2) {
        responseObj.aadhar_back = data.file_url;
      }
      if (data.kyc_master_id === 3) {
        responseObj.pan = data.file_url;
      }
      if (data.kyc_master_id === 4) {
        responseObj.cancel_checque = data.file_url;
      }
      if (data.kyc_master_id === 5) {
        responseObj.gst = data.file_url;
      }

      if (data.kyc_master_id === 7) {
        responseObj.msme = data.file_url;
      }

      setDocImages(responseObj);
    });
  };
  const fetchData = async (dealer_hash_id) => {
    let postdata = { dealer_id_hash: dealer_hash_id, source : "saathi_web" ,_with: ["DIF"] };
    let applicant_type_id;
    await DealerService.getBasicDetails(postdata).then((response) => {
      applicant_type_id = response?.data?.data?.[0]?.applicant_type_id;
      setFormData((prev) => ({
        ...prev,
        id: response?.data?.data?.[0]?.id,
        user_id: response?.data?.data?.[0]?.user_id,
        dealership_email: response?.data?.data?.[0]?.dealership_email,
        profession_id: response?.data?.data?.[0]?.profession_id,
        pincode: response?.data?.data?.[0]?.pincode,
        sfa_referral_user_id: response?.data?.data?.[0]?.sfa_referral_user_id,
        spoc_details: response?.data?.data?.[0]?.spoc_details,
        dealership_contact: response?.data?.data?.[0]?.dealership_contact,
        name: response?.data?.data?.[0]?.first_name,
        pan_no: response?.data?.data?.[0]?.pan_no,
        aadhar_no: response?.data?.data?.[0]?.aadhar_no,
        gst_number: response?.data?.data?.[0]?.gst_number,
        msme_number: response?.data?.data?.[0]?.msme_number,
        applicant_type: response?.data?.data?.[0]?.applicant_type_id,
        spoc_email: response?.data?.data?.[0]?.spoc_email,
        spoc_mobile: response?.data?.data?.[0]?.spoc_phone_number,
        spoc_name: response?.data?.data?.[0]?.spoc_name,
        is_msme: response?.data?.data?.[0]?.is_msme,
        is_gst: response?.data?.data?.[0]?.is_gst,
        parent_partner_id: response?.data?.data?.[0]?.parent_partner_id,
        user_type: response?.data?.data?.[0]?.user_type,
        is_payout_ambak: response?.data?.data?.[0]?.is_payout_applicable,
        status:response?.data?.data?.[0]?.status,
        designation:response?.data?.data?.[0]?.designation,
      }));
      if (response?.data?.data?.[0]?.is_payout_applicable === 0) 
        {
          setIsPayoutApplicable("No")
        }
      else {
        setIsPayoutApplicable("Yes")
      }
    });
    if (applicant_type_id === 1) {
      setSelectedOption("individual");
    } else if (applicant_type_id === 2) setSelectedOption("company");
    

  };



  const getProfessionList = async () => {
    const response = await MasterService.getMasters(
      ["lang", "professions_list"],
      {
        headers: { Authorization: props.auth_token },
      }
    );
    setProfession(response.data.data.professions_list);
  };

  const handleButtonClick = (view) => {
    const nextIndex = views.indexOf(view) + 1;
    setSelectNext(nextIndex);
    setCurrentView(view);
  };

  const handleChange = (event) => {
    if (event?.target?.name === "is_payout_applicable") {
      setIsPayoutApplicable(event?.target?.value);
    } else {
      setSelectedOption(event.target.value);
    }
  };

  const HandleKycData = (
    formData,
    FileData,
    pan,
    aadhar_front,
    aadhar_back,
    msme,
    gst
  ) => {
    setKycData(formData);
    setfileUploadArr(FileData);
    setPan(pan);
    setAadharFront(aadhar_front);
    setAadharBack(aadhar_back);
    setMsme(msme);
    setGst(gst);
  };

  const handleUserType = (dataFromChild) => {
    if (dataFromChild == "company") {
      setSelectedOption("company");
    } else if (dataFromChild == "Individual") {
      setSelectedOption("individual");
    }
  };
  const panAadharValidator = (number, type) => {
    try {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      const aadharRegex = /^[0-9]{12}$/;

      if (type === "pan" && panRegex.test(number)) {
        return false;
      } else if (type === "aadhar" && aadharRegex.test(number)) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return { isValid: false, message: "Validation failed due to an error" };
    }
  };

  const getOnChangeValue = (value) => {
    setKycSaveValue(value);
  };

  const validate = () => {
    const newErrors = {};
    
    if (selectNext === 1) {
      if (!is_team_member ){
      if (!formData.dealership_email) {
        newErrors.dealership_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formData.dealership_email)) {
        newErrors.dealership_email = "Email address is invalid";
      }
      if (!formData.dealership_contact) {
        newErrors.dealership_contact = "Mobile is required";
      } else if (!/^[6789]\d{9}$/.test(formData.dealership_contact)) {
        newErrors.dealership_contact = "Mobile Number is invalid";
      }
      if (!formData.profession_id) {
        newErrors.profession_id = "Profession is required";
      }
      if (!formData.pincode) {
        newErrors.pincode = "Pin code is required";
      } else if (!/^\d{6}$/.test(formData.pincode)) {
        newErrors.pincode = "Pin code is invalid";
      }
    }
  }

    if (selectNext === 1 && selectedOption === "company") {
      if (!formData.name) {
        newErrors.name = "Name is required";
      }
      if (!formData.spoc_email) {
        newErrors.company_spoc = "Company SPOC is required";
      }
      if (!formData.spoc_email) {
        newErrors.spoc_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formData.spoc_email)) {
        newErrors.spoc_email = "Email address is invalid";
      }
      if (!formData.spoc_mobile) {
        newErrors.spoc_mobile = "Mobile is required";
      } else if (!/^[6789]\d{9}$/.test(formData.spoc_mobile)) {
        newErrors.spoc_mobile = "Mobile Number is invalid";
      }
      if (!formData.spoc_name) {
        newErrors.spoc_name = "Spoc Name is required";
      }
    }

    if (selectNext === 2 && selectedOption === "individual") {
      if (!kycData.pan_card && !kycSaveValue.pan_card) {
        newErrors.pan_no = "Pan Card is required";
      }
      if (panAadharValidator(kycData.pan_card, "pan")) {
        newErrors.pan_no = "Please enter a valid pan number";
      }
      // if (!kycData.aadhar_card && !kycSaveValue.aadhar_card) {
      //   newErrors.aadhar_no = "Aadhar number is required";
      // } else if (
      //   panAadharValidator(
      //     kycData.aadhar_card || kycSaveValue.aadhar_card,
      //     "aadhar"
      //   )
      // ) {
      //   newErrors.aadhar_no = "Please enter a valid aadhar number";
      // }
      if (!pan && !kycSaveValue.pan) {
        newErrors.pan_file = "Pan Card is required";
      }
      if (!aadharFront && !kycSaveValue.aadhar_card_front_file) {
        newErrors.aadharFront = "Aadhar front is required";
      }
      if (!aadharBack && !kycSaveValue.aadhar_card_back_file) {
        newErrors.aadharBack = "Aadhar back is required";
      }
    }
    if (selectNext === 2 && selectedOption === "company") {
      if (!kycData.pan_card && !kycSaveValue.pan_card) {
        newErrors.pan_no = "Pan Card is required";
      } else if (
        panAadharValidator(kycData.pan_card || kycSaveValue.pan_card, "pan")
      ) {
        newErrors.pan_no = "Please enter a valid pan number";
      }
      if (!pan && !kycSaveValue.pan) {
        newErrors.pan_file = "Pan Card is required";
      }
      if (kycData.is_gst === "1") {
        if (!kycData.gstin_number) {
          newErrors.gst_number = "Gst number is required";
        }
        if (!gst) {
          newErrors.gst_file = "Gst is required";
        }
      }
      if (kycData.is_msme === "1") {
        if (!kycData.msme_number && kycData.msme_number) {
          newErrors.msme_number = "Msme number is required";
        }
        if (!msme) {
          newErrors.msme = "Gst is required";
        }
      }
    }
    if (selectNext === 3) {
      if (!kycData.beneficiary_name && !kycSaveValue.beneficiary_name) {
        newErrors.beneficiary_name = "Name is required";
      }
      if (!kycData.account_number && !kycSaveValue.account_number) {
        newErrors.account_number = "account number is required";
      }
      if (!kycData.ifsc_code && !kycSaveValue.ifsc_code) {
        newErrors.ifsc_code = "ifsc code is required";
      }
      if (!kycData.bank_name) {
        newErrors.bank_name = "bank type is required";
      }
      // if (!kycData.upi_id && !kycSaveValue.upi_id) {
      //   newErrors.upi_id = "upi id is required";
      // }
      if (
        (fileUpload.length === 0)
      ) {
        newErrors.cancel_checque = "requires";
      }
    }
    
    console.log("newErrors", newErrors);
    setErrors(newErrors);
    console.log("Object.keys(newErrors).length === 0Object.keys(newErrors).length === 0Object.keys(newErrors).length === 0" , Object.keys(newErrors).length === 0)
    return Object.keys(newErrors).length === 0;
  };

  const ShowNext = () => {
    if (is_team_member){
      // toast.success("Data Updated Successfully")
      saveBasicDetails()
      return;
    }
    if (Number(formData.status) === 1)
      {
        toast.success("Details can not be changed once partner is verified")
        return;
      }
    if (validate()) {
      saveBasicDetails();
      // } else if (selectNext === 2 && fileUpload.length != 5) {
      //   toast.error("Kindly upload all the documents");
    } else {
      toast.error("Please fill in all required details.");
    }
  };

  const ShowPre = () => {
    if (selectNext === 1) {
      history.push("/my-account-profile");
    }
    setSelectNext(selectNext - 1);
  };

  const saveBasicDetails = async () => {
    const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    let arr = [];
    const dealerId = loginUserInfo.user_data.dealer_id;
    const user_id = loginUserInfo.user_data.user_id;
    let reqData = {}
    if (!is_team_member) {
     reqData = {
      name: formData?.name,
      email: formData.dealership_email,
      profession_id: formData.profession_id,
      pincode: formData.pincode ? String(formData.pincode) : "",
      sfa_referral_user_id: formData.sfa_referral_user_id,
      term_condition: true,
      mobile: formData.dealership_contact,
      user_id: formData.user_id,
      partner_id: formData.id,
      step: 1,
      // spoc_email: formData.spoc_email,
      spoc_designation: 0,
      spoc_mobile: formData?.spoc_mobile,
      spoc_name: formData?.spoc_name,
      parent_partner_id: formData?.parent_partner_id ?? 0,
      user_type: formData?.user_type.toString(),
      is_payout_ambak: isPayoutApplicable == "Yes" ? 1 : 0,
      pan_number: formData?.pan_no,
      is_pan_verified: 1,
      aadhar_no: formData?.aadhar_no,
      source : "saathi_web"

    };
  }


  else {
    reqData = {
      user_id: formData.user_id,
      partner_id: formData.id,
      name: formData.name,
      mobile: formData.dealership_contact,
      email: formData.dealership_email,
      user_type: "61",
      term_condition: true,
      parent_partner_id: formData?.parent_partner_id ?? 0,
      designation: formData.designation,
      profession_id: 1,
      source: "Team Member Creation",
      applicant_type_id: 1,
      step: 1,
      updated_by: formData?.parent_partner_id ?? 0,
    };
  }
    


    if (selectedOption === "individual") {
      // reqData.user_type = "1";
      reqData.applicant_type_id = 1;
    } else if (selectedOption === "company") {
      // reqData.user_type = "2";
      reqData.applicant_type_id = 2;
      reqData.company_type = 1;
      reqData.spoc_email = formData.company_spoc || formData.spoc_email; // Use company_spoc if available
    }

    if (selectNext === 2) {
      reqData.pan_number = kycData?.pan_card;
      reqData.aadhar_no = kycData?.aadhar_card;
      reqData.msme_number = kycData?.msme_number;
      reqData.gst_number = kycData?.gstin_number;
      reqData.is_pan_verified = 1;
      reqData.is_msme = kycData?.is_msme;
      reqData.is_gst = kycData?.is_gst;
      if (kycData.pan_card_file) arr.push(kycData.pan_card_file);
      if (kycData.aadhar_card_front_file)
        arr.push(kycData.aadhar_card_front_file);
      if (kycData.aadhar_card_back_file)
        arr.push(kycData.aadhar_card_back_file);
      if (kycData.msme_file) arr.push(kycData.msme_file);
      if (kycData.gstin_file) arr.push(kycData.gstin_file);

      reqData.docs = fileUpload;
    }

    if (selectNext === 3) {
      let bankRequestData = {
        account_number: kycData.account_number,
        bank_name: Number(kycData.bank_name),
        beneficiary_name: kycData.beneficiary_name,
        ifsc_code: kycData.ifsc_code,
        upi_id: kycData.upi_id,
        user_id: user_id,
        partner_id: dealerId,
        is_gst: kycData.is_gst,
        is_msme: kycData.is_msme,
        is_name_matched: 1,
      };
      handleBankDetails(bankRequestData);
      handleFileInput(fileUpload);
      return;
    }

    handleFileInput(fileUpload);

    const response = await MasterService.post(
      "/partner/partner/save-basic-details",
      reqData
    );

    if (response.status == 200) {
      toast.success(response.data.message);
      let nextIndex = selectNext;
      if (selectNext === 2) {
        nextIndex = views.indexOf("KYC Documents") + 1;
        if (!is_team_member)
        {setSelectNext(nextIndex + 1);}
        setCurrentView("Bank Details");
      }
      if(!is_team_member)
     { setSelectNext(nextIndex + 1);}
      // setSelectNext(selectNext + 1);
    } else {
      toast.error(response.data.message[0]);
    }
  };

  const handleBankDetails = async (data) => {
    const response = await MasterService.post(
      "/partner/partner/save-bank-details",
      data
    );
    if (response.status === 200) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message[0]);
    }
  };
  const handleFileInput = async (fileUpload) => {
    let reqData = {
      dealer_id_hash: dealer_hash_id,
      docs: fileUpload,
    };

    const response = await DealerService.post(
      "/dealer/dealer/kyc/doc/save",
      reqData
    );
  };

  setTimeout(() => {
    setLoading(false)
  }, 500)

  return (
    <>
      <div className="LeadContainer">

        <div className="myaccountcard">

           {loading && <PageLoader/>}
          <div className="myaccountflex">
            {/* <div className="accountpre" onClick={ShowPre}>
              <img
                src="https://ambak.storage.googleapis.com/partner/1886/1720781007726.svg"
                alt="doc"
                width={16}
                height={16}
              ></img>
              <span>Profile Details</span>
            </div> */}

           { !is_team_member && <div className="accountimgfixed">
              <div className="dropdown" style={{ padding: 5 }}>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink to="/logout" className="headermenulogout">
                      Logout
                    </NavLink>
                  </li>
                </ul>
                {loginType && (
                  <img
                    src={require(`../images/threedotoption.svg`)}
                    alt="threedot"
                  />
                )}
              </div>
            </div>}
          </div>
          <div className="myaccountborder">
            <div className="maininfokyc">
              <div>
                <img
                  src="https://ambak.storage.googleapis.com/partner/1730/1720763140702.svg"
                  alt="doc"
                  width={60}
                  height={60}
                ></img>
              </div>
              <div>
                <div className="mainkycinput">{formData?.name}</div>
                <div className="mobilenumbershow">
                  +91<span>{formData?.dealership_contact}</span>
                </div>
              </div>
            </div>
          </div>

         {!is_team_member && <div className="myaccountpadding">
            <div
              className="leftbottomcontainer1"
              style={{ borderBottom: "none" }}
            >
              <div>
                <button
                  className={selectNext === 1 ? "active" : ""}
                  onClick={() => handleButtonClick("Basic Details")}
                >
                  Basic Details
                </button>
                <button
                  className={selectNext === 2 ? "active" : ""}
                  onClick={() => handleButtonClick("KYC Documents")}
                >
                  KYC Documents
                </button>
                <button
                  className={selectNext === 3 ? "active" : ""}
                  onClick={() => handleButtonClick("Bank Details")}
                >
                  Bank Details
                </button>
              </div>
            </div>
          </div>}
          {selectNext === 1 && (
            <>
              {currentView === "Basic Details" && (
                <>
                  <div className="myaccountradio">
                  {!is_team_member &&  <div className="myteamsflex ">
                      <div style={{ marginRight: 10 }}>
                        <RadioBox
                          type="radio"
                          name="kyc_add"
                          id="individual"
                          value="individual"
                          readOnly={true}
                          disabled={true}
                          label={
                            <>
                              Individual
                              <span style={{ color: "red" }}>*</span>
                            </>
                          }
                          checked={selectedOption === "individual"}
                          onChange={handleChange}
                        />
                      </div>
                      <div style={{ marginRight: 10 }}>
                        <RadioBox
                          type="radio"
                          name="kyc_add"
                          id="company"
                          value="company"
                          readOnly={true}
                          disabled={true}
                          label={
                            <>
                              Company
                              <span style={{ color: "red" }}>*</span>
                            </>
                          }
                          checked={selectedOption === "company"}
                          onChange={handleChange}
                        />
                      </div>
                    </div>}

                    {selectedOption === "individual" && (
                      <IndividualProfile
                        profession={profession}
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                        handleUserType={handleUserType}
                        is_team_member = {is_team_member}
                      />
                    )}
                    {selectedOption === "company" && (
                      <CompanyProfile
                        profession={profession}
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                        handleUserType={handleUserType}
                      />
                    )}
                   {(partner_type !== 1 || sub_partner) && 
                   <div className="row">
                      <div className="col-md-6">
                        <span>Is payout applicable</span>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ marginRight: 10, marginTop: 10 }}>
                            <RadioBox
                              type="radio"
                              name="is_payout_applicable"
                              id="Yes"
                              value="Yes"
                              label="Yes"
                              checked={isPayoutApplicable === "Yes"}
                              onChange={handleChange}
                            />
                          </div>

                          <div style={{ marginRight: 10, marginTop: 10 }}>
                            <RadioBox
                              type="radio"
                              name="is_payout_applicable"
                              id="No"
                              value="No"
                              label="No"
                              checked={isPayoutApplicable === "No"}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </>
              )}
            </>
          )}

          {(selectNext === 2 || currentView === "KYC Documents") && (
            <KycProfile
              HandleKycData={HandleKycData}
              formData={formData}
              dealer_hash_id={dealer_hash_id}
              docsImages={docsImages}
              errors={errors}
              selectedOption={selectedOption}
              kyc_save_value={kycSaveValue}
              set_kyc_save_value={setKycSaveValue}
              getOnChangeValue={getOnChangeValue}
            />
          )}

          {(selectNext === 3 || currentView === "Bank Details") && (
            <BankDetailsProfile
              HandleKycData={HandleKycData}
              company_type={company_type}
              dealer_hash_id={dealer_hash_id}
              bankDetails={bankDetails}
              formData={formData}
              docsImages={docsImages}
              errors={errors}
              kyc_save_value={kycSaveValue}
              set_kyc_save_value={setKycSaveValue}
              getOnChangeValue={getOnChangeValue}
            />
          )}

          <div className="accountbtndertails">
            <div className="myaccountbtnflex">
              <div className="mainflexbg">
                <div className="mainkycflex">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/1886/1720765123441.svg"
                    alt="doc"
                    width={20}
                    height={20}
                  ></img>
                  <div>
                    Don't worry, your personal data is safe with us. You will
                    not receive any spam.
                  </div>
                </div>
              </div>

              <div className="btnflexcommon">
  {selectNext !== 3 ? (
    <>
      {/* <button className="saveandexitbtn">Save & Exit</button> */}
      <button
        className="accountnextbtn"
        onClick={ShowNext}
        disabled={Number(formData.status) === 1}
        style={{

          cursor: Number(formData.status) === 1 ? 'not-allowed' : 'pointer',
          
        }}
      >
        Save and Next
      </button>
    </>
  ) : (
    <button
      className="accountnextbtn"
      onClick={ShowNext}
      disabled={Number(formData.status) === 1}
      style={{
      
        cursor: Number(formData.status) === 1 ? 'not-allowed' : 'pointer',
   
      }}
    >
      Submit
    </button>
  )}
</div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(MyAccountProfile)
);
