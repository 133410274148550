import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import LoanOffersCalculator from "./component/LoanOffersCalculator";
import LoanOffer from "./component/loanoffer/LoanOffer";
import { LoanDetailProvider } from "../../view/ruleEngine/component/loanDetailContext";

const MyTools = (props) => {
  const updateStateIntoRedux = props.updateStateIntoRedux;
  const [checkOfferLoan, setCheckOfferLoan] = useState(false);


  useEffect(() => {
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();
  }, [updateStateIntoRedux]);

  const handleMYToolsVisiblity = (visiblity) => {
    setCheckOfferLoan(visiblity);
  }

  const renderLoanOffer = () => {
    return <LoanDetailProvider><LoanOffer setHome={handleMYToolsVisiblity}></LoanOffer></LoanDetailProvider>;
  };

  const renderMyTools = () => {
    return(
        <div className="mytoolscard">
        <div className="myleadcontainer">
          <div className="leadheading">My Tools</div>
          {/* <div className="notificationbutton">
            <img
              src={require("../../../src/saathi-web/images/notifybell.svg")}
              alt="not"
              width={24}
              height={24}
            />
          </div> */}
        </div>

        <LoanOffersCalculator setCheckOfferLoan={setCheckOfferLoan} />
      </div>
    );
  };

  return (
    <>
    {checkOfferLoan ? renderLoanOffer() : renderMyTools()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(MyTools)
);
