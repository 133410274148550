import React, { useState, useEffect, useMemo } from "react";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/leadFormField.json";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import { UPDATE_LEAD_STATUS } from "../../queries/sangam/leads.gql";
import GeneralService from "../../service/generalService";
import { gql } from "@apollo/client";
import Formsy from "formsy-react";
import FormsyInputField from "../../view/elements/FormsyInputField";
import FormsySelect from "../../view/elements/FormsySelect";
import FormsyDatePicker from "../../view/elements/FormsyDatePicker";
import Q, { async } from "q";

const LOGIN_DOC_ID = 42;

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date("1900-01-01 00:00:00");
const FORM_FIELDS = FormFields["LOGGEDIN"];
const DocJson = FormFields["LOGGED_DOCS"];

const LoginDetails = ({ leadDetail, masterdata, masterStatusData }) => {
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [disbursalDocuments, setDisbursalDocuments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [existingUploadedFiles, setExistingUploadedFiles] = useState([]);

  let updatedStatusList =
    leadDetail?.lead_status_history?.map((data) => data.status_id) || [];

  useEffect(() => {
    if (leadDetail && leadDetail?.lead_details) {
      let record = {
        login_amount: leadDetail?.lead_details.login_amount,
        login_number: leadDetail?.lead_details?.login_number,
        login_remark: "",
        bank_id: leadDetail?.lead_details?.bank_id || 0,
        login_date: leadDetail.lead_details.login_date
          ? dateFormat(leadDetail.lead_details.login_date, "dd mmm, yyyy")
          : "",
      };
      setBasicDetails(record);
      setExistingUploadedFiles(leadDetail.leaddocs || []);
    }
  }, [leadDetail]);

  useEffect(() => {
    let doc_list = [
      {
        id: LOGIN_DOC_ID,
        max_upload: 5,
        min_upload: 1,
        name: "Approval Image",
      },
    ];
    let doc_array = [];
    DocJson &&
      DocJson.map((field) => {
        doc_list.map((doc) => {
          return doc.id === field.doc_id
            ? doc_array.push(doc)
            : doc_array.push({});
        });
      });
    doc_array = doc_array.filter((obj) => Object.keys(obj).length !== 0);
    setDisbursalDocuments(doc_array);
  }, []);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    if (options?.bank_list && options?.bank_list?.length) {
      options.bank_list = options?.bank_list?.map((data) => {
        data.value = data?.id || "";
        data.label = data?.banklang?.bank_name || "";
        return data;
      });
    }
    return options;
  }, [masterdata]);

  const handleInputChange = (event) => {
    if (event.target) {
      const { id, value } = event.target;
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [id]: value,
      }));
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

  const uploadDocsAndSave = (lead_id) => {
    const awsRes = [],
      leadDocArr = [];

    if (uploadedFiles.length) {
      let formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of uploadedFiles) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        GeneralService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
    } else {
      awsRes.push(
        Promise.resolve({
          data: { status: 200, message: "Success", data: ["not_updated"] },
        })
      );
    }

    if (uploadedFiles.length) {
      Q.allSettled(awsRes).then((uploadOutput) => {
        uploadOutput.forEach((res, index) => {
          let response = res.value || [];
          if (res.state === "fulfilled") {
            if (response.data.data.length) {
              let resData = response.data.data;
              resData.forEach((row, i) => {
                const loanDetailsSaveDoc = {
                  lead_id,
                  doc_path: row.file_url,
                  parent_doc_id: 9,
                  doc_id: LOGIN_DOC_ID,
                  doc_source: "web",
                  user_id: loginUserInfo?.dealer_id,
                  customer_type: "customer",
                  customer_id: leadDetail?.customer?.customer_id,
                };
                leadDocArr.push(loanDetailsSaveDoc);
              });
            }
          }
        });
        if (leadDocArr.length) {
          let variables = {
            saveDocInput: {
              lead_id,
              lead_docs: leadDocArr,
            },
            api_called_by: "saathi_web",
            partner_id: loginUserInfo?.dealer_id,
          };

          let mutation = gql`
            mutation save_doc(
              $saveDocInput: SaveDocInput!
              $api_called_by: API_CALL_BY!
              $partner_id: Float!
            ) {
              save_doc(
                saveDocInput: $saveDocInput
                api_called_by: $api_called_by
                partner_id: $partner_id
              ) {
                lead_id
                doc_id
                doc_path
              }
            }
          `;

          executeGraphQLMutationFinex(mutation, variables)
            .then((result) => {
              if (result && result.data) {
                toast.success("Document uploaded successfully");
              } else {
                console.log(result);
                toast.error("Internal server error");
              }
            })
            .catch((error) => {
              toast.error(error.message);
            });
        } else {
          toast.error("Error in image upload!");
        }
      });
    }
  };

  const handleSubmit = () => {
    const isExistingDoc = leadDetail?.leaddocs?.some(
      (doc) => doc.doc_id === LOGIN_DOC_ID
    );
    if (!(isExistingDoc || uploadedFiles.length))
      return toast.warn("Please upload supporting docs");
    const lead_id = leadDetail?.id;
    uploadDocsAndSave(lead_id);
    let reqBody = {
      bank_id: basicDetails.bank_id || 0,
      login_number: basicDetails.login_number || 0,
      login_amount: basicDetails.login_amount,
      login_remark: basicDetails.login_remark || "",
      login_date: dateFormat(basicDetails.login_date, "yyyy-mm-dd"),
    };

    let mutation = UPDATE_LEAD_STATUS,
      variables = {
        api_called_by: "saathi_web",
        partner_id: loginUserInfo?.dealer_id,
        UpdateLeadStatus: {
          lead_id: lead_id,
          user_id: loginUserInfo?.dealer_id,
          update_type: "status",
          save_type:
            [2].filter((status_id) => updatedStatusList.includes(status_id))
              .length >= 1
              ? "save"
              : "save",
          status_id: [2],
          after_verified: is_login_verified ? 1 : 0,
          login_form: reqBody,
        },
      };

    executeGraphQLMutationFinex(mutation, variables)
      .then((resp) => {
        let response = resp?.data?.UpdateLeadStatus || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let BasicDetailsForm = useMemo(() => {
    return FORM_FIELDS.map((field) => {
      if (field.name === "dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    });
  }, []);

  BasicDetailsForm = useMemo(() => {
    if (basicDetails) {
      // To Show nationality input text box in case of non phillipines
      if (basicDetails.nationality !== "other") {
        return FORM_FIELDS.filter((v) => v.id !== "other_nationality");
      }
    }
    return FORM_FIELDS;
  }, [basicDetails]);

  const is_login_verified = useMemo(() => {
    return [1].includes(leadDetail.status_id) ||
      leadDetail.is_login_verified === "1"
      ? true
      : false;
  }, []);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((current) => [...current, ...files]);
  };

  const handleOpenNewTab = (event) => {
    const { src } = event.target;
    if (src) {
      window.open(src, "_blank");
    } else {
      const pdfUrl = event.target.getAttribute("data-docurl");
      window.open(pdfUrl, "_blank");
    }
  };

  // @index - consider doc_id if existing OR index if newly added doc.
  const handleRemoveDocument = async (docType, index) => {
    try {
      const isConfirmed = window.confirm(
        "Confirm, do you want to remove this document"
      );
      if (isConfirmed) {
        if (docType === "new") {
          const uploadedDocsClone = [...uploadedFiles];
          uploadedDocsClone.splice(index, 1);
          setUploadedFiles(uploadedDocsClone);
        } else {
          const lead_id = leadDetail?.id;
          const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
              update_doc(updateDocInput: $updateDocInput) {
                id
                lead_id
                doc_id
              }
            }
          `;
          const variables = {
            updateDocInput: {
              id: index,
              lead_id,
              status: "0",
              parent_doc_id: 0,
              doc_id: 0,
              doc_source: "web",
              user_id: loginUserInfo?.dealer_id,
              tab_name: "first_tab",
            },
          };

          await executeGraphQLMutationFinex(mutation, variables);
          const updatedDocs =
            leadDetail?.leaddocs?.filter((doc) => doc?.id !== index) || [];
          setExistingUploadedFiles(updatedDocs);
          toast.success("Success");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div>
        <h5>Logged In Details</h5>

        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => setAllowSubmit(true)}
          onInvalid={() => setAllowSubmit(false)}
          onValidSubmit={handleSubmit}
        >
          <div className="row">
            {BasicDetailsForm.map((field) =>
              ["pattern-format", "number-format"].includes(field.type) ? (
                <fieldset className="form-filed col-md-6 ">
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={basicDetails && basicDetails[field.name]}
                    placeholder=" "
                    thousandsGroupStyle="lakh"
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    label={field.label}
                    onChange={
                      field.type === "number-format"
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                    }
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={field.validations}
                    validationError={
                      basicDetails[field.name] ? field.validationError : ""
                    }
                    readOnly={field.readOnly}
                    required={field.required}
                  />
                </fieldset>
              ) : ["text"].includes(field.type) ? (
                <fieldset className="form-filed col-md-6 ">
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={basicDetails && basicDetails[field.name]}
                    label={field.label}
                    onChange={
                      field.type === "pattern-format"
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                    }
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={field.validations}
                    validationError={
                      basicDetails[field.name] ? field.validationError : ""
                    }
                    readOnly={
                      is_login_verified
                        ? field.name == "login_amount"
                          ? false
                          : true
                        : false
                    }
                    required={field.required}
                  />
                </fieldset>
              ) : field.type === "date-picker" ? (
                <fieldset className="form-filed col-md-6 ">
                  <FormsyDatePicker
                    name={field.name}
                    selectedDate={
                      basicDetails &&
                      basicDetails[field.name] &&
                      new Date(basicDetails[field.name])
                    }
                    placeholder={field.label}
                    maxDate={new Date()}
                    value={basicDetails && basicDetails[field.name]}
                    onDateSelect={(date) => handleDateChange(date, field.name)}
                    dateFormat="dd MMM, yyyy"
                    validations={field.validations}
                    validationError={
                      basicDetails[field.name] ? field.validationError : ""
                    }
                    required={field.required}
                    hasError={
                      basicDetails.hasOwnProperty(field.name) &&
                      !basicDetails[field.name]
                        ? true
                        : false
                    }
                  />
                </fieldset>
              ) : field.type === "dropdown" ? (
                <fieldset className="single-select col-md-6">
                  <FormsySelect
                    name={field.name}
                    id={field.id}
                    inputProps={{
                      options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                      placeholder: field.label,
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value:
                        ALL_OPTIONS &&
                        ALL_OPTIONS[field.optionsKey] &&
                        ALL_OPTIONS[field.optionsKey].filter(
                          ({ value }) => value == basicDetails[field.name]
                        ),
                      isDisabled: is_login_verified ? true : field.readOnly,
                    }}
                    required={field.required}
                    value={basicDetails && basicDetails[field.name]}
                    onChange={(data) => handleSelectChange(data, field.name)}
                  />
                </fieldset>
              ) : field.type === "textarea" ? (
                <fieldset className="col-md-12">
                  <div className="material">
                    <label>{field.label}</label>
                    <textarea
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={basicDetails && basicDetails[field.name]}
                      placeholder=" "
                      onChange={handleInputChange}
                      className="form-input"
                    ></textarea>
                  </div>
                </fieldset>
              ) : null
            )}
          </div>

          <br></br>
          <br></br>
          <div className="">
            <h5>Login Supporting Docs</h5>
            <ul>
              {disbursalDocuments?.length > 0 &&
                disbursalDocuments?.map((fields, index) => (
                  <li key={index}>
                    <div className="col-sm-2">
                      <div className="displayflex">
                        <div className="updatefileupload">
                          <input
                            type="file"
                            id={`${index}_fileInput`}
                            onChange={handleFileChange}
                            required={fields.is_required}
                            accept="image/*,.pdf"
                            multiple
                          />
                          <label
                            htmlFor={`${index}_fileInput`}
                            className="file-label"
                          >
                            <span>
                              <img
                                src={require(`../../../src/saathi-web/images/uploadimg.svg`)}
                                alt="not"
                                width={20}
                                height={20}
                              />
                            </span>
                            <div id="fileName" className="statusdoc">
                              Upload Doc
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="displayflex">
              {/* Already uploaded docs */}
              {existingUploadedFiles.length > 0 &&
                existingUploadedFiles.map((doc, index) => {
                  if (doc.doc_id === LOGIN_DOC_ID) {
                    return (
                      <div key={index} className="showimage">
                        {doc.ext === "pdf" || doc.ext === "PDF" ? (
                          <i
                            data-docurl={doc?.doc_path}
                            className="ic-pdf-file icons-normal"
                            onClick={handleOpenNewTab}
                            style={{ fontSize: "30px" }}
                          ></i>
                        ) : (
                          <img
                            src={doc.doc_path}
                            width={70}
                            height={40}
                            alt={`Document ${doc.doc_id}`}
                            onClick={handleOpenNewTab}
                          />
                        )}
                        <i
                          className="close-icon"
                          onClick={() =>
                            handleRemoveDocument("existing", doc.id)
                          }
                        >
                          &times;
                        </i>
                      </div>
                    );
                  }
                  return null;
                })}

              {/* Newly added ones */}
              {uploadedFiles?.map((file, index) => {
                const docUrl = URL.createObjectURL(file);
                const fileType = file.type;
                const isPDF = fileType === "application/pdf";
                return (
                  <div key={`${index}_uploadedDoc`} className="showimage">
                    {isPDF ? (
                      <i
                        data-docurl={docUrl}
                        className="ic-pdf-file icons-normal"
                        onClick={handleOpenNewTab}
                        style={{ fontSize: "30px" }}
                      ></i>
                    ) : (
                      <img
                        src={docUrl}
                        width={70}
                        height={40}
                        onClick={handleOpenNewTab}
                      />
                    )}
                    <i
                      className="close-icon"
                      onClick={() => handleRemoveDocument("new", index)}
                    >
                      &times;
                    </i>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="updatestatusflex">
            <button className="submitbtnnewleadstatus">Submit</button>
          </div>
        </Formsy>
      </div>
    </>
  );
};

export default LoginDetails;
