import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { SITE_CONTENTS } from './../../../config/constant';
import InputField from '../../elements/InputField';


const ContactUs = (props) => {

    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        massage:""
    });

    const handleInput =(e) =>{
        const name = e.target.name;
        const val = e.target.value;
        setFormData({ ...formData, [name]: val });
    }


        return (
            <div className="item os-animation animated">
                <div id="divSec" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                    <div className="col-md-12 clearfix" id="modified_design" style={{ display: "flex",justifyContent:"space-between",alignItems:"center" }}>
                        <div className="signUp-container text-left" style={{marginTop:20,marginRight:40}}>
                            <h1 className="login-h1" style={{ fontSize: 18, fontWeight: 600 }}>{props.t('site_content.contact_us')}</h1>
                            <p className="login-p">{props.t('site_content.get_in_touch')}</p>
                            <h1 className="login-h1" style={{ fontSize: 18, fontWeight: 600,marginBottom:20 }}>{props.t('site_content.company_name')}</h1>

                            <div className="row">
                            <div className="col-md-12 clearfix" style={{ display: "flex",alignItems:"center" }}>
                                    <div className="contact_Ic">
                                        <img src={require('./../assets/images/address.svg')} alt="" />
                                    </div>
                                    <div className="contactInfo">
                                        {SITE_CONTENTS.SITE_ADDRESS}
                                    </div>
                                </div>
                                <div className="col-md-12 clearfix" style={{ display: "flex",alignItems:"center" }}>
                                    <div className="contact_Ic">
                                        <img src={require('./../assets/images/email.svg')} alt="" />
                                    </div>
                                    <div className="contactInfo">
                                        {SITE_CONTENTS.DEALER_SUPPORT_EMAIL}
                                    </div>
                                </div>
                                <div className="col-md-12 clearfix" style={{ display: "flex",alignItems:"center" }}>
                                    <div className="contact_Ic">
                                        <img src={require('./../assets/images/mobile.svg')} alt="" />
                                    </div>
                                    <div className="contactInfo">
                                        {SITE_CONTENTS.DEALER_SUPPORT_PHONE}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='contactcard'>
                            <div className='row'>
                            <div className="col-md-12">
                                    <InputField
                                        inputProps={{
                                            id: "full_name",
                                            type: "text",
                                            name: "full_name",
                                            label: ('Full Name'),
                                            value: formData.full_name || '',
                                            placeholder:"Please Enter Name",
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <InputField
                                        inputProps={{
                                            id: "email",
                                            type: "email",
                                            name: "email",
                                            label: ('Email'),
                                            placeholder:"Please Enter Email",
                                            value: formData.email || '',
                                        }}
                                        onChange={handleInput}
                                    />
                                </div>
                                <div className="col-sm-12 form-group">
                                <label>Massage</label>
                                <textarea maxLength="2000" name="massage" className="form-control"  value={formData.massage || ''}  onChange={handleInput} ></textarea>
                            </div>

                                <div className="col-xs-12">
                                        <input type="button" value={('submit')} className="btn btn-primary btn-lg btn-block" />
                                   </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }

export default withTranslation('common')(ContactUs);