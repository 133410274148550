import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../view/elements/Input";
import { PartnerOnBordingContext } from "../../PartnerContextApi/PartnerOnBordingContext";
import { fileUploader } from "../../Utility/user.utility";
import FileInput from "./Input/FileInput";
import ValidatorIcon from "./Icons/ValidatorIcon";
import { isFileTypeAllowed } from "../../Utility/file.utility";

export const AadharCardKycComponent = () => {
  const { kycData, setKycData, isLoading, setIsLoading, error, setError } = useContext(PartnerOnBordingContext);
  const [isAadharFront, setIsAadharFront] = useState(false);
  const [isAadharBack, setIsAadharBack] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [frontAadharDoc, setFrontAadharDoc] = useState({});
  const [backAadharDoc, setBackAadharDoc] = useState({});
  
  useEffect(() => {
    setError({...error, aadharFront: true, aadharBack: true, aadhar_no: true});
    if (kycData?.maskedAadhaar) {
      setShowIcons(true);
      setError((prevState) => ({
        ...prevState,
        aadhar_no: false
      }));
    }
  },[]);

  useEffect(() => {
    if (frontAadharDoc.file) {
      handleFileUpload(frontAadharDoc.file);
    } else if (backAadharDoc.file) {
      handleFileUpload(backAadharDoc.file);
    }
  }, [frontAadharDoc.file, backAadharDoc.file]);

  const handleOnChange = (e) => {
    const {id='', value='', files=[]} = e?.target;
    if (files && !isFileTypeAllowed(files[0])) {
      return;
    }
     if (id === 'adhar_card_front') {
       setFrontAadharDoc((prevState) => ({
        ...prevState,
        file: files[0],
      }));
      setIsAadharFront(true);
    } else if(id === 'adhar_card_back') {
      setBackAadharDoc((prevState) => ({
        ...prevState,
        file: files[0],
      }));
      setIsAadharBack(true);
    }
    if(error.aadhar_no && id === 'aadhar_no') {
      setError({...error, aadhar_no: false});
    }
    setKycData((prevState) => ({
     ...prevState,
      [id]: value,
    }));
  };


  const handleFileUpload = async (file) => {
    setIsLoading(true);
    try {
      const response = await fileUploader(file);
      if(isAadharFront) {
        setFrontAadharDoc({
          file_name : response?.file_name,
          file_url : response?.file_url,
        })
        setKycData((prevState) => ({
          ...prevState,
          docs : [...prevState.docs, {
            kyc_master_id: 1,
            file_name: response?.file_name,
            file_url: response?.file_url,
          }]
        }))
        setIsAadharFront(false);
        setError({...error, aadharFront: false});
      } else if(isAadharBack) {
        setBackAadharDoc({
          file_name : response?.file_name,
          file_url : response?.file_url,
        })
        setKycData((prevState) => ({
          ...prevState,
          docs : [...prevState.docs, {
            kyc_master_id: 2,
            file_name: response?.file_name,
            file_url: response?.file_url,
          }]
        }))
        setIsAadharBack(false);
        setError({...error, aadharBack: false});
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h6>Aadhar Card Details</h6>
      <div>
        <div col-md-12>
          <InputField
            type="text"
            name="aadhar_no"
            id="aadhar_no"
            placeholder="Enter Aadhar Number "
            maxLength="12"
            is_label_required="true"
            onChange={handleOnChange}
            value={kycData?.maskedAadhaar ? kycData.maskedAadhaar : kycData.aadhar_no}
            disabled={kycData.maskedAadhaar}
            error={error?.aadhar_no}
          >
            {!isLoading && showIcons &&
              <>
              {showIcons || kycData.isPanKycCompleted ? (
                <ValidatorIcon 
                  isValid={kycData.maskedAadhaar}/>
              ) : null}
              </>
            }
          </InputField>
        </div>
        <div style={{ paddingBottom: 20}}>
          <FileInput
            name="adhar_card_front"
            id="adhar_card_front"
            placeholder={frontAadharDoc.file_name ?? "Aadhar Card Front"}
            onChange={handleOnChange}
            file={frontAadharDoc.file}
            fileUrl={frontAadharDoc.file_url}
            required={true}
            // error={error?.aadharFront}
          />
          <FileInput
            name="adhar_card_back"
            id="adhar_card_back"
            placeholder={backAadharDoc.file_name ?? "Aadhar Card Back"}
            onChange={handleOnChange}
            file={backAadharDoc.file}
            fileUrl={backAadharDoc.file_url}
            required={true}
            // error={error?.aadharBack}
          />
        </div>
      </div>
    </div>
  );
};
