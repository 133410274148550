import React, {Component} from 'react';

class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            type: '',
            name: '',
            label:'',
            dataerror:'',
            maxLength:'',
            value:'',
            onChange: '',
        }
    }

    changeHandel = (event)=>{
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }
    onKeyPress = (event)=>{
        if (typeof this.props.onKeyPress === 'function') {
            this.props.onKeyPress(event);
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        // let prevVal = prevState.value;
        if(prevState.value !== nextProps.value){
            return {value: nextProps.value};
        } 
        return null;
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({ 
    //         value: nextProps.value
    //     });
    // }

    validateFields = (event, rule)=>{
        const { name, value, id, type } = event.target;
        let rules = rule.split(',');
        for(let index in rules){
            if(rules[index] === 'required'){
                if(value == ''){
                    document.getElementById(id).parentNode.querySelector('.error').innerHTML = name+" is required";
                    document.getElementById(id).parentNode.querySelector('.error').classList.remove('hide');
                    document.getElementById(id).closest('.form-field').classList.remove('active');
                } else {
                    document.getElementById(id).parentNode.querySelector('.error').classList.add('hide');
                    document.getElementById(id).closest('.form-field').classList.add('active');
                }
            }else if(rules[index] === 'email'){
                let regx = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                if(regx.test(value) == false){
                    document.getElementById(id).parentNode.querySelector('.error').innerHTML = "Invalid email ID";
                    document.getElementById(id).parentNode.querySelector('.error').classList.remove('hide');
                    document.getElementById(id).closest('.form-field').classList.remove('active');
                }else{
                    document.getElementById(id).parentNode.querySelector('.error').classList.add('hide');
                    document.getElementById(id).closest('.form-field').classList.add('active');
                }
            }else if(rules[index] === 'password'){

            }
        }
    }

    chkValBlur = (event) => {
        const { value, id,  } = event.target;
        const dataerror = event.target.getAttribute('dataerror');
        const validationReq = event.target.getAttribute('validationreq');
        let errorElement = document.getElementById(id).parentNode.querySelector('.error');
        let formField = document.getElementById(id).closest('.form-field');
        //this.validateFields(event, validationReq);
        if(value == ''){
            if(errorElement) errorElement.classList.remove('hide');
            if(formField) formField.classList.remove('active');
        } else {
            if(errorElement) errorElement.classList.add('hide');
            if(formField) formField.classList.add('active');
        }
    }
    chkValFocus = (event) => {
        const { name, value, id } = event.target;
        const dataerror = event.target.getAttribute('dataerror');
        let formField = document.getElementById(id).closest('.form-field');
        if(formField) formField.classList.add('active');
    }
        
    render() {
        let props = this.props;
        return (
            <div className="form-field">
            {(props.inputProps.type == 'checkbox' || props.inputProps.type == 'radio')?
                <label htmlFor={props.inputProps.id} className="gs_control gs_checkbox">{props.inputProps.label}
                    <input {...props.inputProps} className="form-control" autoComplete="off" onChange={this.changeHandel} onKeyPress={this.onKeyPress} />
                    <span className="gs_control__indicator"></span>
                </label>
                :
                <div>
                    {props.inputProps.label_before_input == 'true' ?
                        <label htmlFor={props.inputProps.id} className="">{props.inputProps.label}
                                {props.inputProps.validationreq == "true" ? <span className="required" style={{ color: 'red' }}>*</span> : ''}
                        </label>
                    : ''}                       
                    {props.inputProps.label_before_input == undefined ?
                        <label htmlFor={props.inputProps.id} className="">{props.inputProps.label}</label>
                        : ''}
                        <input {...props.inputProps} onBlur={this.chkValBlur} onFocus={this.chkValFocus} onChange={this.changeHandel} onKeyPress={this.onKeyPress} className="form-control" autoComplete="off" />
                        <span className="error show">{props.inputProps.dataerror}</span>
                    { props.inputProps.validation_error ? <span className="error show">{props.inputProps.validation_error}</span> : ''
                    }                    
                </div>
                }
            </div>    
        );
    }
}

export default InputField;
