import React, { useState } from "react";
import Select from "react-select";
import { useLoanDetail } from "../../view/ruleEngine/component/loanDetailContext";
import { convertJsonToCsv, downloadFile, getNumberFormat_IN } from "../MyLeads/helpers/helpers";
import PageLoader from "../../view/elements/PageLoader";



const BankOfferList = ({bankOffer,currentView,handleTabClick,loading}) => {

  const { loanDetails, updateLoanDetails } = useLoanDetail();
  const [checkedOffers, setCheckedOffers] = useState([]);

  const getDateAndTimeStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  const handleExportCSVFile = () => {
    const requiredOfferCol = JSON.parse(JSON.stringify(checkedOffers)).map(
      (offer) => {
        offer.bankId && delete offer.bankId;
        offer.bankLogo && delete offer.bankLogo;
        offer.propValue && delete offer.propValue;
        offer.documentSet && delete offer.documentSet;
        offer.messages = offer.messages?.join("||").replaceAll(",", "");
        offer.processingText = offer.processingText?.replaceAll(",", "").replaceAll('\n', '');
        offer.login_fee = offer.login_fee?.replaceAll(",", "");
        offer.ac_handling_charge = offer.ac_handling_charge?.replaceAll(",", "");
        offer.legal_technical_fee = offer.legal_technical_fee?.replaceAll(
          ",",
          ""
        );
        offer.e_stamp_charge = offer.e_stamp_charge?.replaceAll(",", "");
        offer.due_diligence_charge = offer.due_diligence_charge?.replaceAll(
          ",",
          ""
        );
        offer.CERSAI_charge = offer.CERSAI_charge?.replaceAll(",", "");
        offer.MODT_charge = offer.MODT_charge?.replaceAll(",", "");
        offer.NOI_charge = offer.NOI_charge?.replaceAll(",", "");
        offer.netMonthlyIncome = String(offer.netMonthlyIncome)?.replaceAll(",", "");
        offer.eligibalLoanAmount = String(offer.eligibalLoanAmount)?.replaceAll(",", "");
        offer.monthlyEMI = String(offer?.monthlyEMI).replaceAll(",", "");
        
        return { ...offer };
      }
    );
    const csvData = convertJsonToCsv(requiredOfferCol);
    downloadFile(csvData, "text/csv", `BRE_OFFERS_${getDateAndTimeStr()}.csv`);
  };

    const handleCheckboxChange = (item, isChecked) => {
      if (isChecked) {
        setCheckedOffers((prevCheckedOffers) => [...prevCheckedOffers, item]);
      } else {
        setCheckedOffers((prevCheckedOffers) =>
          prevCheckedOffers.filter((offer) => offer !== item)
        );
      }
    };
    console.log("currentView", currentView)
  return (
    <>
      <div className="bankofferselect">
        <div style={{ display: "flex" }}>
          <div className="loantype">Your Offers</div>
        </div>

        {/* <div className="col-md-2">
                <label>Tenure</label>
                <Select
                  id="tenure"
                  options={""}
                  onChange=""
                  name="tenure"
                  value={""}
                  // getOptionLabel={}
                  // getOptionValue={}
                />
              </div> */}
      </div>

      <div className="leftbottomcontainer1">
            
                  <div style={{width:"60%"}}>
                    {loanDetails.property_detail=="yes" && <><button
                      className={currentView === "property" ? "active" : ""}
                      onClick={() => {
                        handleTabClick("property")
                        setCheckedOffers([])
                      }}
                    >
                      Property Based Offers
                    </button>
                    <button
                      className={currentView === "income" ? "active" : ""}
                      onClick={() => {
                        handleTabClick("income")
                        setCheckedOffers([])
                      }}
                    >
                      Income Based Offers
                    </button></>}
                    {checkedOffers.length ? <button
                      onClick={handleExportCSVFile}
                    >
                      Download
                    </button>:null}
                    
                  </div>
                 
                  <div>
                   {loanDetails.property_detail=="yes" && <div className="bankloantips">
                      <span><img src="https://ambak.storage.googleapis.com/partner/3030/1724907775784.svg" alt="tips" width={24} height={24} /></span>
                      <div style={{marginLeft:10}}>Income allows for a higher loan; customer can increase their property budget!</div>
                    </div>}
              
                </div>
             
        </div>
      {loading?<PageLoader loaderClass="loading"/>:bankOffer.map((item)=>
  <div className="bankoffercard">
        <div className="pcoffercard">
          <div className="offerpadding">
            <div className="bankofferbox">
              <div className="bankcheckbox">
                <input type="checkbox" name="bank_check" id="bank_check"
                checked={checkedOffers.includes(item)}
                onChange={(e) => handleCheckboxChange(item, e.target.checked)}/>
              </div>
              <div className="banklogo">
                <img
                  src={item.bankLogo}
                  alt="thankyou"
                  width={50}
                  height={20}
                />
                <span>{item.bankName}</span>
              </div>
            </div>
            <div className="priceofferbox">
              <div>
                <div className="offerprice">
                  ₹ {getNumberFormat_IN(item.eligibalLoanAmount)}
                  {/* {bankcard.eligibalLoanAmount}  */}
                </div>
                <div className="bankonwords">Max Amount</div>
              </div>
              <div>
                <div className="offerprice">
                  ₹ {getNumberFormat_IN(item.monthlyEMI)}*
                  {/* {bankcard.monthlyEMI}* */}
                </div>
                <div className="bankonwords">Monthly EMI</div>
              </div>
              <div>
                <div className="offerprice">
                  {item.minRoi}%*
                  {/* {bankcard.minRoi}%* */}
                </div>
                <div className="bankonwords">Rate of interest</div>
              </div>
            </div>
            <div className="offergst">
              <span></span>
              {item.processingText}
              {/* Processing Fees: 0.25% of loan amt + GST */}
            </div>
          </div>
          <div className="mobileonwordshide">*onwards</div>

          {/* <div className="appylbtnborder">
            <button
              className="applybtn"
              //   onClick={() => handleapply(bankcard)}
            >
              Apply Now
            </button>
          </div> */}
        </div>
        <div className="offerfeeslist">
        <div className="offerpricelist">
        <div className="offergstpc">
        <span></span>
        {item.processingText}
          {/* Processing Fees : 0.25% of loan amt + GST */}
        </div>
        <div className="offergstpc">
          <span></span>
          {item.login_fee}
        </div>
        </div>
        <div className="offergstpc">
          Payout :  0.80-0.95%*
        </div>
        </div>
      </div>
      )}


      <div style={{ fontSize: 12 }}>
        *All offers are subject to document proofs submitted and lender policies
        and approval.
      </div>
    </>
  );
};

export default BankOfferList;
