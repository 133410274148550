import React, { createContext, useContext, useState } from 'react';

const LoanDetailContext = createContext();

export const useLoanDetail = () => useContext(LoanDetailContext);

export const LoanDetailProvider = ({ children }) => {
  const [loanDetails, setLoanDetail] = useState({
    loan_amount: null,
    employment_type: null,
    age:"30",
    credit_score:'650',
    property_detail:"no",
    income_entries:[{}],
    account_entries:[{}],
    acc_details:[],
    coapplicant_available:"no",
    coapplicant_details:[],
    itr_year: 'one', 
    co_itr_year: 'one', 
    ITR:{
      year1: {
        npat: '',
        depreciation: '',
        interest: '',
        tax_paid: '',
      },
      year2: {
        npat: '',
        depreciation: '',
        interest: '',
        tax_paid: '',
      },
    },
    CO_ITR:{
      year1: {
        npat: '',
        depreciation: '',
        interest: '',
        tax_paid: '',
      },
      year2: {
        npat: '',
        depreciation: '',
        interest: '',
        tax_paid: '',
      },
    }
    // Add other fields as needed
  });

  // Function to update Loan details
  const updateLoanDetails = (field, value) => {
    setLoanDetail((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  return (
    <LoanDetailContext.Provider value={{ loanDetails, updateLoanDetails }}>
      {children}
    </LoanDetailContext.Provider>
  );
};
