// NoAccess.js
import React, { useEffect } from 'react';
import noAccess from "../webroot/images/no-access.svg";
import secureStorage from '../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const NoAccess = ({ updateStateIntoRedux }) => {

  useEffect(() => {
    const updateReduxData = async () => {
      if (secureStorage.getItem("loginUserInfo")) {
        var loginResponse = secureStorage.getItem("loginUserInfo");
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      } else {
      }
    };
    const fetchData = async () => {
      await updateReduxData();
    };
    fetchData();
    return () => {};
  }, [updateStateIntoRedux]);
  

  return (
    <div 
      className='container-fluid' 
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Set the height to 100% of the viewport height
      }}
    >
      <div className='noaccesspage' style={{ textAlign: 'center' }}>
        <img src={noAccess} alt='logo' width={150} height={100} />
        <h3 className='noaccessheading'>We are Sorry...</h3>
        <p className='noaccesspara'>You do not have access to this page</p>
        <p className='noaccesspara'>Please ask your system administrator for access</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(NoAccess)
);
