import React, { useState } from "react";
import InputField from "../../../../view/elements/Input";
import Select from "react-select";
import { useLoanDetail } from "../../../../view/ruleEngine/component/loanDetailContext";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";
const NO_OF_ACCOUNTS = [
  { id: "1", label: "1",value: "1" },
  { id: "2", label: "2",value: "2" },
  { id: "3", label: "3",value: "3" },
  { id: "4", label: "4",value: "4" },
  { id: "5", label: "5",value: "5" },
];

const ACCOUNT_TYPE=[
  {id: "1", label: "Current",value:"1" },
  { id: "2", label: "Saving" ,value:"2"},
]

const MODE = [
  {id: "1", label: "Account" ,value:"1"},
  { id: "2", label: "Cash",value:"2" },
]
const yearsData = [
  { key: 'year1', label: 'Year 1' },
  { key: 'year2', label: 'Year 2' },
];

const LoanOfferIncomeDetails = ({allOptions,handleErrorReset,errorFields}) => {
  const { loanDetails, updateLoanDetails } = useLoanDetail();

  const handleAddIncome = () => {
    updateLoanDetails("income_entries",[...loanDetails.income_entries, {}]);
    // setIncomeEntries([...incomeEntries, {}]);
  };

  const handleRemoveIncome = (index) => {
    updateLoanDetails("income_entries",loanDetails.income_entries.filter((_, i) => i !== index));
    // setIncomeEntries(incomeEntries.filter((_, i) => i !== index));
  };
  

  const handleIncomeEntryChange = (index, field, value,price=false) => {
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
    const updatedEntries = [...loanDetails.income_entries];
    updatedEntries[index] = {
      ...updatedEntries[index],
      [field]: value,
    };
    updateLoanDetails("income_entries",updatedEntries)
    if(field=="income_type"){
      handleAdditionalIncomeType(value,index)
    }

  };
  const updateAccountDetails = (index, key, value,price=false) => {
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
      const updatedAccDetails = [...loanDetails.acc_details];
      updatedAccDetails[index] = {
        ...updatedAccDetails[index], 
        [key]: value, 
      };
      updateLoanDetails('acc_details', updatedAccDetails)
  
  };

  const handleItrValue = (yearKey, field, value,price=false) => {
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
      const updatedAccDetails = {...loanDetails.ITR};
      updatedAccDetails[yearKey] = {
        ...updatedAccDetails[yearKey] , 
        [field]: value, 
      };
      updateLoanDetails('ITR', updatedAccDetails)
  
  };

  const handleBankingIncome=(e) => {
    updateLoanDetails('banking_acc', e.value)
    const newAccDetails=[...loanDetails.acc_details]
    if (e.value > newAccDetails.length) {
      for (let i = newAccDetails.length; i < e.value; i++) {
        newAccDetails.push({ account_type: '', amount: '' }); // Add empty objects
      }
    } else if (e.value < newAccDetails.length) {
      newAccDetails.length = e.value; // Truncate the array to the new length
    }
  
    updateLoanDetails('acc_details', newAccDetails)

  }

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      allOptions[`filtered_additional_income_type_${index + 1}`] =
        allOptions[`filtered_additional_income_type_${index}`].filter(
          (obj) => obj.value !== +data
        );
    }
  };
  const handleFieldsValue = (key,value,price=false)=>{
    if(price){
      value=value.replace(/[^0-9]/g, '')
    }
    handleErrorReset(key)
    updateLoanDetails(key, value)
  }

  return (
    <>
      <div className="commonloancard">
        <div className="mainheadingall">Income Details</div>
        <div className="loanofferallbox">
          {loanDetails.employment_type===3 ?<>
          <div className="allheadingincome">What income do you want to consider?</div>
          <div className="cibilloanflex">
            <div
              className={`cibilhometab ${loanDetails?.itr_filing === "itrincome" ? "active" : ""}`}
              onClick={() => handleFieldsValue('itr_filing', 'itrincome')}
            >
              <span>ITR Income</span>
            </div>

            <div
              className={`cibilhometab ${loanDetails?.itr_filing === "banking" ? "active" : ""}`}
              onClick={() => handleFieldsValue('itr_filing', 'banking')}
            >
              <span>Banking Income</span>
            </div>
          </div>
          </>:<div className="row">
          <div className="col-sm-4">
                    <InputField
                      type="text"
                      name="take_home_salary"
                      id="take_home_salary"
                      maxLength={12}
                      placeholder="Enter Take Home Monthly Salary"
                      label="Take Home Monthly Salary"
                      onChange={(e) => handleFieldsValue('take_home_salary', e.target.value,true)}
                      value={handlePriceInputIndian(loanDetails.take_home_salary)}
                      required={true}
                      />
                      {errorFields?.take_home_salary && <div className="formerrorinput">{errorFields.take_home_salary}</div>}
                  </div>
                  <div className="col-sm-4">
                  <InputField
                    type="text"
                    name="gross_salary"
                    id="gross_salary"
                    maxLength={12}
                    placeholder="Enter Gross Salary"
                    label="Gross Salary"
                    onChange={(e) => handleFieldsValue('gross_salary', e.target.value,true)}
                    value={handlePriceInputIndian(loanDetails.gross_salary)}
                  />
                </div>
          </div>}

          {loanDetails?.itr_filing === "itrincome" && (
          <div className="cibilloanflex">
            <div
              className={`cibilhometab ${loanDetails?.itr_year === "one" ? "active" : ""}`}
              onClick={() => handleFieldsValue('itr_year', 'one')}
            >
              <span>Year 1</span>
              
            </div>

            <div
              className={`cibilhometab ${loanDetails?.itr_year === "two" ? "active" : ""}`}
              onClick={() => handleFieldsValue('itr_year', 'two')}
            >
              <span>Year 2</span>
            </div>
            </div>
          )}
           {loanDetails?.itr_filing === "itrincome" && loanDetails?.itr_year === "one" && (
              <div className="row">
                <div className="col-sm-4">
                  <InputField
                    type="text"
                    maxLength={12}
                    placeholder="NPAT"
                    value={handlePriceInputIndian(loanDetails.ITR.year1.npat)}
                    label="NPAT"
                    onChange={(e) => handleItrValue('year1', 'npat', e.target.value,true)}
                  />
                </div>
                <div>
                <div className="col-sm-4">
                <InputField
                    type="text"
                    maxLength={12}
                    label="Depreciation"
                    placeholder="Depreciation"
                    value={handlePriceInputIndian(loanDetails.ITR.year1.depreciation)}
                    onChange={(e) => handleItrValue('year1', 'depreciation', e.target.value,true)}
                  />
                 </div>
                 <div className="col-sm-4">
                <InputField
                    type="text"
                    maxLength={12}
                    label="Interest"
                    placeholder="Interest"
                    value={handlePriceInputIndian(loanDetails.ITR.year1.interest)}
                    onChange={(e) => handleItrValue('year1', 'interest', e.target.value,true)}
                  />
                 </div>
                 <div className="col-sm-4">
                <InputField
                    type="text"
                    maxLength={12}
                    label="Tax paid"
                    placeholder="Tax paid"
                    value={handlePriceInputIndian(loanDetails.ITR.year1.tax_paid)}
                    onChange={(e) => handleItrValue('year1', 'tax_paid', e.target.value,true)}
                  />
                 </div>
                </div>
              </div>
            )}

          {loanDetails?.itr_filing === "itrincome" && loanDetails?.itr_year === "two" && (
                        <div className="row">
                          <div className="col-sm-4">
                            <InputField
                              type="text"
                              maxLength={12}
                              placeholder="NPAT"
                              value={handlePriceInputIndian(loanDetails.ITR.year2.npat)}
                              label="NPAT"
                              onChange={(e) => handleItrValue('year2', 'npat', e.target.value,true)}
                            />
                          </div>
                          <div>
                          <div className="col-sm-4">
                          <InputField
                              type="text"
                              maxLength={12}
                              label="Depreciation"
                              placeholder="Depreciation"
                              value={handlePriceInputIndian(loanDetails.ITR.year2.depreciation)}
                              onChange={(e) => handleItrValue('year2', 'depreciation', e.target.value,true)}
                            />
                          </div>
                          <div className="col-sm-4">
                          <InputField
                              type="text"
                              maxLength={12}
                              label="Interest"
                              placeholder="Interest"
                              value={handlePriceInputIndian(loanDetails.ITR.year2.interest)}
                              onChange={(e) => handleItrValue('year2', 'interest', e.target.value,true)}
                            />
                          </div>
                          <div className="col-sm-4">
                          <InputField
                              type="text"
                              maxLength={12}
                              label="Tax paid"
                              placeholder="Tax paid"
                              value={handlePriceInputIndian(loanDetails.ITR.year2.tax_paid)}
                              onChange={(e) => handleItrValue('year2', 'tax_paid', e.target.value,true)}
                            />
                          </div>
                          </div>
                        </div>
              )}
           

          {
            loanDetails.itr_filing=='banking' && (
              <>
              <div className="col-sm-4" style={{ marginBottom: 20 }}>
              <label>Banking Income</label>
              <Select
                id="banking_acc"
                onChange={handleBankingIncome}
                options={NO_OF_ACCOUNTS||[]}
                name="banking_acc"
                placeholder="No of accounts"
                value={NO_OF_ACCOUNTS.find(option => option.value === loanDetails.banking_acc)}
              />
              </div>
              {
                loanDetails?.banking_acc && loanDetails?.banking_acc>0 && loanDetails?.acc_details.map((entry,index)=>(
            
                  <div className="bank-account-entry">
                    <div className="allheadingincome">Bank Account {index + 1}</div>
                {/* Example fields for each bank account component */}
                <div className="row">
                  <div className="col-sm-4">
                    <label>Account Type</label>
                    <Select
                      id={`account_type_${index}`}
                      onChange={(e) => updateAccountDetails(index, 'account_type', e.value)}
                      options={ACCOUNT_TYPE || []}
                      placeholder="Select Account Type"
                      value={ACCOUNT_TYPE.find(option=>option.value===entry.account_type)}
                    />
                  </div>
                  <div className="col-sm-4">
                    <InputField
                      type="text"
                      maxLength={12}
                      name={`amount_${index}`}
                      id={`amount_${index}`}
                      onChange={(e) => updateAccountDetails(index, 'amount', e.target.value,true)}
                      placeholder="Amount"
                      label="Amount"
                      value={handlePriceInputIndian(entry.amount)}
                      />
                  </div>
                  {/* Add more fields as necessary */}
                </div>
                </div>
                 
                ))
              }

              </>
            )
          }

          <div className="allheadingincome">Any Additional Income?</div>
          <div className="cibilloanflex">
            <div
              className={`cibilhometab ${
                loanDetails.additional_income === "yes" ? "active" : ""
              }`}
              onClick={() => updateLoanDetails('additional_income', 'yes')}
            >
              <span>Yes</span>
            </div>

            <div
              className={`cibilhometab ${
                loanDetails.additional_income === "no" ? "active" : ""
              }`}
              onClick={() => updateLoanDetails('additional_income', 'no')}
            >
              <span>No</span>
            </div>
          </div>

          {loanDetails.additional_income === "yes" && (
            <>
              {loanDetails.income_entries.map((entry, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-4">
                    <label>Income Type</label>
                    <Select
                      id={`income_type_${index}`}
                      onChange={(e) => handleIncomeEntryChange(index, 'income_type', e.value)}
                      options={loanDetails.employment_type==1?allOptions[`filtered_additional_income_type_${index}`].filter(item=>item.value!==6):allOptions[`filtered_additional_income_type_${index}`]}
                      name={`income_type_${index}`}
                      placeholder="Income Type"
                      value={allOptions[`filtered_additional_income_type_${index}`].find(option=>option.value === entry.income_type)||""}
                    />
                  </div>
                  <div className="col-sm-4">
                  <label>Mode</label>
                    <Select
                      id={`mode_${index}`}
                      onChange={(e) => handleIncomeEntryChange(index, 'mode', e.value)}
                      options={MODE||[]}
                      name={`mode_${index}`}
                      placeholder="Select Mode"
                      value={MODE.find(option=>option.value === entry.mode)||""}
                    />
                    {/* <InputField
                      type="text"
                      name={`mode_${index}`}
                      id={`mode_${index}`}
                      placeholder="Mode"
                      label="Mode"
                    /> */}
                  </div>
                  <div className="col-sm-4">
                    <InputField
                    maxLength={12}
                      type="text"
                      name={`amount_${index}`}
                      id={`amount_${index}`}
                      placeholder="Amount"
                      label="Amount"
                      onChange={(e) => handleIncomeEntryChange(index, 'amount', e.target.value,true)}
                      value={handlePriceInputIndian(entry?.amount)}
                    />
                  </div>
                  <div className="loanremoveoffer">
                    <div onClick={() => handleRemoveIncome(index)}>Remove</div>
                  </div>
                </div>
              ))}

              <div className="loanadditional" onClick={handleAddIncome}>
                + Additional Income
              </div>
            </>
          )}

        <div className="allheadingincome">Obligation</div>
        <br/>
          <div className="row">
            <div className="col-sm-4">
              <InputField
                type="text"
                maxLength={12}
                name="current_emi"
                id="current_emi"
                placeholder="Current EMIs"
                label="Current EMIs"
                onChange={(e) => handleFieldsValue('current_emi', e.target.value,true)}
                value={handlePriceInputIndian(loanDetails.current_emi)}
              />
            </div>
            <div className="col-sm-4">
              <InputField
                type="text"
                maxLength={12}
                name="emi_end_in_6_month"
                id="emi_end_in_6_month"
                placeholder="EMIs Ending in 6 Months"
                label="EMIs Ending in 6 Months"
                onChange={(e) => handleFieldsValue('emi_end_in_6_month', e.target.value,true)}
                value={handlePriceInputIndian(loanDetails.emi_end_in_6_month)}
              />
            </div>
          </div>

          

        </div>
      </div>
    </>
  );
};

export default LoanOfferIncomeDetails;
