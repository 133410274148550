import React, { Component } from 'react';
import Select from 'react-select';
import InputField from './../../elements/InputField';
import CheckBox from './../../elements/CheckBox';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import RadioBox from './../../elements/Radiobox';

const search_by_kyc = [
  { id: 'pending', value: 'Pending' },
  { id: 'uploaded', value: 'Uploaded' },
  { id: 'verified', value: 'Verified' },
];
class SearchDealer extends Component {
  constructor(props) {
    super(props);
    this.handleChangetext = this.handleChangetext.bind(this);
    this.state = {
      filterData: {
      },
    };
  }


  handleChangetext = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    if (sname === "profession_id" || sname === "assigned_bm_id" || sname === "kyc") {
      filterData[sname] = (ovalue.id != null) ? ovalue.id : ovalue.id;
    } else {
      filterData[sname] = (ovalue.value != null) ? ovalue.value : ovalue.target.value;
    }
    this.setState({ filterData });
    if (typeof this.props.optionChange === 'function') {
      this.props.optionChange(sname, ovalue);
    }

  }
  handleCityOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = (ovalue.id != null) ? ovalue.id : ovalue.target.id;
    this.setState({ filterData });
    if (typeof this.props.optionCityChange === 'function') {
      this.props.optionCityChange(sname, ovalue);
    }

  }
  handleSkuOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = (ovalue.sku_short_name != null) ? ovalue.sku_short_name : ovalue.target.sku_short_name;
    this.setState({ filterData });
    if (typeof this.props.optionSkuChange === 'function') {
      this.props.optionSkuChange(sname, ovalue);
    }

  }

  handlePremiumChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = (ovalue.code) != null ? ovalue.code : ovalue.target.code;
    this.setState({ filterData })
    if (typeof this.props.optionPremiumChange === 'function') {
      this.props.optionPremiumChange(sname, ovalue);
    }
  }

  componentWillMount() {
    if (sessionStorage.getItem('userData')) {
      this.setState({ redirect: true })
    }
  }
  submitFilterForm = (event) => {
    event.preventDefault();
    if (typeof this.props.onSubmitFilter === 'function') {
      this.props.onSubmitFilter(event);
      const toggleCheckbox = document.getElementById('toggleCheckbox');
      toggleCheckbox.checked = false;
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.searchByList && nextProps.searchByList[0] && !this.state.filterData.search_by) {
      this.handleOptionChange("search_by", { value: nextProps.searchByList[0].value })
    }
  }

  resetFilterForm = (event) => {
    window.location.reload(false);
  }
  render() {
    const { searchByList, cityAllList, dcSkusList, dealerCountStatusDetail, getProfessionlist, rmList } = this.props;
    const { filterData } = this.state;
    return (
      <div className="search-wrap">
        <form onSubmit={this.submitFilterForm}>
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>{this.props.t('Listing.Search_Dealer.Search_By')}</label>
              <Select
                id="search_by"
                //  value={filterData.search_by}
                onChange={this.handleOptionChange.bind(this, 'search_by')}
                options={searchByList}
                name="search_by"
                placeholder={this.props.t('Listing.Search_Dealer.Search_By_Placeholder')}
                value={searchByList.filter(({ value }) => value === filterData.search_by)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
              />
            </div>

            <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "search_text",
                  type: "text",
                  placeholder: this.props.t('Listing.Search_Dealer.Enter_Keyword_Placeholder'),
                  name: "search_text",
                  autocompleate: "off",
                  label: this.props.t('Listing.Search_Dealer.Enter_Keyword_Label')
                }}
                onChange={this.handleOptionChange.bind(this, 'search_text')}
              />
            </div>
            <div className="col-sm-2 col-md-2">
              <label>{this.props.t('Listing.Search_Dealer.City')}</label>
              <Select
                id="city_id"
                //  value={filterData.city_id}
                onChange={this.handleCityOptionChange.bind(this, 'city_id')}
                options={cityAllList && cityAllList.length > 0 ? cityAllList : []}
                name="city_id"
                placeholder={this.props.t('Listing.Search_Dealer.City_Placeholder')}
                value={cityAllList && cityAllList.length > 0 && cityAllList.filter(({ id }) => id === filterData.city_id)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
              />
            </div>

            <div className="col-sm-2 col-md-2">
              <label>Reffered BM</label>
              <Select
                id="assigned_bm_id"
                options={rmList}
                placeholder="Select Assigned BM"
                onChange={this.handleOptionChange.bind(this, 'assigned_bm_id')}
                name="assigned_bm_id"
                value={rmList && rmList.length > 0 && rmList.filter(({ id }) => id === filterData.assigned_bm_id)}
                getOptionLabel={({ profession_name }) => profession_name}
                getOptionValue={({ id }) => id}
              />
            </div>


            <div className='col-sm-2'>
            <label></label>
              <div className="more-filter" style={{ marginTop: 8 }}>
                <label className="morefilterbtn" for="toggleCheckbox">
                  <i className="ic-arrow_drop_down" style={{ fontSize: 25 }}></i>More filter
                </label>
                <input type="checkbox" id="toggleCheckbox" />
                <div id="filterCard">
                  <div>
                    <div>
                      <label>Aadhar-Pan Linked</label>
                      <div className="rc-inline" style={{ marginLeft: 20 }}></div>
                      <RadioBox
                        type="radio"
                        name="aadhar_pan_linked"
                        id="aadhar_pan_linked"
                        value="1"
                        label="Yes"
                        //checked={(this.state.basic_details.applicant_type == 1 || !this.state.basic_details.applicant_type) ? true : false}
                        onChange={this.handleOptionChange.bind(this, 'aadhar_pan_linked')}
                      />
                      <div className="rc-inline" style={{ marginLeft: 70, marginTop: -27 }}>
                        <RadioBox
                          type="radio"
                          name="aadhar_pan_linked"
                          id="aadhar_pan_linked"
                          value="2"
                          label="No"
                          //checked={(this.state.basic_details.applicant_type == 2) ? true : false}
                          onChange={this.handleOptionChange.bind(this, 'aadhar_pan_linked')}
                        />
                      </div>
                    </div>

                    <div >
                      <label>{this.props.t('Listing.Search_Result.profession')}</label>
                      <Select
                        id="profession_id"
                        options={getProfessionlist}
                        name="profession_id"
                        onChange={this.handleOptionChange.bind(this, 'profession_id')}
                        placeholder={this.props.t('dealerDetails.Basic_Employee_Details.dealership_professions_label')}
                        value={getProfessionlist && getProfessionlist.filter(({ id }) => id == filterData.profession_id)}
                        getOptionLabel={({ profession_name }) => profession_name}
                        getOptionValue={({ id }) => id}
                      />
                    </div>
                    <div >
                      <InputField
                        inputProps={{
                          id: "from_date",
                          type: "date",
                          name: "from_date",
                          autocompleate: "off",
                          label: ('Registration Start Date'),

                        }}
                        onChange={this.handleOptionChange.bind(this, 'from_date')}
                      />
                    </div>
                    <div >
                      <InputField
                        inputProps={{
                          id: "to_date",
                          type: "date",
                          name: "to_date",
                          autocompleate: "off",
                          label: ('Registration End Date'),

                        }}
                        onChange={this.handleOptionChange.bind(this, 'to_date')}
                      />
                    </div>
                    <div >
                      <InputField
                        inputProps={{
                          id: "dealer_updated_date_from",
                          type: "date",
                          name: "dealer_updated_date_from",
                          autocompleate: "off",
                          label: ('Start Updated Date'),

                        }}
                        onChange={this.handleOptionChange.bind(this, 'dealer_updated_date_from')}
                      />
                    </div>
                    <div >
                      <InputField
                        inputProps={{
                          id: "dealer_updated_date_to",
                          type: "date",
                          name: "dealer_updated_date_to",
                          autocompleate: "off",
                          label: ('End Updated Date'),

                        }}
                        onChange={this.handleOptionChange.bind(this, 'dealer_updated_date_to')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-2 col-md-2">
            <label></label>
              <div style={{ marginTop: 8 }}>
                <button type="submit" className="btn btn-primary mrg-r10">{this.props.t('Listing.Search_Dealer.Search')}</button>
                <button type="reset" className="btn btn-reset" onClick={this.resetFilterForm}>{this.props.t('Listing.Search_Dealer.Reset')}</button>
              </div>
            </div>






            <div className="col-sm-2 col-md-2">
              {/* <label>Kyc Details</label>
              <Select
                id="kyc"
                options={search_by_kyc}
                placeholder="Select Kyc"
                onChange={this.handleOptionChange.bind(this, 'kyc')}
                name="kyc"
                value={search_by_kyc && search_by_kyc.length > 0 && search_by_kyc.filter(({ id }) => id === filterData.kyc)}
                getOptionLabel={({ id }) => id}
                getOptionValue={({ value }) => value}
              /> */}

            </div>
          </div>
          <div className="row">



            

            {/* <div className="col-sm-2 col-md-2">
            <label>{this.props.t('Listing.Search_Dealer.Search_By_Sku')}</label>
            <Select
              id="dc_skus_id"
           //
              onChange={this.handleSkuOptionChange.bind(this, 'sku_short_name')}
              options={dcSkusList}
              name="dc_skus_id"
              placeholder={this.props.t('Listing.Search_Dealer.Search_By_Sku_Placeholder')}
              value={dcSkusList.filter(({ sku_short_name }) => sku_short_name === filterData.sku_short_name)}
              getOptionLabel={({ sku_name }) => sku_name}
              getOptionValue={({ sku_short_name }) => sku_short_name}
            />
          </div> */}

            {/* <div className="col-sm-2 col-md-2">
              <label>{this.props.t('dealerDetails.Basic_Employee_Details.premium_type')}</label>
              <Select
                id="premium_code"
                options={this.props.dealerPremiumTypeList}
                value={this.props.dealerPremiumTypeList.filter(({ code }) => code === filterData.premium_type)}
                onChange={this.handlePremiumChange.bind(this, 'premium_type')}
                name="premium_type"
                placeholder={this.props.t('dealerDetails.Basic_Employee_Details.premium_type_placeholder')}
                getOptionLabel={({ title }) => title}
                getOptionValue={({ code }) => code}
              />
            </div> */}
          </div>
          {/* <div className="row">
            
            </div> */}
        </form>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(SearchDealer));
//export default SearchDealer;
