import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { FINEX_MASTER_LIST } from "../../queries/scheme.gql";

const MyEarningsListFilter = (props) => {
  const [bank_list, setBank_list] = useState([]);
  const searchByList = [
    { label: "Customer Name", value: "customer_name" },
    { label: "Lead ID", value: "lead_id" },
  ];
  const [state, setState] = useState({
    filterData: {
      search_by: "",
      search_text: "",
      bank_name: null,
      product_type: "",
    },
  });

  useEffect(() => {
    getBankList();
  }, []);

  const loanType = [
    { id: 1, name: "Home Loan" },
    { id: 2, name: "LAP" },
  ];

  const { filterData } = state;

  const getBankList = async () => {
    try {
      const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST());
      setBank_list(masterData.data.masterdata.bank_list);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptionChange = (sname, ovalue) => {
    let newFilterData = { ...filterData };

    if (sname === "search_by") {
      newFilterData[sname] = ovalue.value;
      newFilterData["search_text"] = "";
    } else if (sname === "search_text") {
      newFilterData[sname] = ovalue.target.value;
    } else if (sname === "financersids") {
      newFilterData.bank_name = ovalue.id;
    } else if (sname === "product_type") {
      newFilterData[sname] = ovalue.id;
    }

    setState((prev) => ({ ...prev, filterData: newFilterData }));
  };

  const handleSearch = () => {
    let searchParams = {};
    if (filterData.search_by === "lead_id") {
      searchParams.lead_id = parseInt(filterData.search_text, 10);
    } else if (filterData.search_by === "customer_name") {
      searchParams.customer_name = filterData.search_text;
    }
    if (filterData.bank_name) {
      searchParams.financersids = filterData.bank_name;
    }
    if (filterData.product_type) {
      searchParams.product_type = filterData.product_type;
    }

    if (typeof props.onFilterChange === "function") {
      props.onFilterChange(searchParams);
    }
  };

  const handleReset = () => {
    setState({
      filterData: { search_by: "", search_text: "", bank_name: null },
    });
    if (typeof props.onFilterChange === "function") {
      props.onFilterChange({});
    }
  };

  return (
    <>
      <div className="searchinput">
        <div className="row">
          <div className="col-md-2">
            <label>Search Type</label>
            <Select
              onChange={(ovalue) => handleOptionChange("search_by", ovalue)}
              options={searchByList}
              name="search_by"
              placeholder="Search By"
              value={searchByList.find(
                ({ value }) => value === filterData.search_by
              )}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </div>

          <div className="col-md-2">
            <InputField
              type="text"
              name="search_text"
              onChange={(e) => handleOptionChange("search_text", e)}
              className="form-input-new"
              title="SEARCH_TEXT"
              placeholder="Enter Keyword"
              label="Search Text"
              value={filterData.search_text}
            />
          </div>
          <div className="col-md-2">
            <label>
              Bank Name <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              id="financersids"
              name="financersids"
              options={bank_list}
              onChange={(ovalue) => handleOptionChange("financersids", ovalue)}
              value={filterData.financersids} // Bind the selected bank object
              getOptionLabel={({ banklang }) => banklang.bank_name} // Use correct key for bank name
              getOptionValue={({ banklang }) => banklang.bank_id} // Use correct key for bank ID
            />
          </div>
          <div className="col-md-2">
            <label>
              Type <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              id="product_type"
              name="product_type"
              options={loanType}
              onChange={(ovalue) => handleOptionChange("product_type", ovalue)}
              value={filterData.type}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
            />
          </div>

          <div
            className="newleadbutton"
            style={{ marginTop: 20, textAlign: "right" }}
          >
            <button onClick={handleSearch}>Search</button>
            <button
              onClick={handleReset}
              style={{
                border: "1px solid #6E55B8",
                backgroundColor: "white",
                color: "#6E55B8",
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEarningsListFilter;
