import React, {Component} from 'react';
import Select from 'react-select';

const sku = [
    { value: '1', label: 'Buyer Listing' },
    { value: '2', label: 'Seller Lead' },
    { value: '3', label: 'Warranty' },
    { value: '4', label: 'Digital Consultant' },
    { value: '5', label: 'Cloud Listing' },
    { value: '6', label: 'Featured Listing' },
    { value: '7', label: 'SERP Banners' },
    { value: '8', label: 'RSA' },
    { value: '9', label: 'Knowlarity Number' },
    { value: '10', label: 'BTL Marketing' },
    { value: '11', label: 'Digital Marketing - Buyer Leads through Facebook' },
    { value: '12', label: 'Dealer App' },
    { value: '13', label: 'Dealer Website' },
    { value: '14', label: 'Digital Marketing - Seller Leads through Facebook' },
    { value: '15', label: 'Digital Marketing - Awareness Campaign on FB' },
    { value: '16', label: 'Digital Marketing - FB Page Management' },
    { value: '17', label: 'Digital Marketing - Google Adwords' },
    { value: '18', label: 'Digital Marketing - Facebook Likes Campaign' },
    { value: '19', label: 'Instagram Page Management' },
    { value: '20', label: 'Priority Dealer' },
    { value: '21', label: 'Market Search' },
    { value: '22', label: 'Accessory Listing' },
    { value: '23', label: 'Refresh Inventory' },
    { value: '24', label: 'Dealer Page' },
    { value: '25', label: 'VDP Banner' },
    { value: '26', label: 'CDrive' },
    { value: '27', label: 'Inspection' },


];
class AddSKU extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            sku: "",
        };
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div>
                <form>
                    <div className="row">
                        <div className="col-md-12 form-group">
                        <label>Choose SKU*</label>
                            <Select
                                id="sku"
                                value={this.state.sku}
                                onChange={this.handleChange.bind(this, 'sku')}
                                options={sku}
                                name="sku"
                                placeholder="Choose SKU"
                                value={sku.filter(({ value }) => value === this.state.sku)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="col-md-12 form-group text-right">
                        <button type="reset" id="cancel" name="cancel" className="btn btn-link mrg-r15">Cancel</button>
                        <button type="submit" id="savedetail" name="submit" className="btn btn-primary">Save Detail</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default AddSKU;