
import React from 'react';
import { Link } from "react-router-dom";

const ProtectedRoute = ({ component: Component, access, ...rest }) => {
  return access === 1 ? <Component {...rest} /> : <Link to="/no-access" />;
};


export default ProtectedRoute;
