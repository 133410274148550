import { gql } from "@apollo/client";

export const GET_MASTER_DATA = () => gql`{
	masterdata(api_called_by: partner) {
        qualification{
            id,
            label
        },
        tenure{
            id,
            label,
            value
        }
        duration_of_stay{
            id,
            label
        },
        customer_type{
            id,
            label
        },
        company_type{
            id,
            label
        },
        property_type{ 
            id,
            label
        },
        property_sub_type{ 
            id,
            label
        },
        loan_type{ 
            id,
            label
        },
        agreement_type{
            id,
            label
        },
        usage_type{
            id,
            label
        },
        marital_status{
            id,
            label
        },
        fulfillment_type{
            id,
            label
        },
        bank_list{
            id,
            banklang{
                bank_name
            }
        },
        tenure{
            id,
            label
        },
        profession{
            id,
            label
        },
        salary_credit_mode{
            id,
            label
        },
        obligation_type{
            id,
            label
        },
        partner_list{
            value,
            label
        },
        relationship{
            id
            label
            value
        },
        transaction_mode{
            id
            label
            value
        },
        loan_sub_type{
            id
            label
        },
        subvension_type{
            id
            label
            value
        },
        cross_sell_product_type{
            id
            label
            value
        },
        subsource_type{
            id
            source_id
            source_name
            sub_source_name
        },
        additional_income_type{
            id
            label
        }
           
    }
}`;

export const GET_MASTER_DATA_MMV = () => gql`{
	masterdata(api_called_by: partner) {
        qualification {
            id,
            label
        },
        tenure{
            id,
            label
        },
        bank_list {
            id
            banklang{
                bank_name
            }
        }
    }
}`;