import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import InputField from "../../view/elements/Input";
import VerificationCibil from "./VerifictionCibil";
import MobileAlready from "./MobileAlready";
import CibilAdditionalDetails from "./CibilAdditionalDetails";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CHECK_CIBIL_CONSIGNMENT,
  CREATE_LEAD_BY_PAN,
  GENERATE_CIBIL_PDF,
  GET_CIBIL_DETAILS,
  GET_DETAILS_BY_PANCARD_UPDATE,
  GET_LAST_CIBIL_INFO,
  SEND_CONSIGNMENT_LINK,
  LEADS_QUERY,
} from "../../queries/sangam/cibil.gql";
import {
  executeGraphQLMutationFinex,
  executeGraphQLQueryFinex,
} from "../../common/executeGraphQLQuery";
import { LEAD_DETAIL_QUERY } from "../../queries/sangam/leads.gql";
import MyCreditReports from "./MyCreditReports";
import { LEAD_CREATION_FORM } from "../../queries/sangam/create.gql";
import crypto from "../../config/crypto";
import { useParams } from "react-router-dom";
import Modal from "../../view/common/Modal";

const MyToolCibilMain = (props) => {
  const { updateStateIntoRedux } = props;
  const [showNext, setShowNext] = useState(1);
  const [pan, setPan] = useState("");
  const [pan_details, setPan_details] = useState({});
  const [lead_id, setLead_id] = useState("");
  const [lead_details, setlead_details] = useState({});
  const [mobile, setMobile] = useState("");
  const [cibilData, setCibilData] = useState({});
  const [leadList, setLeadlist] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const params = useParams();

  useEffect(() => {
    let lead_id = crypto.decode(params.lead_id);

    setLead_id(lead_id);
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();
    getLeadsDetailsByID(lead_id);
  }, [updateStateIntoRedux]);

  const handleNext = async () => {
    if (showNext === 1) {
      getPanCardDetails();
    }
    if (showNext === 2) {
      getCibilDetails();
    }
  };



  const ShowPopup = () => {
    // handleAdditionInfoButton(true);
    // setOpenModal(!openModal);
    if (!openModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const getLeadsDetailsByID = async (leadID) => {
    // For fetching Data with ID
    if (leadID) {
      const result = await executeGraphQLQueryFinex(LEAD_DETAIL_QUERY(leadID));
      setlead_details(result.data.get_lead_detail[0]);
      setPan(result.data.get_lead_detail[0]?.customer?.pancard_no);

      getPreviousCibilData(
        result.data.get_lead_detail[0]?.customer?.pancard_no
      );
    }
  };





  const getPreviousCibilData = async (pan_no) => {
    //For fetching cibil data of generated cibil report
    try {
      const result = await executeGraphQLQueryFinex(
        GET_LAST_CIBIL_INFO(pan_no)
      );

      if (result?.data?.last_cibil_info?.data) {
        setShowNext(5);
        setCibilData(result.data.last_cibil_info);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching CIBIL data:", error);
    }
  };






  const getPanCardDetails = async () => {
    //For fetching details based on Pan Card
    const data = await getPreviousCibilData(pan);
    if (data == true) {
      return;
    }
    try {
      const response = await executeGraphQLQueryFinex(
        GET_DETAILS_BY_PANCARD_UPDATE(pan)
      );
      if (
        response &&
        JSON.parse(response?.data?.get_details_by_pancard)?.status == 200
      ) {
        setPan_details(
          JSON.parse(response.data.get_details_by_pancard).data.response
        );
        setMobile(
          JSON.parse(response.data.get_details_by_pancard).data.response
            ?.mobile_no
        );
        // const duplicate_leads = checkDuplicatMoble(  JSON.parse(response.data.get_details_by_pancard).data.response)
        toast.success("Data Fetched Successfully");
        setShowNext(showNext + 1);
      }
      else {
        toast.error("Invalid Pan")
      }
    } catch (error) {
      // toast.error(error.message);
      return {
        status: 500,
        message: error.message,
      };
    }
  };






  const checkDuplicatMoble = async (data) => {
    const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
    setLead_id(data.id);
    let variables = {
      partner_id: user_info?.dealer_id,
      api_called_by: "saathi_web",
      user_id: user_info?.user_id,
      newLeadInput: {
        fulfillment_type: "self",
        loan_type: 1,
        loan_sub_type: "2",
        first_name: data.firstName,
        last_name: data.lastName,
        mobile: data.mobile_no,
      },
    };
    const mutation = LEAD_CREATION_FORM;
  
    const resp = await executeGraphQLMutationFinex(mutation, variables);
    const leadId = resp.data.create_loan.id;

    if (resp.data.create_loan.is_duplicate === true) {
      // console.log("truetruetruetruetrue");
      getLeadListData();
      await callCheckConsignment(leadId);
      await getConsentLInk(leadId);
    } else if (resp.data.create_loan.message === false) {
      // Assuming value should be based on resp
      // console.log ("falsefalsefalsefalsefalsefalsefalsefalsefalsefalsefalse")
      await getCibilDetails();
      await callCheckConsignment(leadId);
      await getConsentLInk(leadId);
  
      setLead_id(leadId);
      setShowNext(4);
      return true;
    }
  };
  





  const getConsentLInk = async (id) => {
    // for sending the consent link
    const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
    const leadID = lead_id;
    const variables = {
      sendConsignmentLinkInput: {
        pan_card: pan_details?.pan,
        mobile: mobile,
        partner_id: String(user_info?.dealer_id),
      },
    };

    const data = await executeGraphQLMutationFinex(
      SEND_CONSIGNMENT_LINK(String(user_info?.user_id), id),
      variables
    );
    if (data) {
      toast.success("Link send Successfully");
      ShowPopup()
    }
  };







  const callCheckConsignment = async (id) => {
    // for checking if the user has given the consent or not
    // console.log("callfunction", lead_id);
    const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
    try {
      const variables = {
        checkConsignmentInput: {
          pan_card: pan_details?.pan,
          mobile: mobile,
          partner_id: String(user_info?.dealer_id),
        },
      };
      const response = await executeGraphQLMutationFinex(
        CHECK_CIBIL_CONSIGNMENT,
        variables
      );
      generatePdf(id);

      if (response.data?.check_cibil_consignment) {
        generatePdf(id);
        // setShowNext(5)

        return JSON.parse(response.data?.check_cibil_consignment);
      } else {
        throw Error("Internal server error");
      }
    } catch (error) {
      return {
        status: 500,
        message: error.message,
      };
    }
  };







  const handleDataFromVerificationCibil = (value) => {
    // console.log ("+++++++++++++++++++++++++++++++++++++++" , value)
    setMobile(value);
  };

  const handleDataFromMobileAlready = async (value) => {
    // handling data from mobile already component
    // console.log("value.idvalue.idvalue.idvalue.idvalue.idvalue.idvalue.id", value.id);
    if (value) {
      setLead_id(value.id);
      callCheckConsignment(value.id);
      const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));

      const variables = {
        cibilDetailsInput: {
          pan_card: pan_details.pan,
          first_name: pan_details.firstName || pan_details.lastName,
          middle_name: pan_details.middleName,
          last_name: pan_details.lastName || pan_details.firstName,
          dob: pan_details.dob,
          gender: pan_details.gender,
          mobile: pan_details.mobile_no,
          partner_id: String(user_info?.dealer_id),
          user_id: user_info?.user_id,
          partner_name: String(user_info?.name) || "Ambak",
          is_consigned: 0,
        },
      };

      const getCibilDetailsData = await executeGraphQLMutationFinex(
        GET_CIBIL_DETAILS,
        variables
      );

      if (
        JSON.parse(getCibilDetailsData.data.get_cibil_details).status == 200
      ) {
        getConsentLInk(value.id);
      }
    }
  };








  const generatePdf = async (id) => {
    // console.log(
    //   ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
    //   pan_details?.pan,
    //   lead_id
    // );
    // for generating the PDF
    const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
    try {
      // setLoading(true);
      // const pan_card = cibilDetails["pancard_no"];
      const result = await executeGraphQLQueryFinex(
        GENERATE_CIBIL_PDF(pan_details.pan, id)
        // client
      );
      if (result.data) {
        // setLoading(false);
        const record = result.data?.generate_cibil_report?.storePath;
        // window.open(record, "_blank");
      } else {
        // setLoading(false);
        // toast.error("Internal Server Error");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };









  const getCibilDetails = async () => {
    // for getting cibil details and generating the link
    // const data = await checkDuplicatMoble(pan_details);
    // if (data === true) setShowNext(4);
    // (data === false) {
    // const getLeadList = await getLeadListData();
    // if (getLeadList === true) {
    //   setShowNext(3);
    //   return;
    // }
    const res = checkDuplicatMoble(pan_details);
    if (!res) {
      return;
    }
    // callCheckConsignment();
    // generatePdf();

    const user_info = JSON.parse(localStorage.getItem("loginUserInfo")); // Parse the string into an object

    const variables = {
      cibilDetailsInput: {
        pan_card: pan_details.pan,
        first_name: pan_details.firstName || pan_details.lastName,
        middle_name: pan_details.middleName,
        last_name: pan_details.lastName || pan_details.firstName,
        dob: pan_details.dob,
        gender: pan_details.gender,
        mobile: pan_details.mobile_no,
        partner_id: String(user_info?.dealer_id),
        user_id: user_info?.user_id,
        partner_name: String(user_info?.name) || "Ambak",
        is_consigned: 0,
      },
    };

    const getCibilDetailsData = await executeGraphQLMutationFinex(
      GET_CIBIL_DETAILS,
      variables
    );

    if (JSON.parse(getCibilDetailsData.data.get_cibil_details).status == 200) {
      // getConsentLInk();
      // generatePdf();
    }
  };









  const getLeadListData = async () => {
    // for getting the lead list on an existing lead
    const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
    let filterData = {
      mobile: mobile,
      pan_card: pan_details,
    };

    const response = await executeGraphQLQueryFinex(
      LEADS_QUERY(filterData, user_info?.dealer_id, user_info?.user_id)
    );
    setLeadlist(response?.data?.leads[0]?.lead_list);
    if (response?.data?.leads[0]?.lead_list.length > 1) {
      setShowNext(3);

      return true;
    } else {
    }
  };







  const handleBack = () => setShowNext(showNext - 1);






  const handlePan = (e) => {
    const panValue = e.target.value.toUpperCase();
    const panCardPattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (panCardPattern.test(panValue)) {
      setPan(panValue);
    } else {
      // Optionally, you can display an error message or feedback here
      // console.error("Invalid PAN card format");
    }
  };







  const validatePanCard = (pan) => {
    const panCardPattern = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    return panCardPattern.test(pan);
  };

  const handleAdditionInfoButton = (value) => {
    if (value === true) {
      getCibilDetails();
    }
    handleAdditionInfo();
  };







  const handleAdditionInfo = () => {
    const variables = {};
    const response = executeGraphQLMutationFinex(CREATE_LEAD_BY_PAN, variables);
  };

  return (
    <div className="LeadContainer">
      <div className={showNext === 5 ? "cibiltoolcibilleft" : 'mytoolcibilcard'}>
        
      <div className='toolcibilleft'>
          <div onClick={handleBack} style={{ cursor: "pointer" }}>
            {showNext !== 1 && showNext !== 5 && (
              <img src={require("../images/left arrow.svg")} alt="not" />
            )}
            Check Credit Report
          </div>

          <div>
            {lead_id && (
              <div style={{ display: "flex" }}>
                <div className="user_info">
                  <div>
                    <img
                      src={require(`../../../src/saathi-web/images/user.svg`)}
                      alt="not"
                    />
                  </div>
                  <div className="nam_mob_mail">
                    <div>
                      {lead_details?.customer?.first_name}{" "}
                      {lead_details?.customer?.last_name}
                    </div>
                    <div>
                      <img
                        src={require(`../../../src/saathi-web/images/mobile.svg`)}
                        alt="mobile"
                      />
                      {lead_details?.customer?.mobile}
                    </div>
                    <div>
                      <img
                        src={require(`../../../src/saathi-web/images/email.svg`)}
                        alt="email"
                      />
                      {lead_details?.customer?.email}
                    </div>
                  </div>
                </div>
                <div
                  className="loan_id_cont"
                  style={{ borderRight: "none", paddingLeft: 40 }}
                >
                  <div>
                    <button>
                      {lead_details?.status_info?.statuslang?.status_name} -{" "}
                      {
                        lead_details?.sub_status_info?.substatuslang
                          ?.sub_status_name
                      }
                    </button>
                  </div>
                  <div>
                    {" "}
                    Lead ID • <span>{lead_details?.id}</span>
                  </div>
                  {/* <div>{loan_type?.filter(data => data.id == lead_details?.loan_type)?.map(v => v.label)} {lead_details?.lead_details?.bank?.banklang?.bank_name ? '•' : ''} <span> {nameFormatter.formatFirstName(lead_details?.lead_details?.bank?.banklang?.bank_name)}</span></div> */}
                </div>
              </div>
            )}
          </div>

          {(showNext === 1 && showNext !==5 ) && (
            <div className="mytoolcibilflexcard">
              <div>
                <img
                  src="https://ambak.storage.googleapis.com/partner/2006/1723186894708.png"
                  alt="CIBIL"
                  width={150}
                  height={120}
                />
              </div>
              <div className="cibilheadingmain">
                Get your Customer Free CIBIL Score & <br />
                Report instantly.
              </div>
              <div className="cibilsubheadingmain">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </div>
              <div>
                <InputField
                  type="text"
                  name="pan_card"
                  id="pan_card"
                  placeholder="Pan Card"
                  label="Pan Card"
                  onChange={handlePan}
                  value={pan}
                  // disabled={lead_id}
                  maxLength={10}
                />
              </div>

              <button
                className="cibilcheckbtn"
                onClick={handleNext}
                // disabled={validPan === false}
              >
                Check Now
              </button>
            </div>
          )}

          {showNext === 2 && (
            <>
              <VerificationCibil
                pan_details={pan_details}
                lead_id={lead_id}
                handleDataFromVerificationCibil={
                  handleDataFromVerificationCibil
                }
                mobile={mobile}
              />
              <div className="cibilsendconsent">
                <button className="cibilcheckbtn" onClick={handleNext}>
                  Send Consent Link
                </button>
              </div>
            </>
          )}

          {showNext === 3 && (
            <>
              <MobileAlready
                leadList={leadList}
                handleDataFromMobileAlready={handleDataFromMobileAlready}
              />
            </>
          )}
          {showNext === 4 && (
            <>
              <CibilAdditionalDetails
                handleAdditionInfoButton={handleAdditionInfoButton}
              />
            </>
          )}

         
        </div>

        {showNext !== 5 &&
        <div className="toolcibilright">
          <div className="verificationheadingmain">Other Tools</div>
          <ul className="otherlinkshow">
            <li>
              <Link to="#">
                <div className="otherlinkslist">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/2123/1724136701567.png"
                    alt="home"
                    width={60}
                    height={60}
                  ></img>
                  <div className="cibiltextwidth">
                    Get home insurance starting @ just ₹90/ month*
                  </div>
                  <div>
                    <img
                      src="https://ambak.storage.googleapis.com/partner/2123/1724140240138.png"
                      alt="icon"
                      width={12}
                      height={14}
                    ></img>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="#">
                <div className="otherlinkslist">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/2006/1723192167400.png"
                    alt="home"
                    width={60}
                    height={60}
                  ></img>
                  <div className="cibiltextwidth">APF Search Engine</div>
                  <div>
                    <img
                      src="https://ambak.storage.googleapis.com/partner/2123/1724140240138.png"
                      alt="icon"
                      width={12}
                      height={14}
                    ></img>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="#">
                <div className="otherlinkslist">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/2006/1723193405707.png"
                    alt="home"
                    width={60}
                    height={60}
                  ></img>
                  <div className="cibiltextwidth">EMI Calculator</div>
                  <div>
                    <img
                      src="https://ambak.storage.googleapis.com/partner/2123/1724140240138.png"
                      alt="icon"
                      width={12}
                      height={14}
                    ></img>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="#">
                <div className="otherlinkslist">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/2006/1723193491801.png"
                    alt="home"
                    width={60}
                    height={60}
                  ></img>
                  <div className="cibiltextwidth">Payout Calculator</div>
                  <div>
                    <img
                      src="https://ambak.storage.googleapis.com/partner/2123/1724140240138.png"
                      alt="icon"
                      width={12}
                      height={14}
                    ></img>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="#">
                <div className="otherlinkslist">
                  <img
                    src="https://ambak.storage.googleapis.com/partner/2006/1723193561304.png"
                    alt="home"
                    width={60}
                    height={60}
                  ></img>
                  <div className="cibiltextwidth">Doc List Generator</div>
                  <div>
                    <img
                      src="https://ambak.storage.googleapis.com/partner/2123/1724140240138.png"
                      alt="icon"
                      width={12}
                      height={14}
                    ></img>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        
        }
         <Modal isOpen={openModal} togglePopup={ShowPopup} width={800}>
        <div className="cibilgiffile">
          <img
            src={require("../../saathi-web/images/pingball.gif")}
            alt=""
            width={150}
            height={150}
          />
          <h4 className="verificationheadingmain">
            Waiting for customer’s consent
          </h4>
          <p>
            Consent Link has been Shared with the Customer via SMS. You can also
            copy the link below and send to your customer. They will receive an
            OTP on clicking on the link.
          </p>
          <div className="cibillink">
            <a href="https:/ambak.consentlink.com" target="blank">
              https:/ambak.consentlink.com{" "}
            </a>
            <div className="cibillinkcopy">
              <i className="ic-content_copy"> Copy</i>
            </div>
          </div>
        </div>
        <div className="buttonflexmobile">
          <button className="cibilcheckbtn" >
            Go To Lead
          </button>
        </div>
      </Modal>
      </div>
      {(showNext === 5  && showNext !== 1)&& <MyCreditReports cibilData={cibilData} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(MyToolCibilMain)
);
