import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { SAVE_CUST_DETAILS } from "../../queries/sangam/customer.gql";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import FormsyInputField from "../../view/elements/FormsyInputField";
import Formsy from "formsy-react";

const IS_PROPERTY_DECIDED_OPTIONS = [
  { id: "yes", label: "Yes", value: "yes" },
  { id: "no", label: "No", value: "no" },
];

/**
 * key: property_type
 * value: list of sub_properties
 */
const SUB_PROPERTY_MAPPING_OBJ = {
  9: [1, 3],
  11: [1, 4],
  12: [1, 4],
  13: [1, 2, 3],
  14: [1, 2, 3, 4],
};

const MAX_CO_APPLICANT = 2;

const DocumentPopup = ({ leadDetail, masterdata, stateUpdate }) => {
  const [activeTab, setActiveTab] = useState("customer");
  const [recommendData, setRecommendData] = useState({});

  const { customer } = leadDetail;
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

  const ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata } : {};
    options["property_sub_type"] = options["property_sub_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    return options;
  }, [masterdata]);

  useEffect(() => {
    const {
      profession,
      is_property_identified,
      property_type,
      property_sub_type,
    } = leadDetail?.lead_details;

    const existingDataObj = {
      employment_type: profession,
      is_property_decided: is_property_identified,
      property_type,
      property_sub_type,
      co_applicant: leadDetail?.co_applicant || [],
    };
    const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter((obj) =>
      SUB_PROPERTY_MAPPING_OBJ[property_type]?.includes(obj.value)
    );
    ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
    setRecommendData(existingDataObj);
  }, [leadDetail]);

  const handleCheckboxChange = (event) => {
    if (event.target) {
      const { id, checked } = event.target;
      const exCoApp = [...(recommendData["co_applicant"] || [])];
      const index = activeTab.split("-")[1];

      if (exCoApp[index]) {
        exCoApp[index] = {
          ...exCoApp[index],
          [id]: checked ? "financing" : "non_financing",
        };
      } else {
        exCoApp[index] = { [id]: checked ? "financing" : "non_financing" };
      }

      setRecommendData((current) => ({
        ...current,
        ...(exCoApp.length ? { co_applicant: exCoApp } : {}),
      }));
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSelectChange = (data, id) => {
    const exCoApp = [...(recommendData["co_applicant"] || [])];
    if (id === "property_type") {
      const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[data.value].includes(obj.value)
      );
      ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
    }
    if (id === "ca_profession") {
      const index = activeTab.split("-")[1];
      if (exCoApp[index]) {
        exCoApp[index] = { ...exCoApp[index], [id]: data.value };
      } else {
        exCoApp[index] = { [id]: data.value };
      }
    }
    setRecommendData((current) => ({
      ...current,
      [id]: data.value,
      ...(exCoApp.length ? { co_applicant: exCoApp } : {}),
    }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    const exCoApp = [...(recommendData["co_applicant"] || [])];
    const index = activeTab.split("-")[1];

    if (exCoApp[index]) {
      exCoApp[index] = { ...exCoApp[index], [id]: value };
    } else {
      exCoApp[index] = { [id]: value };
    }

    setRecommendData((current) => ({
      ...current,
      ...(exCoApp.length ? { co_applicant: exCoApp } : {}),
    }));
  };

  const handleSubmit = () => {
    const {
      employment_type,
      is_property_decided,
      property_type,
      property_sub_type,
    } = recommendData;

    const mutation = SAVE_CUST_DETAILS(loginUserInfo?.dealer_id);
    const lead_id = leadDetail?.id || "";
    const lead_details = {
      profession: employment_type,
      is_property_identified: is_property_decided,
      ...(is_property_decided === "yes"
        ? { property_type, property_sub_type }
        : {}),
    };
    const coApplicant = recommendData["co_applicant"]?.map((coApp, index) => {
      return {
        ...(coApp?.id ? { id: coApp?.id } : {}),
        ca_profession: +coApp?.ca_profession,
        ca_type: coApp?.ca_type,
        ...(coApp?.ca_first_name || coApp?.ca_last_name
          ? {
              ca_first_name: coApp?.ca_first_name,
              ca_last_name: coApp?.ca_last_name,
            }
          : {}),
      };
    });
    const variables = {
      api_called_by: "saathi_web",
      partner_id: loginUserInfo?.dealer_id,
      LeadAdditionalInput: {
        lead_id,
      },
    };
    if (activeTab === "customer") {
      variables.LeadAdditionalInput["lead_details"] = lead_details;
    } else {
      variables.LeadAdditionalInput["co_applicant"] = coApplicant;
    }

    executeGraphQLMutationFinex(mutation, variables)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        stateUpdate(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleAddCoApplicant = () => {
    const exCoAppInState = [...(recommendData["co_applicant"] || [])];
    exCoAppInState.push({ isNew: true });
    setRecommendData((current) => ({
      ...current,
      co_applicant: exCoAppInState,
    }));
  };

  return (
    <>
      <h4>Basic Details</h4>
      <p>
        Share a few details to get a list of recommended docs required for file
        login.
      </p>

      <div className="documenttabs">
        <div className="commontabs" style={{ width: "70%" }}>
          <div>
            <div className="partnername">Primary</div>
            <div
              className={
                activeTab === "customer"
                  ? "commontabsItem active"
                  : "commontabsItem"
              }
              onClick={() => handleTabClick("customer")}
            >
              <div className="newtabContent">
                {customer?.first_name} {customer?.last_name}
              </div>
            </div>
          </div>
          <div>
            {recommendData["co_applicant"]?.length > 0 && (
              <div className="coapplicantname">Co-Applicants</div>
            )}
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {recommendData["co_applicant"]?.map((coApp, index) => (
                <>
                  <div
                    key={index}
                    className={
                      activeTab === `co_applicant-${index}`
                        ? "commontabsItem active"
                        : "commontabsItem"
                    }
                    onClick={() => handleTabClick(`co_applicant-${index}`)}
                  >
                    <div key={`${index}_child`} className="newtabContent">
                      {coApp.ca_first_name} {coApp.ca_last_name}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>

        {recommendData["co_applicant"]?.length < MAX_CO_APPLICANT && (
          <div>
            <div className="addcoapplicantbtn" onClick={handleAddCoApplicant}>
              <span>+</span>Co-Applicant
            </div>
          </div>
        )}
      </div>

      {activeTab === "customer" && (
        <>
          <div className="row">
            <div className="col-md-6">
              <label>Employment Type</label>
              <Select
                id="employment_type"
                options={ALL_OPTIONS["profession"]}
                onChange={(data) => handleSelectChange(data, "employment_type")}
                name="employment_type"
                value={ALL_OPTIONS["profession"]?.filter(
                  (obj) => obj.value === recommendData["employment_type"]
                )}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </div>
            <div className="col-md-6">
              <label>Property Decided</label>
              <Select
                id="is_property_decided"
                options={IS_PROPERTY_DECIDED_OPTIONS}
                onChange={(data) =>
                  handleSelectChange(data, "is_property_decided")
                }
                name="is_property_decided"
                value={IS_PROPERTY_DECIDED_OPTIONS.filter(
                  (obj) => obj.value === recommendData["is_property_decided"]
                )}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </div>
          </div>
          {recommendData["is_property_decided"] === "yes" && (
            <>
              <div className="row">
                <div className="col-md-6" style={{ marginTop: 20 }}>
                  <label>Property Type</label>
                  <Select
                    id="property_type"
                    options={ALL_OPTIONS["property_type"]}
                    onChange={(data) =>
                      handleSelectChange(data, "property_type")
                    }
                    name="property_type"
                    value={ALL_OPTIONS["property_type"]?.filter(
                      (obj) => obj.value === recommendData["property_type"]
                    )}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </div>

                <div className="col-md-6" style={{ marginTop: 20 }}>
                  <label>Property Sub Type</label>
                  <Select
                    id="property_sub_type"
                    options={ALL_OPTIONS["filtered_property_sub_type"]}
                    onChange={(data) =>
                      handleSelectChange(data, "property_sub_type")
                    }
                    name="property_sub_type"
                    value={ALL_OPTIONS["filtered_property_sub_type"]?.filter(
                      (obj) => obj.value === recommendData["property_sub_type"]
                    )}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}

      {activeTab.indexOf("co_applicant") > -1 && (
        <>
          {recommendData?.["co_applicant"]?.[activeTab.split("-")[1]]?.[
            "isNew"
          ] && (
            <Formsy
              // className="lead-form-filed"
              autoComplete="off"
              onValid={() => null}
              onInvalid={() => null}
            >
              <div className="row" style={{ marginBottom: 20 }}>
                <div className="col-md-6 " key={"first_name"}>
                  <FormsyInputField
                    id={"ca_first_name"}
                    name={"ca_first_name"}
                    type={"text"}
                    value={
                      recommendData?.["co_applicant"]?.[
                        activeTab.split("-")[1]
                      ]?.["ca_first_name"]
                    }
                    placeholder=""
                    label={"First Name"}
                    onChange={handleInputChange}
                    validations={null}
                    validationError={""}
                    readOnly={false}
                    required={true}
                  />
                </div>
                <div className="col-md-6 " key={"last_name"}>
                  <FormsyInputField
                    id={"ca_last_name"}
                    name={"ca_last_name"}
                    type={"text"}
                    value={
                      recommendData?.["co_applicant"]?.[
                        activeTab.split("-")[1]
                      ]?.["ca_last_name"]
                    }
                    placeholder=""
                    label={"Last Name"}
                    onChange={handleInputChange}
                    validations={null}
                    validationError={""}
                    readOnly={false}
                    required={true}
                  />
                </div>
              </div>
            </Formsy>
          )}
          <div className="row">
            {!recommendData?.["co_applicant"]?.[activeTab.split("-")[1]]?.[
              "isNew"
            ] && (
              <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => null}
                onInvalid={() => null}
              >
                <div className="col-md-6 " key={"first_name"}>
                  <FormsyInputField
                    id={"ca_first_name"}
                    name={"ca_first_name"}
                    type={"text"}
                    value={
                      recommendData?.["co_applicant"]?.[
                        activeTab.split("-")[1]
                      ]?.["ca_first_name"]
                    }
                    placeholder=""
                    label={"First Name"}
                    onChange={handleInputChange}
                    validations={null}
                    validationError={""}
                    readOnly={false}
                    required={true}
                  />
                </div>
              </Formsy>
            )}
            <div className="col-md-6">
              <label>Co-Applicant Employment Type</label>
              <Select
                id="ca_profession"
                options={ALL_OPTIONS["profession"]}
                onChange={(data) => handleSelectChange(data, "ca_profession")}
                name="ca_profession"
                value={ALL_OPTIONS["profession"]?.filter(
                  (obj) =>
                    obj.value ==
                    recommendData?.["co_applicant"]?.[
                      activeTab.split("-")[1]
                    ]?.["ca_profession"]
                )}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </div>
          </div>

          <div className="coapplicantcheckbox">
            <input
              type="checkbox"
              name="ca_type"
              id="ca_type"
              checked={
                recommendData?.["co_applicant"]?.[activeTab.split("-")[1]]?.[
                  "ca_type"
                ] === "financing"
                  ? true
                  : false
              }
              onChange={handleCheckboxChange}
            />
            <label htmlFor="ca_type">This is a financial co-applicant</label>
          </div>
        </>
      )}

      <div className="newleadbtnflex">
        <button className="submitbtnnewlead" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default DocumentPopup;
