import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, NavLink } from "react-router-dom";
import BasicDetails from './BasicDetails';
import OutletDetails from './OutletDetails';
import EmployeeDetails from './EmployeeDetails';
import KycDetails from './KycDetails';
import ContactSetting from './ContactSetting';
import SubscriptionDetails from './SubscriptionDetails';
import InputField from '../elements/Input';
import RadioBox from './../elements/Radiobox';
import CheckBox from './../elements/CheckBox';
import Button from '../elements/Button';


class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    

    render() {
        return (
                <div>
                    <Router>
                        <ul className="pagesteps">
                            <li className="active">
                                <NavLink exact activeClassName="active" to="/my-account/basic-info"><span className="pagecount">1</span>Basic Details</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to="/my-account/outlet-info"><span className="pagecount">2</span>Outlet Details</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to="/my-account/employee-info" ><span className="pagecount">3</span>Employee Details</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to="/my-account/subscription-details"><span className="pagecount">4</span>Subscription Details</NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to="/my-account/contact-setting"><span className="pagecount">5</span>Contact Setting</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-account/kyc-info"><span className="pagecount">6</span>KYC Details</NavLink>
                            </li>
                        </ul>
                        <div className="container-fluid">
                        {this.props.children}
                            <Switch>
                                <Route exact path="/my-account/basic-info">
                                    <BasicDetails />
                                </Route>
                                <Route path="/my-account/outlet-info">
                                    <OutletDetails />
                                </Route>
                                <Route path="/my-account/employee-info">
                                    <EmployeeDetails />
                                </Route>
                                <Route path="/my-account/subscription-details">
                                    <SubscriptionDetails />
                                </Route>
                                <Route path="/my-account/contact-setting">
                                    <ContactSetting />
                                </Route>
                                <Route path="/my-account/kyc-info">
                                    <KycDetails />
                                </Route>
                            </Switch>
        
                        </div>
                    </Router>
                </div>
        )
    }
}

export default MyAccount
