import React, { useEffect, useState } from "react";

const ActivityHistory = (props) => {
  const [activityData, setActivityData] = useState([]);

  useEffect(() => {
    setActivityData(props.activityData);
  }, []);

  const renderData = () => {
    return (
      <div style={{marginTop: -30}} className="container mt-4">
        <h1 className="text-center mb-4">Activity History</h1>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="text-left">Created Date</th>
                <th className="text-left">Activity Name</th>
                <th className="text-left">Source</th>
                <th className="text-left">Created By</th>
              </tr>
            </thead>
            <tbody>
              {activityData?.map((item, index) => (
                <tr key={index}>
                  <td className="text-left">{item.created_date.split("T")[0]}</td>
                  <td className="text-left">{item.activity_name}</td>
                  <td className="text-left">{item.source}</td>
                  <td className="text-left">
                  <span>{item.created_by_name}</span><br/>
                    <a>{item.created_by_email}</a>
                  </td>
                  {/* <td className="text-left">{item.created_by_email}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  
  return renderData()
};

export default ActivityHistory;
