import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import InputField from '../elements/Input';
import RadioBox from './../elements/Radiobox';
import CheckBox from './../elements/CheckBox';
import Button from '../elements/Button';
import Accordion from './../elements/Accordion';
import Rupees from '../elements/Rupees';
import ModalPopup from './../elements/Popup';
import AddSKU from './AddSKU';
const role = [
    { value: '125', label: 'Owner' },
    { value: '227', label: 'Dealer' },

];


class SubscriptionDetails extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            role: "",
            startDate: new Date(),
            sellerLead: false,
            showmodal: false,
        };
    }
    showModal = () => {
        this.setState({ showmodal: true });
    }
    
    closeModal = () => {
        this.setState({ showmodal: false, showgallerymodal: false });
    }
    SellerLeadShow = () => { 
        this.setState({ sellerLead: true });
    }
    SellerLeadClose = () => { 
        this.setState({ sellerLead: false });
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div><h1>Subscription Details(Saroj Sahoo - GCD1069)</h1>
            <div className="card">
                <div className="card-heading">
                    <div className="row">
                        <div className="col-sm-7"><h2 className="card-title">Subscription Details</h2></div>                        
                        <div className="col-sm-5 text-right">
                            <button type="button" className="btn btn-link" title="Other Settings" onClick={this.showModal}>+ Add SKU</button>
                        </div>
                    </div>
                </div>                    
                <div className="card-body">
                    <Accordion title="Subscription Services">
                        <form name="finance_form" id="finance-0-form">
                            <div className="form-inline rc-inline">
                                <CheckBox type="checkbox" name="sub-service" id="Finance" value="1" label="Finance" checked="checked" />
                                <CheckBox type="checkbox" name="sub-service" id="Insurance" value="2" label="Insurance"  checked="checked"/>
                                <CheckBox type="checkbox" name="sub-service" id="C2DAuction" value="3" label="C2D Auction" />
                                <CheckBox type="checkbox" name="sub-service" id="InsuranceAuction" value="4" label="Insurance Auction" />
                                <CheckBox type="checkbox" name="sub-service" id="FinanceAuction" value="5" label="Finance Auction" />
                                <CheckBox type="checkbox" name="sub-service" id="NCDAuction" value="6" label="NCD Auction" />
                                <CheckBox type="checkbox" name="sub-service" id="Inspection" value="7" label="Inspection" />
                            </div>
                        </form>
                    </Accordion>
                    <Accordion title="Existing Sku #">
                        <div>
                            <form>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="card card-success">
                                            <div className="card-heading">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <h3 className="card-title">Seller Lead #9464 </h3>
                                                        <div>Price: <Rupees /> 1</div>
                                                    </div>
                                                    <div className="col-sm-4 text-right">
                                                        <div className="icons-normal">
                                                            <span><i className="ic-refresh mrg-r15"></i></span>
                                                            <span><i className="ic-save mrg-r15"></i></span>
                                                            <span onClick={() => this.setState({ sellerLead: !this.state.sellerLead })}>{ this.state.sellerLead ? <i className="ic-remove"></i> : <i className="ic-add"></i> }</span>
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            { this.state.sellerLead ?
                                            <div className="card-body">
                                            
                                                <div className="row">
                                                    <div className="col-sm-6 form-group">
                                                        <label>Start Date*</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>End Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>Ext End Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>Selling Price Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-cd_R icons-normal"></i></span></div>
                                                            <InputField type="tel" name="sellprice" id="sellprice"></InputField>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField type="text" name="ConversionCommitment" id="ConversionCommitment" label="Conversion Commitment"></InputField>                              
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField type="text" name="LeadDistribution" id="LeadDistribution" label="Lead Distribution type"></InputField>                              
                                                    </div>
                                                    <div className="col-sm-12">
                                                    <CheckBox type="checkbox" name="selleractive" id="selleractive" value="selleractive" label="Active" />
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <InputField type="text" name="comments" id="comments" label="Comments*"></InputField>                              
                                                    </div>
                                                </div>
                                            </div>
                                            : null }
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="card card-danger">
                                            <div className="card-heading">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <h3 className="card-title">Seller Lead #9464 </h3>
                                                        <div>Price: <Rupees /> 1</div>
                                                    </div>
                                                    <div className="col-sm-4 text-right">
                                                        <div className="icons-normal">
                                                            <span><i className="sprite ic-rotate mrg-r15"></i></span>
                                                            <span><i className="sprite ic-save-w mrg-r15"></i></span>
                                                            <span onClick={() => this.setState({ sellerLead: !this.state.sellerLead })}>{ this.state.sellerLead ? <i className="sprite ic-minus"></i> : <i className="sprite ic-plus"></i> }</span>
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            { this.state.sellerLead ?
                                            <div className="card-body">
                                            
                                                <div className="row">
                                                    <div className="col-sm-6 form-group">
                                                        <label>Start Date*</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>End Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>Ext End Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>Selling Price Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-cd_R icons-normal"></i></span></div>
                                                            <InputField type="tel" name="sellprice" id="sellprice"></InputField>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField type="text" name="ConversionCommitment" id="ConversionCommitment" label="Conversion Commitment"></InputField>                              
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField type="text" name="LeadDistribution" id="LeadDistribution" label="Lead Distribution type"></InputField>                              
                                                    </div>
                                                    <div className="col-sm-12">
                                                    <CheckBox type="checkbox" name="selleractive" id="selleractive" value="selleractive" label="Active" />
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <InputField type="text" name="comments" id="comments" label="Comments*"></InputField>                              
                                                    </div>
                                                </div>
                                            </div>
                                            : null }
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="card card-secondary">
                                            <div className="card-heading">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <h3 className="card-title">Seller Lead #9464 </h3>
                                                        <div>Price: <Rupees /> 1</div>
                                                    </div>
                                                    <div className="col-sm-4 text-right ">
                                                        <div className="icons-normal">
                                                            <span><i className="sprite ic-rotate mrg-r15"></i></span>
                                                            <span><i className="sprite ic-save-w mrg-r15"></i></span>
                                                            <span onClick={() => this.setState({ sellerLead: !this.state.sellerLead })}>{ this.state.sellerLead ? <i className="sprite ic-minus"></i> : <i className="sprite ic-plus"></i> }</span>
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            { this.state.sellerLead ?
                                            <div className="card-body">
                                            
                                                <div className="row">
                                                    <div className="col-sm-6 form-group">
                                                        <label>Start Date*</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>End Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>Ext End Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-date_range icons-normal"></i></span></div>
                                                            <div className="form-field">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6 form-group"> 
                                                    <label>Selling Price Date*</label>                                                       
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text"><i className="ic-cd_R icons-normal"></i></span></div>
                                                            <InputField type="tel" name="sellprice" id="sellprice"></InputField>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField type="text" name="ConversionCommitment" id="ConversionCommitment" label="Conversion Commitment"></InputField>                              
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField type="text" name="LeadDistribution" id="LeadDistribution" label="Lead Distribution type"></InputField>                              
                                                    </div>
                                                    <div className="col-sm-12">
                                                    <CheckBox type="checkbox" name="selleractive" id="selleractive" value="selleractive" label="Active" />
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <InputField type="text" name="comments" id="comments" label="Comments*"></InputField>                              
                                                    </div>
                                                </div>
                                            </div>
                                            : null }
                                        </div>
                                    </div>
                                </div>
                            
                            </form>
                        </div>
                    </Accordion>
                </div>

                <div className="card-footer text-center">
                        <Button btnClass="btn btn-default mrg-r30" ><i className="sprite ic-prev-b"></i> Previous</Button>
                        <Button btnClass="btn btn-primary"> Next <i className="sprite ic-next-w"></i></Button>
                    </div>
                </div>
                {this.state.showmodal == false ? '' : <ModalPopup id="EditBuyerDetails" className="modal " title="Update Buyer Details" modalClose={this.closeModal}>
                    <AddSKU />
                </ModalPopup>
                }
            </div>

        )
    }
}

export default SubscriptionDetails