import React from 'react';
import { Link } from 'react-router-dom';
import { LINK_URL } from './../../config/constant';

class Brandlogo extends React.Component {
    
    render() {
    return (
        // <Link className="navbar-brand" to="/"><img src={require('./../login/assets/images/'+LINK_URL.LOGO)} alt=''/></Link>
        <Link  to="/saathi-leads">
            {/* <img src="https://storage.googleapis.com/ambak/logo/ambak_logo_white.svg" alt='' width={186} height={52}/> */}
            <div className="AMlogo">
            <img
                src={require(`../../saathi-web/images/ambaklogo.svg`)}
                alt="logo"
            />
            </div>
            </Link>
               
        );
    }
}
export default Brandlogo;