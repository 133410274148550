import React from 'react';
import { ReactComponent as VerifiedIcon } from '../../../../webroot/images/verified.svg';
import { ReactComponent as UnverifiedIcon } from '../../../../webroot/images/cross_Icon.svg';

const ValidatorIcon = (props) => {
  const iconStyles = {
    display: 'inline-block',
    position: 'absolute',
    right: '10px',
    top: '55%',
    transform: 'translateY(-50%)',
    pointerEvents: props.isValid ? 'none' : 'auto',
    cursor: props.isValid ? 'none' : 'pointer',
    transition: 'transform 0.3s ease',
  };

  const onPress = () => {
    props.onPress();
  }

  return (
    <div style={iconStyles}>
      {props.isValid ? (
       <div>
         <VerifiedIcon width="25" height="25" fill="green" />
       </div>
      ) : (
        <div>
          <UnverifiedIcon onClick={() => onPress()} width="23" height="23" fill="red" />
        </div>
      )}
    </div>
  );
};

export default ValidatorIcon;