import { gql } from '@apollo/client';

export const SAVE_REMARKS = gql`
mutation saveRemark($LeadData: SaveRemarkInput!){
    save_remark(saveRemarkInput: $LeadData) {
        lead_id 
    }
  }
`;

