import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../config/encrypt";
import { Link } from "react-router-dom";

const MyCreditReports = (props) => {
  const updateStateIntoRedux = props.updateStateIntoRedux;
  const { cibilData } = props;
  useEffect(() => {
    const updateReduxData = async () => {
      const loginResponse = secureStorage.getItem("loginUserInfo");
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();
  }, [updateStateIntoRedux]);
  const currencyFormatter = (x) => {
    if (x > 0) {
      console.log("?????????????????????////", x);
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res;
    } else return 0;
  };
  return (
    <>
      <div className="LeadContainer">
        <div className="leadheading">My Credit Reports</div>
        <div className="cibilreportflex">
          <div className="cibilleftpale">
            <div className="cibilscorecard">
              <div class="cibilcircle">
                <img
                  src="https://ambak.storage.googleapis.com/partner/2123/1724145263610.png"
                  alt="circle"
                  width="180"
                  height="160"
                />
                <div class="textoverlay">
                  <div className="cibilscoreheading">
                    {cibilData?.data?.mobile_res?.credit_score}
                  </div>
                  {console.log(
                    "cibilData?.data?.mobile_res?.credit_score",
                    cibilData
                  )}
                  <div className="cibilsubstatus">
                    {cibilData?.data?.mobile_res?.cibil_label}
                  </div>
                </div>
              </div>
              <div className="cibilupdatedstatus">
                Last Updated :{" "}
                <span>{cibilData?.data?.mobile_res?.last_update_date}</span>
              </div>
              <div className="cibilupdatedstatus">
                Powered by <span className="cibilcolor">CIBIL</span>
              </div>
              <a
                href={cibilData?.pdf_path}
                target="_blank"
                rel="noopener noreferrer"
                className="cibiladownloadbtn"
              >
                <button className="cibildownloadbtn">Download Report</button>
              </a>
            </div>
            {/* <div className="cibilotherinfocard">
              <div>Lorem Ipsum is simply dummy</div>
              <ul className="cibilreportlist">
                <li>• Lorem Ipsum is simply dummy</li>
                <li>• Lorem Ipsum is simply dummy</li>
              </ul>
              <div>Lorem Ipsum is simply dummy</div>
              <ul className="cibilreportlist">
                <li>• Lorem Ipsum is simply dummy</li>
                <li>• Lorem Ipsum is simply dummy</li>
              </ul>
              <div>Lorem Ipsum is simply dummy</div>
              <ul className="cibilreportlist">
                <li>• Lorem Ipsum is simply dummy</li>
                <li>• Lorem Ipsum is simply dummy</li>
              </ul>
            </div> */}
          </div>

          <div className="cibilrightpale">
            <div className="cibilreportsummery">Report Summary</div>

            <div className="reportsummarylist">
              <div>
                <div className="cibilmonthly">Est. Annual Income</div>
                <div className="cibilprice">
                  ₹{" "}
                  {currencyFormatter(
                    cibilData?.data?.report_summary?.estimated_annual_income
                  )}
                </div>
              </div>
              <div>
                <div className="cibilmonthly">Sanctioned Amount</div>
                <div className="cibilprice">
                  ₹{" "}
                  {currencyFormatter(
                    cibilData?.data?.report_summary
                      ?.total_sanctioned_loan_amount
                  )}
                </div>
              </div>
              <div>
                <div className="cibilmonthly">Monthly Obligation</div>
                <div className="cibilprice">
                  ₹{" "}
                  {currencyFormatter(
                    cibilData?.data?.report_summary?.current_monthly_obligations
                  )}
                </div>
              </div>
              <div>
                <div className="cibilmonthly">Outstanding Balance</div>
                <div className="cibilprice">
                  ₹{" "}
                  {currencyFormatter(
                    cibilData?.data?.report_summary?.current_outstanding
                  )}
                </div>
              </div>
            </div>

            <div className="cibilreportsummery">Total Loans</div>

            <div className="reportsummarylistaccount">
              <div>
                Total Accounts :{" "}
                <span className="cibiltotalcount">
                  {cibilData?.data?.report_summary?.total_loan}
                </span>
              </div>
              <div className="cibilaccount">
                <div className="activeacountshow">
                  {
                    cibilData?.data?.credit_utilization_details
                      ?.active_account_count
                  }
                </div>
                <div className="cibilmonthly">Active</div>
              </div>
              <div className="cibilaccount">
                <div className="activeacountshow">
                  {cibilData?.data?.report_summary?.total_overdue_accounts}
                </div>
                <div className="cibilmonthly">Overdue </div>
              </div>
              <div className="cibilaccount">
                <div className="activeacountshow">
                  ₹{" "}
                  {currencyFormatter(
                    cibilData?.data?.report_summary?.max_overdue_amount
                  )}
                </div>
                <div className="cibilmonthly">Overdue Amount</div>
              </div>
            </div>

            <div className="viewreportsflex">
              <h3 className="cibilreportsummery">Credit Score Insights</h3>
            </div>
            <div>
              <div className="scoreimpact">High Impact</div>
              <div className="cibilhistory">
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <img
                      src={require(`../../../src/saathi-web/images/cibilhistory.svg`)}
                      alt="thankyou"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div>
                    <div className="cibilstatus">
                      <div>Repayment History</div>
                      {/* {scoreData.repayment_history.title} */}
                    </div>
                    <div className="cibilsubstatus">
                      <div>Never miss due date</div>
                      {/* {scoreData.repayment_history.sub_title} */}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                  <div>
                    {cibilData?.data?.report_summary?.repayment_history != null &&
                    typeof cibilData.data.report_summary.repayment_history === 'number'
                      ? `${cibilData?.data?.report_summary?.repayment_history?.toFixed(2)}%`
                      : "N/A"}
                  </div>


                    {/* {scoreData.repayment_history.value}% */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>Excellent</div>
                    {/* {scoreData.repayment_history.value_label} */}
                  </div>
                </div>
              </div>
              <div className="cibilhistory">
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <img
                      src={require(`../../../src/saathi-web/images/cibilhistorytwo.svg`)}
                      alt="thankyou"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div>
                    <div className="cibilstatus">
                      <div>Credit Utilisation</div>
                      {/* {scoreData.credit_utilization.title} */}
                    </div>
                    <div className="cibilsubstatus">
                      <div>Keep outstanding 30%</div>
                      {/* {scoreData.credit_utilization.sub_title} */}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                    <div>
                      {cibilData?.data?.report_summary?.credit_card_utilization}
                    </div>
                    {/* {scoreData.credit_utilization.value}% */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>Very Good</div>
                    {/* {scoreData.credit_utilization.value_label} */}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="scoreimpact">Medium Impact</div>
              <div className="cibilhistory">
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <img
                      src={require(`../../../src/saathi-web/images/cibilhistory.svg`)}
                      alt="thankyou"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div>
                    <div className="cibilstatus">
                      <div>Credit Age</div>
                      {/* {scoreData.credit_age.title} */}
                    </div>
                    <div className="cibilsubstatus">
                      <div>above 5 years is excellent</div>
                      {/* {scoreData.credit_age.sub_title} */}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                    <div>{cibilData?.data?.report_summary?.credit_age}</div>
                    {/* {scoreData.credit_age.value} */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>Very Good</div>
                    {/* {scoreData.credit_age.value_label} */}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="scoreimpact">Low Impact</div>
              <div className="cibilhistory">
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <img
                      src={require(`../../../src/saathi-web/images/cibilhistory.svg`)}
                      alt="thankyou"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div>
                    <div className="cibilstatus">
                      <div>Credit Enquiries</div>
                      {/* {scoreData.credit_age.title} */}
                    </div>
                    <div className="cibilsubstatus">
                      <div>Avoid too many enquiries</div>
                      {/* {scoreData.credit_age.sub_title} */}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                    <div>
                      {
                        cibilData?.data?.report_summary
                          ?.no_of_equiries_in_last_6_months
                      }
                    </div>
                    {/* {scoreData.credit_age.value} */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>Very Good</div>
                    {/* {scoreData.credit_age.value_label} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(MyCreditReports)
);
