import DateFormate from 'dateformat';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { toast } from 'react-toastify';
import secureStorage from '../../../config/encrypt';
import { DealerService, InventoryService } from '../../../service';
import MasterService from './../../../service/MasterService';
import ApprovePartner from './ApprovePartner';
import BankPartnerDetails from './BankPartnerDetails';
import BasicDetails from './BasicDetails';
import KycDetails from './KycDetails';
import MasterContext from './MasterContext';


class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: {},
            role: null,
            redirect: false,
            errors: {},
            loading: true,
            bussiness_approve:false,
            responseMsg: null,
            urlprefix: props.urlprefix || null,
            dealer_hash_id: props.dealer_hash_id || null,
            dealer_id: null,
            basic_details: props.basic_details || {},
            owner_details: props.owner_details || {},
            dealership_list: [],
            ac_managerList: [],
            outlet_list: props.outlet_list || [],
            StateList: [],
            cityAllList: [],
            localityList: [],
            outlet_errors: [],
            kycMasterlist: [],
            dealership_list_filtered: {},
            getLanguagelist: [],
            getProfessionlist: [],
            dealerPremiumTypeList: [],
            stockCategoryTypeList: [],
            formSubType: []
        }
        this.getBasicDetails = this.getBasicDetails.bind(this);
        this.getOwnerDetails = this.getOwnerDetails.bind(this);
        this.getDealertypeList = this.getDealertypeList.bind(this);
        this.getOutletList = this.getOutletList.bind(this);

    }

    updateReduxData = async () => {
        let loginResponse = secureStorage.getItem('loginUserInfo');
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role;
        let isMyAccount = (this.props.match.url).indexOf("/my-account") > -1
        let parent_partner_hash = "";
        if ((role === "admin" || role === "Partner") && !isMyAccount) {
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer_hash_id = this.props.match.params.dealer_id_hash;
            let urlprefix = '/dealer/' + dealer_hash_id;
            if (dealer_hash_id === 'basic-details') {
                urlprefix = '/dealer'; dealer_hash_id = '';
            }
            this.setState({ urlprefix: urlprefix, dealer_hash_id: dealer_hash_id })
        } else if (role === "dealercentral") {
            // console.log("role",role)
            let permission = { dealerTypeViewOnly: true, cityViewOnly: true };
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            let dealer_hash_id = this.props.match.params.dealer_id_hash;
            //    let dealer_hash_id = loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0] && loginResponse.data.dealer_data[0].dealer_id_hash;
            if (!dealer_hash_id) return this.setState({ redirect: '/' });
            let subTab = ((window.location.href.slice(-1) === '/') ? window.location.href.substr(0, window.location.href.length - 1) : window.location.href).split("/my-account")[1];
            let urlprefix = this.props.match.url

            urlprefix = (urlprefix.slice(-1) === '/') ? urlprefix.substr(0, urlprefix.length - 1) : urlprefix;
            if (dealer_hash_id === 'basic-details') {
                urlprefix = '/dealer'; dealer_hash_id = '';
                parent_partner_hash = loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0] && loginResponse.data.dealer_data[0].dealer_id_hash;
                console.log("parent_partner_hash", parent_partner_hash);
            }
            this.setState({ urlprefix: urlprefix, dealer_hash_id: dealer_hash_id, role, permission }, () => {
                if (!subTab) {
                    // this.setState({ redirect: urlprefix + '/basic-details' })
                    this.props.history.push(urlprefix + '/basic-details');
                }
            })
        } else {
            console.log(role)
            this.setState({ redirect: '/' })
        }
    }

    componentDidMount = async () => {
        await this.updateReduxData();
        await this.getLanguageList();
        // await this.getAccountManagerList();
        await this.getCityList();
        // await this.getlocalityList();
        await this.getBasicDetails();
        // await this.getDealertypeList();
        await this.getKycMasterDetails();
        await this.getDealerFormInfo();
    }

    handleClickNav = (name, e) => {
        const { dealer_hash_id } = this.state
        if (!dealer_hash_id || dealer_hash_id === 'basic-details') {
            e.preventDefault()
        } else {
            this.getBasicDetails();
        }
    }

    getLanguageList() {
        MasterService.getMasters(['lang', 'professions_list'],
            { 'headers': { 'Authorization': this.props.auth_token } })
            .then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    this.setState({
                        getLanguagelist: response.data.data.lang,
                        dealerPremiumTypeList: response.data.data.dealer_premium_type,
                        getProfessionlist: response.data.data.professions_list
                    })
                } else if (response.status === 401 && response.data.status === 401) {
                    this.setState({ redirect: true })
                }
            }).catch((error) => {
            });
    }
    getCityList() {
        InventoryService.cityList({}).then(response => {
            if (response.data.status === 200 && response.status === 200) {
                this.setState({
                    StateList: response.data.data.state,
                    cityAllList: response.data.data.city
                })
            } else {
                toast.error((response.data.message) ? response.data.message : "Error")
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getDealerFormInfo = () => {
        let tempFormSubType = [];
        if (this.state.dealer_hash_id) {
            let postdata = { dealer_id_hash: this.state.dealer_hash_id }
            DealerService.getDealerFormInfo(postdata).then(response => {
                if (response.data.status === 200 && response.status === 200) {
                    let responsedata = response.data;
                    if (responsedata.data.length > 0) {
                        responsedata.data.forEach(element => {
                            tempFormSubType.push(element.form_sub_type);
                            this.setState({ formSubType: tempFormSubType }, () => { })
                        })
                    }
                }
            });
        }
    }
    getBasicDetails = () => {
        if (this.state.dealer_hash_id) {
            let postdata = { dealer_id_hash: this.state.dealer_hash_id, _with: ['DIF'] }
            DealerService.getBasicDetails(postdata).then(response => {

                if (response.data.status === 200 && response.status === 200) {
                    let responsedata = response.data;

                    let datasend = {}
                    if (responsedata && responsedata.data.length > 0) {
                        datasend = responsedata.data[0];
                        this.setState({ basic_details: datasend, dealer_id: datasend.id, loading: false }, () => {
                            this.getOutletList();
                            this.getOwnerDetails();

                        })
                    }
                    else {
                        this.setState({ redirect: '/' });
                    }
                } else {
                    toast.error((response.data.message) ? response.data.message : "Error")
                }
            }).catch(error => {
                toast.error(error.message);
            });
        } else {
            this.setState({ loading: false })
        }
    }

    getOwnerDetails() {
        if(this.state.basic_details?.id > 0) {
        let postdata = { dealer_id: this.state.basic_details.id };
        DealerService.getOwnerDetails(postdata).then(response => {
            if (response.data.status === 200 && response.status === 200) {
                let responsedata = response.data;
                let datasend = {}
                if (responsedata.data.length > 0) {
                    datasend = responsedata.data[0];
                }

                this.setState({ owner_details: datasend }, () => {
                })

            } else {
                toast.error((response.data.message) ? response.data.message : "Error")
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }
    }

    getAccountManagerList() {
        DealerService.getAccountManagerList().then(response => {
            if (response.data.status === 200 && response.status === 200) {
                let responsedata = response.data;
                let ac_manager_list = responsedata.data;
                this.setState({ ac_managerList: ac_manager_list }, () => {
                })

            } else {
                toast.error((response.data.message) ? response.data.message : "Error")
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getDealertypeList() {

        DealerService.getDealertypeList().then(response => {
            if (response.data.status === 200 && response.status === 200) {
                let responsedata = response.data;
                let dealershiplist = responsedata.data;
                this.setState({ dealership_list: dealershiplist }, () => {
                })

            } else {
                toast.error((response.data.message) ? response.data.message : "Error")
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getKycMasterDetails() {

        DealerService.getKycMasterDetails().then(response => {
            if (response.data.status === 200 && response.status === 200) {
                let responsedata = response.data;
                let kycMasterlist = responsedata.data;

                this.setState({ kycMasterlist: kycMasterlist }, () => {
                })

            } else {
                toast.error((response.data.message) ? response.data.message : "Error")
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    getOutletList() {
        let postdata = { dealer_id: this.state.dealer_id, _with: ["id", "dealer_id", "name", "address", "description", "type", "contact_number", "is_primary", "city_id", "state_id", "locality_id", "pincode", "latitude", "longitude", "is_lat_long_verified", "website_url", "opening_timings", "lead_preference_cities", "can_lead_cities"] }
        DealerService.getOutletList(postdata).then(response => {
            //    console.log(JSON.stringify(response))
            if (response.data.status === 200 && response.status === 200) {
                let responsedata = response.data;
                let datasend = responsedata.data;
                // datasend = datasend.map((singledata) => {
                //     return singledata;
                // })
                this.setState({ outlet_list: datasend }, () => {
                })

            } else {
                toast.error((response.data.message) ? response.data.message : "Error")
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    validateAndSaveBasicinfo = (event, owner_details, basic_details) => {
        let loginResponse = secureStorage.getItem('loginUserInfo');
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role;
        this.setState({ loading: true }, () => {
        });
        var parentObj = this;
        event.preventDefault();
        if (owner_details.date_of_joining) {
            let date_of_joining = new Date(owner_details.date_of_joining);
            let join_date = date_of_joining.getFullYear() + '-' + (date_of_joining.getMonth() + 1) + '-' + date_of_joining.getDate();
            owner_details.date_of_joining = join_date;
        }

        if (owner_details.birth_date) {
            let birth_date = new Date(owner_details.birth_date);
            let birthdate = birth_date.getFullYear() + '-' + (birth_date.getMonth() + 1) + '-' + birth_date.getDate();
            owner_details.birth_date = birthdate;
        }
        // let languageList = this.state.getLanguagelist;
        // let lanLen = languageList && languageList.length ? languageList.length : 0;
        // if (!basic_details.default_lang_id && lanLen === 1) {
        //     let langId = languageList[0].id
        //     basic_details.default_lang_id = langId;
        // }
        basic_details.default_lang_id = 1;
        if (!basic_details.parent_dealer_type) {
            basic_details.parent_dealer_type = '1';
        }

        if (role === 'dealercentral') {
            basic_details.parent_partner_hash = loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0] && loginResponse.data.dealer_data[0].dealer_id_hash;
        }

        let data2save = Object.assign({}, basic_details);
        data2save.owner = owner_details;
        //data2save.owner.id = '';
        var METHOD = 'post';
        if (data2save.dealer_id_hash) {
            METHOD = 'put';
        }

        DealerService.saveBasicinfo(data2save, METHOD).then(response => {

            this.setState({ loading: false }, () => {
            });

            if (response.data.status === 200 && response.status === 200) {
                let responseData = response.data;
                let dealer_id_hash = responseData.data && responseData.data[0] && responseData.data[0].dealer_id_hash;
                let other = {};
                if (METHOD === 'post') {
                    if (dealer_id_hash) {
                        basic_details.dealer_id_hash = dealer_id_hash;
                        other = {
                            urlprefix: '/dealer/' + basic_details.dealer_id_hash,
                            dealer_hash_id: basic_details.dealer_id_hash,
                        }
                    }
                    else {
                        return toast.error("Error In Create");
                    }
                }

                this.setState({ basic_details: basic_details, owner_details: owner_details, ...other }, () => {
                    toast.success((response.data.message) ? response.data.message : "Success")

                    if (METHOD === 'post') {

                        let url2go = '/dealer/' + basic_details.dealer_id_hash + '/outlet-info';
                        this.props.history.push(url2go);
                        window.location.reload();
                    }

                })

            } else {
                this.setState({ basic_details: basic_details, owner_details: owner_details }, () => {
                    toast.error((response.data.message) ? response.data.message : "Error")
                });
            }
        }).catch(error => {
            toast.error(error.message);
            this.setState({ loading: true }, () => {
            });
        });
    }

    saveoutletInfo = (event, idx, formdata) => {

        this.setState({ loading: true }, () => {
        });
        var parentObj = this;
        event.preventDefault();
        let saveData = Object.assign({}, this.state.outlet_list[idx]);
        saveData.name = formdata.name;
        let fulladdress = formdata.address;
        if (formdata.address2) {
            fulladdress = fulladdress + '|' + formdata.address2;
        }
        saveData.address = fulladdress;
        saveData.city_id = formdata.city_id;
        saveData.contact_number = formdata.contact_number;
        saveData.state_id = formdata.state_id;
        saveData.city_id = formdata.city_id;
        if (formdata.locality_id) {
            saveData.locality_id = 0;
        } else {
            saveData.locality_id = 0;
        }

        saveData.pincode = formdata.pincode;
        saveData.website_url = formdata.website_url;
        // if(formdata.showroom_type && formdata.workshop){
        //     saveData.type = formdata.showroom_type+','+formdata.workshop;
        // }else if(formdata.showroom_type){
        //     saveData.type = formdata.showroom_type;
        // }else if(formdata.workshop){
        //     saveData.type = formdata.workshop;
        // }

        // if(!saveData.type) saveData.type = '2';

        if (formdata.is_primary) {
            saveData.is_primary = formdata.is_primary;
        } else {
            saveData.is_primary = 0;
        }
        /*
        if(formdata.is_primary_button){
            saveData.is_primary = formdata.is_primary_button;
        }else{
            saveData.is_primary = 0;
        } */



        saveData.latitude = formdata.latitude;
        saveData.longitude = formdata.longitude;
        if (formdata.is_lat_long_verified) {
            saveData.is_lat_long_verified = formdata.is_lat_long_verified;
        }


        let save_outlet_url = 'dealer/showroom/add';
        if (formdata.id) {
            save_outlet_url = 'dealer/showroom/edit';
        }

        if (saveData.id) {
            if (isNaN(saveData.id)) {
                saveData.id = '';
            }
        }

        DealerService.saveoutletInfo(saveData, save_outlet_url).then(response => {
            if (response.data.status === 200 && response.status === 200) {
                let responseData = response.data;
                this.setState({ responseMsg: response.data.message, loading: false }, () => {
                    toast.success((response.data.message) ? response.data.message : "Success")
                    this.getOutletList();
                })

            } else {
                this.setState({ loading: false });
                toast.error((response.data.message) ? response.data.message : "Error")
            }
        }).catch(error => {
            this.setState({ loading: false });
            toast.error(error.message);
        });

    }



    render() {
        let loginResponsedata = secureStorage.getItem('loginUserInfo');
        let role = loginResponsedata && loginResponsedata.data && loginResponsedata.data.user_data && loginResponsedata.data.user_data.role;

        let { formSubType, basic_details } = this.state;
        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
        }
        let masters = {
            aaaaa: [
            ]
        }
        return (

            <div>
                <MasterContext.Provider value={masters}>
                    <Router>
                        <div >
                            {this.state.basic_details.dealer_id_hash ?
                            <div className="container-fluid" style={{marginTop:10}}>
                                <div className="search-wrap">
                                    <div className='partnerflex'>
                                        <div>
                                            <div className='partnername'>{this.state.basic_details.first_name} <span>({this.state.basic_details.agent_code})</span></div>
                                            <div className='commonpartner'>{this.state.basic_details.dealership_email}</div>
                                            <div className='commonpartner'>{this.state.basic_details.dealership_contact}</div>
                                        </div>
                                        <div>
                                            <div className='commontype'>Type : <span>{this.state.basic_details.applicant_type==="1" ?"Individual":this.state.basic_details.applicant_type==="2" ?"Company":''}</span></div>
                                        </div>
                                        <div>
                                            <div className='commontype'>City : <span>{this.state.basic_details.cityname}</span></div>
                                            <div className='commontype'>PinCode : <span>{this.state.basic_details.pincode}</span></div>
                                        </div>
                                        <div>
                                            <div className='commontype'>Status: <span>{this.state.basic_details.is_payout_approve===1?"(Verified for payout)":this.state.basic_details.is_bussiness_approve===1?"(Verified for Bussiness)":"(Pending)"}</span></div>
                                            <div className='commonpartner'>Created on : <span>{DateFormate(this.state.basic_details.created_date, 'dS mmm yyyy h:MM TT')}</span></div>
                                            {/* <div className='commonpartner'>Verified by : <span>{this.state.basic_details.sfa_refferal_user_name}</span></div> */}
                                        </div>
                                        <div>
                                            <div className='commontype'>BM Name : <span>{this.state.basic_details.sfa_refferal_user_name || ""}</span></div>
                                            <div className='commonpartner'>BM Mobile : <span>{this.state.basic_details.sfa_refferal_user_mobile ||""}</span></div>

                                        </div>
                                    </div>

                                    </div>
                                </div>
                                : ""}

                            <div className="container-fluid">
                                <div className="search-wrap">

                                    <ul className="pagepartner">
                                        <li>
                                            <NavLink activeClassName="active" to={this.state.urlprefix + '/basic-details'} onClick={this.getBasicDetails}>Basic Information
                                                <span>{(formSubType.indexOf("basic_detail") !== -1 && parseInt(basic_details.status) === 3) ? <i className="ic-peson-listed icons-normal"></i> : (parseInt(basic_details.status) === 3) ? <i className="ic-peson-blacklist icons-normal"></i> : ''}</span>
                                            </NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink activeClassName="active" to={this.state.urlprefix + '/bank-details'} onClick={this.handleClickNav.bind(this, 'bank-details')}>Bank Details</NavLink>
                                        </li> */}
                                        <li>
                                            <NavLink activeClassName="active" to={this.state.urlprefix + '/kyc-info'} onClick={this.handleClickNav.bind(this, 'kyc-info')}>{this.props.t('dealerDetails.myAccount.KYC_Details')}</NavLink>
                                        </li>

                                        <li>
                                            <NavLink activeClassName="active" to={this.state.urlprefix + '/approve-partner'} onClick={this.handleClickNav.bind(this, 'approve-partner')}>Approval</NavLink>
                                        </li>


                                    </ul>
                                    <div className="progress-bar">
                                        <div className="step" key={1}>
                                            <div className='stepsflex'>
                                                <NavLink to={this.state.urlprefix + '/basic-details'} onClick={this.getBasicDetails} isActive={(match, location) => location.pathname === this.state.urlprefix + '/basic-details'}>
                                                    <span className={this.state.urlprefix + '/basic-details' === this.props.location.pathname ? 'steppagecount active' : 'steppagecount'}>1</span>
                                                </NavLink>
                                                <div className="step-line"></div>
                                            </div>
                                        </div>
                                        {/* <div className="step" key={2}>
                                            <div className='stepsflex'>
                                                <NavLink to={this.state.urlprefix + '/bank-details'} onClick={this.handleClickNav.bind(this, 'bank-details')} isActive={(match, location) => location.pathname === this.state.urlprefix + '/bank-details'}>
                                                    <span className={this.state.urlprefix + '/bank-details' === this.props.location.pathname ? 'steppagecount active' : 'steppagecount'}>2</span>
                                                </NavLink>
                                                <div className="step-line"></div>
                                            </div>
                                        </div> */}
                                        <div className="step" key={2}>
                                            <div className='stepsflex'>
                                                <NavLink to={this.state.urlprefix + '/kyc-info'} onClick={this.handleClickNav.bind(this, 'kyc-info')} isActive={(match, location) => location.pathname === this.state.urlprefix + '/kyc-info'}>
                                                    <span className={this.state.urlprefix + '/kyc-info' === this.props.location.pathname ? 'steppagecount active' : 'steppagecount'}>2</span>
                                                </NavLink>
                                                <div className="step-line"></div>
                                            </div>
                                        </div>
                                        <div className="step" key={3}>
                                            <div className='stepsflex'>
                                                <NavLink to={this.state.urlprefix + '/approve-partner'} onClick={this.handleClickNav.bind(this, 'approve-partner')} isActive={(match, location) => location.pathname === this.state.urlprefix + '/approve-partner'}>
                                                    <span className={this.state.urlprefix + '/approve-partner' === this.props.location.pathname ? 'steppagecount active' : 'steppagecount'}>3</span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>

                            {/* <ul className="pagesteps">
                                <li className="active">
                                    <NavLink exact activeClassName="active" to={this.state.urlprefix + '/basic-details'} onClick={this.getBasicDetails}><span className="pagecount">1</span>{this.props.t('dealerDetails.myAccount.Basic_Details')}
                                        <span>{(formSubType.indexOf("basic_detail") != -1 && parseInt(basic_details.status) === 3) ? <i className="ic-peson-listed icons-normal"></i> : (parseInt(basic_details.status) === 3) ? <i className="ic-peson-blacklist icons-normal"></i> : ''}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to={this.state.urlprefix + '/employee-info'} onClick={this.handleClickNav.bind(this, 'employee-info')}><span className="pagecount">2</span>{this.props.t('dealerDetails.myAccount.Employee_Details')}</NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to={this.state.urlprefix + '/contact-setting'} onClick={this.handleClickNav.bind(this, 'contact-setting')}><span className="pagecount">3</span>{this.props.t('dealerDetails.myAccount.Contact_Setting')}</NavLink>
                                </li>

                                <li>
                                    <NavLink to={this.state.urlprefix + '/outlet-info'} onClick={this.handleClickNav.bind(this, 'outlet-info')}><span className="pagecount">2</span>{this.props.t('dealerDetails.Outlet_Details_master.Outlet')}</NavLink>
                                </li>
                                <li>
                                    <NavLink to={this.state.urlprefix + '/kyc-info'} onClick={this.handleClickNav.bind(this, 'kyc-info')}><span className="pagecount">3</span>{this.props.t('dealerDetails.myAccount.KYC_Details')}</NavLink>
                                </li>
                                {role === 'admin' ?
                                    <li>
                                        <NavLink to={this.state.urlprefix + '/partner/addscheme'} onClick={this.handleClickNav.bind(this, 'partner/addscheme')}><span className="pagecount">4</span>{this.props.t('Schemes')}</NavLink>
                                    </li>
                                    : ""
                                }


                            </ul> */}

                            <div className="container-fluid">
                                {this.props.children}
                                <Switch>

                                    <Route exact path={this.state.urlprefix + '/basic-details'}>
                                        {/* <BasicDetails loading={this.state.loading} getLanguagelist={this.state.getLanguagelist} getAccountManagerList={this.state.ac_managerList} dealerPremiumTypeList={this.state.dealerPremiumTypeList} stockCategoryTypeList={this.state.stockCategoryTypeList} nextpage={this.state.urlprefix + '/outlet-info'} basic_details={this.state.basic_details} owner_details={this.state.owner_details} dealership_list={this.state.dealership_list} responseMsg={this.state.responseMsg} onClick={this.validateAndSaveBasicinfo} onChange={this.handleChangeEvent} permission={this.state.permission} getProfessionlist={this.state.getProfessionlist} /> */}
                                        <BasicDetails
                                            loading={this.state.loading}
                                            getLanguagelist={this.state.getLanguagelist}
                                            getAccountManagerList={this.state.ac_managerList}
                                            dealerPremiumTypeList={this.state.dealerPremiumTypeList}
                                            stockCategoryTypeList={this.state.stockCategoryTypeList}
                                            nextpage={this.state.urlprefix + '/bank-details'}
                                            basic_details={this.state.basic_details}
                                            owner_details={this.state.owner_details}
                                            dealership_list={this.state.dealership_list}
                                            responseMsg={this.state.responseMsg}
                                            onChange={this.handleChangeEvent}
                                            permission={this.state.permission}
                                            getProfessionlist={this.state.getProfessionlist} />
                                    </Route>

                                    {/* <Route path={this.state.urlprefix + '/employee-info'}>
                                    <EmployeeDetails prevpage={this.state.urlprefix + '/basic-details'} nextpage={this.state.urlprefix + '/contact-setting'} dealer_id={this.state.dealer_id} basic_details={this.state.basic_details} dealer_id_hash={this.state.dealer_hash_id} />
                                </Route>
                                 <Route path={this.state.urlprefix+'/subscription-details'}>
                                    <SubscriptionDetails loading={this.state.loading} prevpage={this.state.urlprefix+'/employee-info'} nextpage={this.state.urlprefix+'/contact-setting'} dealer_id={this.state.dealer_id} basic_details={this.state.basic_details} dealer_id_hash={this.state.dealer_hash_id} role={this.state.role} />
                                </Route> 
                                <Route path={this.state.urlprefix + '/contact-setting'}>
                                    <ContactSetting loading={this.state.loading} prevpage={this.state.urlprefix + '/employee-info'} nextpage={this.state.urlprefix + '/kyc-info'} dealer_id={this.state.dealer_id} basic_details={this.state.basic_details} dealer_id_hash={this.state.dealer_hash_id} />
                                </Route>*/}

                                    <Route path={this.state.urlprefix + '/Bank-details'}>
                                        <BankPartnerDetails StateList={this.state.StateList}
                                            cityAllList={this.state.cityAllList}
                                            localityList={this.state.localityList}
                                            basic_details={this.state.basic_details} outlet_list={this.state.outlet_list}
                                            responseMsg={this.state.responseMsg}
                                            dealer_id={this.state.dealer_id}
                                            //onClick={this.saveoutletInfo}
                                            outlet_errors={this.state.outlet_errors}
                                            prevpage={this.state.urlprefix + '/basic-details'}
                                            nextpage={this.state.urlprefix + '/kyc-info'}
                                            loading={this.state.loading}
                                            permission={this.state.permission}
                                            dealer_id_hash={this.state.dealer_hash_id}
                                            owner_details={this.state.owner_details}
                                        />
                                    </Route>
                                    <Route path={this.state.urlprefix + '/kyc-info'}>
                                        <KycDetails
                                            basic_details={this.state.basic_details}
                                            outlet_list={this.state.outlet_list}
                                            dealer_id_hash={this.state.dealer_hash_id}
                                            StateList={this.state.StateList}
                                            cityAllList={this.state.cityAllList}
                                            localityList={this.state.localityList}
                                            kycMasterlist={this.state.kycMasterlist}
                                            dealer_id={this.state.dealer_id}
                                            loading={this.state.loading}
                                            prevpage={this.state.urlprefix + '/Bank-details'}
                                            nextpage={this.state.urlprefix + '/partner/addscheme'}
                                        />
                                    </Route>
                                    <Route path={this.state.urlprefix + '/approve-partner'}>
                                        <ApprovePartner
                                            basic_details={this.state.basic_details}
                                            dealer_id_hash={this.state.dealer_hash_id}
                                            StateList={this.state.StateList}
                                            cityAllList={this.state.cityAllList}
                                            localityList={this.state.localityList}
                                            kycMasterlist={this.state.kycMasterlist}
                                            dealer_id={this.state.dealer_id}
                                            loading={this.state.loading}
                                            prevpage={this.state.urlprefix + '/outlet-info'}
                                            nextpage={this.state.urlprefix + '/partner/addscheme'}
                                        />
                                    </Route>

                                    {/* {this.state.dealer_id ?
                                        <Route path={this.state.urlprefix + '/partner/addscheme'}>
                                            <PartnerSchemeAdd
                                                basic_details={this.state.basic_details}
                                                dealer_id={this.state.dealer_id ? this.state.dealer_id : 0}
                                                //  onClick={this.saveoutletInfo} 
                                                outlet_errors={this.state.outlet_errors}
                                                prevpage={this.state.urlprefix + '/kyc-info'}
                                                loading={this.state.loading}
                                                permission={this.state.permission}
                                            />
                                        </Route> : ""} */}

                                </Switch>

                            </div>
                        </div>
                    </Router>
                </MasterContext.Provider>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn
    };
}
const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(MyAccount));
