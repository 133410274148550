import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateFormate from 'dateformat';
import SearchStock from './search-stock';
import InputField from './../../elements/InputField';
import Select from 'react-select';
import StockCard from './StockCard';
import secureStorage from './../../../config/encrypt';
import DatePicker from "react-datepicker";
import CustomStock from './customStock';
import { toast } from 'react-toastify';
//import {withRouter} from 'react-router';
import { withTranslation } from 'react-i18next';
import {STOCK_PAGE_LIMIT,MOBILE_VALIDATION} from './../../../config/constant';
import MasterService from './../../../service/MasterService';
import { APIV, COUNTRY_CODE } from './../../../config/constant'
import { Link } from 'react-router-dom';

class AddbuyerEnquires extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: [true, false],
            showrow: false,
            showcustome: true,
            formData: {},
            errors:{},
            redirect: false,
            sourceList: [],
            statusList: [],
            budgetList: [],
            stockFilter: {
                pagelimit: STOCK_PAGE_LIMIT,
                car_status: [1]
            },
            stockCarList: [],
            displayTab: 'stock',
            dealerLocality: [],
            loaderClass:''
        };
    }
    componentDidMount = async()=>{
        await this.updateReduxData();
        let formData = {...this.state.formData};
        let stockFilter = {...this.state.stockFilter};
        formData['dealer_id'] = this.props.dealer_id;
        stockFilter['dealer_id'] = this.props.dealer_id;
        this.setState({formData: formData, dealerLocality: this.props.dealer_locality, stockFilter: stockFilter});
        await this.loadAllComponentData();
    }

    loadAllComponentData = async () => {
        this.loadSourceList();
        this.loadStatusList();
        this.loadBudgetList();
        this.submitFilterForm();
    }

    loadStatusList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllStatus')
        .then((response)=> {
            if (response.status == 200 && response.data.status == 200) {
                parentObj.setState({
                    statusList: response.data.data
                })
            }
        }).catch((error)=> {
        });
    }

    loadSourceList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllSource')
        .then((response)=> {
            if (response.status == 200 && response.data.status == 200) {
                parentObj.setState({
                    sourceList: response.data.data
                })
            }
        }).catch((error)=> {

        });
    }

    loadBudgetList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllBudget')
        .then((response)=> {
            if (response.status == 200 && response.data.status == 200) {
                parentObj.setState({
                    budgetList: response.data.data
                })
            }
        }).catch((error)=> {
        });
    }

    updateReduxData = ()=>{
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
        } else {
            this.setState({ redirect: true })
        }
    }

    showcustome = () => {
        this.setState({ showcustome: false });
    }

    handleChange = (sname, ovalue) => {
        let formData = {...this.state.formData};
        let errors   = {...this.state.errors};
        if(sname==='lead_status' || sname==='lead_source'){
            if(ovalue.value === ''){
                errors[sname] = this.props.t('validation.'+sname)+this.props.t('validation.is_required');
            }else{
                delete errors[sname];
            }
        }
        formData[sname] = (sname==='lead_status') ? ovalue.value: ((sname === 'cust_location') ? ovalue.id : ovalue.key);
        this.setState({ formData: formData, errors: errors });
    }

    handleChangetext = (event) => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let formData = {...this.state.formData};
        let errors   = {...this.state.errors};
        if(fieldRequired === 'yes'){
            if(event.target.value === ''){
                errors[event.target.name] = this.props.t('validation.'+event.target.name)+this.props.t('validation.is_required');
            }else{
                delete errors[event.target.name];
            }
        }
        formData[event.target.name] = event.target.value;
        this.setState({ formData: formData, errors: errors });
    }

    handleDateChange = (date, target_name)=>{
       // let formatedDate  = DateFormate(date, 'yyyy-mm-dd hh:mm TT');
        let formatedDate  = (APIV > 1) ? new Date(date) : DateFormate(new Date(date),"yyyy-mm-dd HH:MM:ss");
        let formData = {...this.state.formData};
        let errors   = {...this.state.errors};
        if(target_name === 'followup_date'){
            let currentDate = new Date();
            if(date === ''){
                errors[target_name] = this.props.t('validation.'+target_name)+ this.props.t('validation.is_required');
            }else if(currentDate > new Date(date)){
                errors[target_name] = this.props.t('validation.future_date_only');
            }else{
                delete errors[target_name];
            }
        }
        formData[target_name] = formatedDate;
        this.setState({
            formData: formData,
            errors: errors,
            [target_name]: date
        });
    }

    submitLeadForm = (event)=>{
        event.preventDefault();
        if(this.validFormData()){
            this.setState({ loaderClass: 'loading' });
            let formData = this.state.formData;
            MasterService.post('lead/lead/createLead', formData)
            .then((response) => {
                if(response.status == 200 && response.data.status == 200){
                    toast.success(response.data.message);
                    this.props.history.push('/lead');
                    this.setState({ loaderClass: '' });
                }
            }).catch((error) => {
                
            })
        }
    }

    validFormData = ()=>{ 
        let formData = this.state.formData;
        let errors   = {...this.state.errors};
        let formIsValid = true;
        let mobileRule = MOBILE_VALIDATION;
        if (!formData["cust_name"]) {
            formIsValid = false;
            errors["cust_name"] = this.props.t('validation.cust_name_required');
        }
        // if (!formData["cust_email"]) {
        //     formIsValid = false;
        //     errors["cust_email"] = this.props.t('validation.cust_email_required');
        // }
        if(formData["cust_email"] && !formData["cust_email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            formIsValid = false;
            errors["cust_email"] = this.props.t('validation.cust_email_invalid');
        }
        if(formData["cust_alt_mobile"] && !mobileRule.test(formData["cust_alt_mobile"])){
            formIsValid = false;
            errors["cust_alt_mobile"] = this.props.t('validation.mobile_invalid_'+COUNTRY_CODE);
        }else{
            delete errors['cust_alt_mobile'];
        }
        if (!formData["cust_mobile"]) {
            formIsValid = false;
            errors["cust_mobile"] = this.props.t('validation.mobile_required');
        }else if(!mobileRule.test(formData["cust_mobile"])){
            formIsValid = false;
            errors["cust_mobile"] = this.props.t('validation.mobile_invalid_'+COUNTRY_CODE);
        }
        if(!formData["lead_source"]){
            formIsValid = false;
            errors["lead_source"] = this.props.t('validation.source_required');
        }
        if(!formData["lead_status"]){
            formIsValid = false;
            errors["lead_status"] = this.props.t('validation.status_required');
        }
        if(!formData["followup_date"]){
            formIsValid = false;
            errors["followup_date"] = this.props.t('validation.followup_required');
        }else if(new Date() > new Date(formData["followup_date"])){
            formIsValid = false;
            errors["followup_date"] = this.props.t('validation.future_date_only');
        }
        this.setState({errors: errors})
        return formIsValid;
    }

    updateCar = (carId)=>{
        let formData = this.state.formData;
        if(!formData.car_id) formData.car_id = [];
        if(formData.car_id.indexOf(carId) !== -1){
            let index = formData.car_id.indexOf(carId);
            formData.car_id.splice(index, 1);
        } else{
            formData.car_id.push(carId);
        }
        this.setState({formData});
    }

    handleStockFilter = (fieldName, fieldValue)=>{
        let stockFilter = {...this.state.stockFilter};
        if(fieldName === 'car_make' || fieldName === 'car_model' || fieldName === 'car_body_type_id' || fieldName === 'fuel_type_id' || fieldName === 'uc_transmission_id'){
            if(!stockFilter[fieldName]) {stockFilter[fieldName] = [];}
            let info = (typeof fieldValue === 'string') ? fieldValue.split(',').map((v)=> Number(v)) : [fieldValue];
            stockFilter[fieldName] = (fieldValue == '') ? [undefined] : info;
        }else{
            stockFilter[fieldName] = fieldValue;
        }
        this.setState({stockFilter: stockFilter})
    }

    submitFilterForm = async ()=>{
        var parentObj = this;
        MasterService.post('inventory/inventory/stocklist', this.state.stockFilter)
        .then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                parentObj.setState({
                    stockCarList: response.data.data
                });
            }
        }).catch((error) => {
               
        })
    }

    switchStockTab = (event)=>{
        let tabAction = event.currentTarget.dataset.key;
        let stockFilter = {...this.state.stockFilter};
        if(tabAction === 'stock'){
            stockFilter = {pagelimit: STOCK_PAGE_LIMIT, dealer_id: this.props.dealer_id};
        }
        this.setState({ displayTab: tabAction, stockFilter: stockFilter },()=>{
            this.submitFilterForm();
        });
    }

    handleCustomStockChange = (field_name, field_value)=>{
        if(typeof field_value !== 'undefined'){
            let formData = this.state.formData;
            formData[field_name] = field_value;
            this.setState({formData});
        }
    }

    resetFormFilter = ()=>{
        this.setState({
            stockFilter: {
                pagelimit: STOCK_PAGE_LIMIT,
                dealer_id: this.props.dealer_id
            }
        },()=>{
            this.submitFilterForm();
        })
    }

    render() {
        // if (this.state.redirect) {
        //     return (<Redirect to={'/login'} />);
        // }
        const {sourceList, statusList, budgetList, formData, errors, dealerLocality } = this.state;
        return (
            <div className="container-fluid addbuyer">
                <div className="">
                    <h1>{this.props.t('listLead.Add_Buyer_Enquiry')}</h1>
                </div>           
                <div>
                    <form name="editcustomer" id="editcustomer" method="post">
                        <div className="card"> 
                            <div className="card-heading">
                                <h2 className="card-title">{this.props.t('listLead.Customer_Details')}</h2>
                            </div>                       
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div>
                                            <InputField
                                                inputProps={{
                                                    id: "cust_name",
                                                    type: "text",
                                                    placeholder: this.props.t('listLead.Customer_Name'),
                                                    name: "cust_name",
                                                    autocompleate: "off",
                                                    label: this.props.t('listLead.Customer_Name'),
                                                    validationreq: "yes",
                                                    dataerror: errors.cust_name || ''
                                                }}
                                                onChange={this.handleChangetext}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <InputField
                                            inputProps={{
                                                id: "cust_email",
                                                type: "text",
                                                placeholder: this.props.t('listLead.Email'),
                                                name: "cust_email",
                                                autocompleate: "off",
                                                label: this.props.t('listLead.Customer_Email'),
                                                dataerror: errors.cust_email || ''
                                            }}
                                            onChange={this.handleChangetext}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <InputField
                                            inputProps={{
                                                id: "cust_mobile",
                                                type: "tel",
                                                placeholder: this.props.t('listLead.Mobile'),
                                                name: "cust_mobile",
                                                autocompleate: "off",
                                                label: this.props.t('listLead.Mobile_No'),
                                                validationreq: "yes",
                                                dataerror: errors.cust_mobile || '',
                                                maxLength: 12
                                            }}
                                            onChange={this.handleChangetext}
                                        />
                                    </div>
                                    
                                    <div className="col-sm-3">
                                        <InputField
                                            inputProps={{
                                                id:"cust_alt_mobile",
                                                type: "text",
                                                placeholder: this.props.t('listLead.Alternate_Mobile'),
                                                name: "cust_alt_mobile",
                                                autocompleate:"off",
                                                label: this.props.t('listLead.Alternate_Mobile_No'),
                                                dataerror: errors.cust_alt_mobile || '',
                                                maxLength: 12
                                            }}
                                            onChange={this.handleChangetext}
                                        />
                                    </div>
                                    <div className="col-sm-3 form-group">
                                        <label>{this.props.t('listLead.Select_Location')}</label>
                                        <Select
                                            id="cust_location"
                                            value={dealerLocality}
                                            onChange={this.handleChange.bind(this, 'cust_location')}
                                            options={dealerLocality}
                                            name="cust_location"
                                            placeholder={this.props.t('listLead.Select_Location')}
                                            value={dealerLocality && dealerLocality.filter(({ id }) => id === formData.cust_location)}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div>
                                    <div className="col-sm-3 form-group">
                                    <label>{ this.props.t('listLead.Source') }*</label>
                                        <Select
                                            id="lead_source"
                                            value={formData.lead_source}
                                            onChange={this.handleChange.bind(this, 'lead_source')}
                                            options={sourceList}
                                            name="lead_source"
                                            placeholder= { this.props.t('listLead.Source') }
                                            value={sourceList.filter(({ key }) => key === formData.lead_source)}
                                            getOptionLabel={({ value }) => value}
                                            getOptionValue={({ key }) => key}
                                        />
                                        <span className="error show">{errors.lead_source}</span>
                                    </div>
                                    <div className="col-sm-3 form-group">
                                    <label>{ this.props.t('listLead.Select_budget') }</label>
                                        <Select
                                            id="budget"
                                            value={formData.budget}
                                            onChange={this.handleChange.bind(this, 'budget')}
                                            options={budgetList}
                                            name="budget"
                                            placeholder= { this.props.t('listLead.Select_budget') }
                                            value={budgetList.filter(({ key }) => key === formData.budget)}
                                            getOptionLabel={({ value }) => value}
                                            getOptionValue={({ key }) => key}
                                        />
                                    </div>
                                    <div className="col-sm-3 form-group">
                                    <label>{ this.props.t('listLead.select_dp_budget') }</label>
                                        <Select
                                            id="dp_budget"
                                            value={formData.dp_budget}
                                            onChange={this.handleChange.bind(this, 'dp_budget')}
                                            options={budgetList}
                                            name="dp_budget"
                                            placeholder= { this.props.t('listLead.select_dp_budget') }
                                            value={budgetList.filter(({ key }) => key === formData.dp_budget)}
                                            getOptionLabel={({ value }) => value}
                                            getOptionValue={({ key }) => key}
                                        />
                                    </div>
                                    <div className="col-sm-3 form-group">
                                    <label>{ this.props.t('listLead.status') }*</label>
                                        <Select
                                            id="lead_status"
                                            value={formData.lead_status}
                                            onChange={this.handleChange.bind(this, 'lead_status')}
                                            options={statusList.filter(data=>data.key != 1)}
                                            name="lead_status"
                                            placeholder={ this.props.t('listLead.status') }
                                            value={statusList.filter(({ value }) => value === formData.lead_status)}
                                            getOptionLabel={({ value }) => value}
                                            getOptionValue={({ key }) => key}
                                        />
                                        <span className="error show">{errors.lead_status || ''}</span>
                                    </div>
                                    <div className="col-sm-3 form-group">
                                    <label>{ this.props.t('listLead.followup_date') }*</label>
                                        <DatePicker
                                            selected={this.state.followup_date}
                                            showTimeSelect
                                            id= "followup_date"
                                            name="followup_date"
                                            dateFormat="yyyy-MM-dd h:mm aa"
                                            onChange={(date)=>this.handleDateChange(date,'followup_date')}
                                            minDate={new Date()}
                                            className="form-control"
                                            autocompleate="off"
                                            placeholderText= { this.props.t('listLead.followup_date') }
                                        />
                                        <span className="error show">{errors.followup_date || ''}</span>

                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <InputField
                                            inputProps={{
                                                id: "lead_comment",
                                                type: "text",
                                                placeholder: this.props.t('listLead.Comments'),
                                                name: "lead_comment",
                                                autocompleate: "off",
                                                label:this.props.t('listLead.Comments')
                                            }}
                                            onChange={this.handleChangetext}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {this.state.displayTab === 'stock' ?
                    <div>
                        <h6>{this.props.t('listLead.Assign_Car')}</h6>
                        <SearchStock handleStockFilter={this.handleStockFilter} submitFilterForm={this.submitFilterForm} resetFormFilter={this.resetFormFilter} filterData={this.state.stockFilter} />
                    </div>
                    : ''
                    }
                    <div className="card">
                        <div className="card-heading">
                            <ul className="list-inline nav-tabs">
                                <li onClick={this.switchStockTab} data-key="stock" className={"nav-item " + (this.state.displayTab === 'stock' ? "active" : " ")} ><div className="text-bold">{this.props.t('listLead.From_Stock')}<span>({this.state.stockCarList.length})</span></div></li>
                                <li onClick={this.switchStockTab} data-key="custom" className={"nav-item " + (this.state.displayTab === 'custom' ? "active" : " ")} ><div className="text-normal">{this.props.t('listLead.Customer_Information')}</div></li>
                            </ul>
                        </div>

                        <div className="card-body">
                            {this.state.displayTab === 'stock' ?
                            <div id="carstock">
                                <StockCard updateCar={this.updateCar} stockCarData={this.state.stockCarList} />
                            </div>
                            : '' 
                            }
                            {this.state.displayTab === 'custom' ?
                            <div id="customstock">
                                <CustomStock customStockChange={this.handleCustomStockChange}  />
                            </div>
                            : '' 
                        }
                        </div>
                    </div>
                </div>
                <div className="actionbar text-cenetr">
                    <Link to="/lead">
                        <button type="reset" name="reset" id="reset" className="btn btn-link mrg-r15">{this.props.t('listLead.Cancel')}</button>
                    </Link>    
                    <button type="submit" onClick={this.submitLeadForm} name="save_Enquiries" id="save_Enquiries" className={"btn btn-primary " + this.state.loaderClass} >{this.props.t('listLead.Save')}</button>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
        dealer_locality: state.locality
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(AddbuyerEnquires));
