import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import secureStorage from "../../config/encrypt";
import MasterService from "../../service/MasterService";
import PageLoader from '../../view/elements/PageLoader'
const MyTeamDashboard = ({ updateStateIntoRedux }) => {
  const [cardData, setCardData] = useState([
    {
      count: 0,
      text: "Sub Partner",
      icon: "ic-person_add",
      link: "/my-saathi-teams?type=sub_partner",
    },
    {
      count: 0,
      text: "Team Member",
      icon: "ic-person_add",
      link: "/my-saathi-teams?type=team_member",
    },
    {
      count: 0,
      text: "Verified",
      icon: "ic-check",
      link: "/my-saathi-teams?type=verified",
    },
  ]);
  const [loading , setLoading] = useState(false)

  const [name, setName] = useState("Admin");

  useEffect(() => {
    const updateReduxData = async () => {
      if (secureStorage.getItem("loginUserInfo")) {
        var loginResponse = secureStorage.getItem("loginUserInfo");
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      } else {
      }
    };
    const fetchData = async () => {
      await updateReduxData();
    };
    fetchData();
    return () => {};
  }, [updateStateIntoRedux, cardData]);
  useEffect(() => {
    const fetchData = async () => {
      let dealer_id;
      let updatedCardData = [];
      const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
      if (loginUserInfo && loginUserInfo.dealer_id) {
        dealer_id = loginUserInfo.dealer_id;
        setName(loginUserInfo.name);
      
      }

      try {
        const result = await MasterService.get(
          "partner/partner/get-sub-partner-count",
          { parent_partner_id: dealer_id }
        );
        updatedCardData = cardData.map((card) => {
          switch (card.text) {
            case "Sub Partner":
              return { ...card, count: result.data.message.sub_partner || 0 };
            case "Team Member":
              return { ...card, count: result.data.message.team_member || 0 };
            case "Verified":
              return { ...card, count: result.data.message.verified || 0 };
            default:
              return card;
          }
        });
        setCardData(updatedCardData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const cardStyles = ["color-one", "color-two", "color-three"];

  return (
    <div className="dashboard-bg">
      <div className="dashboard-outer">
        <div className="heading">
          <span className="subheading">Hello, {name}</span>
          <h1>My Team Partner</h1>
        </div>
        <div className="dashboard-card">
          {cardData.map((card, index) => (
            <Link to={card.link} key={index}>
              <div className={`cardnew ${cardStyles[index]}`} key={index}>
                <div className="roung-bg">
                  <i className={card.icon}></i>
                </div>
                <span className="count-txt">{card.count}</span>
                <span className="card-txt">{card.text}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(MyTeamDashboard)
);
