// InputProvider.js
import React, { Component } from 'react';
import InputContext from './InputContext';

class InputProvider extends Component {
  state = {
    inputValues: {},
    setInputValue: (inputName, value) => {
      this.setState((prevState) => ({
        inputValues: {
          ...prevState.inputValues,
          [inputName]: value,
        },
      }));
    },
  };

  render() {
    const { children } = this.props;

    return (
      <InputContext.Provider value={this.state}>
        {children}
      </InputContext.Provider>
    );
  }
}

export default InputProvider;
