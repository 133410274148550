import React, { useState, useEffect } from 'react';
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import { Dropdown, Nav, Tab } from 'react-bootstrap';
//import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { LEAD_DETAIL_QUERY } from '../../queries/sangam/leads.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { useParams,useHistory } from 'react-router-dom';
import Loader from '../../view/elements/PageLoader';
import { toast } from 'react-toastify';
import crypto from '../../config/crypto'
import { getLeadRemarks } from '../../store/action/allAction';
import CoApplicantIncomes from "../EditLeadComponent/CoApplicantIncomes";

const CoApplicantIncomeTabs = ({leadDetail, masterdata, masterStatusData}) => {  
    const params = useParams();
    const [currentStep, setCurrentStep] = useState(3);
    const [isLoading, setIsLoading] = useState(false);
    let history = useHistory();
   
    
    const handleStepClick = (step) => {
      // setCurrentStep(step);  
      let url = '' ; 
      if(step === 1){
       url =  `/lead-detail/${crypto.encode(leadDetail?.id)}`;
      } else if(step === 2){
        url =  `/customer-detail/${crypto.encode(leadDetail?.id)}`;
      } else if(step === 3){
        url = `/income-detail/${crypto.encode(leadDetail?.id)}`;
      } else if(step === 4){
        url = `/property-detail/${crypto.encode(leadDetail?.id)}`;
      } 
         
      history.push(url)
     };
   

   return (
            <>
          
           <div className="editleadnew">
          <div className="lefteditleadcont">
          <section className="step-wizard">
            <ul className="step-wizard-list">
            {[1, 2, 3, 4].map((stepNumber) => (
          <li
            key={stepNumber}
            className={`step-wizard-item ${currentStep === stepNumber ? 'current-item' : ''}`}
            onClick={() => handleStepClick(stepNumber)}
          >
            <span className="progress-count">{stepNumber} </span>
          </li>
        ))}
            </ul>
            <ul className="Editlead_flowlis">
              <li onClick={() => handleStepClick(1)}>Details</li>
              <li onClick={() => handleStepClick(2)}>Customer</li>
              <li onClick={() => handleStepClick(3)}>Income</li>
              <li onClick={() => handleStepClick(4)}>Property</li>             
            </ul>
          </section>
        </div>
        <div className="righteditleadcont">          
          {<CoApplicantIncomes leadDetail = {leadDetail} masterdata = {masterdata} masterStatusData = {masterStatusData} />}
          </div>
      </div>
          
</>
        )
}
export default CoApplicantIncomeTabs;