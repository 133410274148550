import React, { useEffect, useState, useCallback } from "react";
import InputField from "../../view/elements/Input";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { SCHEME_FIINACER_GROUP, Update_SCHEME, DELETE_FINANCER_FROM_GROUP_FINANCER, GET_SCHEME_FINANCER_GROUPS, DELETE_SCHEME_RANGE, SAVE_FINANCE_RANGES, FINEX_MASTER_LIST } from "../../queries/scheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery, executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import CheckBox from "../elements/Radiobox";
// import Accordion from '../elements/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelect from "../../commonselect/MultiSelect.js";
import { connect } from 'react-redux';
import secureStorage from './../../config/encrypt';
import { withTranslation } from 'react-i18next';
import CheckBox from "../elements/Radiobox.js";


const PayoutRanges = (props) => {
    const param = useParams()
    const [formData, setFormData] = useState({});
    const [rangeArray, setRangeArray] = useState([]);
    const [financerGroupList, setFinancerGroupList] = useState([]);
    const [financers, setFinancers] = useState([]);
    const [productType, setProductType] = useState([]);
    const [loanType,setLoanType] = useState([]);
    const [is_disabled, setIsDisabled] = useState(false)
    const [pageloading, setpageloading] = useState(false)
    const [rangeErrors, setRangeErrors] = useState({
        range_overlap_error: "",
        payout: [],
        rangeblank: []
    });
    const [rangeIndex, setRangeIndex] = useState(0);
    // const financerGroupListDataNew = useRef([]);
    const { handleTabClick } = props;
    const [financersData, setFinancersData] = useState({
        scheme_id: 0,
        schemefinancers: [],
        financersids: [],
        product_type: [],
        product_type_ids: ['ALL'],
        loan_type: [],
        loan_type_ids: ['ALL'],
        employment_type_ids: ['ALL'],
        employment_type: [],
        financier_id:[]
    });
    const [formValues, setFormValues] = useState([
        { "start_range": null, "end_range": null, "payout": null }
    ]);

    const [amountRange, setAmountRange] = useState({
        fromArray: [
            { id: 0, name: '0+' },
            { id: 10000001, name: '1Cr+' },
            { id: 20000001, name: '2Cr+' },
            { id: 30000001, name: '3Cr+' },
            { id: 40000001, name: '4Cr+' },
        ],
        toArray: [
            { id: 10000000, name: '1Cr' },
            { id: 20000000, name: '2Cr' },
            { id: 30000000, name: '3Cr' },
            { id: 40000000, name: '4Cr' },
            { id: 990000000, name: 'All' },
        ]
    });

    const handleInputarray = (parentIndex, childIndex, e, field_name = "") => {
        //  alert(JSON.stringify(e))
        let name = "";
        let val = "";
        if (field_name && (field_name == 'start_range' || field_name == 'end_range')) {
            name = field_name;
            val = e.id;
        } else {
            name = e.target.name;
            val = e.target.value;
        }

        const financerGroupListData = [...financerGroupList]
        const rangeList = financerGroupListData[parentIndex].schemerange && financerGroupListData[parentIndex].schemerange.length > 0 ? financerGroupListData[parentIndex].schemerange : [];
        const currentRangeList = [...rangeList]
        const childListData = { ...currentRangeList[childIndex] }
        childListData[name] = val;
        currentRangeList[childIndex] = childListData;
        const schemeRangeNew = { ...financerGroupListData[parentIndex] }
        const schemeRannne = { ...schemeRangeNew, schemerange: currentRangeList }
        financerGroupListData[parentIndex] = schemeRannne;
        setFinancerGroupList(financerGroupListData);
    };

    const addMoreFields = (parentIndex, financer_group_id) => {
        const financerGroupListData = [...financerGroupList]
        const rangeList = financerGroupListData[parentIndex].schemerange && financerGroupListData[parentIndex].schemerange.length > 0 ? financerGroupListData[parentIndex].schemerange : [];
        const currentRangeList = [...rangeList]
        currentRangeList.push({
            id: 0,
            financer_group_id: financer_group_id,
            start_range: 0,
            end_range: 0,
            is_deleted: 0,
            payout: "0"
        })
        const schemeRangeNew = { ...financerGroupListData[parentIndex] }
        const schemeRannne = { ...schemeRangeNew, schemerange: currentRangeList }
        financerGroupListData[parentIndex] = schemeRannne;
        setFinancerGroupList(financerGroupListData);

    }

    const removeFieled = (parentIndex, childIndex, id = 0) => {
        if (window.confirm("Do you want to delete this range!")) {
            const financerGroupListData = [...financerGroupList]
            const rangeList = financerGroupListData[parentIndex].schemerange && financerGroupListData[parentIndex].schemerange.length > 0 ? financerGroupListData[parentIndex].schemerange : [];
            const currentRangeList = [...rangeList]
            currentRangeList.splice(childIndex, 1)
            const schemeRangeNew = { ...financerGroupListData[parentIndex] }
            const schemeRannne = { ...schemeRangeNew, schemerange: currentRangeList }
            financerGroupListData[parentIndex] = schemeRannne;
            setFinancerGroupList(financerGroupListData);
            if (id) {
                deleteSchemeRange(id)
            }
        }
    }

    const deleteSchemeRange = async (id) => {
        const data = {}
        const variables = { id: id };
        data.updateSchemeArgs = variables;
        try {
            const result = await executeGraphQLMutation(DELETE_SCHEME_RANGE, variables);
            if (result) {
                toast.success(result?.data?.deleteSchemeRange?.message || "Delete Scheme Range Successfully");
            }
        } catch (error) {
            toast.error("Failed");
        }
    }


    useEffect(() => {
        if (props.schemedetailsData && props.schemedetailsData.id != "") {
            setFormData(props.schemedetailsData)
            const end_date_time = new Date(props.schemedetailsData?.end_date).getTime();
            const current_date_time = new Date().getTime();
            if (end_date_time < current_date_time) {
                setIsDisabled(true)
            }
            getSchemeFinacerGroups(props.schemedetailsData.id)
            if (props.schemedetailsData.scheme_for == 'count') {
                setRangeArray(countRange)
            } else {
                setRangeArray(amountRange)
            }
        }
        getFinexMasterData()
    }, [props.schemedetailsData]);

    const saveSchemeRanges = async (index) => {
        setpageloading(true)
        const data = {}
        const financerGroupListData = [...financerGroupList]
        const rangeList = financerGroupListData[index].schemerange;
        const product_type = financerGroupListData[index].product_type;
        const checkRanges = checkOverLapRanges(rangeList, 0);
        const rangesDataList = []
        const payoutError = []
        const rangeBlankError = []
        let isError = false;
        setRangeIndex(index)
        if (checkRanges) {
            setRangeErrors({ range_overlap_error: "Ranges are overlapped!" })
            setpageloading(false)
            return false
        } else {
            setRangeErrors({ range_overlap_error: "" })
        }
      
        if (rangeList && rangeList.length > 0) {
            rangeList.map(rangeItem => {
                if (rangeItem.end_range > 0 && parseFloat(rangeItem.payout) > 0) {
                    rangesDataList.push({
                        id: rangeItem.id,
                        scheme_id: formData.id,
                        financer_group_id: rangeItem.financer_group_id,
                        start_range: parseInt(rangeItem.start_range),
                        end_range: parseInt(rangeItem.end_range),
                        payout: rangeItem.payout.toString(),
                        status: 1
                    });
                    payoutError.push({ error: "" })
                    rangeBlankError.push({ error: "" })
                } else {
                    if (parseFloat(rangeItem.payout) > 0) { payoutError.push({ error: "" }) } else { payoutError.push({ error: "Payout Should be greaterthan 0!" }) }
                    if (!rangeItem.end_range || (rangeItem.end_range == rangeItem.start_range)) { rangeBlankError.push({ error: "Payout range is wrong!" }) } else { rangeBlankError.push({ error: "" }) }
                    isError = true;
                   
                }
            
            }
            )
            if (isError) {
                setRangeErrors({ ...payoutError, ["payout"]: payoutError, rangeblank: rangeBlankError })
                setpageloading(false)
                return false
   
            }
         
            const variables = {
                schemeRangeInputArgs: {
                    scheme_id: formData.id,
                    product_type: product_type,
                    schemerange: rangesDataList
                },
            };
            try {
                const result = await executeGraphQLMutation(SAVE_FINANCE_RANGES, variables);

                if (result) {
                    toast.success(result?.data?.saveSchemeRanges?.message || "Financers Ranges Saved Successfully");
                }
            } catch (error) {
                toast.error("Failed");
             }
             setpageloading(false)
        } else {
            toast.error("Please add ranges!");
        }
       
    }


    const checkOverLapRanges = (ranges, k) => {
        // Sort the array of pairs by the start point of each range
        // const  pairs = JSON.parse(rangelist)
       const rangesArray = [...ranges]
       let pairs =  rangesArray.sort((a, b) => a.start_range - b.start_range);

        // Keep track of the maximum endpoint of k ranges seen so far
        let maxEndpoint = pairs[0].end_range;
        let overlapCount = 0;

        for (let i = 1; i < pairs.length; i++) {
            // If the current range overlaps with the k ranges seen so far

            if (pairs[i].start_range <= maxEndpoint) {
                overlapCount++;
                maxEndpoint = Math.max(maxEndpoint, pairs[i].end_range);
            } else {
                // If k overlapping ranges have been found
                if (overlapCount > k) {
                    return true;
                }
                // Reset the counters for the next set of ranges
                overlapCount = 0;
                maxEndpoint = pairs[i].end_range;
            }
        }
        // Check if the last set of ranges overlap k times or more
        if (overlapCount > k) {
            return true;
        }

        // No k overlapping ranges found
        return false;
    }



    const countRange = {
        fromArray: [
            { id: 0, name: '0', isdisabled: true },
            { id: 11, name: '11' },
            { id: 16, name: '16' },
            { id: 21, name: '21' },
            { id: 26, name: '26' },
        ],
        toArray: [
            { id: 10, name: '10' },
            { id: 15, name: '15' },
            { id: 20, name: '20' },
            { id: 25, name: '25' },
            { id: 3000, name: '30+' }
        ]
    };


    const employmentType = [
        { id: 'ALL', name: 'All' },
        { id: 'SALARIED', name: 'Salaried' },
        { id: 'SELF', name: 'Self Employed' },
        { id: 'PROFESSIONALS', name: 'Professionals' },
    ];

   

    const saveFinacerGroup = async () => {
        const formDataNew = { ...financersData, scheme_id: formData.id };
        formDataNew.product_type = formDataNew.product_type_ids && formDataNew.product_type_ids.length > 0 ? parseInt(formDataNew.product_type_ids[0]) : 'ALL';
        formDataNew.loan_type = formDataNew.loan_type_ids && formDataNew.loan_type_ids.length > 0 ? parseInt(formDataNew.loan_type_ids[0]) : null;
        formDataNew.employment_type = formDataNew.employment_type_ids && formDataNew.employment_type_ids.length > 0 ? formDataNew.employment_type_ids[0] : 'ALL';
        formDataNew.schemefinancers = formDataNew.financier_id;
        delete formDataNew.financier_id
        delete formDataNew.financersids
        delete formDataNew.loan_type_ids
        delete formDataNew.product_type_ids
        delete formDataNew.employment_type_ids
        const variables = {
            schemeFinancerGroupArgs: formDataNew,
        };

        try {
            const result = await executeGraphQLMutation(SCHEME_FIINACER_GROUP, variables);
            if (result && result.data && result.data.saveSchemeFinancerGroup) {
                toast.success(result?.data?.saveSchemeFinancerGroup || "Success");
                setFinancersData({
                    scheme_id: formData.scheme_id,
                    schemefinancers: [],
                    financersids: [],
                    product_type: [],
                    product_type_ids: ['ALL'],
                    loan_type: [],
                    loan_type_ids: ['ALL'],
                    employment_type_ids: ['ALL'],
                    employment_type: [],
                    financier_id: [],
                })
            }
           await getSchemeFinacerGroups(formData.id)
            
        } catch (error) {
            toast.error("Failed");
        }
    }

    const getSchemeFinacerGroups = async (scheme_id ) => {
        try {
            if(scheme_id && scheme_id>0){
                const schemeFGDataList = await executeGraphQLQuery(GET_SCHEME_FINANCER_GROUPS(scheme_id),{
                    notifyOnNetworkStatusChange: true,
                  });
                if (schemeFGDataList && schemeFGDataList.data && schemeFGDataList.data.schemeFinancerGroupbySchemeId) {
                    const financerGroupListNew = schemeFGDataList.data.schemeFinancerGroupbySchemeId;
                    setFinancerGroupList(financerGroupListNew)
                }
               } 
        } catch (error) {
        }
      
    }

    const deleteFinacers = async (financer_id, financer_group_id) => {
        if (!window.confirm("Do you want to delete financer from range group!")) {
            return false;
        }
        const variables = {
            financer_group_id: financer_group_id,
            financer_id: financer_id
        };

        try {
            const result = await executeGraphQLMutation(DELETE_FINANCER_FROM_GROUP_FINANCER, variables);
            if (result) {
                toast.success(result?.data?.save_range?.message || "Success");
                getSchemeFinacerGroups(props.schemedetailsData.id)
            }
        } catch (error) {
            toast.error("Failed");
        }

    }

    const handleFinancersOptionChange = (name, selectedOptions) => {
        const rangeFinancersdata = { ...financersData }
        const financers = []
        const financersids = []
        selectedOptions && selectedOptions.length > 0 && selectedOptions.map((selectedData) => {
            financers.push({ financer_id: selectedData.id })
            financersids.push(selectedData.id)
        })
        setFinancersData({ ...rangeFinancersdata, schemefinancers: financers, financersids: financersids });
    };


    const handleProductTypeOptionChange = (name, selectedOptions) => {
        if (name == 'product_type') {
            const rangeFinancersdata = { ...financersData }
            const product_type = [{ id: selectedOptions.id }]
            const product_type_ids = [selectedOptions.id]
            setFinancersData({ ...rangeFinancersdata, product_type: product_type, product_type_ids: product_type_ids });
        }
        if (name == 'employment_type') {
            const rangeFinancersdata = { ...financersData }
            const employment_type = [{ id: selectedOptions.id }]
            const employment_type_ids = [selectedOptions.id]
            setFinancersData({ ...rangeFinancersdata, employment_type: employment_type, employment_type_ids: employment_type_ids });
        }
    };


    const handleLoanTypeOptionChange = (name, selectedOptions) => {
        const rangeFinancersdata = { ...financersData }
        const loan_type = []
        const loan_type_ids = []
            loan_type.push({ id: selectedOptions.id })
            loan_type_ids.push(selectedOptions.id)
        setFinancersData({ ...rangeFinancersdata, loan_type: loan_type, loan_type_ids: loan_type_ids });
    };

    const showFinancers = (financer_id, financer_group_id) => {

        const financerobj = financers.find(o => o.id === financer_id);
        return (
            <div className="removebtn">
                {financerobj && financerobj.name} {is_disabled ? "" : <div className="finence-btn" name="financer_id[]" onClick={() => deleteFinacers(financer_id, financer_group_id)} > X</div> }
            </div>
        )
    }

    const handleCheckboxall = (e) => {
        const checked = e.target.checked
        const is_on_payin = checked ? 1 : 0;
        const formDataNew = { ...financersData };
        formDataNew.is_on_payin = is_on_payin;
        setFinancersData(formDataNew)
    }

    const handleFinancerGroupData = (e) => {
        const name = e.target.name
        const value = e.target.value;
        const formDataNew = { ...financersData };
        formDataNew[name] = parseInt(value);
        setFinancersData(formDataNew)
    }

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST());
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const producttypedata = []
            const loan_type = []
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const product_type = data.loan_type && data.loan_type.length > 0 ? data.loan_type : []
            const loan_sub_type = data.loan_sub_type && data.loan_sub_type.length > 0 ? data.loan_sub_type : []
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
            }
            if (product_type && product_type.length > 0) {
                product_type.map(ptype => {
                    producttypedata.push({ id: ptype.id, name: ptype.label })
                })
                setProductType(producttypedata)
            }
            if (loan_sub_type && loan_sub_type.length > 0) {
                loan_sub_type.map(ptype => {
                    loan_type.push({ id: ptype.id, name: ptype.label })
                })
                setLoanType(loan_type)
            }          
        }


    }
    const handleChange = async (n, v, isMulti, opt, optl) => {
        switch (n) {
     
          case 'financier_id':
            let financier_ids = [];
            financier_ids = v.map(obj => { return obj.id });
            setFinancersData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
            break;
        }
      }

    return (
        <>   {is_disabled ?   <div className="card"> <div className="card-body"><div className="row">  <div className="col-sm-12"> Scheme End date validation Failed! You can not add/update ranges of scheme! </div></div></div></div> :
            
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-3">
                            <label>Banks<span className="required" style={{ color: 'red' }}>*</span></label>
                            <MultiSelect
                                options={financers}
                                placeholder={"Financiers"}
                                name={'financier_id'}
                                optionLabel="name"
                                optionValue="id"
                                values={financersData.financier_id}
                                onChange={handleChange}
                                isMulti
                            />
                        </div>

                        <div className="col-sm-2">
                            <label>Employment Type<span className="required" style={{ color: 'red' }}>*</span></label>
                            <Select
                                id="employment_type"
                                onChange={(e) => handleProductTypeOptionChange('employment_type', e)}
                                options={employmentType}
                                label={"Select Product Type"}
                                name="employment_type"
                                value={employmentType && employmentType.length > 0 && financersData.employment_type_ids.length > 0 && employmentType.filter(({ id }) => financersData.employment_type_ids.includes(id))}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            />
                        </div>


                        <div className="col-sm-2">
                            <label>Product Type</label>
                            <Select
                                id="product_type"
                                onChange={(e) => handleProductTypeOptionChange('product_type', e)}
                                options={productType}
                                label={"Select Product Type"}
                                name="product_type"
                                value={productType && productType.length > 0 && financersData.product_type_ids.length > 0 && productType.filter(({ id }) => financersData.product_type_ids.includes(id))}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            />
                        </div>

                        
                                    <div className="col-sm-2">

                                     <label>Sub Product Type</label>
                                        <Select
                                            id="loan_type"
                                            onChange={(e) => handleLoanTypeOptionChange('loan_type', e)}
                                            options={loanType}
                                            label={"Select Sub Product Type"}
                                            name="loan_type"
                                            value={loanType && loanType.length > 0 && financersData.loan_type.length > 0 && loanType.find(({ id }) => financersData.loan_type_ids.includes(id))}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div>

                             <div className="col-sm-2" style={{ marginTop: 30 }}>
                              
                                   <CheckBox type="checkbox" name="is_on_payin" id="is_on_payin" value="1" label="Payout on Payin by Financer" checked={financersData.is_on_payin ? true : false} onChange={(e) => handleCheckboxall(e)} />
                             </div>
                        {financersData.financier_id.length > 0 && financersData.product_type.length > 0 && financersData.employment_type.length > 0 ? <button style={{ marginTop: 30 }} className="btn btn-primary" onClick={() => saveFinacerGroup()}> New Finance Group</button> : ""}

                    </div>

                   


                </div>
            </div> }
            {/* <Accordion title={('Payout Ranges')} active="false" delete="true"> */}
            <div className={(pageloading) ? "container-fluid loading" : "container-fluid"}>  
            {
                financerGroupList && financerGroupList.length > 0 && financerGroupList.map((groupData, parentIndex) => {
                    return (
                     
                        <div className="card">
                            {/* {JSON.stringify(rangeErrors)} */}
                            <div className="card-body">
                                <div className="row" style={{ marginBottom: 20 }}>
                                    <div className="col-sm-3">
                                        <div >
                                            Financers
                                        </div>
                                        <div className="finence">
                                            {
                                                groupData.schemebanks && groupData.schemebanks.length > 0 && groupData.schemebanks.map(banksdata => {
                                                    return (
                                                        <>
                                                            <div className="finencegroup">
                                                                <b>{showFinancers(banksdata.financer_id, groupData.id)}</b>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                
                                    <div className="col-sm-2">
                                        <div >
                                            Employment Type
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <b>{groupData.employment_type}</b>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div >
                                            Product Type
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <b>{productType && productType.length > 0 && productType.filter((type) => type.id == groupData.product_type)[0]?.name}</b>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div>
                                            Sub Product Type
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <b>{groupData.loan_type ? loanType && loanType.length > 0 && loanType.filter((type) => type.id == groupData.loan_type)[0]?.name: "All"}</b>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div>
                                            On Payin
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <b>{groupData.is_on_payin ? "Yes": "No"}</b>
                                        </div>
                                    </div>

                                </div>

                               
                                    <>
                                        {groupData.schemerange && groupData.schemerange.length > 0 ? <div className="row">
                                            <div className="col-sm-3 payouttable">
                                                <div>From</div>
                                            </div>
                                            <div className="col-sm-3 payouttable">
                                                <div>To</div>
                                            </div>
                                            <div className="col-sm-3 payouttable">
                                                <div>Payout(%)</div>
                                            </div>

                                        </div> : ""}

                                        <div>

                                            {groupData.schemerange && groupData.schemerange.length > 0 && groupData.schemerange.map((rangedata, childIndex) => {
                                                return (
                                                    <>
                                                        <div className="payout-row">

                                                            <div className="row" style={{ marginBottom: 10 }}>

                                                                <div className="col-sm-3">
                                                                    <Select
                                                                        id="start_range"
                                                                        onChange={(e) => handleInputarray(parentIndex, childIndex, e, 'start_range')}
                                                                        options={childIndex == 0 ? rangeArray.fromArray : rangeArray.fromArray.filter((item) => item.id >= groupData.schemerange[childIndex - 1]?.end_range)}
                                                                        label={"Select Financers"}
                                                                        name="start_range"
                                                                        value={rangeArray && rangeArray.fromArray.length > 0 && rangeArray.fromArray.find((item) => item.id == rangedata.start_range)}
                                                                        getOptionLabel={({ name }) => name}
                                                                        getOptionValue={({ id }) => id}
                                                                        error={rangeErrors[childIndex] && rangeErrors[childIndex].rangeblank ? rangeErrors[childIndex].rangeblank : ''}
                                                                        isDisabled = {is_disabled}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <Select
                                                                        id="end_range"
                                                                        onChange={(e) => handleInputarray(parentIndex, childIndex, e, 'end_range')}
                                                                        options={rangeArray.toArray.filter((item) => item.id >= groupData.schemerange[childIndex]?.start_range)}
                                                                        label={"Select Financers"}
                                                                        name="end_range"
                                                                        value={rangeArray && rangeArray.toArray.length > 0 && rangeArray.toArray.length > 0 && rangeArray.toArray.find((item) => item.id == rangedata.end_range)}
                                                                        getOptionLabel={({ name }) => name}
                                                                        getOptionValue={({ id }) => id}
                                                                        isOptionDisabled={(option) => option.id <= groupData.schemerange[childIndex] && groupData.schemerange[childIndex].start_range ? parseInt(groupData.schemerange[childIndex].start_range) : 0}
                                                                        error={rangeErrors[childIndex] && rangeErrors[childIndex].rangeblank ? rangeErrors[childIndex].rangeblank : ''}
                                                                    // isOptionDisabled={}
                                                                        isDisabled = {is_disabled}
                                                                    />
                                                                    {rangeErrors && rangeErrors.rangeblank && rangeErrors.rangeblank.length > 0 && rangeErrors.rangeblank[childIndex] && parentIndex == rangeIndex ? <div className="error show"> {rangeErrors.rangeblank[childIndex].error}</div> : ""}
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <InputField
                                                                        type="text"
                                                                        name="payout"
                                                                        id="payout"
                                                                        maxLength="4"
                                                                        is_label_required={true}
                                                                        form_field={true}
                                                                        value={rangedata.payout ? rangedata.payout : ""}
                                                                        onChange={(e) => handleInputarray(parentIndex, childIndex, e)}
                                                                        disabled = {is_disabled}
                                                                    />
                                                                    {rangeErrors && rangeErrors.payout && rangeErrors.payout.length > 0 && rangeErrors.payout[childIndex] && parentIndex == rangeIndex ? <div className="error show"> {rangeErrors.payout[childIndex].error}</div> : ""}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                            {rangeErrors.range_overlap_error && rangeIndex == parentIndex ?
                                                <div className="col-sm-12 error show">{rangeErrors.range_overlap_error}</div> : ""
                                            }
                                        </div>

                                   
                                        {groupData.schemerange && groupData.schemerange.length > 0 && !is_disabled ? <span className="btn btn-reset-new" onClick={() => removeFieled(parentIndex, (groupData.schemerange.length - 1), groupData.schemerange[groupData.schemerange.length - 1].id)}><i style={{ fontSize: 21 }} className="ic-delete"></i></span> : ""}

                                      { !is_disabled ?   <button style={{ marginTop: 15, fontSize: 20 }} className="btn-new" onClick={() => addMoreFields(parentIndex, groupData.id)}>+</button> : ""}
                                        {groupData.schemerange.length > 0 && !is_disabled ? <button style={{ marginLeft: 10 }} className="btn btn-reset" onClick={() => saveSchemeRanges(parentIndex)} disabled={groupData.schemerange.length > 0 ? false : true}>Save Ranges</button> : ""}
                                    </> 
                            </div>
                            </div>
                       )

                })
            }
 </div>
            {/* </Accordion> */}

        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export  default withTranslation('dealer')(connect(mapStateToProps, mapDispatchToProps)(PayoutRanges));