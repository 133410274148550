import React from 'react';
import { ReactComponent as EditSvg } from '../../../../webroot/images/edit-3.svg';

const EditIcon = (props) => {
  const iconStyles = {
    display: 'inline-block',
    position: 'absolute',
    right: '10px',
    top: '47%',
    pointerEvents: 'auto',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
  };

  const onPress = () => {
    props.onPress();
  }

  return (
    <div style={iconStyles}>
      {props.isValid ? (
        <div>
        <EditSvg onClick={() => onPress()} width="23" height="23" />
      </div>
      ): null}
    </div>
  );
};

export default EditIcon;