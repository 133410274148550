import React, { Component } from 'react';
import OtpLogin from '../login/component/otp-login';
import { withTranslation } from 'react-i18next';
import LeftPanel from '../login/component/left-panel';
import loginlogo from '../../view/login/assets/images/loginlogo.png'
import DeleteOtp from './DeleteOtp';


class DeleteAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayForm: 'email',
            reloadCaptcha:1,
            fields: {},
            inputFieldCaptcha:{captcha:""},
            errors: {captchaErr:'Please enter valid captcha'},
            showOtp:false,
            otpTimer:0
        }
    }
    componentDidMount = ()=>{
        let self = this
        this.myInterval = setInterval(() => {
            self.setState(({ otpTimer }) => ({
                otpTimer: otpTimer - 1
            }))
        }, 1000)
    }

    componentWillReceiveProps = (nextProps)=>{
        this.setState({errors : nextProps.errorMessage});
        this.setState({reloadCaptcha : nextProps.reloadCaptcha});
        this.setState({showOtp : nextProps.showOtp});
        if(this.state.showOtp != nextProps.showOtp && nextProps.showOtp){
            this.setState({otpTimer:60})
        }
    }
    submitLoginForm = (event) =>{
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }
    }

    handleChange = event =>{
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    showLoginForm = event => {
        let showingSection = event.currentTarget.dataset.key;
        this.setState({
            displayForm: showingSection
        })
    }

    showEmailLoginForm = () => {
        this.setState({
            displayForm: 'email'
        })
    }
     captchaFiledHandler = (childData) => {
        let captcahId = childData.captchaData.data.value;
        let tempErrors=this.state.errors;
        let tempInputFieldCaptcha=this.state.inputFieldCaptcha;
        if(captcahId){                  
           tempErrors['captchaErr']="";           
           tempInputFieldCaptcha['captcha']=childData.captchaVal;               
        } else {            
            tempErrors['captchaErr']="Please enter valid captcha.";             
        }
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(childData);
        }
        this.setState({tempErrors});        
    }

    handleResend = async (event) =>{
        await this.props.sendOtpEmail(event)
        this.setState({otpTimer:60})
    }

    render() {
        const {fields, errors} = this.state;
        return (
            <div className= "container-fluid" id="homePage">
                 <LeftPanel />
            <div className="rightWhiteBg">
            <div className='deletecard'>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginBottom:"30px"}}>
                <img src={loginlogo} alt='logo' width={180} height={60} />
                </div>
                
                <div className="sm">
                    <div id="fedupDix" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                        <div className="col-md-12" id="modified_design">
                            <div className="signUp-container text-left">
                            {/* <h1 className="login-h1">{('Delete ')}</h1>
                                    <p className="login-p">{('Delete your partner Account mobile')}</p> */}
                                <DeleteOtp /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        );
    }
}

export default withTranslation('common')(DeleteAccount);