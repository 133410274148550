import React, { useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { postApiUtility } from "../Utility/user.utility";
import { toast } from "react-toastify";

const CompanyProfile = ({ profession, formData, setFormData, errors , handleUserType }) => {
  const [panData , setPanData] = useState({})
  const [loading , setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length === 10 && name == 'pan_no')
      {
        validatePan(value)
      }
    // Ensure only numbers are allowed for mobile fields
    if (name === "spoc_mobile" || name === "dealership_contact") {
      const numericValue = value.replace(/\D/g, '');
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: numericValue
      }));
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (selectedOption, name) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: selectedOption ? selectedOption.id : ''
    }));
  };

  const selectedProfession = profession.find(
    (prof) => prof.id === formData.profession_id
  );

  const selectedCompanyType = profession.find(
    (prof) => prof.id === formData.company_type
  );

  const validatePan = async(pan_no) => {
    formData.pan_no = pan_no
    const validatePanResponse = await postApiUtility('partner/partner/validate-PAN', {
      "pan_number": pan_no,
      "partner_id": formData.id
    })
    const panParams = {
      "pan_card":pan_no, 
      "partner_id": formData.id
    }
    const panData = await postApiUtility('central-service/pancard/validate', panParams)
    if(validatePanResponse?.data?.data?.message === 'New PAN') {
      if (panData.status !== 200) 
        {
          toast.error(panData.data.message)
          setLoading(false)
        }
        else {
          toast.success("Pan Verified Successfully")
          
        }
      setPanData(panData?.data?.data?.response)
      setLoading(false)

    }
  }
   if (panData)
    {
      if (panData.typeOfHolder) {
      if (panData.typeOfHolder == "Company") {
        handleUserType("company");
      } else {
        handleUserType("Individual");
      }
    }
      console.log ("panData" , panData)
      if(panData.name)
      formData.name = panData.name 
      if(panData.mobile_no)
      formData.dealership_contact = panData.mobile_no 
  
    }

  return (
    <form>
      <div className="kycinfooverflow">
        <div className="row">
          <div className="col-md-4">
          <InputField
            type="text"
            name="pan_no"
            id="pan_no"
            placeholder="Pan Card Number"
            label="Pan Card Number*"
            value={formData.pan_no || ''}
            onChange={handleChange}
            error={errors.pan}
            maxLength="10"
            pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
            title="PAN should be in the format: ABCDE1234F"
          />
            <InputField
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              label = "Company Name"
              value={formData.name || ''}
              onChange={handleChange}
              error={errors.name}
              disabled = {true}
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="col-md-4">
            <label>Profession Type*</label>
            <Select
              id="profession_id"
              name="profession_id"
              options={profession}
              value={selectedProfession}
              getOptionLabel={({ profession_name }) => profession_name}
              getOptionValue={({ id }) => id}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'profession_id')}
            />
            {errors.profession_id && <div className="error">{errors.profession_id}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="spoc_email"
              id="spoc_email"
              placeholder=""
              label="Company SPOC*"
              value={formData.spoc_email || ''}
              onChange={handleChange}
              error={errors.company_spoc}
            />
            {errors.company_spoc && <div className="error">{errors.company_spoc}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="spoc_name"
              id="spoc_name"
              placeholder=""
              label="SPOC Name*"
              value={formData.spoc_name || ''}
              onChange={handleChange}
              error={errors.spoc_name}
            />
            {errors.spoc_name && <div className="error">{errors.spoc_name}</div>}
          </div>
          <div className="col-md-4">
            <InputField
              type="text"
              name="spoc_mobile"
              id="spoc_mobile"
              placeholder=""
              label="SPOC Mobile*"
              value={formData.spoc_mobile || ''}
              onChange={handleChange}
              error={errors.spoc_mobile}
              maxLength={10}
              inputMode="numeric"
              pattern="[0-9]*" 
            />
            {errors.spoc_mobile && <div className="error">{errors.spoc_mobile}</div>}
          </div>
            <div className="col-md-4">
              <InputField
                type="text"
                name="referrel_code"
                id="referrel_code"
                placeholder="Referral Code"
                label="Referral Code"
                value={formData.referrel_code || ''}
                onChange={handleChange}
                disabled
              />
            </div>
            <div className="col-md-4">
              <InputField
                type="text"
                name="dealership_email"
                id="dealership_email"
                placeholder="Company Email"
                label="Company Email Id*"
                value={formData.dealership_email || ''}
                onChange={handleChange}
                error={errors.dealership_email}
                // disabled = {true}
              />
              {errors.dealership_email && <div className="error">{errors.dealership_email}</div>}
            </div>
            <div className="col-md-4">
              <InputField
                type="text"
                name="dealership_contact"
                id="dealership_contact"
                placeholder=""
                label="Company Mobile Number*"
                value={formData.dealership_contact || ''}
                onChange={handleChange}
                error={errors.dealership_contact}
                maxLength={10}
                inputMode="numeric" 
                pattern="[0-9]*" 
                // disabled = {true}
              />
              {errors.dealership_contact && <div className="error">{errors.dealership_contact}</div>}
            </div>
            <div className="col-md-4">
              <InputField
                type="text"
                name="pincode"
                id="pincode"
                placeholder="Pin Code"
                label="Pincode*"
                value={formData.pincode || ''}
                onChange={handleChange}
                error={errors.pincode}
              />
              {errors.pincode && <div className="error">{errors.pincode}</div>}
            </div>
        </div>
      </div>
    </form>
  );
};

export default CompanyProfile;
