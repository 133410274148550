import React, { useState } from "react";
import Select from "react-select";
import InputField from "../../view/elements/Input";
import Modal from "../../view/common/Modal";
import { useHistory } from "react-router-dom";

const CibilAdditionalDetails = ({ handleAdditionInfoButton }) => {
  const history = useHistory();
  const [loanDetails, setLoanDetails] = useState(1);
  const [activeTab, setActiveTab] = useState("homeloan");
  const [openModal, setOpenModal] = useState(false);
  const [filterData, setFilterData] = useState({});
  const ShowPopup = () => {
    handleAdditionInfoButton(true);
    // setOpenModal(!openModal);
    if (!openModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const data = [
    { name: "salaried", id: 1 },
    { name: "self-employeed", id: 2 },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLoanTypeChange = (event) => {
    setLoanDetails(event.target.value);
  };

  const handleReport = () => {
    history.push("/my-tool-cibil");
    history.push("/my-credit-reports");
  };
  return (
    <>
      <div className="mytoolverification">
        <h2 className="verificationheadingmain">Additional Details</h2>
        <div>Loan Type*</div>

        <div className="cibilloanflex">
          <div
            className={`cibilhometab ${
              activeTab === "homeloan" ? "active" : ""
            }`}
            onClick={() => handleTabClick("homeloan")}
          >
            {activeTab === "homeloan" ? (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724129825863.svg"
                alt="home"
                width={20}
                height={20}
              />
            ) : (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724129398984.svg"
                alt="loan"
                width={20}
                height={20}
              />
            )}
            <span>Home Loan</span>
          </div>

          <div
            className={`cibilhometab ${activeTab === "lap" ? "active" : ""}`}
            onClick={() => handleTabClick("lap")}
          >
            {activeTab === "lap" ? (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724130094744.svg"
                alt="home"
                width={20}
                height={20}
              />
            ) : (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724129903084.svg"
                alt="loan"
                width={20}
                height={20}
              />
            )}
            <span>Lap</span>
          </div>
        </div>

        <div>
          <div className="loanfulfillment">
            <div className="loaninput">
              <input
                type="checkbox"
                id="balancetransfer"
                name="bt"
                value="1"
                checked={loanDetails?.loan_sub_type?.includes("1")}
                onChange={handleLoanTypeChange}
              />
              <label htmlFor="balancetransfer">Balance Transfer</label>
            </div>
            <div className="loaninput">
              <input
                type="checkbox"
                id="top-up"
                name="top_up"
                value="2"
                checked={loanDetails?.loan_sub_type?.includes("2")}
                onChange={handleLoanTypeChange}
              />
              <label htmlFor="top-up">Top-Up</label>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <InputField
                type="text"
                name="required_loan"
                id="required_loan"
                placeholder="Required Loan Amount"
                label="Required Loan Amount"
              />
            </div>
            <div className="col-sm-6">
              <label>Employment Type</label>
            <Select
              id="employment_type"
              options={data}
              onChange={(selectedOption) =>
                setFilterData({
                  ...filterData,
                  employment_type: selectedOption,
                })
              }
              name="employment_type"
              value={filterData?.employment_type}
              getOptionLabel={(option) => option.name} // Corrected
              getOptionValue={(option) => option.id} // Corrected
            />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <InputField
                type="text"
                name="monthly_salary"
                id="monthly_salary"
                placeholder="Take Home Monthly Salary"
                label="Take Home Monthly Salary"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="allcustomerinfo">
        All customer information is secure and no customer will be directly
        reached out without your permission
      </div>
      <div className="buttonflexmobile">
        <button className="cibilcheckbtn" onClick={ShowPopup}>
          Send Link
        </button>
      </div>

      <Modal isOpen={openModal} togglePopup={ShowPopup} width={800}>
        <div className="cibilgiffile">
          <img
            src={require("../../saathi-web/images/pingball.gif")}
            alt=""
            width={150}
            height={150}
          />
          <h4 className="verificationheadingmain">
            Waiting for customer’s consent
          </h4>
          <p>
            Consent Link has been Shared with the Customer via SMS. You can also
            copy the link below and send to your customer. They will receive an
            OTP on clicking on the link.
          </p>
          <div className="cibillink">
            <a href="https:/ambak.consentlink.com" target="blank">
              https:/ambak.consentlink.com{" "}
            </a>
            <div className="cibillinkcopy">
              <i className="ic-content_copy"> Copy</i>
            </div>
          </div>
        </div>
        <div className="buttonflexmobile">
          <button className="cibilcheckbtn" onClick={handleReport}>
            Go To Lead
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CibilAdditionalDetails;
