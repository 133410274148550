import React from 'react';
import { withFormsy, addValidationRule } from 'formsy-react';
import { PatternFormat, default as NumberFormat } from 'react-number-format';
import "react-datepicker/dist/react-datepicker.css";

// Add common validation rules
addValidationRule("isValidPhoneNumber", (values, value) => {
  if (value) {
    value = value.trim();
    if (value[0] !== '9' && value[0] !== '8' && value[0] !== '7' && value[0] !== '6') {
      return "Phone number must start with 6 to 9";
    }
    if (value.length > 10) {
      return "Phone number must be of 10 digits";
    }
  }
  return true;
});

addValidationRule("isValidTelephonePhoneNumber", (values, value) => {
  if (value) {
    value = value.trim();
    if (value.length !== 11) return "Must be of 11 digits";
  }
  return true;
});

addValidationRule("isValidPinCode", (values, value) => {
  if (value) {
    value = value.trim();
    const pinValidet = /^\d{1,6}$/.test(value); // Allow up to 6 digits
    if (!pinValidet) return "Invalid pincode.";
  }
  return true;
});

addValidationRule("isValidAddharCard", (values, value) => {
  if (value) {
    value = value.trim().replace(/\s/g, ''); 
    const addharValidet = /^[X]{8}\d{4}$/.test(value);
    if (!addharValidet) return "Invalid aadhar number (should be like XXXXXXXX1234)";
  }
  return true;
});

addValidationRule("isValidnoOfEMI", (values, value) => {
  if (value) {
    value = value;
    if (value > 360) {
      return "No of EMI should not be more than 360"; 
    }  
  }
  return true;
});

addValidationRule("isValidAnnualIncome", (values, value) => {
  if (value) {
    value = value.trim();
    if (value < 199999) {
      return "Min value should be more than 199999"; 
    }    
  }
  return true;
});

addValidationRule("allowNonNegativeNumberOnly", (values, value) => {
  if (value) {
    value = value.trim();
    if (value < 0) {
      return "Value should be more than 0"; 
    }    
  }
  return true;
});

addValidationRule("isValidPropertyValue", (values, value) => {
  if (value) {
    value = value.trim();
    if (value < 1000000) {  
      return "Min value should be more than 1000000";  
    }   
  }
  return true;
});

addValidationRule("isValidPanCard", (values, value) => {
  if (value) {
    value = value.trim();
    const panValidet = /^[A-Z]{3}[CHFATBLJGP][A-Z]\d{4}[A-Z]$/.test(value);
    if (!panValidet) return "Invalid PAN card number.";
  }
  return true;
});

const FormsyInputField = (props) => {
    const changeHandler = event => {
        const { value, id } = event.target;
        if (!props.errorMessage && value) {
            document.getElementById(id).style.border = "1px solid #bbbbbb";
        }
        if (typeof props.onChange === "function") { 
            if (props.maxLength && event.target.value.length > +props.maxLength) {
                return true;
            } 
            props.onChange(event);
        }
        props.setValue(event.target.value);
    };

    const patternFormatChangeHandler = (data) => {
        if (typeof props.onChange === "function") {
            props.onChange(data);
        }
        props.setValue(data.value);
    }
    
    const blurHandler = event => {
        const { value, id } = event.target;
        let errorElement = document
            .getElementById(id)
            .parentNode.querySelector(".error-msg-txt");
        if (props.isRequired && value === '') {
            document.getElementById(id).style.border = "1px solid red";
        }
        let formField = document.getElementById(id).closest(".form-field");
        if (value === "") {
            if (errorElement) errorElement.classList.remove("hide");
            if (formField) formField.classList.remove("active");
        } else {
            if (errorElement) errorElement.classList.add("hide");
            if (formField) formField.classList.add("active");
        }
    }

    const focusHandler = event => {
        const { id } = event.target;
        let formField = document.getElementById(id).closest(".form-field");
        if (formField) formField.classList.add("active");
    };

    const newPropsValue = (props) => {
        let newProps = { ...props };
        // Remove props not needed for the input field
        [
            'validate', 'allowEmptyFormatting', 'showRequired', 'showError', 'setValue', 
            'setValidations', 'resetValue', 'isValid', 'isRequired', 'isPristine',
            'isFormSubmitted', 'hasValue', 'errorMessages', 'errorMessage', 
            'validationErrors', 'innerRef', 'runValidation', 'isValidValue', 
            'isFormDisabled', 'detachFromForm', 'attachToForm', 'validationError'
        ].forEach(prop => delete newProps[prop]);
        return newProps;
    }

    let label = props.label;
    let labeltxtClass = (label && label.length && label.length >= 25) && props.col !== "col-md-12" ? 'label-txt' : '';
    let labelClass = props.isRequired ? 'label-required' : '';

    let Formatter = props.type === "pattern-format" ? NumberFormat : NumberFormat; 

    let newProps = newPropsValue(props);

    return (
        <>
            {["text", "date", "datetime-local", "textarea", "password", "number", "radio"].includes(props.type) ? (
                <div className="material">
                    <label data-label={label} className={`form-label ${labeltxtClass} ${labelClass}`}>
                        {label}
                    </label>
                    <input
                        {...newProps}
                        autoComplete="new-off"
                        style={{ border: props.errorMessage ? "1px solid red" : "" }}
                        className={props.className ? props.className : "form-input"}
                        title={labeltxtClass ? label : ""}
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        onFocus={focusHandler}
                    />
                    {props.errorMessage ? (
                        <div className="error-msg-txt">{props.errorMessage}</div>
                    ) : null}
                </div>
            ) : ["pattern-format", "number-format"].includes(props.type) ? (
                <div className="material">
                    <label data-label={label} className={`form-label ${labeltxtClass} ${labelClass}`}>
                        {label}
                    </label>
                    <Formatter
                        {...newProps}
                        style={{ border: props.errorMessage ? "1px solid red" : "" }}
                        className={props.className ? props.className : "form-input"}
                        format={props.type === "pattern-format" ? props.format : null}
                        title={labeltxtClass ? label : ""}
                        onValueChange={(data) => patternFormatChangeHandler(data)}
                        onBlur={blurHandler}
                        onFocus={focusHandler}
                    />
                    {props.errorMessage ? (
                        <div className="error-msg-txt">{props.errorMessage}</div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
}

export default withFormsy(FormsyInputField);
