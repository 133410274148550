import DateFormate from 'dateformat';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DEALER_WALLET_ID } from '../../../config/constant';
import { DealerService } from '../../../service';
import MasterService from '../../../service/MasterService';
import ViewHistory from '../../dealerDetail/component/ViewHistory';
import ModalPopup from '../../elements/Popup';
import BlackListPopup from './BlackListPopup';
class PartnerResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dealerListData: props.dealerListData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'pending': 'nav-item',
                'all': 'nav-item'
            },
            filterData: {
            },
            dealerUpdateData: {
            },
            blackListPopup: false,
            blackListUpdateData: {},
            currentIndex: null,
            current_org_name: null,
            historyPopup : false,
            histoy_dealer_id : 0,
        };
    }

    closeHistoryModel = () => {
        this.setState({ historyPopup: false });
    }

    handleHistory = (id) => {
        this.setState({historyPopup : true});
        this.setState({histoy_dealer_id : id});
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            dealerListData: nextProps.dealerListData,
            loading: nextProps.loading,
            pageloading: nextProps.pageloading
        });
    }
    handleStatusClick = (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname == 'active') {
            filterData['status'] = 1;
        } else if (sname == 'inactive') {
            filterData['status'] = 2;
        } else if (sname == 'incomplete') {
            filterData['status'] = 3;
        } else if (sname == 'pending') {
            filterData['status'] = 4;
        } else if (sname == 'blacklist') {
            filterData['status'] = 5;
        } else if (sname == 'all') {
            filterData['status'] = null;
        }
        this.setState({ filterData });
        if (typeof this.props.statusClick === 'function') {
            this.props.statusClick(sname, ovalue);
            this.setState({
                statusClassname: {
                    'active': 'nav-item',
                    'inactive': 'nav-item',
                    'incomplete': 'nav-item',
                    'blacklist': 'nav-item',
                    'pending': 'nav-item',
                    'all': 'nav-item',
                    [sname]: 'nav-item active'
                }
            })
        }
    }

    getBasicDetails() {
        return new Promise((resolve, reject) => {
            DealerService.getMasters(["dealer_sku"]).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let responsedata = response.data.data || {};
                    if (responsedata.dealer_sku && responsedata.dealer_sku.length) {
                        let subscM = [];
                        responsedata.dealer_sku.map(v => {
                            if (v.sku_type == "subscription") subscM.push(v);
                        })
                        resolve(subscM);
                    }
                }else{
                    reject(response.data)
                }
            }).catch((error) => {
                reject(error)
            });
        })
    }

    checkBalance = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let balance = await DealerService.checkBalance(params);
                if(balance && balance.data && balance.data.status === 200){
                    if (balance && balance.data && balance.data.data && balance.data.data.closing_balance === 0) {
                        resolve(true)
                    } else {
                        resolve(false);
                    }
                }else{
                    reject(balance.data.message)
                }
            } catch (error) {
                reject(error)
            }
        })
    }

    fetchEditData = (dealer_id_hash) => {
        return new Promise((resolve, reject) => {
            DealerService.getSkusDetails(dealer_id_hash).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let editDataAll = response.data.data || [];
                    let [row] = editDataAll.filter(item => item.b_details_sku_id === DEALER_WALLET_ID);
                    this.getBasicDetails().then(subM => {
                        let [master] = subM.filter(item => item.id === DEALER_WALLET_ID);
                        row.b_details_is_active = '0';
                        row.master = master;
                        resolve(row);
                    }).catch(error => reject(error));
                }
                else {
                    reject(response.data)
                }
            }).catch(error => reject(error));
        })
    }

    handleChange = (idx, dealer_id_hash, current_status) => async ({ target }) => {
        let isChecked = target.checked;
        let [dealer_data] = this.state.dealerListData.filter(item => item.dealer_id_hash === dealer_id_hash)
            await this.changeDealerStatus(idx, target,isChecked, current_status, dealer_id_hash);
    }

    handleBlackList = (idx, dealer_id_hash, status) => async ({ target }) => {
        let tempBlackListUpdateData = { ...this.state.blackListUpdateData };
        tempBlackListUpdateData.status = status;
        tempBlackListUpdateData.dealer_id_hash = dealer_id_hash;
        let org_name = this.state.dealerListData[idx] && this.state.dealerListData[idx].organization
        this.setState({ blackListPopup: true, blackListUpdateData: tempBlackListUpdateData, currentIndex: idx, current_org_name: org_name }, () => { });
    }

    closeModal = () => {
        this.setState({ blackListPopup: false });
    }
    updateDealerListData = (updatedObj, index) => {
        let dealerListTempData = [...this.state.dealerListData];
        dealerListTempData[index].status = updatedObj.status;
        this.setState({ dealerListData: dealerListTempData });
    }
    async changeDealerStatus(idx, target, isChecked, current_status, dealer_id_hash) {
        let org = this.state.dealerListData[idx] || {};
        let active = isChecked ? "Active" : "Inactive";
        var r = window.confirm(this.props.t('Listing.Search_Result.alert_active_inactive', { status: active, dealer_name: org.organization }));
        let dealerUpdateData = this.state.dealerUpdateData;
        if (r == true) {
            if (isChecked) {
                dealerUpdateData['status'] = '1';
                dealerUpdateData['current_status'] = current_status;
                dealerUpdateData['dealer_id_hash'] = dealer_id_hash;

            } else {
                dealerUpdateData['status'] = '2';
                dealerUpdateData['current_status'] = current_status;
                dealerUpdateData['dealer_id_hash'] = dealer_id_hash;
            }
            this.setState({ dealerUpdateData });
            var thisObj = this;
            let dealerListTempData = [...this.state.dealerListData];
            thisObj.setState({loading:true})
            let checkVal = isChecked;
            const response = await MasterService.post('dealer/dealer/status_change', { ...this.state.dealerUpdateData });
            thisObj.flag = false;
            if (response.data.status == 200) {
                if (checkVal) {
                    target.parentNode.style.textDecoration = "";
                    dealerListTempData[idx].status = 1;
                } else {
                    target.parentNode.style.textDecoration = "line-through";
                    dealerListTempData[idx].status = 2;
                }
                thisObj.setState({ dealerListData: dealerListTempData, loading: false });
            }else{
                thisObj.setState({loading:false})
                toast.error(response.data.message)
            }
        }
    }

    render() {
        const { dealerCountStatusDetail } = this.props;
        return (
            <div className={this.state.loading?"loading result-wrap":"result-wrap"}>
                <div className="card">
                    <div className="pad-15">
                        <div className="row">
                            <div className="col-sm-8">
                                <ul className="list-inline nav-tabs">
                                    <li className={this.state.statusClassname.active} onClick={this.handleStatusClick.bind(this, 'active')}><div>{this.props.t('Listing.Search_Result.Active')}<span>({dealerCountStatusDetail.active})</span></div></li>
                                    <li className={this.state.statusClassname.inactive} onClick={this.handleStatusClick.bind(this, 'inactive')}><div>{this.props.t('Listing.Search_Result.Inactive')}<span>({dealerCountStatusDetail.inactive})</span></div></li>
                                    {/* <li className={this.state.statusClassname.incomplete} onClick={this.handleStatusClick.bind(this, 'incomplete')}><div>{this.props.t('Listing.Search_Result.Incomplete')}<span>({dealerCountStatusDetail.incomplete})</span></div></li> */}
                                    <li className={this.state.statusClassname.blacklist} onClick={this.handleStatusClick.bind(this, 'blacklist')}><div>{this.props.t('Listing.Search_Result.Blacklist')}<span>({dealerCountStatusDetail.blacklist})</span></div></li>
                                    <li className={this.state.statusClassname.pending} onClick={this.handleStatusClick.bind(this, 'pending')}><div>{this.props.t('Listing.Search_Result.Pending')}<span>({dealerCountStatusDetail.pending})</span></div></li>
                                    <li className={this.state.statusClassname.all} onClick={this.handleStatusClick.bind(this, 'all')}><div>{this.props.t('Listing.Search_Result.All')}<span>({dealerCountStatusDetail.all})</span></div></li>
                                </ul>
                            </div>
                            <div className="text-right">
                                <NavLink to="/dealer/basic-details" className="btn btn-success">{this.props.t('Listing.Search_Result.Add_Dealer')}</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{this.props.t('Listing.Search_Result.AMB')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Details')}</th>
                                        <th>{this.props.t('Listing.Search_Result.City')}</th>
                                        <th>{this.props.t('Listing.Search_Result.partner_type')}</th>
                                        <th>{this.props.t('Listing.Search_Result.profession')}</th>
                                        <th>{this.props.t('Listing.Search_Result.registration_date')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Status')}</th> 
                                        <th>{this.props.t('Listing.Search_Result.Actions')}</th>
                                        {/* <th>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</th> */}
                                        {/* <th>Active</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.dealerListData.length == 0 ? <tr><td align="center" colSpan="8"><h6 className="text-center text-danger text-bold">{this.props.t('Listing.Search_Result.No_Record_Found')}</h6></td></tr> : null}
                                    {(this.state.dealerListData.length != 0) && this.state.dealerListData.map((dealerdata, k) =>
                                        <tr key={k}>
                                            <td>{dealerdata.agent_code}
                                                {dealerdata.bm_info.name ?
                                                    <div>
                                                        <div><b>BM Name: </b><span title={dealerdata.bm_info.name}>{dealerdata.bm_info.name.substring(0, 20)}</span>{dealerdata.bm_info.name.length > 20 ? "..." : ''}</div>
                                                        <div><b>BM Mobile: </b>{dealerdata.bm_info.mobile} </div>
                                                    </div>
                                                    : ''}
                                                {dealerdata.bro_info.name ?
                                                    <div>
                                                        <div><b>BRO Name: </b><span title={dealerdata.bro_info.name}>{dealerdata.bro_info.name.substring(0, 20)}</span>{dealerdata.bro_info.name.length > 20 ? "..." : ''}</div>
                                                        <div><b>BRO Mobile: </b>{dealerdata.bro_info.mobile} </div>
                                                    </div>
                                                    : ''}
                                            </td>
                                            <td>
                                                <div>{(dealerdata.applicant_type_id =='2') ? dealerdata.organization : dealerdata.first_name}</div>
                                                <div>{dealerdata.dealership_email}</div>
                                                <div>{dealerdata.dealership_contact}</div>
                                                <div>{dealerdata.pincode ? dealerdata.pincode: ""}</div>
                                                {/* <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png"/></div> */}
                                            </td>
                                            <td>{(dealerdata.cityname) ? dealerdata.cityname : null}</td>
                                            <td>{(dealerdata.applicant_type_id =='2') ? "Company" : "Individual"}</td>
                                            <td>{(dealerdata.profession_name) ? dealerdata.profession_name : null}</td>
                                            <td>{ dealerdata.created_date ? DateFormate(dealerdata.created_date, 'dS mmm yyyy h:MM TT') : ""}</td>
                                            {/* <td>
                                                {
                                                    dealerdata.skunamestr == null ? null :
                                                        dealerdata.skunamestr.split(',').map((skuname, k) =>
                                                            <span className={skuname == 'SFAP' ? "label blue-status mrg-r5" : "label green-status mrg-r5"} key={k}>{skuname}</span>
                                                        )
                                                }
                                            </td>*/}
                                            <td>
                                            { dealerdata.status == '1' ? "Active" : dealerdata.status == '4'  ? "Pending" : dealerdata.status == '5' ? "Blacklisted" : "Inactive"
                                                       
                                                    }
                                            </td> 
                                            <td>
                                                <div className="btn-group btn-group-small mrg-r10">

                                                    <Link to={`/dealer/${dealerdata.dealer_id_hash}/basic-details`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link>
                                                   
                                                </div>

                                            </td>
                                            {/* <td>
                                                <button className="btn" onClick={() => {this.handleHistory(dealerdata.id)}}>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</button>
                                            </td> */}
                                        </tr>
                                    )}
                                    {
                                        (this.state.loading) ? <tr><td className="loading" colSpan="6"></td></tr> : null
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                {this.state.blackListPopup == false ? null :
                    <ModalPopup id="black_list" className="modal" title={(parseInt(this.state.blackListUpdateData.status) == 5) ? this.props.t('Listing.Search_Result.Black_List_Dealer') : this.props.t('Listing.Search_Result.active_inactive_Dealer')} modalClose={this.closeModal}>
                        <BlackListPopup modalClose={this.closeModal} blackListUpdateData={this.state.blackListUpdateData} updateDealerListData={this.updateDealerListData} currentIndex={this.state.currentIndex} current_org_name={this.state.current_org_name} />
                    </ModalPopup>
                }

                {/* {this.state.historyPopup === false ? null : <ModalPopup id="view_history" className="modal ViewHistory data-table" title={'View History'} modalClose={this.closeHistoryModel}>
                    { this.state.histoy_dealer_id !== 0 &&  <ViewHistory histoy_dealer_id={this.state.histoy_dealer_id} />}
               </ModalPopup>} */}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(PartnerResults));
