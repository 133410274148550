import React, { Component } from 'react';
import Select from 'react-select';
import Button from '../../elements/Button';
import InputField from '../../elements/Input';
import RadioBox from '../../elements/Radiobox';
import InputContext from './InputContext';
import { toast } from 'react-toastify';
import MasterService from './../../../service/MasterService';

const property_usage = [
    { value: 'residential', label: 'Residential' },
    { value: 'commercial', label: 'Commercial' },
    { value: 'industrial', label: 'Industrial' },
];

const property_type = [
    { value: 'direct_allotment', label: 'Direct Allotment' },
    //{ value: 'fresh_purchase', label: 'Fresh Purchase' },
    { value: 'resale', label: 'Resale' },
    { value: 'allotment_transfer', label: 'Allotment Transfer' },
];

const property_sub_type = [
    { value: 'ready_to_move', label: 'Ready-to-move/ Completed' },
    { value: 'under_construction', label: 'Under Construction' },
    { value: 'plot', label: 'Plot' },
    { value: 'plot_construction', label: 'Plot + Construction' },
    { value: 'b_a_v', label: 'Bunglow/ Apartment/ Villa' },
    // { value: 'other', label: 'Other' },
];

const project_name = [
    { value: 'The Hermitage(Deemed)', label: 'The Hermitage(Deemed)' },
    { value: 'Zen Residence', label: 'Zen Residence' },
    { value: 'AIPL - The Peaceful Homes', label: 'AIPL - The Peaceful Homes' },
    { value: 'THE PEACEFUL HOMES ( DEEMED )', label: 'THE PEACEFUL HOMES ( DEEMED )' },
    { value: 'BOULEVARD', label: 'BOULEVARD' },
    { value: 'Runway', label: 'Runway' },
    { value: 'Arihant South Winds', label: 'Arihant South Winds' },
    { value: 'Arihant Abode', label: 'Arihant Abode' },
    { value: 'Arihant Ambar', label: 'Arihant Ambar' },
    { value: 'Bestech Park View Ananda (Deemed)', label: 'Bestech Park View Ananda (Deemed)' },
    { value: 'Bestech Park View Sanskruti', label: 'Bestech Park View Sanskruti' },
    { value: 'Conscient Heritage Max', label: 'Conscient Heritage Max' },
    { value: 'Skycourt (Deemed)', label: 'Skycourt (Deemed)' },
    { value: 'DLF CAPITAL GREENS', label: 'DLF CAPITAL GREENS' },
    { value: 'The Ultima (Deemed)', label: 'The Ultima (Deemed)' },
    { value: 'Live by the Greens', label: 'Live by the Greens' },
    { value: 'Palm Gardens', label: 'Palm Gardens' },
    { value: 'EMAAR "MARBELLA"', label: 'EMAAR "MARBELLA"' },
    { value: 'Experion Heart Song', label: 'Experion Heart Song' },
    { value: 'Gaursons Sportswood', label: 'Gaursons Sportswood' },
    { value: '16TH PARK VIEW', label: '16TH PARK VIEW' },
    { value: '2nd park View', label: '2nd park View' },
    { value: 'Gaur Siddhartham', label: 'Gaur Siddhartham' },
    { value: 'Gaur 7th Avenue', label: 'Gaur 7th Avenue' },
    { value: 'Gaur City 2 - 14th Avenue', label: 'Gaur City 2 - 14th Avenue' },
    { value: 'Gaur City Center', label: 'Gaur City Center' },
    { value: '2nd park View', label: '2nd park View' },
    { value: 'Gaur 11th Avenue', label: 'Gaur 11th Avenue' },
    { value: 'Gaur Saundaryam (deemed)', label: 'Gaur Saundaryam (deemed)' },
    { value: 'Kalpataru Vista', label: 'Kalpataru Vista' },
    { value: 'M3M Height', label: 'M3M Height' },
    { value: 'M3M Marina / Sierra / Natura', label: 'M3M Marina / Sierra / Natura' },
    { value: 'M3M Merlin(Deemed)', label: 'M3M Merlin(Deemed)' },
    { value: 'M3M Woodshire', label: 'M3M Woodshire' },
    { value: 'M3M Latitude(Deemed)', label: 'M3M Latitude(Deemed)' },
    { value: 'M3M Golf Estate', label: 'M3M Golf Estate' },
    { value: 'M3M Escala (Deemed)', label: 'M3M Escala (Deemed)' },
    { value: 'Mahindra Luminare', label: 'Mahindra Luminare' },
    { value: 'Mapsko Mountville', label: 'Mapsko Mountville' },
    { value: 'Microtek Greenburg(Deemed)', label: 'Microtek Greenburg(Deemed)' },
    { value: 'PARAS IRENE', label: 'PARAS IRENE' },
    { value: 'Puri Emerald Bay(Deemed)', label: 'Puri Emerald Bay(Deemed)' },
    { value: 'SHAPOORJI PALLONJI JOYVILLE', label: 'SHAPOORJI PALLONJI JOYVILLE' },
    { value: 'The Almeria (Deemed)', label: 'The Almeria (Deemed)' },
    { value: 'The Coralwood(Deemed)', label: 'The Coralwood(Deemed)' },
    { value: 'Stellar One Phase 1 ( Deemed)', label: 'Stellar One Phase 1 ( Deemed)' },
    { value: 'STELLAR JEEVAN', label: 'STELLAR JEEVAN' },
    { value: 'Platinum Tower', label: 'Platinum Tower' },
    { value: 'Tulip Ivory', label: 'Tulip Ivory' },
    { value: 'Ace Golfshire', label: 'Ace Golfshire' },
    { value: 'DLF Midtown', label: 'DLF Midtown' },
    { value: 'Amanvilas', label: 'Amanvilas' },
    { value: 'TATA La Vida', label: 'TATA La Vida' },
    { value: 'Global Heights 89', label: 'Global Heights 89' },
    { value: 'Tulip Monsella', label: 'Tulip Monsella' },
    { value: 'Independent Floors at DLF Alameda, Sec 73', label: 'Independent Floors at DLF Alameda, Sec 73' },
    { value: 'SS The Leaf (Phase 1)', label: 'SS The Leaf (Phase 1)' },
    { value: 'Puri The Aravallis', label: 'Puri The Aravallis' },
    { value: 'Linden Floors (Phase I and II)', label: 'Linden Floors (Phase I and II)' },
    { value: 'CENTRAL PARK FLOWER VALLEY (CENTRAL PARK III) - Mikasa Plots', label: 'CENTRAL PARK FLOWER VALLEY (CENTRAL PARK III) - Mikasa Plots' },
    { value: 'M3M Skywalk (Deemed)', label: 'M3M Skywalk (Deemed)' },
    { value: 'INDEPENDENT FLOORS DLF GARDEN CITY 91 / 92 PHASE-2A (Deemed)', label: 'INDEPENDENT FLOORS DLF GARDEN CITY 91 / 92 PHASE-2A (Deemed)' },
    { value: 'Sobha City Phase 1 (Deemed)', label: 'Sobha City Phase 1 (Deemed)' },
    { value: 'Birla Navya Anaika (Deemed)', label: 'Birla Navya Anaika (Deemed)' },
    { value: 'DLF SIRIS ESTATE (Deemed)', label: 'DLF SIRIS ESTATE (Deemed)' },
    { value: 'Adani Samsara & Samsara Vilasa (Deemed)', label: 'Adani Samsara & Samsara Vilasa (Deemed)' },
    { value: 'CENTRAL PARK FLOWER VALLEY (CENTRAL PARK III) Aqua Front Towers', label: 'CENTRAL PARK FLOWER VALLEY (CENTRAL PARK III) Aqua Front Towers' },
    { value: 'Bestech Altura(Deemed )', label: 'Bestech Altura(Deemed )' },
    { value: 'The Orchard by Central Park Group, CP III', label: 'The Orchard by Central Park Group, CP III' },
    { value: 'PARAS DEWS Sector 106 ( Deemed )', label: 'PARAS DEWS Sector 106 ( Deemed )' },
    { value: 'ELEVATE PHASE I', label: 'ELEVATE PHASE I' },
    { value: 'Paras Irene, Sector 70A', label: 'Paras Irene, Sector 70A' },
    { value: 'Cleo County( Deemed )', label: 'Cleo County( Deemed )' },
    { value: 'The Islands', label: 'The Islands' },
    { value: 'Ace Divino', label: 'Ace Divino' },
    { value: 'Bellavista Suites', label: 'Bellavista Suites' },
    { value: 'The Arbour', label: 'The Arbour' },
    { value: 'SS Cendana', label: 'SS Cendana' },
    { value: 'SLF Anushree Apartments', label: 'SLF Anushree Apartments' },
    { value: 'Sobha City Phase-IV(Deemed)', label: 'Sobha City Phase-IV(Deemed)' },
    { value: 'Sobha City Phase V( Deemed APF)', label: 'Sobha City Phase V( Deemed APF)' },
    { value: 'The Amaryllis Phase II', label: 'The Amaryllis Phase II' },
    { value: 'DLF One Midtown', label: 'DLF One Midtown' },
    { value: 'Sobha International City', label: 'Sobha International City' },
    { value: 'SUNCITY VATSAL VALLEY INDEPENDENT FLOORS 1 & 2', label: 'SUNCITY VATSAL VALLEY INDEPENDENT FLOORS 1 & 2' },
    { value: 'Urban Oasis - Phase 1 & 2', label: 'Urban Oasis - Phase 1 & 2' },
    { value: 'Tata La Vida - Phase 2', label: 'Tata La Vida - Phase 2' },
    { value: 'Independent Floors At DLF City Phase I & III', label: 'Independent Floors At DLF City Phase I & III' },
    { value: 'The Presidential', label: 'The Presidential' },
    { value: 'Ss Linden Residential Floors - Phase 1', label: 'Ss Linden Residential Floors - Phase 1' },
    { value: 'VVIP Mangal', label: 'VVIP Mangal' },
    { value: 'Hero Homes Tower 8', label: 'Hero Homes Tower 8' },
    { value: 'Elevate Reserve', label: 'Elevate Reserve' },
    { value: 'Tata La Vida - Phase 2', label: 'Tata La Vida - Phase 2' },
    { value: 'DLF Independent Floors at DLF City Phase I & III', label: 'DLF Independent Floors at DLF City Phase I & III' },
    { value: 'Elan The Presidential', label: 'Elan The Presidential' },
    { value: 'VVIP Mangal', label: 'VVIP Mangal' },
    { value: 'SS Linden Floors - Phase 1', label: 'SS Linden Floors - Phase 1' },
    { value: 'Godrej Aristocrat', label: 'Godrej Aristocrat' },
    { value: 'Godrej Woods Phase 2', label: 'Godrej Woods Phase 2' },
    { value: 'DLF Privana', label: 'DLF Privana' },
    { value: 'Other', label: 'Other' },
    // ... (add other projects in a similar way)
];

const digit1 = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
const digit2 = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];


class propertyProfile extends Component {
    static contextType = InputContext;

    constructor(props) {
        super(props);
        this.state = {
            property_decided: "No",
            inputValues: {
                property_city: '',
                property_usage: '',
                property_usage: null,
                property_type: null,
                cityAllList: [],
                // Add more input fields as needed
            },
        };
    }

    handleInputChange = (event, inputName) => {
        const { setInputValue } = this.context;
        const value = event.target.value;
        if(inputName=='property_decided'){
            this.setState({ property_decided: value });
        }
        setInputValue(inputName, value);
    };

    handleCitySelectChange = (selectedOption, inputName) => {
        const { setInputValue } = this.context;
        const value = selectedOption ? selectedOption.id : null;
        setInputValue(inputName, value);
        this.setState({ [inputName]: value });
    };

    handleSelectChange = (selectedOption, inputName) => {
        const { setInputValue } = this.context;
        const value = selectedOption ? selectedOption.value : null;
        setInputValue(inputName, value);
        this.setState({ [inputName]: value });
    };

    handlePriceInputChange = (event, inputName) => {
        const { setInputValue } = this.context;
        let inputValue = event.target.value;
        // Remove non-numeric characters
        inputValue = inputValue.replace(/[^0-9]/g, '');
        // Format to Indian currency
        inputValue = new Intl.NumberFormat('en-IN').format(inputValue);
        setInputValue(inputName, inputValue);
    };

    formatCurrency = (value) => {
        if (value) {
            // Format the raw numeric value for display
            const parts = value.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        }
    };


    navigateToBasicIncomeComponent = () => {
        const { inputValues } = this.context;
        //(inputValues);

        if (!inputValues.property_decided) {
            inputValues.property_decided ="No";
        }   

        if (!inputValues.property_decided) {
            toast.error('Please select property decided.'); return;
        }
        else if (!inputValues.property_city && inputValues.property_decided !== 'No') {
            toast.error('Please select property city.'); return;
        }
        else if (!inputValues.property_usage && inputValues.property_decided !== 'No') {
            toast.error('Please select property usage.'); return;
        }
        else if (!inputValues.property_type && inputValues.property_decided !== 'No') {
            toast.error('Please select property type.'); return;
        }
        else if (!inputValues.property_sub_type && inputValues.property_decided !== 'No') {
            toast.error('Please select property sub type.'); return;
        }

        this.props.history.push({
            pathname: '/admin/income-profile',
            state: { inputValues: this.state.inputValues },
        });
    };

    handleRadioChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            inputValues: {
                [name]: value,
            },
        }));
    };

    handleBackTab = () => {
        this.props.history.push('/admin/basic-requirement');
    };

    getCityList = async () => {
        var parentObj = this;

        var thisObj = this;
        await MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                thisObj.flag = false;
                if (response.data.status == 200) {
                    const customOrder = ["New Delhi","Gurgaon","Noida","Greater Noida","Bangalore", "Mumbai","Hyderabad"];
                    const sortedCities = response.data.data.city.sort((a, b) => {
                        const indexA = customOrder.indexOf(a.name);
                        const indexB = customOrder.indexOf(b.name);

                        if (indexA !== -1 && indexB !== -1) {
                            return indexA - indexB;
                        }
                        if (indexA !== -1) {
                            return -1;
                        }

                        if (indexB !== -1) {
                            return 1;
                        }
                        return 0;
                    });
                    //const cityOptions = sortedCities.map(({ id, name }) => ({ label: name, value: id }));
                    parentObj.setState({
                        cityAllList: sortedCities
                    })
                }
            })
            .catch(function (response) {
            });
    }

    componentDidMount = async () => {
        await this.getCityList();
    }

    convertToWords(num) {
        if ((num = num.toString()).length > 9) return 'overflow';
        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (digit1[Number(n[1])] || digit2[n[1][0]] + ' ' + digit1[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (digit1[Number(n[2])] || digit2[n[2][0]] + ' ' + digit1[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (digit1[Number(n[3])] || digit2[n[3][0]] + ' ' + digit1[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (digit1[Number(n[4])] || digit2[n[4][0]] + ' ' + digit1[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (digit1[Number(n[5])] || digit2[n[5][0]] + ' ' + digit1[n[5][1]]) + 'only ' : '';
        return str;
    }


    render() {
        const { inputValues } = this.context;
        //console.log(inputValues);
        if (inputValues.property_decided === 'No') {
            inputValues.expected_market_value = "";
            inputValues.project_name = "";
        }
        const cityArr = this.state.cityAllList;
        //console.log("cityArr", cityArr);
        return (
            <>
                <div className='card'>
                    <div className='card-body'>

                        <div className='property-card'>
                            <div className="card-new">
                                <div className="card-new-body">

                                    <div>
                                        <div className='mainheading'>Property Decided</div>
                                        <div className="rc-inline">
                                            <RadioBox type="radio"
                                                name="property_decided"
                                                id="property_yes"
                                                value="Yes"
                                                checked={inputValues.property_decided === 'Yes' ||  this.state.property_decided==='Yes'}
                                                onChange={(event) => this.handleInputChange(event, 'property_decided')}
                                                //onChange={this.handleRadioChange}
                                                label="Yes" />
                                            <RadioBox
                                                type="radio"
                                                name="property_decided"
                                                id="property_no"
                                                value="No"
                                                checked={inputValues.property_decided != 'Yes'}
                                                onChange={(event) => this.handleInputChange(event, 'property_decided')}
                                                //onChange={this.handleRadioChange}
                                                label="No" />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>Property City{inputValues.property_decided === 'Yes' && (
                                                <span style={{ color: "red" }}>*</span>
                                            )}</label>
                                            <Select
                                                id="property_city"
                                                onChange={(selectedOption) => this.handleCitySelectChange(selectedOption, 'property_city')}
                                                options={cityArr}
                                                name="property_city"
                                                placeholder="Enter City"
                                                value={cityArr != undefined ? cityArr.find(({ id }) => id === inputValues.property_city) : null}
                                                getOptionLabel={({ name }) => name}
                                                getOptionValue={({ id }) => id}
                                                label="Property City"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label>Property Usage
                                                {inputValues.property_decided === 'Yes' && (
                                                    <span style={{ color: "red" }}>*</span>
                                                )}
                                            </label>
                                            <Select
                                                id="property_usage"
                                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'property_usage')}
                                                options={property_usage}
                                                name="property_usage"
                                                placeholder="Select Property Usage"
                                                value={property_usage.find(({ value }) => value === inputValues.property_usage)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                                label="Property Usage"
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className="col-sm-6">
                                            {/* <InputField
                                                type="text"
                                                name="property_type"
                                                id="property_type"
                                                placeholder="INR"
                                                value={inputValues.property_type}
                                                onChange={(event) => this.handleInputChange(event, 'property_type')}
                                                label="Property Type" /> */}
                                            <label>Property Type
                                                {inputValues.property_decided === 'Yes' && (
                                                    <span style={{ color: "red" }}>*</span>
                                                )}
                                            </label>
                                            <Select
                                                id="property_type"
                                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'property_type')}
                                                options={property_type}
                                                name="property_type"
                                                placeholder="Select Property Type"
                                                value={property_type.find(({ value }) => value === inputValues.property_type)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                                label="Property Type"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label>Property Sub Type
                                                {inputValues.property_decided === 'Yes' && (
                                                    <span style={{ color: "red" }}>*</span>
                                                )}
                                            </label>
                                            <Select
                                                id="property_sub_type"
                                                onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'property_sub_type')}
                                                options={property_sub_type}
                                                name="property_sub_type"
                                                placeholder="Select Property Sub Type"
                                                value={property_sub_type.find(({ value }) => value === inputValues.property_sub_type)}
                                                getOptionLabel={({ label }) => label}
                                                getOptionValue={({ value }) => value}
                                                label="Property Sub Type"
                                            />
                                        </div>
                                    </div>
                                    {inputValues.property_decided === 'Yes' && (
                                        <div className="row" style={{ marginTop: 15 }}>
                                            <div className="col-sm-6">
                                                <label>Project Name</label>
                                                <Select
                                                    id="project_name"
                                                    onChange={(selectedOption) => this.handleSelectChange(selectedOption, 'project_name')}
                                                    options={project_name}
                                                    name="project_name"
                                                    placeholder="Select Project Name"
                                                    value={project_name.find(({ value }) => value === inputValues.project_name)}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                    label="Project Name"
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <InputField
                                                    type="text"
                                                    name="expected_market_value"
                                                    id="expected_market_value"
                                                    placeholder="INR"
                                                    autoComplete="off"
                                                    value={this.formatCurrency(inputValues.expected_market_value)}
                                                    onChange={(event) => this.handlePriceInputChange(event, 'expected_market_value')}
                                                    label="Expected Market Value" />
                                                {inputValues.expected_market_value && (
                                                    <div className="amount-in-words">
                                                        <span>{this.convertToWords(inputValues.expected_market_value.replace(/[^\d.]/g, ''))}</span>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    )}
                                    <div className="row">
                                        <div className=''>

                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 15 }}>
                                        <div className='propertybtn'>
                                            <Button btnClass="btn btn-primary" onClick={this.handleBackTab}>Back</Button>
                                            <span style={{ marginRight: '10px' }} />
                                            <Button btnClass="btn btn-primary" onClick={this.navigateToBasicIncomeComponent} title="Next"></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default propertyProfile;
//export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(propertyProfile));
