import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQuery";
import { FINEX_MASTER } from "../../queries/scheme.gql";
import FileInput from "../NewLogin/Components/Input/FileInput";
import { fileUploader } from "../Utility/user.utility";
import { toast } from "react-toastify";

const BankDetailsProfile = (props) => {
  const error = props.errors;
  const getOnChangeValue = props.getOnChangeValue
  const { kyc_save_value, set_kyc_save_value } = props; 
  const { dealer_hash_id, HandleKycData, bankDetails } = props;
  const kyc_save_value_change = props.kyc_save_value
  const [bankList, setBankList] = useState([]);
  const [fileUploadArr, setFileUploadArr] = useState([]);
  const [checque, setChecque] = useState(props.docsImages.cancel_checque);
  const [formData, setFormData] = useState({
    beneficiary_name: bankDetails?.beneficiary_name || "",
    account_number: bankDetails?.bank_account_no || "",
    ifsc_code: bankDetails?.bank_ifsc_code || "",
    upi_id: bankDetails?.upi_id || "",
    cancel_checque: "",
    bank_name: bankDetails?.bank_id || "",
  });



  useEffect(() => {
    const getBankList = async () => {
      try {
        const masterData = await executeGraphQLQueryFinex(FINEX_MASTER());
        setBankList(masterData.data.masterdata.bank_list);
      } catch (err) {
        console.error(err);
      }
    };

    getBankList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const capitalizedValue = value.toUpperCase();

    if (name === "ifsc_code") {
      setFormData((prev) => ({
        ...prev,
        [name]: value.toUpperCase(),
      }));
    } else if (name === "account_number") {
      const numericValue = value.replace(/\D/g, '');
      setFormData((prev) => ({
        ...prev,
        [name]: numericValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    set_kyc_save_value((prev) => ({
      ...prev,
      [name]: capitalizedValue
    }));

  };
  getOnChangeValue(kyc_save_value)


  useEffect(() => {
    HandleKycData(formData, fileUploadArr, checque);
  }, [formData, fileUploadArr, HandleKycData]);

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      bank_name: selectedOption ? selectedOption.bank_id : "",
    }));
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      try {
        const response = await fileUploader(files[0]);
        set_kyc_save_value((prev) => ({
          ...prev,
          [name]: response.file_url
        }));
        const kycMasterIds = {
          cancel_checque: 4,
        };

       
          setFileUploadArr((prevState) => [
            ...prevState,
            {
              dealer_id_hash: dealer_hash_id,
              kyc_master_id: 4,
              file_name: response.file_name,
              file_url: response.file_url,
              verified_status: "1",
            },
          ]);

        setChecque(response.file_url);
        setFormData((prev) => ({
          ...prev,
          [name]: response.file_url,
        }));
      } catch (err) {
        toast.error("Failed to upload file");
        console.error(err);
      }
    }
  };

  let bankOptions = [];
  if (bankList.length > 0) {
    bankOptions = bankList.map(({ banklang }) => ({
      bank_id: banklang.bank_id,
      bank_name: bankDetails?.bank_name ? bankDetails?.bank_name : banklang.bank_name,
    }));
  }

  const selectedBankValue = 
    bankOptions.find(({ bank_id }) => bank_id === Number(formData?.bank_name)) || null;

  return (
    <div className="myaccountpadding">
      <h6 className="kycheadingmain">Bank Details</h6>
      <div>
        Bank details are critical to ensure payouts are done to the correct account
      </div>

      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-md-4">
          <InputField
            type="text"
            name="beneficiary_name"
            id="beneficiary_name"
            placeholder="Beneficiary Name"
            label="Beneficiary Name"
            value={kyc_save_value_change.beneficiary_name ? kyc_save_value_change.beneficiary_name : formData?.beneficiary_name}
            onChange={handleChange}
            required={true}
            error={error.beneficiary_name}
          />
        </div>
        <div className="col-md-4">
          <InputField
            type="text"
            name="account_number"
            id="account_number"
            placeholder="Account Number"
            label="Account Number"
            value={kyc_save_value_change.account_number ? kyc_save_value_change.account_number :formData.account_number}
            onChange={handleChange}
            required={true}
            error={error.account_number}
          />
        </div>

        <div className="col-md-4">
          <label>Bank Name <a style={{ color: 'red' }}>*</a></label>
          <Select
            id="bank_name"
            name="bank_name"
            options={bankOptions}
            value={selectedBankValue}
            getOptionLabel={({ bank_name }) => bank_name}
            getOptionValue={({ bank_id }) => bank_id}
            onChange={handleSelectChange}
            styles={{
              control: (base) => ({
                ...base,
                borderColor: error.bank_name ? 'red' : base.borderColor,
              }),
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <InputField
            type="text"
            name="ifsc_code"
            id="ifsc_code"
            placeholder="IFSC Code"
            label="IFSC Code"
            value={kyc_save_value_change.ifsc_code ? kyc_save_value_change.ifsc_code :formData.ifsc_code}
            onChange={handleChange}
            required={true}
            error={error.ifsc_code}
          />
        </div>
        <div className="col-md-4">
          <InputField
            type="text"
            name="upi_id"
            id="upi_id"
            placeholder="UPI ID"
            label="UPI ID"
            value={kyc_save_value_change.upi_id ? kyc_save_value_change.upi_id :formData.upi_id}
            onChange={handleChange}
            // error={error.upi_id}
          />
        </div>
        <div className="col-md-4">
          <label>Upload Cancel Cheque</label>
          <FileInput
            name="cancel_Checque"
            id="cancel_Checque"
            placeholder="Cancel Cheque"
            onChange={handleFileChange}
            fileUrl={kyc_save_value.cancel_Checque ? kyc_save_value.cancel_Checque : checque}
            required={true}
            error={error.cancel_checque}
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetailsProfile;
