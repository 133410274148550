import React, { useState } from "react";
import { components } from 'react-select'
const { ValueContainer, Option } = components;
export const MultiSelectValueContainer = ({ children, ...props }) => {
    let [values, input] = children;
    if (Array.isArray(values)) {
        if (values.some(v => v.key.includes("all"))) {
            values = `All selected (${values.length - 1})`;
        } else {
            values = `${values.length} selected`;
        }
    }
    return (
        props?.selectProps?.menuIsOpen ? (
            <ValueContainer {...props}>
                {input}
            </ValueContainer>
        ) : (
            <ValueContainer {...props}>
                {values}
                {input}
            </ValueContainer>
        )
    );
};
export const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive || isSelected) bg = "#B2D4FF";
    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };
    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };
    return (
        <Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} style={{ marginRight: "5px" }} onChange={() => { }} />
            {children}
        </Option>
    );
};