import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Add_PARTNER_SCHEME, PARTNER_SCHEME_LIST, SCHEME_LIST,SCHEME_LIST_WITH_FILTERS } from "../../queries/scheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import DateFormate from 'dateformat';

const PartnerSchemeAdd = (props) => {
   
    const dealer_id = props.dealer_id;
    //console.log("dealer_id",dealer_id)
    const [formData, setFormData] = useState({"scheme_id":0});
    const [PartnerScheme, setPartnerScheme] = useState([]);
    const [schemeList, setschemeList] = useState([]);


    const handleInput = (e, key = "") => {
        const name = e.target ? e.target.name : key;
        const val = e.target ? e.target.la : e.label;
        setFormData({ ...formData, ["scheme_id"]: val })
    }

   
    const getSchemeList = async () => {
        const partnerArray = []
        const schemeData = await executeGraphQLQuery(SCHEME_LIST);
        const data = schemeData.data.getAllSchemsList
        data && data.map((list) => {
            const partnerData = {
                label: list.id,
                value: list.scheme_name,
            }
            partnerArray.push(partnerData)
        })
        setschemeList(partnerArray);
    }

    useEffect(() => {
        getSchemeList()
    }, []);

    const PartnerAddScheme = async () => {
        if(!formData.scheme_id){
            alert("Please select Scheme!")
        }
        const variables = {
            schemePartnerInputArgs: {
                scheme_id: formData.scheme_id,
                partner_id: props.dealer_id,
                status: 1,
            },
        };
        try {
            const result = await executeGraphQLMutation(Add_PARTNER_SCHEME, variables);
            if (result?.data?.saveSchemePartner?.status=='true') {
                toast.success(result?.data?.saveSchemePartner?.msg || "Partner Scheme Added Successfully ");
                getPatnerSchemeList()
            }else{
                toast.error(result?.data?.saveSchemePartner?.msg || "Something went wrong! "); 
            }
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        }
    }

    useEffect(() => {
        if(props.dealer_id){
            console.log(props.dealer_id)
        }
     
        if(props.dealer_id){
            getPatnerSchemeList(props.dealer_id)
        }
    }, []);

    const getPatnerSchemeList = async (partner_id) => {
        const schemeData = await executeGraphQLQuery(PARTNER_SCHEME_LIST(partner_id));
        const data = schemeData.data.getSchemesByPartnerId
        console.log("schemeData", data)
        setPartnerScheme(data);
    }

    return (
        <>

            <div className="card">
                {/* <h3>Add Scheme</h3> */}
                <div className="search-wrap">
                    <div className="row">
                        <div className="col-sm-4 col-md-4">
                            <label>{('Partner Scheme')}</label>
                            <Select
                                id="Select Scheme"
                                onChange={(e) => handleInput(e, 'partnerscheme')}
                                options={schemeList}
                                name="partnerscheme"
                                label = "Select Scheme"
                                // value={schemeList.filter(({ value }) => value === formData.partnerscheme)}
                                getOptionLabel={({ value }) => value}
                                getOptionValue={({ label }) => label}
                            />
 
                        </div>
                        <div className="text-left col-sm-4 col-md-4">
                        <label> &nbsp;</label>
                    <button style={{ marginBottom: 10, marginTop: 20 }}
                            onClick={(e) => PartnerAddScheme(e)}
                            className="btn btn-primary"><b>Save</b></button>
                       
                    </div>
                    </div>

                    <h1>Subscribed Schemes</h1>
                <div className="search-wrap">
                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{('Scheme')}</th>
                                        <th>{('Scheme Type')}</th>
                                        <th>{('Scheme For')}</th>
                                        <th>{('Start Date')}</th>
                                        <th>{('End Date')}</th>
                                        <th>{('Subscribed At')}</th>
                                        <th>{('Status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {JSON.stringify()}
                                    {PartnerScheme && PartnerScheme.map((partner) =>

                                        <tr key={partner.scheme_code}>
                                            <td><a href={"/scheme/"+partner.scheme_code+"/details"} target="_blank">{partner.scheme_name !== null ? partner.scheme_name : ''}</a></td>
                                            <td>{partner.scheme_type !== null ? partner.scheme_type : ''}</td>
                                            <td>{partner.scheme_for !== null ? partner.scheme_for : ''}</td>
                                            <td>{partner.start_date !== null ? DateFormate(partner.start_date, 'dS mmm yyyy') : ''}</td>
                                            <td>{partner.end_date !== null ? DateFormate(partner.end_date, 'dS mmm yyyy') : ''}</td>
                                            <td>{partner.schemepartner  &&  partner.schemepartner.length>0 ? DateFormate(partner.schemepartner[0].created_at, 'dS mmm yyyy') : ''}</td>
                                            <td>{partner.status == '1' ? "Active" : 'In-active'}</td>

                                            {/* <td>{DateFormate(new Date(partner.end_date),"yyyy-mm-dd")}</td>
                                        <td>{DateFormate(new Date(partner.end_date), 'dS mmm yyyy')}</td> */}
                                         
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
                    
                </div>
            </div>

            <div className="container-fluid">
                
            </div>

        </>
    )
}


export default PartnerSchemeAdd