import React, { Component } from 'react';
import InputField from '../elements/InputField';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MasterService from '../../service/MasterService';
import { MOBILE_VALIDATION } from '../../config/constant';
import { CONF_VAL } from '../../config/constant';
import OtpDeleteValidate from './otpDeleteValidate';


class OtpLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showotpFrm: false,
            thankyou: false,
            errors: {},
            fields: {},
            redirect: 0,
            countryCodeList: [],
            reloadCaptcha: 1,
            fields: { mobileCountryCode: "IN", captchaVal: "", captchaId: '' },
        }
    }

    handleChange = event => {
        let fields = { ...this.state.fields };
        const errors = { ...this.state.errors };
        let name = '', value = '';
        if (event.label) {
            value = event.value
            name = 'mobileCountryCode'
        } else {
            name = event.target.name
            value = event.target.value;
        }
        fields[name] = value;
        if (value !== '') {
            delete errors[name];
        } else {
            errors[name] = 'Please enter ' + name;
        }
        this.setState({ fields: fields, errors: errors }, () => {

        });

        if (event.target.name === "mobile") {
            const mobile = event.target.value
            let validateMobNum = /^[6-9]\d{9}$/gi;
            if (validateMobNum.test(mobile.toString())) {
                errors['mobile'] = "";
            }
            else {
                errors['mobile'] = "Please enter valid mobile number starting with 6";
            }
        }
    }

    handleChangeEvent = event => {
        let fields = { ...this.state.fields };
        const errors = { ...this.state.errors };
        errors['captchaErr'] = '';
        errors['captchaErrorMessage'] = '';
        if (event && event.captchaData && event.captchaData.status == 200) {
            fields["captchaVal"] = event.captchaVal;
            fields['captchaId'] = event.captchaData.data.value;
            if (event.captchaVal == '') {
                errors['captchaErr'] = 'Please enter valid captcha';
            }
        } else {
            fields[event.target.name] = event.target.value;
            if (event.target.value !== '') {
                delete errors[event.target.name];
            } else {
                errors[event.target.name] = 'Please enter ' + event.target.name;
            }
        }
        this.setState({ fields: fields, errors: errors });
        console.log('fields', this.state.fields)
    }


    submitOtpForm = (event) => {

        var parentObj = this;
        let { captchaVal, captchaId, ...sendData } = this.state.fields;
        event.preventDefault();
        if (this.validForm()) {
            MasterService.post('account/user/send_otp', sendData)
                .then(function (response) {
                    if (response.status == 200 && response.data.status == 200) {
                        let responseData = response.data;
                        parentObj.setState({ showotpFrm: true });
                    } else {
                        window.commonErrorToast(response.data.message);
                    }
                }).catch(function (error) {
                    window.commonErrorToast(error.message);
                });
        }
    }

    validForm = () => {
        let fields = { ...this.state.fields };
        let errors = { ...this.state.errors };
        let formIsValid = true;
        let mobileRule = MOBILE_VALIDATION;
        if (!fields["mobile"]) {
            errors['mobile'] = this.props.t('joinus.enter_mobile');
            formIsValid = false;
        } else if (!mobileRule.test(fields['mobile'])) {
            errors['mobile'] = this.props.t('joinus.' + CONF_VAL.VALID_MOBILE_MSG);
            formIsValid = false;
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    confirmandDelete = (event) => {
        this.validateOtpAndLogin(event);
    };

    deleteApiAccount = async (dealer_id, user_id, headers) => {
        let errors = { ...this.state.errors };
        var parentObj = this;
        MasterService.post('dealer/dealer/deleteaccount', { dealer_id: dealer_id, user_id: user_id }, headers)
            .then((result) => {
                if (result && result.data && result.data.status === 200) {
                    parentObj.setState({ thankyou: true });
                }
            })
    };

    validateOtpAndLogin = (event) => {
        var parentObj = this;
        let errors = { ...this.state.errors };
        event.preventDefault();
        if (this.validOtpForm()) {
            let postData = Object.assign(this.state.fields);
            //delete postData.mobileCountryCode;
            MasterService.post('account/user/login-otp', postData)
                .then(function (response) {
                    const confirmstatus = window.confirm('Do you want to delete your account')
                    if (response.status == 200 && response.data.status == 200) {
                        if (confirmstatus === true) {
                            const responsedata = response?.data?.data;
                            let dealer_id = responsedata.dealer_data && responsedata.dealer_data.length>0 && responsedata.dealer_data[0]?.dealer_id ? responsedata.dealer_data[0]?.dealer_id :0;
                            let user_id = responsedata.user_data?.user_id;
                            if(dealer_id && dealer_id>0 ){
                                let user_id = responsedata.user_data?.user_id;
                                let headers = {};
                                headers['Authorization'] = responsedata.token;
                                parentObj.deleteApiAccount(dealer_id, user_id, headers);
                             }else{
                                alert("Partner already deleted!")
                                parentObj.setState({ thankyou: false });
                             }
                          
                        } else {
                            parentObj.setState({ thankyou: false });
                        }
                    } else {
                        window.commonErrorToast(response.data.message);
                        if (response.data.message === 'Invalid Captcha' || response.data.message === 'Wrong Captcha') {
                            errors['captchaErr'] = response.data.message;
                            errors['captchaErrorMessage'] = response.data.message;
                        } else {
                            errors['otp'] = response.data.message;
                        }
                        parentObj.setState({ errors: errors, reloadCaptcha: !(parentObj.state.reloadCaptcha), fields: { ...parentObj.state.fields, captchaVal: '' } })
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        }
    }


    validOtpForm = () => {
        let fields = { ...this.state.fields };
        let errors = { ...this.state.errors };
        let formIsValid = true;
        if (!fields["otp"]) {
            errors['otp'] = this.props.t('joinus.enter_otp');
            formIsValid = false;
        }
        if (errors["captchaErr"] || (!fields["captchaVal"])) {
            if (!fields["captchaVal"]) {
                errors['captchaErr'] = 'Captcha is required';
                errors['captchaErrorMessage'] = 'Captcha is required';
            } else if (errors["captchaErr"]) {
                errors['captchaErr'] = 'Please enter valid captcha';
                errors['captchaErrorMessage'] = 'Please enter valid captcha';
                this.setState({ reloadCaptcha: !(this.state.reloadCaptcha) });
            }
            formIsValid = false;
        }
        this.setState({ errors: errors })
        return formIsValid;
    }
    thankyou = () => {

    }

    render() {

        const { errors, countryCodeList } = this.state;
        return (
            <div id="otp-forms" style={{ width: '420px' }}>
                {this.state.showotpFrm === false ?
                    <form method="post" id="otp-form1" className=" text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                        <div className="phonewithcountry">

                            <div className="countrymobile">
                                <InputField
                                    inputProps={{
                                        id: "mobile",
                                        type: "tel",
                                        name: "mobile",
                                        maxLength: 10,
                                        label: this.props.t('login.mobile'),
                                        dataerror: errors.mobile
                                    }}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary btn-lg " id="send-otp" onClick={this.submitOtpForm}>{this.props.t('login.get_otp')}</button>

                    </form>
                    :
                    <>
                        {this.state.thankyou === false ? <OtpDeleteValidate errors={this.state.errors} onClick={this.validateOtpAndLogin} onChange={this.handleChangeEvent} reloadCaptcha={this.state.reloadCaptcha} /> : ""}
                    </>}
                {this.state.thankyou ? (
                    <div style={{ padding: 30, background: "white", alignItems: "center", display: "flex", flexDirection: "column", borderRadius: 20, textAlign: "center" }}>
                        <img src={require('../login/assets/images/thumbs.png')} alt='thumbs' width={100} height={100} />
                        <h6 style={{ marginTop: 20 }}>Thank you for your account deletion request</h6>
                        <p style={{ alignItems: "center", display: "flex" }}>We are working on it. We will update you once your request is processed</p>
                    </div>
                ) : ""}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
        user_id: state.user_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(OtpLogin));
