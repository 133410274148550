import React from "react";
//import ReactStoreIndicator from "react-score-indicator";

const CibilHistoryDetails = (props) => {

    const handleModalClose = () => {
        props.setModalVisiblity();
      }
      
    const showBankOffer = () => {
        props.onPress();
        handleModalClose();
    }

  const getColor = (value) => {
    if (value <= 400) {
      return "red";
    } else if (value <= 700) {
      return "yellow";
    } else {
      return "#6E55B8";
    }
  };

  const getText = (value) => {
    if (value <= 400) {
      return "Low";
    } else if (value <= 700) {
      return "Medium";
    } else {
      return "Very Good";
    }
  };

  return (
    <>
      <div>
        <div className="cibilpoweredby">
          <div>Credit Report Details</div>
          <div className="powerby">
            Powered by <span>Cibil</span>
          </div>
        </div>
      </div>
      <div className="offerpccard">
        <div className="scorecard">
          <div className="scoreflex"></div>
          <div className="cibilscorebox">
            {/* <ReactStoreIndicator
              value={700}
              minValue={300}
              maxValue={900}
              lineWidth={20}
              lineGap={0}
            /> */}
            <div className="cibilscore">
              <div
                style={{
                  fontSize: 32,
                  fontWeight: 600,
                  color: getColor(),
                }}
              >
                <span>700</span>
              </div>
              <div style={{ fontSize: 18, color: getColor() }}>{getText()}</div>
            </div>
            <div style={{ textAlign: "center" }}>
              <span style={{ marginRight: 100 }}>{300}</span>
              <span>{900}</span>
            </div>
          </div>
          <div className="cibilupdated">
            <span>Last Updated : </span>
            <span> 12/04/2024</span>
            {/* <span> {scoreData.last_update_date}</span> */}
          </div>
        </div>

        <div className="goodofferhidemobile">
          <h1 className="goodofferheading">
            You are doing
            {/* {scoreData.cibil_label}! */}
          </h1>
          <p className="goodoffersubheading">
            Your credit score is
            {/* {scoreData.cibil_label} */}
            and therefore you deserve to get the best. Scroll down to choose
            from attractive Pre-Approved loan and credit card offers.
          </p>
        </div>

        <div className="scorebox">
          <div className="viewreportsflex">
            <h3 className="cibilscoreheading">Credit Score Insights</h3>
            <div className="offerhidemobile">
              <div className="viewreport">
              <img
                src={require(`../../../src/saathi-web/images/cibilreporticon.svg`)}
                alt="thankyou"
                width={20}
                height={20}
              />
                <div className="reportshow">View Reports</div>
              </div>
            </div>
          </div>
          <div>
            <div className="scoreimpact">High Impact</div>
            <div className="cibilhistory">
              <div className="cibilicon">
                <div className="cibilhighscore">
                <img
                src={require(`../../../src/saathi-web/images/cibilhistory.svg`)}
                alt="thankyou"
                width={30}
                height={30}
                />
                </div>
                <div>
                  <div className="cibilstatus">
                    <div>Repayment History</div>
                    {/* {scoreData.repayment_history.title} */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>Never miss due date</div>
                    {/* {scoreData.repayment_history.sub_title} */}
                  </div>
                </div>
              </div>

              <div>
                <div className="cibilcolorper">
                  <div>100%</div>
                  {/* {scoreData.repayment_history.value}% */}
                </div>
                <div className="cibilsubstatus">
                  <div>Excellent</div>
                  {/* {scoreData.repayment_history.value_label} */}
                </div>
              </div>
            </div>
            <div className="cibilhistory">
              <div className="cibilicon">
                <div className="cibilhighscore">
                <img
                src={require(`../../../src/saathi-web/images/cibilhistorytwo.svg`)}
                alt="thankyou"
                width={30}
                height={30}
              />
                </div>
                <div>
                  <div className="cibilstatus">
                    <div>Credit Utilisation</div>
                    {/* {scoreData.credit_utilization.title} */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>Keep outstanding 30%</div>
                    {/* {scoreData.credit_utilization.sub_title} */}
                  </div>
                </div>
              </div>

              <div>
                <div className="cibilcolorper">
                  <div>28.5%</div>
                  {/* {scoreData.credit_utilization.value}% */}
                </div>
                <div className="cibilsubstatus">
                  <div>Very Good</div>
                  {/* {scoreData.credit_utilization.value_label} */}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="scoreimpact">Medium Impact</div>
            <div className="cibilhistory">
              <div className="cibilicon">
                <div className="cibilhighscore">
                <img
                src={require(`../../../src/saathi-web/images/cibilhistory.svg`)}
                alt="thankyou"
                width={30}
                height={30}
              />
                </div>
                <div>
                  <div className="cibilstatus">
                    <div>Credit Age</div>
                    {/* {scoreData.credit_age.title} */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>above 5 years is excellent</div>
                    {/* {scoreData.credit_age.sub_title} */}
                  </div>
                </div>
              </div>

              <div>
                <div className="cibilcolorper">
                  <div>4y 8m</div>
                  {/* {scoreData.credit_age.value} */}
                </div>
                <div className="cibilsubstatus">
                  <div>Very Good</div>
                  {/* {scoreData.credit_age.value_label} */}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="scoreimpact">Low Impact</div>
            <div className="cibilhistory">
              <div className="cibilicon">
                <div className="cibilhighscore">
                <img
                src={require(`../../../src/saathi-web/images/cibilhistory.svg`)}
                alt="thankyou"
                width={30}
                height={30}
              />
                </div>
                <div>
                  <div className="cibilstatus">
                    <div>Credit Enquiries</div>
                    {/* {scoreData.credit_age.title} */}
                  </div>
                  <div className="cibilsubstatus">
                    <div>Avoid too many enquiries</div>
                    {/* {scoreData.credit_age.sub_title} */}
                  </div>
                </div>
              </div>

              <div>
                <div className="cibilcolorper">
                  <div>3</div>
                  {/* {scoreData.credit_age.value} */}
                </div>
                <div className="cibilsubstatus">
                  <div>Very Good</div>
                  {/* {scoreData.credit_age.value_label} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="newleadbtnflex">
        <button onClick={showBankOffer} className="submitbtnnewlead">Check Loan Offers</button>
      </div>
    </>
  );
};

export default CibilHistoryDetails;
