import React, { useContext, useEffect, useState } from "react";
import RadioBox from "../../../../view/elements/Radiobox";
import InputField from "../../../../view/elements/Input";
import { PartnerOnBordingContext } from "../../../PartnerContextApi/PartnerOnBordingContext";
import FileInput from "../Input/FileInput";
import { fileUploader } from "../../../Utility/user.utility";
import { isFileTypeAllowed } from "../../../Utility/file.utility";

export const CompanyKycComponent = () => {
  const { kycData, setKycData, isLoading, setIsLoading, handleOnChange } = useContext(PartnerOnBordingContext);
  const [selectedGstOption, setSelectedGstOption] = useState("");
  const [selectedMsmeOption, setSelectedMsmeOption] = useState("");
  const [isGst, setIsGst] = useState(false);
  const [isMsme, setIsMsme] = useState(false);
  const [gstDoc, setGstDoc] = useState({});
  const [msmeDoc, setMsmeDoc] = useState({});

  useEffect(() => {
    if (gstDoc.file) {
      handleFileUpload(gstDoc.file);
    } else if (msmeDoc.file) {
      handleFileUpload(msmeDoc.file);
    }
  }, [gstDoc.file, msmeDoc.file]);

  const handleGstChange = (event) => {
    setSelectedGstOption(event.target.value);
  };

  const handleMsmeChange = (event) => {
    setSelectedMsmeOption(event.target.value);
  };

  const handleFileChange = (e) => {
    const {id='', files=[]} = e?.target;
    if (files && !isFileTypeAllowed(files[0])) {
      return;
    }
     if (id === 'gst_certificate') {
       setGstDoc((prevState) => ({
        ...prevState,
        file: files[0],
      }));
     setIsGst(true);
    } else if(id === 'msme_certificate') {
      setMsmeDoc((prevState) => ({
        ...prevState,
        file: files[0],
      }));
     setIsMsme(true);
    }
  };

  const handleFileUpload = async (file) => {
    setIsLoading(true);
    try {
      const response = await fileUploader(file);
      if(isGst) {
        setGstDoc((prevState) => ({
          ...prevState,
          file_name : response?.file_name,
          file_url : response?.file_url,
        }));
        setKycData((prevState) => ({
          ...prevState,
          docs : [...prevState.docs, {
            kyc_master_id: 5,
            file_name: response?.file_name,
            file_url: response?.file_url,
          }]
        }))
        setIsGst(false);
      } else if(isMsme) {
        setMsmeDoc((prevState) => ({
          ...prevState,
          file_name : response?.file_name,
          file_url : response?.file_url,
        }));
        setKycData((prevState) => ({
          ...prevState,
          docs : [...prevState.docs, {
            kyc_master_id: 7,
            file_name: response?.file_name,
            file_url: response?.file_url,
          }]
        }))
        setIsMsme(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="kycinfooverflow">
      <div>
        <div className="flexgst">
          <div>GST Certificate</div>
          <div className="myteamsflex">
            <div style={{ marginRight: 10 }}>
              <RadioBox
                type="radio"
                name="gst_certificate"
                id="gst_yes"
                value="yes"
                label="Yes"
                checked={selectedGstOption === "yes"}
                onChange={handleGstChange}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <RadioBox
                type="radio"
                name="gst_certificate"
                id="gst_no"
                value="no"
                label="No"
                checked={selectedGstOption === "no"}
                onChange={handleGstChange}
              />
            </div>
          </div>
        </div>
        {selectedGstOption === "yes" && (
          <>
            <div>
              <div col-md-12>
                <InputField
                  type="text"
                  name="gst_number"
                  id="gst_number"
                  placeholder=" "
                  is_label_required="true"
                  value={kycData?.gst_number}
                  onChange={handleOnChange}
                />
              </div>
              <FileInput
                name="gst_certificate"
                id="gst_certificate"
                placeholder={gstDoc?.file_name ?? "GST Certificate"}
                onChange={handleFileChange}
                file={gstDoc.file}
                fileUrl={gstDoc.file_url}
              />
            </div>
          </>
        )}
      </div>
      <div>
        <div className="flexgst">
          <div>MSME Certificate</div>

          <div className="myteamsflex">
            <div style={{ marginRight: 10 }}>
              <RadioBox
                type="radio"
                name="msme_certificate"
                id="msme_yes"
                value="yes"
                label="Yes"
                checked={selectedMsmeOption === "yes"}
                onChange={handleMsmeChange}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <RadioBox
                type="radio"
                name="msme_certificate"
                id="msme_no"
                value="no"
                label="No"
                checked={selectedMsmeOption === "no"}
                onChange={handleMsmeChange}
              />
            </div>
          </div>
        </div>
        {selectedMsmeOption === "yes" && (
          <>
            <div>
              <div col-md-12>
                <InputField
                  type="text"
                  name="msme_number"
                  id="msme_number"
                  placeholder=" "
                  is_label_required="true"
                  value={kycData?.msme_number}
                  onChange={handleOnChange}
                />
              </div>
              <FileInput
                name="msme_certificate"
                id="msme_certificate"
                placeholder={msmeDoc?.file_name ?? "MSME Certificate"}
                onChange={handleFileChange}
                file={msmeDoc?.file}
                fileUrl={msmeDoc.file_url}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
