import React, { useEffect, useState } from "react";
import GeneralService from "../../service/generalService";
import { ASSIGN_LEAD_CSV, LEAD_ASSIGN } from "../../queries/sangam/create.gql";
import { toast } from "react-toastify";
import { executeGraphQLQueryFinex, executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";

const ReAssignLeadPopup = ({ masterData, loginUserInfo,selectedLeads }) => {
  const [assignTo, setAssignTo] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const fetchAssignToList = async () => {      
      try {
        const response = await GeneralService.subPartnerList(loginUserInfo.user_data.dealer_id);     
        if (response?.data?.status === 200) {               
          setAssignTo(response?.data?.data?.sub_partner_list || []);       
        }
      } catch (error) {
        console.error("Error fetching assignTo list:", error);
      }
    };
    fetchAssignToList();    
  }, [loginUserInfo.user_data.dealer_id]);


  const handleAssignLeadSubmit = async (event) => {
    const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
    
    if(selectedLeads.length > 0) {
      let assignlead = selectedLeads.map(lead => lead);
      
      const variables = {
        partner_id: user_info?.dealer_id,
        api_called_by: "saathi_web",
        assigned_partner_id: Number(selectedOption),
        lead_ids: assignlead,
      };
      
      try {
        const response = await executeGraphQLMutationFinex(LEAD_ASSIGN, variables);
        const message = response?.data?.assign_new_partner?.message;
  
        if (message) {
          toast.success(message);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          throw new Error("Something went wrong");
        }
      } catch (err) {
        console.log(err.message);
        toast.error('Got invalid value. Please check');
      }
    } else {
      toast.error('No leads selected.');
    }
  };

  const handleTypeChange = (event) => {
    const { id, value } = event.target;
    setSelectedOption(value);
  }


  return (
    <>
      <h4>My Teams</h4>
      <div className="reassigntableheight">
        <div className="table-container">
          <table>
            <thead className="tablesticky">
              <tr>
                <th></th>
                <th>ID No.</th>
                <th>Name</th>
                <th>Designation</th>
                {/* <th>Phone Number</th> */}
              </tr>
            </thead>
            <tbody>
            {assignTo
                .filter(lead => lead.user_type === 'team_member')
                .map((lead, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="re_assign_id"
                        id={`re_assign_${index}`}
                        value={lead.sub_partner_id}
                        onChange={handleTypeChange}
                      />
                    </td>
                    <td>{lead.sub_partner_id}</td>
                    <td>{lead.sub_partner_name}</td>
                    <td>{lead.designation}</td>
                    {/* <td>{lead.phoneNumber}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="newleadbtnflex">
        <button className="submitbtnnewlead"  onClick={handleAssignLeadSubmit} >Assign Lead</button>
      </div>
    </>
  );
};

export default ReAssignLeadPopup;
