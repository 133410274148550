import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import InputField from '../elements/Input';
import RadioBox from './../elements/Radiobox';
import CheckBox from './../elements/CheckBox';
import Button from '../elements/Button';
import Accordion from './../elements/Accordion';
const role = [
    { value: '125', label: 'Owner' },
    { value: '227', label: 'Dealer' },

];


class EmployeeDetails extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            role: "",
            startDate: new Date()
        };
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div><h1>Contact Setting (Saroj Sahoo - GCD1069)</h1>
            <div className="card">
                <div className="card-heading"><h2 className="card-title">Contact Setting</h2></div>
                <div className="card-body">
                    <Accordion title="Buyer Leads">
                        <div>
                        <div className="form-group"><CheckBox type="checkbox" name="checkall" id="checkall" value="checkall" label="Check all" /></div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="sms" id="sms" value="sms" label="SMS" /></div>
                                <div className="col-sm-3 form-group">
                                    <label>Role</label>
                                    <Select
                                        id="role"
                                        onChange={this.handleChange.bind(this, 'role')}
                                        options={role}
                                        name="role"
                                        placeholder="role"
                                        value={role.filter(({ value }) => value === this.state.role)}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>
                                <div className="col-sm-3"><InputField type="tel" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="emailcheck" id="emailcheck" value="emailcheck" label="Email" /></div>
                                <div className="col-sm-3 form-group">
                                    <label>Role</label>
                                    <Select
                                        id="role"
                                        onChange={this.handleChange.bind(this, 'role')}
                                        options={role}
                                        name="role"
                                        placeholder="role"
                                        value={role.filter(({ value }) => value === this.state.role)}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>
                                <div className="col-sm-3"><InputField type="email" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="dinesh.babu@ambak.com" label="Email" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="whatsapp" id="whatsapp" value="whatsapp" label="Whatsapp" /></div>
                                <div className="col-sm-3">
                                    <InputField type="tel" name="whatsappmobile" id="whatsappmobile" placeholder="Mobile Number" value="9876543210" label="Mobile No." />
                                </div>                                
                            </div> 
                            <div className="row form-group">
                                <div className="col-sm-3 col-sm-offset-2"><Button btnClass="btn btn-primary"> Save </Button></div>
                            </div>
                        </div>
                    </Accordion>
                    <Accordion title="Seller Leads">
                        <div>
                        <div className="form-group"><CheckBox type="checkbox" name="checkall" id="checkall" value="checkall" label="Check all" /></div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="sms" id="sms" value="sms" label="SMS" /></div>
                                <div className="col-sm-3 form-group">
                                    <label>Role</label>
                                    <Select
                                        id="role"
                                        onChange={this.handleChange.bind(this, 'role')}
                                        options={role}
                                        name="role"
                                        placeholder="role"
                                        value={role.filter(({ value }) => value === this.state.role)}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>
                                <div className="col-sm-3"><InputField type="tel" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="emailcheck" id="emailcheck" value="emailcheck" label="Email" /></div>
                                <div className="col-sm-3 form-group">
                                    <label>Role</label>
                                    <Select
                                        id="role"
                                        onChange={this.handleChange.bind(this, 'role')}
                                        options={role}
                                        name="role"
                                        placeholder="role"
                                        value={role.filter(({ value }) => value === this.state.role)}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>
                                <div className="col-sm-3"><InputField type="email" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="dinesh.babu@ambak.com" label="Email" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="whatsapp" id="whatsapp" value="whatsapp" label="Whatsapp" /></div>
                                <div className="col-sm-3">
                                    <InputField type="tel" name="whatsappmobile" id="whatsappmobile" placeholder="Mobile Number" value="9876543210" label="Mobile No." />
                                </div>                                
                            </div> 
                            <div className="row form-group">
                                <div className="col-sm-3 col-sm-offset-2"><Button btnClass="btn btn-primary"> Save </Button></div>
                            </div>                           
                        </div>
                    </Accordion>
                    <Accordion title="Finance Enquiry">
                        <div>
                        <div className="form-group"><CheckBox type="checkbox" name="checkall" id="checkall" value="checkall" label="Check all" /></div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="sms" id="sms" value="sms" label="SMS" /></div>
                                <div className="col-sm-3 form-group">
                                    <label>Role</label>
                                    <Select
                                        id="role"
                                        onChange={this.handleChange.bind(this, 'role')}
                                        options={role}
                                        name="role"
                                        placeholder="role"
                                        value={role.filter(({ value }) => value === this.state.role)}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>
                                <div className="col-sm-3"><InputField type="tel" name="dealership_contact_number" id="dealership_contact_number" placeholder="Enter Dealership Mobile" value="9876543210" label="Dealership Contact No.*" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="emailcheck" id="emailcheck" value="emailcheck" label="Email" /></div>
                                <div className="col-sm-3 form-group">
                                    <label>Role</label>
                                    <Select
                                        id="role"
                                        onChange={this.handleChange.bind(this, 'role')}
                                        options={role}
                                        name="role"
                                        placeholder="role"
                                        value={role.filter(({ value }) => value === this.state.role)}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>
                                <div className="col-sm-3"><InputField type="email" name="dealership_email" id="dealership_email" placeholder="Enter Dealership Email" value="dinesh.babu@ambak.com" label="Email" /></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25"><CheckBox type="checkbox" name="whatsapp" id="whatsapp" value="whatsapp" label="Whatsapp" /></div>
                                <div className="col-sm-3">
                                    <InputField type="tel" name="whatsappmobile" id="whatsappmobile" placeholder="Mobile Number" value="9876543210" label="Mobile No." />
                                </div>                                
                            </div> 
                            <div className="row form-group">
                                <div className="col-sm-3 col-sm-offset-2"><Button btnClass="btn btn-primary"> Save </Button></div>
                            </div>                           
                        </div>
                    </Accordion>
                </div>

                
                </div>
            </div>

        )
    }
}

export default EmployeeDetails